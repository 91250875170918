import React from "react";
import styles from "./Withdraw.module.scss";
import { Button } from "antd";

interface FailedProps {
  handleRetry: () => void;
  error: string;
}

function Failed({ error, handleRetry }: FailedProps) {
  return (
    <div className={styles.stepFour_container}>
      <div className={styles.verification_failed}>
        <div className={styles.box}></div>
        <h3>Transaction Failed</h3>
        <p>{error}</p>
      </div>

      <div className={styles.submit_button}>
        <Button type="default" onClick={handleRetry}>
          Try again
        </Button>
      </div>
    </div>
  );
}

export default Failed;
