import React, { useEffect, useState } from "react";
import { getData, postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import MastercardIcon from "../../../../assets/mastercard.svg";
import styles from "./RepayLoan.module.scss";
import VisaIcon from "../../../../assets/visa.svg";
import VerveIcon from "../../../../assets/verve.svg";
import { BsCheck2All } from "react-icons/bs";
import Loader from "../../../../components/Loader/Loader";
import AddCard from "../addCard/AddCard";
import { CardProp } from "../../profile/card/addCard/AddCardForm";
import { Button } from "antd";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { MixPanel } from "../../../../helpers/mixpanel";

interface IProps {
  updatePayStackStatus: () => void;
  handleRepaymentWithCard: () => void;
}

const AddNewCard = ({
  updatePayStackStatus,
  handleRepaymentWithCard,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);

  const [cards, setCards] = useState<CardProp[]>([]);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (AddNewCard.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: AddNewCard.tsx`, {
      page: "AddNewCard.tsx - (Web)",
    });
  }, []);

  const cardImage = (type: string) => {
    const cardType = type.toLowerCase().trim();
    return cardType?.includes("mastercard") ? (
      <img
        src={MastercardIcon}
        alt="logo"
        style={{ width: "40px", height: "40px" }}
      />
    ) : cardType?.includes("visa") ? (
      <img
        src={VisaIcon}
        alt="logo"
        style={{ width: "40px", height: "40px" }}
      />
    ) : cardType?.includes("verve") ? (
      <img
        src={VerveIcon}
        alt="logo"
        style={{ width: "40px", height: "40px" }}
      />
    ) : null;
  };

  const fetchAddedCards = async () => {
    setIsLoading(true);
    try {
      const response = await getData(
        `${apiEndpoints.fetchCards}?borrower_id=${
          userInfo?.customerId
        }&aggregator_id=${userInfo?.aggregatorId}&isWidget=${false}`,
      );
      setCards(response.data);
    } catch (error) {
      console.error(error.response?.data?.message ?? error.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (AddNewCard.tsx)",
        },
      });
    }
    setIsLoading(false);
  };

  async function makeCardDefault(item: CardProp) {
    setIsLoading(true);
    try {
      await postData(`${apiEndpoints.setDefaultCard}?isWidget=${false}`, {
        aggregator_id: userInfo.aggregatorId,
        borrower_id: userInfo.customerId,
        card_id: item.card_id,
      });
      await fetchAddedCards();
    } catch (error) {
      console.error(error.response?.data?.message ?? error.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (AddNewCard.tsx)",
        },
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchAddedCards();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <h4>Cards</h4>

      {cards?.length === 0 ? (
        <>
          <h4 className="text-center">No available card</h4>
        </>
      ) : (
        cards?.map((item) => (
          <div key={item.card_id}>
            <div className="card">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className=" mr-3">{cardImage(item.card_type)}</div>
                  <div>
                    <p className="font-weight-bold text-dark padding-none">
                      {`**** **** **** ${item.last_four_digits}`}
                      <small className="ml-2 text-success">
                        {item.is_default && (
                          <span
                            className="text-success"
                            style={{ marginTop: "-4rem" }}
                          >
                            <BsCheck2All size="1.3rem" />
                          </span>
                        )}
                      </small>
                    </p>
                    <small className="text-muted padding-none">
                      {`${item.expiry_month}/${item.expiry_year}`}
                    </small>
                  </div>
                </div>

                {!item.is_default && (
                  <p
                    className="cursor padding-none"
                    onClick={() => makeCardDefault(item)}
                  >
                    Set Default
                  </p>
                )}
              </div>
            </div>
            <br />
          </div>
        ))
      )}

      <div className="d-flex justify-content-end" style={{ marginTop: "10%" }}>
        <div className={styles.btns}>
          {cards.length >= 1 && (
            <Button type="primary" ghost onClick={handleRepaymentWithCard}>
              Pay with card
            </Button>
          )}
          <AddCard
            updatePayStackStatus={updatePayStackStatus}
            fetchAddedCards={fetchAddedCards}
          />
        </div>
      </div>
    </div>
  );
};

export default AddNewCard;
