import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const fetchSecurityQuestions = async () => {
  try {
    const res = await getData(apiEndpoints.customerSecurityQuestions);

    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export default function useFetchSecurityQuestions({
  token,
}: {
  token: string | null;
}) {
  return useQuery(
    [{ token }, "fetchSecurityQuestions"],
    fetchSecurityQuestions,
    {
      refetchOnWindowFocus: true,
      enabled: token ? true : false,
    },
  );
}
