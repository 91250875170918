import React, { useEffect } from "react";
import embedLogo from "../../../assets/web/Embed.svg";
import styles from "./InvalidPartner.module.scss";
import requestFailedSVG from "../../../assets/web/failed.svg";
import { useLocation } from "react-router-dom";
import { appInsights } from "../../../components/AppInsight/AppInsight";

function ErrorPage() {
  const location = useLocation();
  const urlExtension = location.pathname.split("/").filter(Boolean);
  const fullURL = `${window.location.origin}/${urlExtension[0]}`;

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (InvalidPartner.tsx)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className={styles.direct_debit__container}>
      <header className={styles.logo__header}>
        <img src={embedLogo} alt="" />
        <span className={styles.logo}>embed</span>
      </header>

      <section className={styles.body}>
        <div>
          <img src={requestFailedSVG} alt="" className={styles.failedSvg} />
          <p className={styles.message}>
            Sorry, <strong>{fullURL}</strong> is an Invalid partner URL
          </p>
          <p>Please enter a valid partner URL to proceed</p>
        </div>
      </section>
    </div>
  );
}

export default ErrorPage;
