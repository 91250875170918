import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import { Alert, Button, Form, Input, Select } from "antd";
import { nigerianStateData } from "../../../../../../helpers/nigerianStatesAndLga";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { postDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";
import { MixPanel } from "../../../../../../helpers/mixpanel";

interface ResidentialInformationProps {
  setError: Dispatch<SetStateAction<string>>;
  error: string;
  aggregator_id: number;
  customer_id: number;
  setCurrentStep?: Dispatch<SetStateAction<number>>;
  handleNext?: () => void;
  existingDirectDebitAccountData?: any;
  encryptedKey: string;
  isLoanRequest?: boolean;
  setShowResidentialInfo?: Dispatch<SetStateAction<boolean>>;
  setShowPin?: Dispatch<SetStateAction<boolean>>;
}

function ResidentialInformation({
  error,
  setError,
  aggregator_id,
  customer_id,
  setCurrentStep,
  handleNext,
  existingDirectDebitAccountData,
  encryptedKey,
  isLoanRequest,
  setShowResidentialInfo,
  setShowPin,
}: ResidentialInformationProps) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [filteredStateValues, setFilteredStateValues] = useState<any>([]);

  const widgetState = useSelector(
    (store: ReduxStoreModel) => store.widgetSettingsReducer,
  );
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer,
  );
  const selectedLoanDetails = useSelector(
    (store: ReduxStoreModel) => store.loanDetailsReducer,
  );

  useEffect(() => {
    if (isLoanRequest) {
      MixPanel.track(`Page View: LoanRequestResidentialInformation.tsx`, {
        page: "LoanRequestResidentialInformation.tsx - (Widget)",
      });
    } else {
      MixPanel.track(`Page View: ResidentialInformation.tsx`, {
        page: "ResidentialInformation.tsx - (Widget)",
      });
    }
  }, []);

  const handleSetSelectedLoan = (name: string) => {
    const response = nigerianStateData?.find((state) => state.name === name);
    setFilteredStateValues(response);
  };

  function handleBackNavigation() {
    const isDirectDebit = widgetState?.isDirectDebit;
    const hasBankAccount =
      existingDirectDebitAccountData?.bank_accounts?.length > 0;

    if (isLoanRequest && setCurrentStep) {
      if (isDirectDebit && hasBankAccount) {
        setCurrentStep(2);
      } else if (isDirectDebit && !hasBankAccount) {
        setCurrentStep(7);
      } else {
        setCurrentStep(4);
      }
    } else if (setShowResidentialInfo) {
      setShowResidentialInfo(false);
    }
  }

  const onSubmit = async (values: {
    state: string;
    lga: string;
    address: string;
  }) => {
    setError("");
    setIsLoading(true);

    const payload = {
      state: values.state,
      lga: values.lga,
      street_address: values.address,
      customer_id: customer_id,
    };
    try {
      const response = await postDataWithKey(
        `${apiEndpoints.createResidentialInformationWidget}?aggregator_id=${aggregator_id}`,
        payload,
        //@ts-ignore
        encryptedKey,
      );

      if (response.status) {
        if (handleNext) {
          handleNext();
        } else if (setShowPin && setShowResidentialInfo) {
          setShowResidentialInfo(false);

          setShowPin(true);
        }
      }
    } catch (error) {
      setError(error?.response?.data?.message ?? error?.message);
      if (isLoanRequest) {
        MixPanel.track("Get Loan", {
          distinct_id: `${borrowerDetails?.email}`,
          "loan amount": `${selectedLoanDetails.loan_amount}`,
          "loan tenor": `${selectedLoanDetails.loan_tenure}`,
          "product name": `${selectedLoanDetails.product_name}`,
          "loan status": `Failed: Error adding residential information`,
          step: "LoanRequestResidentialInformation",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  return (
    <>
      {error && (
        <div style={{ paddingBottom: "20px" }}>
          <Alert
            message={error}
            type="warning"
            showIcon
            closable
            onClose={() => {
              if (setError) {
                setError("");
              }
            }}
          />
        </div>
      )}

      <h4>Residential Information</h4>
      <p>Kindly provide us with your residential information</p>

      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => onSubmit(values)}
      >
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="state"
          label="State"
          rules={[{ required: true, message: "Required field" }]}
        >
          <Select
            placeholder="Select"
            allowClear
            onChange={(val) => {
              handleSetSelectedLoan(val);
            }}
          >
            {nigerianStateData &&
              nigerianStateData?.map((state) => (
                <Select.Option value={state.name} key={state.name}>
                  {state.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="lga"
          label="Local Government Area"
          rules={[{ required: true, message: "Required field" }]}
        >
          <Select placeholder="Select" allowClear>
            {filteredStateValues &&
              filteredStateValues?.localGovernments?.map((lga: any) => (
                <Select.Option value={lga} key={lga}>
                  {lga}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="address"
          label="Enter your street address"
          rules={[
            { required: true, message: "Please enter your street address!" },
          ]}
        >
          <Input />
        </Form.Item>

        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "10%" }}
        >
          <div className="d-flex align-items-center">
            <p
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={handleBackNavigation}
            >
              Go Back
            </p>
            <Button
              type="primary"
              htmlType="submit"
              style={{ borderRadius: "5px", height: "45px" }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default ResidentialInformation;
