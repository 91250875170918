import React, { ReactNode } from "react";
import styles from "./WidgetNavCard.module.scss";

type WidgetNavCardProps = {
  icon: ReactNode;
  title: string;
  subtitle: string;
  onClick: () => void;
  disabled?: boolean;
};
export default function WidgetNavCard({
  icon,
  title,
  subtitle,
  onClick,
  disabled,
}: WidgetNavCardProps) {
  return (
    <div
      className={`${styles.widgetNavCard} ${disabled && "cursor-disabled"}`}
      onClick={onClick}
    >
      {icon && <div className={styles.iconArea}>{icon}</div>}
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
      <div className={styles.actionIcon}>
        <i className="ri-arrow-right-line"></i>
      </div>
    </div>
  );
}
