import React, { useEffect, Dispatch, SetStateAction, useState } from "react";
import styles from "./LoanHistoryDetails.module.scss";
import closeIcon from "../../../../assets/web/close-square.svg";
import arrowBack from "../../../../assets/web/arrow-left.svg";
import { LoanHistoryDetailProps } from "../../../../interfaces/loan";
import moment from "moment";
import { Button } from "antd";
import { useLocation } from "react-router-dom";
import Loader from "../../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  updateLoanDetailsAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { LoanOfferProps } from "../../features/newLoanOffer/NewLoanOffer";
import { useFetchPendingLoanOffer } from "../../../../custom-hooks/useFetchPendingLoanOffer";

interface LoanHistoryDetailsProps {
  loanDetails: LoanHistoryDetailProps;
  isLoanDetailsLoading: boolean;
  isRefetchingRepaymentSchedule: () => void;
  setCurrentLoanOptionStep: Dispatch<SetStateAction<number>>;
}

enum Status {
  Active = "active",
  Repaid = "repaid",
  Approved = "approved",
  Rejected = "rejected",
  Overdue = "overdue",
  AwaitingApproval = "awaiting approval",
  SubmittedAndAwaitingApproval = "submitted_and_awaiting_approval",
  WithdrawnByClient = "withdrawnByClient",
  Closed = "closed",
  WrittenOff = "writtenOff",
  Overpaid = "overpaid",
}

function LoanHistoryDetails({
  loanDetails,
  isLoanDetailsLoading,
  isRefetchingRepaymentSchedule,
  setCurrentLoanOptionStep,
}: LoanHistoryDetailsProps) {
  const [mostRecentLoanOffer, setMostRecentLoanOffer] =
    useState<LoanOfferProps>();
  const location = useLocation();
  const dispatch = useDispatch();
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer,
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (LoanHistoryDetails.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const { data: pendingLoanOffer, isLoading: isLoadingPendingLoanOffer } =
    useFetchPendingLoanOffer({
      customer_id: userInfo?.customerId,
    });

  useEffect(() => {
    if (!isLoadingPendingLoanOffer && pendingLoanOffer) {
      const recentLoanOffer = pendingLoanOffer[pendingLoanOffer?.length - 1];
      setMostRecentLoanOffer(recentLoanOffer);
    }
  }, [pendingLoanOffer, isLoadingPendingLoanOffer]);

  const handleRepaymentSchedule = () => {
    dispatch(
      updateLoanDetailsAction({
        loan_effective_date: loanDetails?.loan_effective_date,
        status: loanDetails?.status,
        loanId: loanDetails?.loan_id,
      }),
    );
    isRefetchingRepaymentSchedule();
    dispatch(updateWebSettingsAction({ showLoanDetails: false }));
    dispatch(updateWebSettingsAction({ showRepaymentSchedule: true }));
  };

  const handleRepayment = () => {
    dispatch(
      updateLoanDetailsAction({
        loan_effective_date: loanDetails?.loan_effective_date,
        status: loanDetails?.status,
        loanId: loanDetails?.loan_id,
      }),
    );
    dispatch(updateWebSettingsAction({ showLoanDetails: false }));
    dispatch(updateWebSettingsAction({ showLoanRepaymentModal: true }));
  };

  const handleShowRepayments = () => {
    dispatch(
      updateLoanDetailsAction({
        loan_effective_date: loanDetails?.loan_effective_date,
        status: loanDetails?.status,
        loanId: loanDetails?.loan_id,
      }),
    );

    dispatch(updateWebSettingsAction({ showLoanDetails: false }));
    dispatch(updateWebSettingsAction({ showLoanRepayments: true }));
  };

  const goBack = () => {
    dispatch(updateWebSettingsAction({ showLoanDetails: false }));
    dispatch(updateWebSettingsAction({ showLoanOptions: true }));

    if (webStateData?.showLoanOptions) {
      setCurrentLoanOptionStep(1);
    }
  };

  const handleClose = () => {
    document.body.style.overflow = "auto";

    dispatch(updateLoanDetailsAction({ loan_ref: "" }));

    dispatch(updateWebSettingsAction({ showLoanDetails: false }));

    setCurrentLoanOptionStep(0);
  };

  const getStatusStyle = (status: string | undefined) => {
    switch (status?.toLowerCase()) {
      case Status.Active:
      case Status.Approved:
      case Status.Repaid:
        return styles.flagActive;
      case Status.AwaitingApproval:
      case Status.SubmittedAndAwaitingApproval:
        return styles.flagPending;
      case Status.Overdue:
      case Status.Rejected:
        return styles.flagRejected;
      default:
        return styles.flagStale;
    }
  };

  function viewNewOfferHandler() {
    document.body.style.overflow = "auto";

    dispatch(
      updateWebSettingsAction({
        showNewLoanOffer: true,
        showLoanDetails: false,
      }),
    );
  }

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_container}>
        {location.pathname === `/${userInfo?.aggregator_name}` && (
          <img
            src={arrowBack}
            alt=""
            className={styles.back_button}
            onClick={goBack}
          />
        )}

        {isLoanDetailsLoading || !loanDetails ? (
          <div className={styles.isLoading_container}>
            <Loader message="Loading loan data..." />
          </div>
        ) : (
          <>
            <header className={styles.modal_header}>
              <div className={styles.amountAndFlag_outer_container}>
                {pendingLoanOffer?.length > 0 &&
                mostRecentLoanOffer?.requested_loan_id ===
                  loanDetails?.loan_id ? (
                  <button
                    className={styles.new_offer}
                    onClick={viewNewOfferHandler}
                  >
                    View new offer
                  </button>
                ) : null}
                <div className={styles.amountAndFlag}>
                  <span className={styles.loan_amount}>
                    ₦{loanDetails?.loan_amount.toLocaleString()}
                  </span>
                </div>
                <small className={styles.title}>Loan Amount</small>
              </div>
              <Button
                className={styles.repayment_btn}
                type="text"
                onClick={handleRepaymentSchedule}
                disabled={loanDetails?.status.toLowerCase() === Status.Rejected}
              >
                View repayment schedule
              </Button>
            </header>

            <table>
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>{loanDetails?.loan_id}</td>
                </tr>
                <tr>
                  <td>Loan Status</td>
                  <td>
                    <span
                      className={`
                        ${getStatusStyle(loanDetails?.status)}
                      `}
                    >
                      {loanDetails?.status.toLowerCase() ===
                      Status.SubmittedAndAwaitingApproval
                        ? "Awaiting approval"
                        : loanDetails?.status}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Request Date</td>
                  <td>
                    {moment(loanDetails?.pub_date).format("DD MMM, YYYY")}
                  </td>
                </tr>
                <tr>
                  <td>Tenor</td>
                  <td>{loanDetails?.loan_tenure} Days</td>
                </tr>
                <tr>
                  <td>Reference Number</td>
                  <td>{loanDetails?.loan_ref}</td>
                </tr>
                {/* <tr>
                  <td>Disbursement Status</td>
                  <td>
                    <span
                      className={
                        loanDetails?.status === "Approved"
                          ? styles.active
                          : loanDetails?.status === "Rejected"
                          ? styles.overdue
                          : loanDetails?.status ===
                            "Submitted_and_awaiting_approval"
                          ? styles.awaiting
                          : ""
                      }
                    >
                      {loanDetails?.status === "Submitted_and_awaiting_approval"
                        ? "Awaiting approval"
                        : loanDetails.status}
                    </span>
                  </td>
                </tr> */}
                <tr>
                  <td>Approval Date</td>
                  <td>
                    {loanDetails?.loan_effective_date
                      ? moment(loanDetails?.loan_effective_date).format(
                          "D/MM/YYYY, h:mm a",
                        )
                      : "Not Approved"}
                  </td>
                </tr>
                <tr>
                  <td>Maturity Date</td>
                  <td style={{ color: "red" }}>
                    {moment(loanDetails?.loan_due_date).format("DD MMM, YYYY")}
                  </td>
                </tr>
                <tr>
                  <td>Interest</td>
                  <td>₦{loanDetails?.loan_interest_amount.toLocaleString()}</td>
                </tr>
                <tr>
                  <td>Amount to Repay</td>
                  <td>
                    ₦{loanDetails?.next_repayment_amount.toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <td>Outstanding</td>
                  <td>₦{loanDetails?.loan_outstanding.toLocaleString()}</td>
                </tr>
                <tr>
                  <td>Amount Repaid</td>
                  <td>₦{loanDetails?.total_loan_repaid.toLocaleString()}</td>
                </tr>
                <tr>
                  <td>Total Amount</td>
                  <td>
                    ₦{Number(loanDetails?.total_loan_amount).toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className={styles.submit_button}>
              <Button
                type="ghost"
                htmlType="submit"
                disabled={
                  loanDetails?.status.toLowerCase() ===
                    Status.SubmittedAndAwaitingApproval ||
                  loanDetails?.status.toLowerCase() ===
                    Status.WithdrawnByClient ||
                  loanDetails?.status.toLowerCase() === Status.Rejected
                }
                onClick={handleShowRepayments}
              >
                View Repayments
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  loanDetails?.status.toLowerCase() ===
                    Status.SubmittedAndAwaitingApproval ||
                  loanDetails?.status.toLowerCase() ===
                    Status.WithdrawnByClient ||
                  loanDetails?.status.toLowerCase() === Status.Rejected ||
                  loanDetails?.status.toLowerCase() === Status.Closed ||
                  loanDetails?.status.toLowerCase() === Status.WrittenOff ||
                  loanDetails?.status.toLowerCase() === Status.Overpaid
                }
                onClick={handleRepayment}
              >
                Repay Loan
              </Button>
            </div>
          </>
        )}

        <img
          src={closeIcon}
          alt=""
          className={styles.cancel_button}
          onClick={handleClose}
        />
      </div>
    </>
  );
}

export default LoanHistoryDetails;
