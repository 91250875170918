import React, { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Form, Input, message } from "antd";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import { postDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";

interface ILoanRepaymentViaMpesa {
  encryptedKeyObject: {
    encryptedKey: string;
  };
}

type TRepaymentPayload = {
  loan_id: number;
  repayment_amount: number;
  phone_number: string;
};

type TLocation = {
  loan_id: number;
};

const LoanRepaymentViaMpesa = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const widgetSettings = useSelector(
    (state: ReduxStoreModel) => state.widgetSettingsReducer
  );
  const { encryptedKey } = widgetSettings;
  const history = useHistory();
  const location = useLocation<TLocation>();

  const {
    state: { loan_id },
  } = location;

  const [form] = Form.useForm();

  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRepaymentMpesa.tsx",
      isLoggedIn: true,
    });
  }, []);

  const handleRepayViaMpesa = async (data: TRepaymentPayload) => {
    setIsLoading(true);

    try {
      const payload = {
        loan_id: loan_id,
        repayment_amount: Number(data?.repayment_amount),
        phone_number: data?.phone_number,
      };
      const response = await postDataWithKey(
        apiEndpoints.postLoanRepaymentViaMpesa,
        payload,
        //@ts-ignore
        encryptedKey
      );

      if (response?.status_code === 200) {
        message.success(response?.message);
        setTimeout(() => {
          history.push(ROUTES.widgetLoanHistory);
        }, 3000);
      } else {
        message.error(response?.message);
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "LoanRepaymentMpesa.tsx" },
      });
      message.error(error?.message);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div onClick={() => history.goBack()} className="cursor">
        <IoMdArrowRoundBack size="26px" />
      </div>
      <br />
      <h4>Pay Via MPesa</h4>
      <Form layout="vertical" form={form} onFinish={handleRepayViaMpesa}>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="repayment_amount"
          label="Amount"
          rules={[{ required: true, message: "Enter Amount" }]}
        >
          <Input placeholder="Amount" />
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="phone_number"
          label="Phone Number"
          rules={[{ required: true, message: "Enter Phone number" }]}
        >
          <Input placeholder="Phone Number" />
        </Form.Item>

        <button
          type="submit"
          value="submit"
          className="btn btn-sm btn-primary btn-block mt-4"
        >
          {isLoading ? <span className="spinner-border text-white" /> : "Pay"}
        </button>
      </Form>
    </div>
  );
};

export default LoanRepaymentViaMpesa;
