import React, { useEffect, useState } from "react";
import { Alert, Button } from "antd";
import styles from "./Password.module.scss";
import CustomInputField from "../../../../components/CustomHTMLElements/EmbedCustomInputField";
import { postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import { useLocation } from "react-router-dom";
import { PasswordSection } from "./Password";
import { useDispatch, useSelector } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

interface ChangePasswordProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<PasswordSection>>;
}

const schema = yup.object().shape({
  oldPassword: yupValidators.oldPassword,
  newPassword: yupValidators.newPassword,
  confirmNewPassword: yupValidators.confirmNewPassword,
});

interface FormData {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

function ChangePassword({ setCurrentStep }: ChangePasswordProps) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const location = useLocation();
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);

  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer
  );
  const urlExtension = location.pathname.split("/").filter(Boolean);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Profile  - (ChangePassword.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const onSubmit = async ({
    oldPassword,
    newPassword,
    confirmNewPassword,
  }: FormData) => {
    const reqBody = {
      old_password: oldPassword,
      password1: newPassword,
      password2: confirmNewPassword,
    };
    setIsLoading(true);
    if (oldPassword === newPassword) {
      dispatch(
        updateWebSettingsAction({
          errorMsg: "Please choose a different password",
        })
      );
      setIsLoading(false);

      setTimeout(() => {
        dispatch(updateWebSettingsAction({ errorMsg: "" }));
      }, 3000);

      return;
    }
    try {
      await postData(apiEndpoints.updatePassword, reqBody);
      setCurrentStep(PasswordSection.SuccessMessage);

      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      }, 3000);
    } catch (error) {
      dispatch(
        updateWebSettingsAction({
          errorMsg: error.response?.data?.message ?? error.message,
        })
      );
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Profile  - (ChangePassword.tsx)",
        },
      });
    } finally {
      setIsLoading(false);

      setTimeout(() => {
        dispatch(updateWebSettingsAction({ errorMsg: "" }));
      }, 3000);
    }
  };

  return (
    <div className={styles.stepThree_container}>
      {webStateData.errorMsg && urlExtension[1] !== "profile" && (
        <div style={{ paddingTop: "20px" }}>
          <Alert
            message={webStateData.errorMsg}
            type="error"
            showIcon
            closable
            onClose={() => dispatch(updateWebSettingsAction({ errorMsg: "" }))}
          />
        </div>
      )}
      <header className={`${styles.stepOne_header}`}>
        <h3>Change Password</h3>
        <p>
          Must be 8 or more characters, contain at least a number and a special
          character
        </p>
      </header>

      <form
        className={`${styles.stepOne_form} ${
          location.pathname === `/${userInfo?.aggregator_name}` &&
          styles.alignLeft
        }`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <CustomInputField
          type="password"
          name="oldPassword"
          placeholder="********"
          label="Old Password"
          hasActionButton={true}
          reference={register}
          errors={errors.oldPassword}
        />

        <CustomInputField
          type="password"
          placeholder="********"
          label="New Password"
          hasActionButton={true}
          name="newPassword"
          reference={register}
          errors={errors.newPassword}
        />

        <CustomInputField
          type="password"
          placeholder="********"
          label="Confirm Password"
          hasActionButton={true}
          name="confirmNewPassword"
          reference={register}
          errors={errors.confirmNewPassword}
        />

        <div className={styles.submit__btn}>
          <Button type="primary" htmlType="submit" disabled={isLoading}>
            {isLoading ? "Updating password..." : "Update"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
