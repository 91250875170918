import React, { useEffect, useRef, useState } from "react";
import { PaystackConsumer } from "react-paystack";
import { postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { paystackConfig } from "../../../../helpers/thirdPartyConfig";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { Button } from "antd";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

const AddCard = ({ updatePayStackStatus, fetchAddedCards }) => {
  const [isLoading, setIsLoading] = useState(false);
  const paystackRef = useRef();
  const openPaystackWidget = () => {
    paystackRef?.current?.click();
  };
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "AddCard.tsx",
      isLoggedIn: true,
    });
  }, []);

  const onPaystackSuccess = async (data) => {
    setIsLoading(true);
    try {
      await postData(`${apiEndpoints.addCard}?isWidget=${false}`, {
        aggregator_id: userInfo.aggregatorId,
        borrower_id: userInfo?.customerId,
        paystack_reference: data?.reference,
        paystack_transaction: data?.trans,
      });
      fetchAddedCards();
      dispatch(updateWebSettingsAction({ errorMsg: "" }));
    } catch (error) {
      dispatch(
        updateWebSettingsAction({
          errorMsg: error.response?.data?.message ?? error.message,
        })
      );
    } finally {
      setIsLoading(false);

      setTimeout(() => {
        dispatch(updateWebSettingsAction({ errorMsg: "" }));
      }, 3000);
    }
  };

  const onPaystackClose = () => {
    setIsLoading(false);
    // window.location.reload();
  };

  const paystackComponentProps = {
    ...paystackConfig({
      email: userInfo?.email,
      amount: 5000, // 50 naira is 5000 kobo
    }),
    onSuccess: (reference) => {
      onPaystackSuccess(reference);
    },
    onClose: onPaystackClose,
  };
  return (
    <div>
      <Button
        type="primary"
        disabled={isLoading}
        // className={`${styles.btn} ${styles.btn_primary}`}
        onClick={() => {
          updatePayStackStatus();
          openPaystackWidget();
        }}
      >
        {isLoading ? (
          <span className="spinner-border text-white" />
        ) : (
          "Add Card"
        )}
      </Button>

      <PaystackConsumer {...paystackComponentProps}>
        {({ initializePayment }) => (
          <button
            ref={paystackRef}
            onClick={() => {
              setIsLoading(true);
              initializePayment(onPaystackSuccess, onPaystackClose);
            }}
            className="d-none"
          />
        )}
      </PaystackConsumer>
    </div>
  );
};

export default AddCard;
