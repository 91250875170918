import React from "react";
import styles from "./Withdraw.module.scss";
import { Button } from "antd";

function Success({ handleCompletedTransaction }: any) {
  return (
    <div className={styles.stepThree_container}>
      <div className={styles.verification_success}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="75"
          height="75"
          viewBox="0 0 75 75"
          fill="none"
        >
          <path
            d="M37.4856 0C58.1739 0 74.9712 16.7973 74.9712 37.4856C74.9712 58.1739 58.1739 74.9712 37.4856 74.9712C16.7973 74.9712 0 58.1739 0 37.4856C0 16.7973 16.7973 0 37.4856 0Z"
            fill="#404040"
          />
          <path
            d="M37.4637 3.54529C56.1953 3.54529 71.404 18.7539 71.404 37.4856C71.404 56.2172 56.1953 71.4258 37.4637 71.4258C18.7321 71.4258 3.52344 56.2172 3.52344 37.4856C3.52344 18.7539 18.7321 3.54529 37.4637 3.54529Z"
            fill="white"
          />
          <path
            d="M22.1348 38.7302L31.3106 47.6975L52.7905 26.2177"
            stroke="#00C83C"
            strokeWidth="3.75377"
          />
        </svg>
        <h3>Transaction Complete</h3>
      </div>

      <div className={styles.submit_button}>
        <Button type="primary" onClick={handleCompletedTransaction}>
          Done
        </Button>
      </div>
    </div>
  );
}

export default Success;
