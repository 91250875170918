import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { formatCurrency } from "../../../../helpers/formatCurrency";
import withdraw from "../../../../assets/withdrawal.svg";
import deposit from "../../../../assets/deposit.svg";
import moment from "moment";
import { getDataWithKey } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { errorHandler, errorToast } from "../../../../helpers/errorHandler";
import Loader from "../../../Loader/Loader";
import { ROUTES } from "../../../../helpers/ROUTES";
import { appInsights } from "../../../AppInsight/AppInsight";
import { IFetchWallet } from "../Topup/TopUpComponent";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";

type Transaction = {
  transactionId: string;
  transactionType: string;
  amount: string;
  transactionDate: string;
  transactionStatus: string;
  transactionRemarks: string;
  transactionCharge: number;
};

const TransactionsComponent = () => {
  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer
  );
  const { customer_id, aggregator_id } = borrowerDetails;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Transactions.component.tsx",
      isLoggedIn: true,
    });
  }, []);

  async function fetchTransactions() {
    setIsLoading(true);
    try {
      let response = await getDataWithKey(
        `${apiEndpoints.getWallet}?borrower_id=${customer_id}&aggregator_id=${aggregator_id}`
      );
      response = await getDataWithKey(
        `${apiEndpoints.getTransactions}?account_number=${response.data?.accountNumber}&aggregator_id=${aggregator_id}`
      );
      setTransactions(response.data?.transactions);
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: TransactionsComponent },
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (customer_id && aggregator_id) {
      fetchTransactions();
    }
  }, [customer_id, aggregator_id]);
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }
  return (
    <div
      style={{
        maxHeight: "40rem",
      }}
    >
      <div
        onClick={() => history.push(ROUTES.widgetDashboard)}
        className="cursor"
      >
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>Transactions</h4>
      <br />
      {transactions?.length === 0 ? (
        <p className="text-center">No transaction available</p>
      ) : (
        transactions?.map((item) => (
          <div key={item?.transactionId}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <img
                  src={`${
                    item?.transactionType?.toLowerCase() === "debit"
                      ? withdraw
                      : deposit
                  }`}
                  alt=""
                  className="img-fluid mr-2"
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "contain",
                  }}
                />
                <div>
                  <div>
                    <p className="padding-none">{item?.transactionType}</p>

                    <p className="padding-none text-muted">
                      {moment(item?.transactionDate).format(
                        "D/MM/YYYY, h:mm a"
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div
                  className={`${
                    item?.transactionType?.toLowerCase() === "debit"
                      ? "text-danger"
                      : "text-success"
                  }`}
                >
                  {formatCurrency(Number(item?.amount || 0))}
                </div>
                <div>
                  <small
                    className={`padding-none ${
                      item?.transactionStatus?.toLowerCase() === "failed"
                        ? "text-danger"
                        : "text-success"
                    }`}
                  >
                    {item?.transactionStatus}
                  </small>
                </div>
              </div>
            </div>
            <p style={{ marginLeft: "3rem" }}>
              Narration : -{" "}
              <span className="text-muted">{item?.transactionRemarks}</span>{" "}
            </p>

            <br />
            <hr />
          </div>
        ))
      )}
    </div>
  );
};

export default TransactionsComponent;
