import React, { useState, useEffect, RefObject } from "react";
import styles from "./LoanHistoryCarousel.module.scss";
import adv from "../../../../assets/web/a.svg";
import { IoIosArrowForward } from "react-icons/io";
import carouselRight from "../../../../assets/web/carousel-arrow-right.svg";
import carouselLeft from "../../../../assets/web/carousel-arrow-left.svg";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  updateLoanDetailsAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";
import { appInsights } from '../../../../components/AppInsight/AppInsight';

interface LoanHistoryCarouselProps {
  RecentLoans: any;
  scrollToTransactions: (elementRef: RefObject<HTMLDivElement>) => void;
  transactionHistoryRef: RefObject<HTMLDivElement>;
  isRefetchingLoanDetails: () => void;
}

enum Status {
  Active = "active",
  Repaid = "repaid",
  Approved = "approved",
  Rejected = "rejected",
  Overdue = "overdue",
  AwaitingApproval = "awaiting approval",
  SubmittedAndAwaitingApproval = "submitted_and_awaiting_approval",
  WithdrawnByClient = "withdrawnByClient",
  Closed = "closed",
  WrittenOff = "writtenOff",
  Overpaid = "overpaid",
}

const LoanHistoryCarousel: React.FC<LoanHistoryCarouselProps> = ({
  RecentLoans,
  scrollToTransactions,
  transactionHistoryRef,
  isRefetchingLoanDetails,
}) => {
  const [startIndex, setStartIndex] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (LoanHistoryCarousel.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleNext = () => {
    setStartIndex((prevIndex) => prevIndex + 3);
  };

  const handlePrev = () => {
    setStartIndex((prevIndex) => Math.max(0, prevIndex - 3));
  };

  const visibleLoans = RecentLoans?.slice(startIndex, startIndex + 3);

  const selectLoan = (id: number) => {
    document.body.style.overflow = "hidden";

    RecentLoans?.find((loan: any) =>
      loan?.id === id
        ? dispatch(updateLoanDetailsAction({ loan_ref: loan.loanRef }))
        : ""
    );

    setTimeout(() => {
      isRefetchingLoanDetails();
      dispatch(updateWebSettingsAction({ showLoanDetails: true }));
    }, 0);
  };

  const getStatusStyle = (status: string | undefined) => {
    switch (status?.toLowerCase()) {
      case Status.Active:
      case Status.Approved:
      case Status.Repaid:
        return styles.flagActive;
      case Status.AwaitingApproval:
      case Status.SubmittedAndAwaitingApproval:
        return styles.flagPending;
      case Status.Overdue:
      case Status.Rejected:
        return styles.flagRejected;
      default:
        return styles.flagStale;
    }
  };

  const checkStatus = (status: string | undefined) => {
    switch (status?.toLowerCase()) {
      case Status.SubmittedAndAwaitingApproval:
      case Status.Rejected:
      case Status.WithdrawnByClient:
        return "";

      default:
        break;
    }
  };

  // console.log("Loan History:", loanHistory)

  return (
    <div className={styles.loanHistory_container}>
      <header className={styles.transaction_header}>
        <h1 className={styles.title}>Recent Transactions</h1>
        <button
          className={styles.button}
          onClick={() => scrollToTransactions(transactionHistoryRef)}
        >
          See all
        </button>
      </header>
      <div className={styles.loan_history_carousel}>
        {startIndex !== 0 && (
          <button onClick={handlePrev} disabled={startIndex === 0}>
            <img src={carouselLeft} alt="" className={styles.letf_img} />
          </button>
        )}
        <div className={styles.carousel_container}>
          {visibleLoans?.map((loan: any) => (
            <div
              key={loan.id}
              className={styles.loan_card}
              onClick={() => selectLoan(loan.id)}
            >
              <div className={styles.loanDetailsAndFlag}>
                <div className={styles.logoAndAmount}>
                  <span className={styles.icon_container}>
                    <img src={adv} alt="" />
                  </span>
                  <div className={styles.amountAndDate}>
                    <span>₦{loan.loanAmount.toLocaleString()}</span>
                    {checkStatus(loan?.status) ?? (
                      <span className={styles.date}>
                        {moment(loan.loanMaturityDate).format("D/MM/YYYY")}
                      </span>
                    )}
                  </div>
                </div>

                <span className={getStatusStyle(loan?.status)}>
                  {loan?.status.toLowerCase() ===
                  Status.SubmittedAndAwaitingApproval
                    ? "Awaiting approval"
                    : loan?.status}
                </span>
              </div>

              <IoIosArrowForward />
            </div>
          ))}
        </div>

        {startIndex === RecentLoans?.length ||
        startIndex + 1 === RecentLoans?.length ||
        startIndex + 2 === RecentLoans?.length ||
        startIndex + 3 === RecentLoans?.length ? (
          ""
        ) : (
          <button onClick={handleNext}>
            <img src={carouselRight} alt="" />
          </button>
        )}
      </div>
    </div>
  );
};

export default LoanHistoryCarousel;
