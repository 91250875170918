import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BiRightArrowAlt } from "react-icons/bi";
import { BsCreditCardFill } from "react-icons/bs";
import { IoWalletOutline } from "react-icons/io5";
import { MdOutlinePayments } from "react-icons/md";
import { RiFileCopyFill } from "react-icons/ri";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { formatCurrency } from "../../../../../../helpers/formatCurrency";
import { IoMdArrowRoundBack } from "react-icons/io";
import {
  getDataWithKey,
  postDataWithKey,
} from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import {
  errorHandler,
  errorToast,
} from "../../../../../../helpers/errorHandler";
import NewLoader from "../../../components/NewLoader";
import { SuccessComponent } from "../../../SuccessComponent";
import styles from "../../../../WidgetFlow.module.scss";
import { deviceType } from "react-device-detect";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import addCircle from "../../../../../../assets/web/add-circle.svg";
import AppCard from "../../../components/AppCard";
import { useFetchWidgetCards } from "../../../../../../custom-hooks/useFetchCard";
import { useWidgetDirectDebitsApprovedAcct } from "../../../../../../custom-hooks/useDirectDebitsApprovedAcct";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";
import { CardProp } from "../../../../../../web/components/profile/card/addCard/AddCardForm";
import { WalletDetails } from "../../../../../../interfaces/wallet";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";
import { MixPanel } from "../../../../../../helpers/mixpanel";
// import { IBorrowerData } from "../../../../../../interfaces/borrower";
type TLocation = {
  state: {
    loan_id: number;
  };
};

const LoanRepaymentMethods = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [success, setSuccess] = useState("");
  const [walletDetails, setWalletDetails] = useState<WalletDetails | null>();
  const [debitCard, setDebitCard] = useState<CardProp[]>([]);
  // const [kenyaBank, setKenyaBank] = useState({});
  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );
  const widgetSettings = useSelector(
    (state: ReduxStoreModel) => state.widgetSettingsReducer,
  );
  const { encryptedKey } = widgetSettings;
  const { aggregator_id, customer_id, currency, country_code } =
    borrowerDetails;
  let location = useLocation<any>();
  const history = useHistory();
  const data = localStorage.getItem("userData");
  const userInfo = data && JSON.parse(data);

  // const paystackRef = useRef();
  const {
    state: { loan_id },
  } = location as TLocation;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const { data: value } = useFetchWidgetCards({
    borrower_id: customer_id ? customer_id : 0,
    aggregator_id: aggregator_id ? aggregator_id : 0,
  });

  const {
    data: ApprovedDirectDebitData,
    isLoading: ApprovedDirectDebitLoading,
  } = useWidgetDirectDebitsApprovedAcct({
    loanRef: location?.state?.loan_ref,
    clientRequest: true,
    isWidget: true,
    aggregator_id: aggregator_id ? aggregator_id : 0,
  });

  useEffect(() => {
    // @ts-ignore
    setDebitCard(value);
  }, [value]);

  async function handleRepaymentWithCard() {
    if (debitCard?.length === 0) {
      history.push({
        pathname: ROUTES.widgetLoanRepaymentViaNewCard,
        state: {
          loan_id: location?.state?.loan_id,
          amount: location?.state?.amount,
        },
      });
    } else {
      setIsLoading(true);
      try {
        const response = await postDataWithKey(
          apiEndpoints.makeRepayment,
          {
            aggregator_id: aggregator_id,
            borrower_id: customer_id,
            borrower_repayment_mode: "Card",
            loan_id: location?.state?.loan_id,
            amount: Number(location?.state?.amount),
          },
          // @ts-ignore
          encryptedKey,
        );

        if (response.status) {
          setDone(true);
          setSuccess(response.message);

          MixPanel.track("Repay loan", {
            distinct_id: `${userInfo?.email}`,
            "amount paid": `${Number(location?.state?.amount)}`,
            "repayment mode": `Card`,
            "repayment status": `Repaid`,
          });
        }
      } catch (error) {
        errorToast(errorHandler(error));
        appInsights.trackException({
          exception: error,
          properties: { fileName: LoanRepaymentMethods },
        });

        MixPanel.track("Repay loan", {
          distinct_id: `${userInfo?.email}`,
          "amount paid": `${Number(location?.state?.amount)}`,
          "repayment mode": `Card`,
          "repayment status": `Failed: ${errorHandler(error)}`,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function fetchWallet() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getWallet}?borrower_id=${customer_id}&aggregator_id=${aggregator_id}`,
      );
      setWalletDetails(response.data);
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRepaymentMethods },
      });
    } finally {
      setIsLoading(false);
    }
  }

  const fetchCollectionAccounts = async () => {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getCollectionAccounts}?AggregatorId=${
          aggregator_id
        }&Currency=${"KES"}&Type=1`,
      );
      setWalletDetails(response.data[0]);
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRepaymentMethods },
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (customer_id && aggregator_id) {
      if (currency !== "NGN") {
        fetchCollectionAccounts().finally();
      } else {
        fetchWallet().finally();
      }
    }
  }, [customer_id, aggregator_id]);

  if (isLoading || ApprovedDirectDebitLoading) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  const nigeriaPayment = () => {
    return (
      <>
        <div
          onClick={() => history.goBack()}
          className="cursor"
          style={{ marginBottom: "10px" }}
        >
          <IoMdArrowRoundBack className="cursor__back" />
        </div>
        <div
          className="card cursor"
          style={{ backgroundColor: "#713FFF", height: "108px" }}
          onClick={() =>
            history.push({
              pathname: ROUTES.widgetLoanRepaymentViaWallet,
              state: {
                loan_id: location?.state?.loan_id,
                amount: location?.state?.amount,
              },
            })
          }
        >
          <div className="card-body d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div
                className={`${
                  deviceType === "mobile bg-primary-dark"
                    ? styles.iconMobile
                    : styles.icon
                } icon__primary mr-3`}
                style={{
                  borderRadius: "10px",
                }}
              >
                <div>
                  <IoWalletOutline
                    className="text-white text-center"
                    size="20px"
                  />
                </div>
              </div>
              <div>
                <p className="font-weight-bold text-white padding-none">
                  Pay with wallet
                </p>
                <small className="text-white padding-none">
                  <span>&#8358;</span>{" "}
                  {formatCurrency(walletDetails?.availableBalance || 0)}
                </small>
              </div>
            </div>

            <BiRightArrowAlt className="text-white" size="20px" />
          </div>
        </div>

        <br />

        {ApprovedDirectDebitData?.length > 0 && (
          <div
            className="card cursor"
            style={{ height: "108px" }}
            onClick={() =>
              history.push({
                pathname: ROUTES.widgetLoanRepaymentViaDirectDebit,
                state: {
                  loan_id: location?.state?.loan_id,
                  amount: location?.state?.amount,
                  loan_ref: location?.state?.loan_ref,
                },
              })
            }
          >
            <div className="card-body d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div
                  className="icon icon__primary mr-3"
                  style={{ backgroundColor: "#9DA9B3", borderRadius: "10px" }}
                >
                  <div style={{ marginTop: "-2px" }}>
                    <MdOutlinePayments className="text-white" size="20px" />
                  </div>
                </div>
                <div>
                  <p className="font-weight-bold text-primary padding-none">
                    Pay with Direct Debit
                  </p>
                  {/*<small className=" padding-none">******6789</small>*/}
                </div>
              </div>

              <BiRightArrowAlt className="" size="20px" />
            </div>
          </div>
        )}

        <br />

        <div
          className="card cursor"
          style={{ height: "108px" }}
          onClick={handleRepaymentWithCard}
        >
          <div className="card-body d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div
                className="icon icon__primary mr-3"
                style={{ backgroundColor: "#9DA9B3", borderRadius: "10px" }}
              >
                <div style={{ marginTop: "-2px" }}>
                  <BsCreditCardFill className="text-white" size="20px" />
                </div>
              </div>
              <div>
                <p className="font-weight-bold text-primary padding-none">
                  Pay with existing card
                </p>
                {/*<small className=" padding-none">******6789</small>*/}
              </div>
            </div>

            <BiRightArrowAlt className="" size="20px" />
          </div>
        </div>

        <br />

        <button
          className={styles.addCard}
          onClick={() =>
            history.push({
              pathname: ROUTES.widgetLoanRepaymentViaNewCard,
              state: {
                loan_id: location?.state?.loan_id,
                amount: location?.state?.amount,
              },
            })
          }
        >
          <img src={addCircle} alt="" />
          <span>Pay with a new card</span>
        </button>
      </>
    );
  };

  const kenyaPayment = () => {
    return (
      <>
        <div className="title">Bank Transfer</div>
        <p>
          Kindly transfer the amount above to this unique account number using
          your mobile banking or ussd.
        </p>
        <AppCard
          className="d-flex justify-content-between"
          handleCopy={() => null}
        >
          <div
            className="d-flex align-items-center justify-content-between w-100"
            onClick={() => null}
          >
            <div>
              <p className="mr-1 padding-none" style={{ color: "#8B8699" }}>
                {walletDetails?.accountName}
              </p>
              <p className="title padding-none" id="accountNumber">
                {walletDetails?.accountNumber}
              </p>
              <p className="mr-1 padding-none" style={{ color: "#8B8699" }}>
                {walletDetails?.bankName}
              </p>
            </div>

            <div className="d-flex align-self-end align-items-center cursor-pointer">
              <RiFileCopyFill className="text-primary" />
              <p
                id="accountNumber"
                className="color-mid-purple ml-2 padding-none"
              >
                Copy
              </p>
            </div>
          </div>
        </AppCard>
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "10%" }}
        >
          <div className="d-flex align-items-center">
            <p
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={() => history.goBack()}
            >
              Go Back
            </p>
            <button
              className="btn btn-primary btn-radius"
              onClick={() =>
                history.push({
                  pathname: ROUTES.widgetLoanRepaymentProof,
                  state: {
                    loan_id: location?.state?.loan_id,
                    amount: location?.state?.amount,
                  },
                })
              }
              // disabled={!walletDetails?.accountNumber}
            >
              I have transferred
            </button>
          </div>
        </div>
      </>
    );
  };

  if (done) {
    return (
      <SuccessComponent
        btnText="Done"
        body={success}
        title="Repayment Successful!"
        handler={() => history.push(ROUTES.widgetDashboard)}
        type="success"
      />
    );
  }

  if (currency !== "NGN") {
    return kenyaPayment();
  } else {
    return nigeriaPayment();
  }
};

export default LoanRepaymentMethods;
