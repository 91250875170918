import React, { useEffect, useState } from "react";
import { deviceType } from "react-device-detect";
import styles from "../WidgetFlow.module.scss";
import { RiCloseCircleFill } from "react-icons/ri";
import WidgetInitializationError from "../WidgetInitializationError/WidgetInitializationError.component";
import WidgetLoader from "../WidgetLoader/WidgetLoader.component";
import { getData, getDataWithKey } from "../../../apis/apiMethods";
import { errorHandler } from "../../../helpers/errorHandler";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import * as CryptoJS from "crypto-js";
import DashboardComponent from "../WidgetRevampv2/Dashboard/Dashboard.component";
import WithdrawalComponent from "../WidgetRevampv2/Withdrawal/Withdrawal.component";
import TransactionPin from "../WidgetRevampv2/Profile/components/TransactionPin";
import { appInsights } from "../../AppInsight/AppInsight";
import WidgetInitialError from "../WidgetRevampv2/Dashboard/components/WidgetInitialError/WidgetInitialError";

const WidgetFlowEmbedApi = () => {
  const [initializationError, setInitializationError] = useState(false);
  const [showWidgetAnimation, setShowWidgetAnimation] = useState(false);
  const [finishedFirstInitializations, setFinishedFirstInitializations] =
    useState(false);
  const [error, setError] = useState("");
  const [userDetails, setUserDetails] = useState();
  const [msgFromAggregator, setMsgFromAggregator] = useState(null);
  const [encryptedKey, setEncryptedKey] = useState("");
  const [activeScreen, setActiveScreen] = useState("dashboard");
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    listenForMessage();
  }, []);

  // create encrypted key
  const getGeneratedAggregatorKey = async (aggregator_id, public_key) => {
    try {
      const response = await getData(
        `${apiEndpoints.getAggregatorKey}?aggregator_id=${aggregator_id}&public_key=${public_key}`
      );
      getEncryptedKey(public_key, response.data.private_key);
    } catch (error) {
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: WidgetFlowEmbedApi },
      });
    }
  };

  const getEncryptedKey = (public_key, private_key) => {
    console.log(private_key, public_key);
    const message = CryptoJS.enc.Utf8.parse(public_key);
    const secretkey = CryptoJS.enc.Utf8.parse(private_key);

    const aesOptions = { mode: CryptoJS.mode.CBC, iv: secretkey };
    const encryptedMessage = CryptoJS.AES.encrypt(
      message,
      secretkey,
      aesOptions
    );
    const encryptedKey = encryptedMessage.toString();
    console.log({ encryptedKey });
    setEncryptedKey(encryptedKey);
  };

  const listenForMessage = () => {
    try {
      // @ts-ignore
      const params = new URL(document.location).searchParams;
      const message = params.get("message");
      let messageFromAggregator;

      if (message && typeof message === "string") {
        messageFromAggregator = JSON.parse(message);
        setInitialData(messageFromAggregator, document.location.origin);
      } else {
        window.addEventListener("message", (event) => {
          const messageFromAggregator = event.data;
          setInitialData(messageFromAggregator, event.origin);
        });
      }
    } catch (error) {
      setFinishedFirstInitializations(true);
      setInitializationError(true);
      appInsights.trackException({
        exception: error,
        properties: { fileName: WidgetFlowEmbedApi },
      });
    }
  };

  const setInitialData = async (messageFromAggregator, origin) => {
    if (
      messageFromAggregator &&
      Object.keys(messageFromAggregator)?.length > 0
    ) {
      const aggregatorId = Number(messageFromAggregator?.aggregator_id);
      if (!Number.isSafeInteger(aggregatorId)) {
        setInitializationError(true);
      } else {
        console.log({ messageFromAggregator });
        setMsgFromAggregator(messageFromAggregator);
        localStorage.setItem(
          "customStyles",
          JSON.stringify(messageFromAggregator.customStyles)
        );
        await getGeneratedAggregatorKey(
          messageFromAggregator.aggregator_id,
          messageFromAggregator.public_key
        );
        setFinishedFirstInitializations(true);
        setInitializationError(false);
      }
    }
  };

  useEffect(() => {
    if (msgFromAggregator && encryptedKey) {
      checkFirstTimeBorrower();
    }
    getBankList();
  }, [msgFromAggregator, encryptedKey]);

  const checkFirstTimeBorrower = async () => {
    if (msgFromAggregator) {
      const { bvn_number, aggregator_id } = msgFromAggregator;
      try {
        const res = await getDataWithKey(
          `/account/check_bvn_exist/?bvn_number=${bvn_number}&aggregator_id=${aggregator_id}`
        );
        const userDetails = res.data;
        userDetails.aggregator_id = aggregator_id;
        userDetails.encryptedKey = encryptedKey;

        setUserDetails(userDetails);
        setFinishedFirstInitializations(true);
      } catch (error) {
        console.log(error);
        setError(errorHandler(error));
        appInsights.trackException({
          exception: error,
          properties: { fileName: WidgetFlowEmbedApi },
        });
      }
    }
  };

  const getBankList = async () => {
    try {
      const response = await getData("/account/signed_banks/");
      let banks = response.data;
      banks.sort(function (a, b) {
        if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
          return -1;
        }
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setBanks(banks);
    } catch (error) {
      setError(errorHandler(error));
      setInitializationError(true);
      appInsights.trackException({
        exception: error,
        properties: { fileName: WidgetFlowEmbedApi },
      });
    }
  };

  useEffect(() => {
    setActiveScreen("dashboard");
  }, []);

  const returnActiveScreen = () => {
    switch (activeScreen) {
      case "dashboard":
        return (
          <DashboardComponent
            wallet={userDetails}
            first_name={userDetails?.first_name}
            deviceType=""
            handleWithdrawal={() => setActiveScreen("withdrawal")}
            handlePin={() => setActiveScreen("pin")}
          />
        );
      case "pin":
        return (
          <TransactionPin
            wallet={userDetails}
            deviceType={deviceType}
            handleDashboard={() => setActiveScreen("dashboard")}
          />
        );
      case "withdrawal":
        return (
          <WithdrawalComponent
            wallet={userDetails}
            handleDashboard={() => setActiveScreen("dashboard")}
          />
        );
    }
  };

  return (
    <div id={deviceType} className={styles.widgetMainWrapper}>
      <div className={styles.widgetWrapper}>
        <div
          className={
            deviceType === "mobile"
              ? styles.cancelIconMobile
              : styles.cancelIcon
          }
          /*onClick={() => {
                      sendResponseToClient({
                          status: WIDGET_RESPONSE_STATUSES.cancel,
                          data: { message: "User cancelled" },
                      });
                  }}*/
        >
          <RiCloseCircleFill
            size={deviceType === "mobile" ? "5rem" : "2rem"}
            color="#fff"
          />
        </div>
        {initializationError ? (
          <WidgetInitialError unexpectedError />
        ) : !finishedFirstInitializations || showWidgetAnimation ? (
          <WidgetLoader showWidgetAnimation={showWidgetAnimation} />
        ) : (
          <div
            className={
              deviceType === "mobile" ? styles.widgetMobile : styles.widget
            }
          >
            <div id="widget-page-top"></div>
            {error !== "" && (
              <p className="alert alert-danger w-100">{error}</p>
            )}
            <div className="w-100">{returnActiveScreen()}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WidgetFlowEmbedApi;
