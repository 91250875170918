import React, { useEffect } from "react";
import styles from "./LoanRequest.module.scss";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

interface LoanRequestFailedProps {
  error: string;
}

function LoanRequestFailed({ error }: LoanRequestFailedProps) {
  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (LoanRequestFailed.tsx)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className={styles.stepSix_container}>
      <div className={styles.verification_failed}>
        <div className={styles.box}></div>
        <h3>Transaction Failed</h3>
        <p>{error}</p>
      </div>
    </div>
  );
}

export default LoanRequestFailed;
