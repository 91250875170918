import * as CryptoJS from "crypto-js";

export const appHelpers = {
  validateEmail: (email) => {
    // eslint-disable-next-line

    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
};

export const storeToken = (token) => {
  localStorage.setItem("token", token);
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const handleGetEncryptedKey = (public_key, private_key) => {
  const message = CryptoJS.enc.Utf8.parse(public_key);
  const secretkey = CryptoJS.enc.Utf8.parse(private_key);

  const aesOptions = { mode: CryptoJS.mode.CBC, iv: secretkey };
  const encryptedMessage = CryptoJS.AES.encrypt(message, secretkey, aesOptions);
  return encryptedMessage.toString();
};

export const getHTTPKey = () => {
  const key = sessionStorage.getItem("encryptedKey");
  if (key) {
    return key;
  }
  return "";
};

export const storeHTTPKey = (key) => {
  sessionStorage.setItem("encryptedKey", key);
};

export const scrollToTop = () => {
  const widgetPageTop = document.getElementById("widget-page-top");
  widgetPageTop?.scrollIntoView({
    behavior: "smooth",
  });
};
