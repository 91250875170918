import React, { ReactElement } from "react";

interface LoaderProps {
  light?: boolean;
  message?: string | boolean | undefined
}

export default function Loader({ light = false, message }: LoaderProps): ReactElement {
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
      <span
        className={`spinner-border spinner-border-sm ml-2 ${
          light ? "text-white" : "text-primary"
        }`}
      ></span>
      {message && <p style={{marginTop: "40px"}}>{message}</p>}
    </div>
  );
}
