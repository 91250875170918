import styles from "../../Loans.module.scss";
import { formatCurrency } from "../../../../../../helpers/formatCurrency";
import { Button } from "antd";
import React from "react";
import successSVG from "../../../../../../assets/web/success.svg";
import { LoanOfferProps } from "../../../../../../web/components/features/newLoanOffer/NewLoanOffer";
import { useHistory } from "react-router-dom";
import {
  updateLoanDetailsAction,
  updateWidgetSettingsAction,
} from "../../../../../../redux/actions";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { useDispatch, useSelector } from "react-redux";

interface NewOfferAcceptedScreenProps {
  mostRecentLoanOffer: LoanOfferProps | null;
  loanData: any;
  refetchPendingLoanOffer: () => void;
}

function NewOfferAcceptedScreen({
  mostRecentLoanOffer,
  loanData,
  refetchPendingLoanOffer,
}: NewOfferAcceptedScreenProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  function handleCloseModal() {
    refetchPendingLoanOffer();

    dispatch(
      updateLoanDetailsAction({
        new_loan_offer_widget: {
          offer_amount: null,
          offer_tenor: null,
        },
      }),
    );

    if (history.location.pathname === "/create-loan-account/loan/history") {
      dispatch(
        updateWidgetSettingsAction({
          showNewLoanOffer: false,
          showLoanDetails: false,
        }),
      );
    } else {
      history.push(ROUTES.widgetDashboard);
    }
  }

  return (
    <div style={{ maxWidth: "480px" }}>
      <div className={styles.offer_verification_success}>
        <img src={successSVG} alt="" />
        <h4>Loan Accepted!</h4>
        <p>
          Congratulations! Your loan of{" "}
          {`₦${formatCurrency(Number(mostRecentLoanOffer?.offer_amount || loanData?.new_loan_offer?.offer_amount))}`}{" "}
          will be processed. The funds will be deposited into your account
          shortly.
        </p>
        <div className={styles.buttons}>
          <Button type="primary" onClick={handleCloseModal}>
            Done
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NewOfferAcceptedScreen;
