import React, { useEffect, useState } from "react";
import styles from "./MobileTransactionView.module.scss";
import { IoMdArrowRoundUp } from "react-icons/io";
import depositIcon from "../../../../assets/web/depositIcon.svg";
import withdrawalIcon from "../../../../assets/web/withdrawIcon.svg";
import emptyBox from "../../../../assets/web/dashboard/noItem.png";
import { WalletTransactions } from "../../../../interfaces/transactions";
import Loader from "../../../../components/Loader/Loader";
import moment from "moment";
import DateRangeSelector from "../../features/dateRangeSelector/DateRangeSelector";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

interface MobileTransactionViewProps {
  transactionHistoryRef: React.RefObject<HTMLDivElement>;
  scrollToTop: (elementRef: React.RefObject<HTMLDivElement>) => void;
  overviewRef: React.RefObject<HTMLDivElement>;
  transactions: WalletTransactions[];
  isTransactionLoading: boolean;
  setTransactionDetail: React.Dispatch<
    React.SetStateAction<WalletTransactions[] | null>
  >;
}

export interface SearchQuery {
  startDate: string;
  endDate: string;
}

function MobileTransactionView({
  transactionHistoryRef,
  scrollToTop,
  overviewRef,
  transactions,
  isTransactionLoading,
  setTransactionDetail,
}: MobileTransactionViewProps) {
  const [showDateRangeSelector, setShowDateRangeSelector] = useState(false);
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    startDate: "",
    endDate: "",
  });
  const [selectRangeItems, setSelectRangeItems] = useState<
    WalletTransactions[]
  >([]);
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Dashboard  - (MobileTransactionView.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleDateSelector = () => {
    setShowDateRangeSelector(true);
  };

  // console.log("Transactions:", transactions)

  const handleConfirmSelection = () => {
    const checkDate = transactions?.filter((transaction) => {
      const transactionDate = new Date(transaction?.transactionDate);
      return (
        transactionDate >= new Date(searchQuery.startDate) &&
        transactionDate <= new Date(searchQuery.endDate)
      );
    });

    setSelectRangeItems(checkDate);

    setShowDateRangeSelector(false);
  };

  const viewTransactionDetail = (id: string) => {
    const result = transactions.filter(
      (transaction) => transaction.transactionId === id
    );
    document.body.style.overflow = "hidden";

    setTransactionDetail(result);
    dispatch(updateWebSettingsAction({ showTransactionDetail: true }));
  };

  return (
    <>
      <div className={styles.container} ref={transactionHistoryRef}>
        <header className={styles.transaction_header}>
          <button
            className={styles.button}
            onClick={() => scrollToTop(overviewRef)}
          >
            <IoMdArrowRoundUp className={styles.arrow} />
            <span>Go up</span>
          </button>
          <h2 className={styles.title}>Transactions</h2>

          <button className={styles.range} onClick={handleDateSelector}>
            Select Range
          </button>
        </header>

        {isTransactionLoading ? (
          <div style={{ marginTop: "20px" }}>
            <Loader />
          </div>
        ) : transactions?.length > 0 ? (
          <div className={styles.transactions}>
            {searchQuery.startDate === "" && searchQuery.endDate === ""
              ? transactions?.slice(0, 10).map((transaction) => (
                  <div
                    className={styles.transaction}
                    key={transaction?.transactionId}
                    onClick={() =>
                      viewTransactionDetail(transaction.transactionId)
                    }
                  >
                    <div className={styles.icon_title_wrap}>
                      <span
                        className={
                          transaction?.transactionType === "Debit"
                            ? styles.withdrawalIcon_wrap
                            : styles.depositIcon_wrap
                        }
                      >
                        <img
                          src={
                            transaction?.transactionType === "Debit"
                              ? withdrawalIcon
                              : depositIcon
                          }
                          alt=""
                          className={styles.icon}
                        />
                      </span>
                      <div className={styles.deposit_date}>
                        <p className={styles.transaction_type}>
                          {transaction?.transactionType}
                        </p>
                        <p className={styles.transaction_date}>
                          {moment(transaction.transactionDate).format(
                            "MMM DD YYYY, hh:mma"
                          )}
                        </p>
                      </div>
                    </div>
                    {transaction?.transactionType === "Debit" ? (
                      <span className={styles.amount}>
                        - ₦{transaction?.debitAmount?.toLocaleString()}
                      </span>
                    ) : (
                      <span className={styles.amount}>
                        ₦{transaction?.amount?.toLocaleString()}
                      </span>
                    )}
                  </div>
                ))
              : selectRangeItems?.map((transaction) => (
                  <div
                    className={styles.transaction}
                    key={transaction.transactionId}
                  >
                    <div className={styles.icon_title_wrap}>
                      <span
                        className={
                          transaction?.transactionType === "Debit"
                            ? styles.withdrawalIcon_wrap
                            : styles.depositIcon_wrap
                        }
                      >
                        <img
                          src={
                            transaction?.transactionType === "Debit"
                              ? withdrawalIcon
                              : depositIcon
                          }
                          alt=""
                          className={styles.icon}
                        />
                      </span>
                      <div className={styles.deposit_date}>
                        <p className={styles.transaction_type}>
                          {transaction?.transactionType}
                        </p>
                        <p className={styles.transaction_date}>
                          {moment(transaction.transactionDate).format(
                            "MMM DD YYYY, hh:mma"
                          )}
                        </p>
                      </div>
                    </div>
                    {transaction?.transactionType === "Debit" ? (
                      <span className={styles.amount}>
                        - ₦{transaction?.debitAmount?.toLocaleString()}
                      </span>
                    ) : (
                      <span className={styles.amount}>
                        ₦{transaction?.amount?.toLocaleString()}
                      </span>
                    )}
                  </div>
                ))}
          </div>
        ) : (
          <div className={styles.noTransaction_container}>
            <img src={emptyBox} alt="" />
            <h3>No transactions yet</h3>
            <p>Your transactions will appear here when you initiate one</p>
          </div>
        )}
      </div>
      {showDateRangeSelector && (
        <DateRangeSelector
          setShowDateRangeSelector={setShowDateRangeSelector}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleConfirmSelection={handleConfirmSelection}
        />
      )}
    </>
  );
}

export default MobileTransactionView;
