import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData, getDataWithKey } from "../apis/apiMethods";

const fetchProducts = async ({
  aggregatorId,
  countryCode,
}: {
  aggregatorId: string;
  countryCode: string;
}) => {
  const response = await getData(
    `${apiEndpoints.customerLoanProducts}?aggregator_id=${aggregatorId}&country_code=${countryCode}`,
  );
  return response.data;
};

async function fetchLoanProductsWidget({
  aggregator_id,
  country_code,
}: {
  aggregator_id: number;
  country_code: string;
}) {
  const response = await getDataWithKey(
    `${apiEndpoints.getLoanProducts}?aggregator_id=${aggregator_id}&country_code=${country_code}`,
  );

  return response.data;
}

export function useFetchProducts({
  aggregatorId,
  countryCode,
}: {
  aggregatorId: string;
  countryCode: string;
}) {
  return useQuery(
    [{ aggregatorId, countryCode }, "fetchProducts"],
    fetchProducts,
    {
      refetchOnWindowFocus: false,
      enabled: countryCode && aggregatorId ? true : false,
    },
  );
}

export function useFetchProductsWidget({
  aggregator_id,
  country_code,
}: {
  aggregator_id: number;
  country_code: string;
}) {
  return useQuery(
    [{ aggregator_id, country_code }, "fetchLoanProductsWidget"],
    fetchLoanProductsWidget,
    {
      refetchOnWindowFocus: false,
      enabled: aggregator_id && country_code ? true : false,
    },
  );
}
