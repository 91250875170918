import React, { useState, useEffect } from "react";
import embedLogo from "../../../assets/web/Embed.svg";
import { Link } from "react-router-dom";
import arrowLeft from "../../../assets/web/arrow-left.svg";
import styles from "./ResetPassword.module.scss";
import OtpInput from "../../components/otpComp/OtpInput";
import { postData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { Alert, Button } from "antd";
import { ResetPasswordData } from "./ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux-interface";
import { updateWebSettingsAction } from "../../../redux/actions";
import { appInsights } from '../../../components/AppInsight/AppInsight'

interface StepTwoProps {
  onNextStep: () => void;
  onPrevStep: () => void;
  setResetPasswordData: React.Dispatch<React.SetStateAction<ResetPasswordData>>;
  userEmail: string;
  resetPasswordData: ResetPasswordData;
}

function StepTwo({
  onNextStep,
  onPrevStep,
  setResetPasswordData,
  userEmail,
  resetPasswordData,
}: StepTwoProps) {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Step2  - (ResetPassword.tsx)",
      isLoggedIn: true,
    });
  }, []);


  const resendOtp = async () => {
    setIsLoading(true);
    const reqBody = {
      reset_step: "first_step",
      email: userEmail,
    };
    try {
      const res = await postData(apiEndpoints.resetPasswordUrl, reqBody);
      setResetPasswordData({
        email: userEmail,
        stateToken: res.state_token,
      });
      dispatch(
        updateWebSettingsAction({
          successMsg: { bodyText: "An OTP has been sent to your email" },
        })
      );
    } catch (error) {
      dispatch(
        updateWebSettingsAction({
          errorMsg: error.response?.data?.message ?? error.message,
        })
      );

      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Step3 - (ResetPassword.tsx)",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const reqBody = {
      reset_step: "second_step",
      otp,
      state_token: resetPasswordData.stateToken,
    };
    try {
      const res = await postData(apiEndpoints.resetPasswordUrl, reqBody);
      setResetPasswordData({
        email: userEmail,
        stateToken: res.state_token,
      });
      onNextStep();
      dispatch(
        updateWebSettingsAction({
          errorMsg: "",
          successMsg: { bodyText: "" },
        })
      );
    } catch (error) {
      dispatch(
        updateWebSettingsAction({
          errorMsg: error.response?.data?.message ?? error.message,
        })
      );
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Step2 - (ResetPassword.tsx)",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.reset_password__container}>
      {webStateData.errorMsg && (
        <div
          style={{ width: "100%", paddingTop: "50px", paddingBottom: "20px" }}
        >
          <Alert
            message={webStateData.errorMsg}
            type="error"
            showIcon
            closable
            onClose={() => dispatch(updateWebSettingsAction({ errorMsg: "" }))}
          />
        </div>
      )}
      {webStateData.successMsg.bodyText && (
        <div
          style={{ width: "100%", paddingTop: "50px", paddingBottom: "20px" }}
        >
          <Alert
            message={webStateData.successMsg.bodyText}
            type="success"
            showIcon
            closable
            onClose={() =>
              dispatch(
                updateWebSettingsAction({ successMsg: { bodyText: "" } })
              )
            }
          />
        </div>
      )}
      <header className={styles.logo__header_diff_step_three}>
        <div className={styles.div_diff_step_three}>
          <img src={embedLogo} alt="" />
          <Link to="/" className={styles.logo_diff_step_three}>
            embed
          </Link>
        </div>

        <button onClick={onPrevStep} className={styles.button_diff_step_three}>
          <img src={arrowLeft} alt="" />
          <span>Go back</span>
        </button>
      </header>

      <section className={styles.section_diff_step_three}>
        <div className={styles.section_div_diff_step_three}>
          <header className={styles.section_header_diff_step_three}>
            <h1>OTP Verifcation</h1>
            <p>
              A one time password was sent to <strong>{userEmail}</strong>{" "}
              <br />{" "}
              <Link to="/login" className={styles.change_email}>
                Back to login?
              </Link>
            </p>
          </header>

          <OtpInput length={5} onComplete={(pin: string) => setOtp(pin)} />
          <button className={styles.resend} onClick={resendOtp}>
            Resend Code?
          </button>

          <div
            className={styles.submit__btn_diff_step_three}
            onClick={handleSubmit}
          >
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              {isLoading ? " Processing..." : "Proceed"}
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default StepTwo;
