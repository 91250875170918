import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDotNetDataWithKey } from "../apis/apiMethods";

const getSavedPaystackDetails = async ({
  aggregator_id,
  bvn_number,
  encryptedKey,
}) => {
  const data = await getDotNetDataWithKey(
    `${apiEndpoints.paystackInfo}?aggregator_id=${aggregator_id}&bvn_number=${bvn_number}`,
  );
  return data.data;
};

export default function useSavedPaystackDetails({
  aggregator_id,
  bvn_number,
  encryptedKey,
}) {
  return useQuery(
    [{ aggregator_id, bvn_number, encryptedKey }, "getSavedPaystackDetails"],
    getSavedPaystackDetails,
  );
}
