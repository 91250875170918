export const sendResponseToClient = ({
  status,
  data,
}: {
  status: string;
  data: any;
}): void => {
  const response = {
    status,
    data,
  };
  console.log({
    sentResponseToClient: response,
  });
  // Send to web
  window?.top?.postMessage(response, "*");
  // Send to React Native App
  // @ts-ignore
  window?.ReactNativeWebView?.postMessage(JSON.stringify(response), "*");
};
