import { getData } from "../apis/apiMethods";
import { apiEndpoints } from "../apis/apiEndpoints";
import { useQuery } from "react-query";

const getOtpTypes = async () => {
  const { data } = await getData(`${apiEndpoints.otp_types}`);
  return data;
};

export default function useOtpTypes() {
  return useQuery(["getOtpTypes", {}], getOtpTypes, {
    refetchOnWindowFocus: true,
  });
}
