import React from 'react';
import { Link } from "react-router-dom";

export const PrivacyPolicy = () => {

        return (
            <div className="container">
                <div className="row mt-3 mt-sm-5">
                    <div className="col-md-12 space-top-lg-1">
                        <div className="ml-lg-auto text-right">
                            <Link  className="btn btn-sm btn-soft-primary transition-3d-hover" to="/">
                                <span className="fas fa-arrow-left small mr-1"></span>
                                    Return to Dashboard
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row mb-10">
                    <h1 className="h1 text-dark font-weight-medium">Privacy Policy</h1>
                    
                    <div className="mt-3">
                        <p className="h3 text-dark">Introduction</p>
                        <p>This Privacy Policy (“Policy”) governs your use of the  Advancly Digital Platform which includes its website “https://www.advancly.com”, the associated domains thereof, applications and other technological solutions(“the Platform”) developed by Venture Garden Nigeria Limited (“VGN/We/Our”),. The Platform enables efficient data collection of programme data and issue reporting from the field.
                        If you choose to use the Platform, then you agree to the collection and use of your personal information in accordance with this Policy. The personal information collected will be used to improve your experience during and after using the Platform. We will not use or share your personal information with anyone except as provided under this Policy.
                        </p>
                    </div>
    
                    <div className="mt-3">
                        <p className="h3 text-dark">Information Collection and Use</p>
                        <p>By using the Platform, you are consenting to our processing of your information as set forth in this Policy as may be amended by us from time to time. For a better experience, while using the Platform, we require users of the Platform to provide us with certain personally identifiable information, including but not limited to name, email address, Global Positioning System (“GPS”) location, phone number, password, PIN. The information that we request will be retained by us and used as described in this Policy.
                        We collect information about how you use the Platform, such as the types of content that you view or engage with, the features you use, the actions you take, the time, frequency and duration of your activities. For example, we log when you're using and have last used the Platform.
                        The Platform utilizes third party services that may collect information used to identify you. You agree that we may use the information provided to contact you with any important information, required notices and marketing promotions.
                        </p>
                    </div>

                    <div className="mt-3">
                        <p className="h3 text-dark">Device information</p>
                        <p>
                            As described below, we collect information from and about the mobile devices, phones, connected TVs and other web-connected devices you use that integrate with our Products, and we combine this information across different devices that you use.
                            Information that we obtain from these devices include
                        </p>
                            <ul>
                                <li><b>Device attributes:</b>information such as the operating system, hardware and software versions, battery level, signal strength, available storage space, etc.</li>
                                <li><b>Device signals:</b> Bluetooth signals, information about nearby Wi-Fi access points, beacons and mobile phone masts.</li>
                                <li><b>Data from device settings:</b> information that you allow us to receive through device settings that you turn on, such as access to your GPS location, camera or photos.</li>
                                <li><b>Network and connections:</b> information such as the name of your mobile operator or Internet Service Provider (“ISP”), language, time zone, mobile phone number, IP address, connection speed.</li>
                            </ul>
                    </div>
                    
                    <div className="mt-3">
                        <p className="h3 text-dark">Log Data</p>
                        <p>Please note that whenever you use the Platform, and you experience any error during the course of use, we collect data and information relating to such errors (“Log Data”), through third party products and services. This Log Data may include GPS, phone model type, e.t.c.</p>
                    </div>
                    
                    <div className="mt-3">
                        <p className="h3 text-dark">Cookies</p>
                        <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers for each user, and the information obtained are stored on your device's internal memory.
                        This Platform does not use these “cookies” explicitly. However, the Platform may use third party code and libraries that use “cookies” to collect information and improve its services. You have the option to either accept or refuse these cookies and you shall be expressly informed before a cookie is sent to your device. If you choose to refuse our cookies, you may not be able to use some functionalities provided by the Platform.
                        </p>
                    </div>

                    <div className="mt-3">
                        <p className="h3 text-dark">Automatically Collected Information / User Provided Information</p>
                        <p>You agree that &#123;VGN&#125; may disclose information provided by you, or automatically collected by the Platform, in the manner listed below:</p>
                            <ul>
                                <li>as required by law, such as to comply with a subpoena, or similar legal process;</li>   
                                <li>when &#123;VGN&#125; believes in good faith that disclosure is necessary to protect its rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</li>
                                <li>with Our trusted services providers who work on Our behalf, do not have an independent use of the information We disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
                                <li>You agree that &#123;VGN&#125; may retain User Provided data for as long as you use the Platform and for a reasonable time thereafter. We will retain automatically collected information for up to twenty-four (24) months and thereafter may store it in aggregate. If you’d like us to delete data that you have provided via the Platform, please contact us at &#123;support@advancly.com&#125;, and we will respond in a reasonable time. Please note that some or all of your data may be required in order for the Platform to function properly.</li>
                            </ul>
                    </div>

                    <div className="mt-3">
                        <p className="h3 text-dark">Security</p>
                        <p>We value your trust in providing us with your Personal Information in connection with your use of the Platform and shall endeavor to use commercially acceptable means of protecting it. However, we do not guarantee its absolute security.</p>
                    </div>

                    <div className="mt-3">
                        <p className="h3 text-dark">Links to Other Websites</p>
                        <p>Please note that this Platform may contain links to other websites. If you click on a third-party link, you will be directed to that website. Note that these third party websites are not operated by us. Therefore, we strongly advise you to review the privacy policy of these websites. We have no control and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                    </div>
                    
                    <div className="mt-3">
                        <p className="h3 text-dark">Children’s Privacy</p>
                        <p>We do not use the Platform to knowingly solicit data from children or individuals under the age of thirteen (13). Where a parent or guardian discovers that a child or individual under the age of thirteen (13) has provided us with his/her personal information without their consent, he or she should contact us at &#123;support@advancly.com&#125; and we shall immediately delete the user’s account from our servers.</p>
                    </div>

                    <div className="mt-3">
                        <p className="h3 text-dark">New owner</p>
                        <p>If the ownership or control of all or part of this Platform changes, we may transfer your information to the new owner.</p>
                    </div>
                    
                    <div className="mt-3">
                        <p className="h3 text-dark">Changes to This Policy</p>
                        <p>We may update this Policy from time to time without any prior notice to you or consent. Thus, you are advised to consult this Platform periodically for any changes. We will notify you of any changes by posting the revised Policy on this Platform. Please note that changes shall be effective immediately after they are updated on this Platform.</p>
                    </div>
                    
                    <div className="mt-3">
                        <p className="h3 text-dark">Contact Us</p>
                        <p>If you have any questions or suggestions about our Policy, do not hesitate to contact us via &#123;support@advancly.com&#125;</p>
                    </div>
                    
                    
                    <p className="h5 text-dark mt-5">Date of last revision: 08/April/2020</p>
                </div>
            </div>
    
        )
    }

export default PrivacyPolicy;