import React, { useMemo, useState } from "react";
import styles from "./Card.module.scss";
import { TextToCopyProps } from "../dashboard/overviewComp/OverviewComp";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux-interface";
import { updateWebSettingsAction } from "../../../redux/actions";
import { Setting } from "../../../interfaces/borrower";
import { message } from "antd";

interface CardProps {
  walletBalance?: boolean;
  loanBalance?: boolean;
  title?: string;
  content?: string;
  bgIcon?: string;
  accountDetails?: boolean;
  accountnumber?: string;
  accountName?: string;
  bankName?: string;
  icon?: string;
  icon2?: string;
  bgColor?: string;
  color?: string;
  amount?: number;
  action?: string;
  copyToClipboard?: (data: TextToCopyProps) => Promise<void>;
  isLoadingWalletDetails?: boolean;
  refetchFetchWalletdetails?: () => void;
  closestDueLoanSchedule?: () => any | null;
  handleLaunchOkraWidget?: () => void;
  // isLoanScheduleLoading?: boolean
  isLoadingOutstandingLoanBalance?: boolean;
  refetchOutstandingLoanBalance?: () => void;
  isLoadingUserExist?: boolean;
  userExistsData?: any;
  NextRepaymentAmount?: any;
  isLoadingNextRepaymentAmount?: boolean;
  setShowLoanRequestModal?: boolean;
  setShowLoanRepaymentModal?: boolean;
}

function Card({
  walletBalance,
  title,
  loanBalance,
  content,
  bgIcon,
  accountnumber,
  icon,
  icon2,
  bgColor,
  amount,
  action,
  accountName,
  bankName,
  copyToClipboard,
  isLoadingWalletDetails,
  refetchFetchWalletdetails,
  accountDetails,
  isLoadingOutstandingLoanBalance,
  refetchOutstandingLoanBalance,
  NextRepaymentAmount,
  isLoadingNextRepaymentAmount,
  setShowLoanRequestModal,
  setShowLoanRepaymentModal,
}: CardProps) {
  const [showWalletBalance, setShowWalletBalance] = useState(false);
  const [isLoadingOutstandingLoanAmount, setIsLoadingOutstandingLoanAmount] =
    useState(false);
  const dispatch = useDispatch();
  const loanDataStore = useSelector(
    (state: ReduxStoreModel) => state.loanDetailsReducer,
  );

  const webAggregatorState = useSelector(
    (state: ReduxStoreModel) => state.webAggregatorSettingsReducer,
  );

  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );

  const { courseDescription, courseAmount } = borrowerDetails;

  const requireEquityContribution = useMemo(() => {
    const setting = webAggregatorState?.data?.find(
      (settings: Setting) =>
        settings.setting_name === "require_equity_contribution",
    );
    return setting?.setting_value === "1";
  }, [webAggregatorState]);

  const handleLoanAction = () => {
    if (setShowLoanRequestModal) {
      if (requireEquityContribution && !courseDescription && !courseAmount) {
        message.error("No course information selected");
      } else if (
        requireEquityContribution &&
        courseDescription &&
        courseAmount
      ) {
        dispatch(
          updateWebSettingsAction({
            showLoanRequestModal: true,
          }),
        );
      } else {
        dispatch(
          updateWebSettingsAction({
            showLoanRequestModal: true,
          }),
        );
      }
    }

    document.body.style.overflow = "hidden";

    if (setShowLoanRepaymentModal) {
      if (loanDataStore?.loan_ref) {
        dispatch(
          updateWebSettingsAction({
            showLoanRepaymentModal: true,
          }),
        );
      }
    }
  };

  const handleCopyToClipboard = () => {
    if (copyToClipboard && accountnumber) {
      copyToClipboard({
        accountBankName: bankName || "",
        accountNumber: accountnumber || "0",
        accountName: accountName || "",
      });
    }
  };

  const handleShowWalletBalance = () => {
    setShowWalletBalance((prev) => !prev);
  };

  const handleOutstandingLoanBalanceRefresh = () => {
    if (refetchOutstandingLoanBalance) {
      setIsLoadingOutstandingLoanAmount(true);
      refetchOutstandingLoanBalance();
    }

    setTimeout(() => setIsLoadingOutstandingLoanAmount(false), 3000);
  };

  return (
    <article
      style={{ backgroundColor: bgColor, flex: 1 }}
      className={styles.card_container}
    >
      {walletBalance && (
        <div className={styles.title_container}>
          {isLoadingWalletDetails ? (
            <Loader light={true} />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span className={styles.title}>
                <span>{title}</span>
                {showWalletBalance ? (
                  <img
                    src={icon2}
                    alt=""
                    onClick={handleShowWalletBalance}
                    style={{ paddingLeft: "5px" }}
                  />
                ) : (
                  <img
                    src={icon}
                    alt=""
                    onClick={handleShowWalletBalance}
                    style={{ paddingLeft: "5px" }}
                  />
                )}
              </span>
              {showWalletBalance ? (
                <span className={styles.credit_balance}>
                  <strong>****</strong>
                </span>
              ) : amount?.toLocaleString().split("").includes(".") ? (
                <span className={styles.credit_balance}>
                  ₦<strong>{amount?.toLocaleString()}</strong>
                </span>
              ) : (
                <span className={styles.credit_balance}>
                  ₦<strong>{amount?.toLocaleString()}.</strong>00
                </span>
              )}
            </div>
          )}
        </div>
      )}
      {loanBalance && (
        <div className={styles.title_container}>
          {isLoadingOutstandingLoanAmount || isLoadingOutstandingLoanBalance ? (
            <Loader light={true} />
          ) : (
            <>
              <span className={styles.title}>
                <span>{title}</span>
                <img
                  src={icon}
                  alt=""
                  onClick={handleOutstandingLoanBalanceRefresh}
                  style={{ cursor: "pointer" }}
                />
              </span>
              {amount && amount > 0 ? (
                <span className={styles.loan_balance}>
                  -₦{amount.toLocaleString()}
                </span>
              ) : (
                <span className={styles.loan_balance}>₦{amount}</span>
              )}
            </>
          )}
        </div>
      )}
      {content && (
        <div className={styles.content}>
          {isLoadingNextRepaymentAmount ? (
            <div
              style={{
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Loader message="Loading next repayment..." />
            </div>
          ) : (
            <>
              <div className={styles.content_wrap}>
                <p>{content}</p>

                <button onClick={handleLoanAction}>
                  <span className={styles.action_word}>{action}</span>
                  {icon && (
                    <img src={icon} alt="" className={styles.arrowRight} />
                  )}
                </button>
              </div>
              <img src={bgIcon} alt="" />
            </>
          )}
        </div>
      )}

      {accountDetails && (
        <div className={styles.accountDetails_container}>
          {isLoadingWalletDetails ? (
            <div className={styles.loader}>
              <Loader />
            </div>
          ) : (
            <div className={styles.userDetails}>
              <div className={styles.accountDetails}>
                <div className={styles.bank_personal_info}>
                  <p>{accountnumber}</p>
                  <p>{accountName}</p>
                </div>
                <img
                  src={icon}
                  alt=""
                  className={styles.copy}
                  onClick={handleCopyToClipboard}
                />
              </div>
              <p className={styles.bank_name}>{bankName}</p>
            </div>
          )}
        </div>
      )}
    </article>
  );
}

export default Card;
