import React, { Dispatch, SetStateAction, RefObject } from "react";
import styles from "./Overview.module.scss";
import Card from "../../card/Card";
import { frame, arrowRight, bgIcon, bgIconWeb } from "./Index";
import { IoIosArrowDown } from "react-icons/io";
import MobileTransactionView from "../mobileTransactionView/MobileTransactionView";
import TransactionOverview from "../TransactionOverview/TransactionOverview";
import LoanHistoryCarousel from "../loanHistoryCarousel/LoanHistoryCarousel";
import { LoanHistory } from "../../../../interfaces/loan";
import Loader from "../../../../components/Loader/Loader";
import { fetchUserOutstandingLoanBalance } from "../../../../custom-hooks/useFetchOutstandingLoanBalance";
import { useQuery } from "react-query";
import useFetchNextRepayment from "../../../../custom-hooks/useFetchNextRepayment";
import moment from "moment";
import useFetchRecentLoans from "../../../../custom-hooks/useFetchRecentLoans";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";

interface OverviewCompProps {
  transactionHistoryRef: RefObject<HTMLDivElement>;
  scrollToTransactions: (elementRef: RefObject<HTMLDivElement>) => void;
  scrollToTop: (elementRef: RefObject<HTMLDivElement>) => void;
  overviewRef: RefObject<HTMLDivElement>;
  loanHistory: LoanHistory;
  isLoanHistoryLoading: boolean;
  isLoadingUserExist: boolean;
  userExistsData: any;
  isRefetchingLoanDetails: () => void;
  setPageNumber: Dispatch<SetStateAction<number>>;
  pageNumber: number;
}

function OverviewComp({
  transactionHistoryRef,
  scrollToTransactions,
  scrollToTop,
  overviewRef,
  loanHistory,
  userExistsData,
  isLoadingUserExist,
  isRefetchingLoanDetails,
  isLoanHistoryLoading,
  setPageNumber,
  pageNumber,
}: OverviewCompProps) {
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const dispatch = useDispatch();

  const handleLoanRequestModal = () => {
    dispatch(
      updateWebSettingsAction({
        showLoanRequestModal: true,
      })
    );
  };

  const {
    data: outstandingLoanBalance,
    isLoading: isLoadingOutstandingLoanBalance,
    refetch: refetchOutstandingLoanBalance,
  } = useQuery(
    [
      { customerId: userInfo?.customerId, currency: userInfo?.currency },
      "fetchUserOutstandingLoanBalance",
    ],
    fetchUserOutstandingLoanBalance,
    {
      refetchOnWindowFocus: true,
    }
  );

  const { data: NextRepaymentAmount, isLoading: isLoadingNextRepaymentAmount } =
    useFetchNextRepayment({
      customerId: userInfo?.customerId,
    });

  const { data: RecentLoans, isLoading: isLoadingRecentLoans } =
    useFetchRecentLoans({
      customerId: userInfo?.customerId,
    });

  return (
    <>
      <header className={styles.overview_header}>
        <h1>Loan</h1>
      </header>
      {isLoadingRecentLoans ? (
        <div className={styles.isLoading_container}>
          <Loader message="Loading loan data..." />
        </div>
      ) : RecentLoans?.length > 0 ? (
        <div className={styles.loan_content}>
          <div className={styles.card_container}>
            <Card
              title="Outstanding Repayment"
              loanBalance
              icon={frame}
              bgColor="#FF5E2E"
              amount={outstandingLoanBalance?.totalAmount ?? 0}
              isLoadingOutstandingLoanBalance={isLoadingOutstandingLoanBalance}
              refetchOutstandingLoanBalance={refetchOutstandingLoanBalance}
            />

            {
              <Card
                content="Apply for a loan in minutes"
                action="Get loan"
                icon={arrowRight}
                bgColor="#DEFADE"
                bgIcon={bgIcon}
                setShowLoanRequestModal={true}
                userExistsData={userExistsData}
                isLoadingUserExist={isLoadingUserExist}
              />
            }

            {NextRepaymentAmount?.outstandingAmount > 0 && (
              <Card
                content={`Next repayment of ₦${NextRepaymentAmount?.outstandingAmount.toLocaleString()} is due on ${moment(
                  NextRepaymentAmount?.dueDate
                ).format("MMM DD, YYYY")}`}
                bgColor="#DEFADE"
                bgIcon={bgIcon}
                setShowLoanRepaymentModal={true}
                isLoadingNextRepaymentAmount={isLoadingNextRepaymentAmount}
              />
            )}
          </div>

          <LoanHistoryCarousel
            RecentLoans={RecentLoans}
            scrollToTransactions={scrollToTransactions}
            transactionHistoryRef={transactionHistoryRef}
            isRefetchingLoanDetails={isRefetchingLoanDetails}
          />

          {isLoanHistoryLoading ? (
            <div className={styles.isLoading_container}>
              <Loader message="Loading loan history..." />
            </div>
          ) : (
            <div className={styles.transactions}>
              <TransactionOverview
                loanHistory={loanHistory}
                isRefetchingLoanDetails={isRefetchingLoanDetails}
              />
            </div>
          )}

          <div
            className={styles.dashboardAction}
            onClick={() => scrollToTransactions(transactionHistoryRef)}
          >
            <span>Tap to scroll to transactions</span>
            <IoIosArrowDown className={styles.arrowDown} />
          </div>

          <MobileTransactionView
            transactionHistoryRef={transactionHistoryRef}
            scrollToTop={scrollToTop}
            overviewRef={overviewRef}
            loanHistory={loanHistory}
            isRefetchingLoanDetails={isRefetchingLoanDetails}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            isLoanHistoryLoading={isLoanHistoryLoading}
          />
        </div>
      ) : (
        <div className={styles.noLoanHistory_container}>
          <div className={styles.content_wrap}>
            <p>Apply for a low - interest loans in minutes</p>
            <button onClick={handleLoanRequestModal}>Get started</button>
          </div>
          <img src={bgIconWeb} alt="" />
        </div>
      )}
    </>
  );
}

export default OverviewComp;
