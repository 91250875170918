import React, { ReactNode, useEffect, useState } from "react";
import styles from "./Layout.module.scss";
import embedLogo from "../../../assets/web/Embed.svg";
import SidebarMenu from "../sidebarMenu/SidebarMenu";
import HamburgerMenu from "../menuComp/hamburgerMenu/HamburgerMenu";
import MobileMenu from "../menuComp/mobileMenu/MobileMenu";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { getToken } from "../../../helpers/appHelpers";
import { isAfter, parseISO } from "date-fns";
import { ROUTES } from "../../../helpers/ROUTES";
import { useIdleTimer } from "react-idle-timer";

interface LayoutProps {
  children: ReactNode;
}

function Layout({ children }: LayoutProps) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const location = useLocation();
  const [isPrompted, setIsPrompted] = useState(false);
  const urlExtension = location.pathname.split("/").filter(Boolean);
  const TIMEOUT_WARNING = 1000 * 60 * 4;
  const LOGOUT_AFTER_TIMEOUT = 1000 * 60;

  const token = getToken();

  const handleOnAction = () => {
    const isTokenExpired = isAfter(
      new Date(),
      parseISO(userInfo.session_timeout),
    );
    if (isTokenExpired) {
      forceLogout("Your session timed out.");
    }
  };

  const idleTimer = useIdleTimer({
    timeout:
      process.env.NODE_ENV === "development" ? 1000 * 60 * 8 : TIMEOUT_WARNING,
    onIdle: getUserLoginStatus,
    onAction: handleOnAction,
    debounce: 500,
  });

  const forceLogout = (message?: string) => {
    localStorage.clear();
    if (message) {
      alert(message);
    }
    history.replace(ROUTES.WEB_LOGIN);
  };

  useEffect(() => {
    const timeoutFunction = () =>
      setTimeout(() => {
        forceLogout("Your session timed out.");
      }, LOGOUT_AFTER_TIMEOUT);
    if (isPrompted) {
      timeoutFunction();
    } else {
      clearTimeout(timeoutFunction());
    }

    return () => {
      clearTimeout(timeoutFunction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrompted]);

  function getUserLoginStatus() {
    setIsPrompted(true);
    setTimeout(() => {
      const status = window.confirm(
        `You have been idle for a long time and you will be logged out in the next few seconds. Do you want to remain logged in?`,
      );
      if (status) {
        setIsPrompted(false);
        idleTimer.reset();
      } else {
        setIsPrompted(false);
        forceLogout();
      }
    }, 200);
  }

  const { search } = useLocation();
  useEffect(() => {
    const redirectUrl = search?.split("?redirectUrl=")[1];
    if (redirectUrl && token) {
      return history.push(redirectUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  if (!token) {
    if (urlExtension[1] && urlExtension[2]) {
      return (
        <Redirect
          to={`/${urlExtension[0]}/${urlExtension[1]}/${urlExtension[2]}/login`}
        />
      );
    } else {
      return <Redirect to={`/${urlExtension[0]}/login`} />;
    }
  }

  return (
    <>
      <div className={styles.layout_container}>
        <header className={styles.header}>
          <div className={styles.logo_wrapper}>
            <img src={embedLogo} alt="" />
            <span className={styles.logo}>embed</span>
          </div>

          <HamburgerMenu open={open} setOpen={setOpen} />
        </header>

        <section className={styles.layout_content_wrapper}>
          <SidebarMenu />
          <div className={styles.children_container}>{children}</div>
        </section>
      </div>
      <MobileMenu open={open} setOpen={setOpen} />
    </>
  );
}

export default Layout;
