import React, { useEffect } from "react";
import "./style/theme.css";
import "./App.less";
import "./style/styles.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// import Login from "./components/Login";
import WidgetFlow from "./components/Widget/WidgetFlow";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import { ROUTES } from "./helpers/ROUTES";
import WidgetFlowEmbedApi from "./components/Widget/EmbedApiSecurity/WidgetFlowEmbedApi";
import WebDashboard from "./web/pages/userData/UserData";
import WebLogin from "./web/pages/login/Login";
import WebSignUp from "./web/pages/signUp/SignUp";
import ResetPassword from "./web/pages/resetPassword/ResetPassword";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "./interfaces/redux-interface";
import DirectdebitConfirmationPage from "./web/pages/directdebitConfirmationPage/DirectdebitConfirmationPage";
import InvalidPartner from "./web/pages/InvalidPartner/InvalidPartner";

function App() {
  const widgetSettings = useSelector(
    (state: ReduxStoreModel) => state.widgetSettingsReducer,
  );

  // useEffect(() => {
  //   console.log({
  //     widgetSettings,
  //   });
  // }, [widgetSettings]);

  useEffect(() => {
    if (widgetSettings && widgetSettings.customStyles) {
      document.body.style.setProperty(
        "--primary-color",
        widgetSettings.customStyles?.primaryColor,
      );
      document.body.style.setProperty(
        "--primary-light-color",
        widgetSettings.customStyles?.primaryLightColor,
      );
      document.body.style.setProperty(
        "--primary-deep-color",
        widgetSettings.customStyles?.primaryDeepColor,
      );
    }
  }, [widgetSettings]);

  return (
    <Router>
      <Switch>
        {/* <Route exact path={ROUTES.login} component={Login} /> */}
        <Route path={ROUTES.widget} component={WidgetFlow} />
        <Route
          path={ROUTES.widgetEmbedApiSecurity}
          component={WidgetFlowEmbedApi}
        />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms-of-use" component={Terms} />
        {/* <Route exact path="/" component={Login} /> */}

        {/* For Web Routes */}
        <Route exact path={ROUTES.WEB_LOGIN}>
          <WebLogin />
        </Route>
        <Route exact path={ROUTES.WEB_LOGIN_PRODUCTDIVE}>
          <WebLogin />
        </Route>
        <Route path={ROUTES.WEB_SIGN_UP}>
          <WebSignUp />
        </Route>
        <Route path={ROUTES.WEB_SIGN_UP_PRODUCTDIVE}>
          <WebSignUp />
        </Route>
        <Route path={ROUTES.RESET_PASSWORD}>
          <ResetPassword />
        </Route>
        <Route path={ROUTES.RESET_PASSWORD_PRODUCTDIVE}>
          <ResetPassword />
        </Route>
        <Route path={ROUTES.WEB_DIRECT_DEBIT_CONFIRMATION}>
          <DirectdebitConfirmationPage />
        </Route>
        <Route path={ROUTES.WEB_INVALIDPARTNER}>
          <InvalidPartner />
        </Route>
        <Route
          exact
          path="/"
          render={() => <Redirect to={ROUTES.WEB_INVALIDPARTNER} />}
        />
        <Route path={ROUTES.WEB_DASHBOARD}>
          <WebDashboard />
        </Route>
        <Route path={ROUTES.WEB_DASHBOARD_PRODUCTDIVE}>
          <WebDashboard />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
