import React from "react";
import styles from "./MobileMenu.module.scss";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { RiHome5Fill } from "react-icons/ri";
import { FaCoins, FaHeadphones, FaUser } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import nouser from "../../../../assets/web/nouser.svg";
import mixpanel from "mixpanel-browser";
import HamburgerMenu from "../hamburgerMenu/HamburgerMenu";


interface SidebarProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function MobileMenu({ open, setOpen }: SidebarProps) {
  const location = useLocation();
  const history = useHistory();
  const data = localStorage.getItem("currentUser")
  const userInfo = data && JSON.parse(data)

  const handleNavigation = (path: string) => {
    setOpen(false);
    document.body.style.overflow = "auto"; // Re-enable scrolling
    history.push(path);
  };

  const handleLogout = () => {
    document.body.style.overflow = "auto"; // Re-enable scrolling
    localStorage.removeItem("currentUser")
    localStorage.removeItem("token")
    localStorage.removeItem("firstTimeLogin")
    history.push(`/${userInfo?.aggregator_name}/login`);
    mixpanel.reset();
  };

  return (
      <>
        <div className={`${open && styles.overlay}`}></div>
        <div className={`${styles.mobile_menu_container} ${open && styles.open}`}>
          <header className={styles.overview_header}>
            <div className={styles.hamburger}>
              <HamburgerMenu open={open} setOpen={setOpen} />
            </div>
            <img src={nouser} alt='' className={styles.avatar}/>
            <h1 className={styles.greeting}>Hi {userInfo?.first_name}👋🏽</h1>
          </header>
          <nav className={styles.sidebar}>
            <ul className={styles.list_container}>
              <li className={styles.list_child}>
                <NavLink to={`/${userInfo?.aggregator_name}`}
                         activeClassName={location.pathname === `/${userInfo?.aggregator_name}` ? styles.selected : ""}
                         onClick={() => handleNavigation(`/${userInfo?.aggregator_name}`)}>
                  <div className={styles.list_child_wrap}>
                    <RiHome5Fill className={styles.icon}/>
                    <span>Home</span>
                  </div>
                </NavLink>
              </li>
              <li className={styles.list_child}>
                <NavLink to={`/${userInfo?.aggregator_name}/loan`} activeClassName={styles.selected}
                         onClick={() => handleNavigation(`/${userInfo?.aggregator_name}/loan`)}>
                  <div className={styles.list_child_wrap}>
                    <FaCoins className={styles.icon}/>
                    <span>Loans</span>
                  </div>
                </NavLink>
              </li>
              <li className={styles.list_child}>
                <NavLink to={`/${userInfo?.aggregator_name}/profile`} activeClassName={styles.selected}
                         onClick={() => handleNavigation(`/${userInfo?.aggregator_name}/profile`)}>
                  <div className={styles.list_child_wrap}>
                    <FaUser className={styles.icon}/>
                    <span>Profile</span>
                  </div>
                </NavLink>
              </li>
              <li className={styles.list_child}>
                <NavLink to={`/${userInfo?.aggregator_name}/contact-us`} activeClassName={styles.selected}
                         onClick={() => handleNavigation(`/${userInfo?.aggregator_name}/contact-us`)}>
                  <div className={styles.list_child_wrap}>
                    <FaHeadphones className={styles.icon}/>
                    <span>Contact Us</span>
                  </div>
                </NavLink>
              </li>
            </ul>
          </nav>

          <button onClick={handleLogout}>
            <IoMdLogOut className={styles.icon}/>
            <span>Logout</span>
          </button>
        </div>
      </>
  );
}

export default MobileMenu;
