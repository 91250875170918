import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const fetchUserTransactions = async ({
  accountNumber,
}: {
  accountNumber: number;
}) => {
  const res = await getData(
    `${apiEndpoints.customerWalletTransactions}?accountNumber=${accountNumber}`,
  );

  return res.data.transactions;
};

export default function useFetchUserTransaction({
  accountNumber,
}: {
  accountNumber: number;
}) {
  return useQuery(
    [{ accountNumber }, "fetchUserTransaction"],
    fetchUserTransactions,
    {
      // refetchOnWindowFocus: true,
      refetchInterval: 5 * 1000 * 60,
      enabled: accountNumber ? true : false,
    },
  );
}
