import React from "react";
import styles from "./HamburgerMenu.module.scss"

interface HamburgerMenuProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function HamburgerMenu({ open, setOpen }: HamburgerMenuProps) {

  const handleSidebarSlider = () => {
    setOpen((prev) => !prev);
    if (open) {
      document.body.style.overflow = "auto"; // Re-enable scrolling
    } else {
      document.body.style.overflow = "hidden"; // Disable scrolling
    }
  };

  return (
      <div
          className={`${styles.menuBtn} ${open && styles.open}`}
          onClick={handleSidebarSlider}>
        <div className={styles.menuBtnBurger}></div>
      </div>
  );
}

export default HamburgerMenu;
