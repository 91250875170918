import React, { useEffect, useRef, useState, Dispatch, SetStateAction, ChangeEvent } from "react";
import styles from "./ResetPin.module.scss";
import CustomInputField from "../../../../../components/CustomHTMLElements/EmbedCustomInputField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../../helpers/yupValidators";
import useFetchSecurityQuestions from "../../../../../custom-hooks/useFetchSecurityQuestion";
import { Question } from "../../../../../interfaces/question";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { postData } from "../../../../../apis/apiMethods";
import { getToken } from "../../../../../helpers/appHelpers";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../interfaces/redux-interface";
import { updateWebSettingsAction } from "../../../../../redux/actions";
import { Alert } from "antd";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  securityQuestion: yupValidators.securityQuestion,
  securityAnswer: yupValidators.securityAnswer,
  pin: yupValidators.pin,
});

interface FormData {
  securityQuestion: string;
  securityAnswer: string;
  pin: string;
}

interface ResetProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

function ResetPin({ setCurrentStep }: ResetProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredQuestionsList, setFilteredQuestionsList] = useState<
    Question[]
  >([]);
  const [questionLists, setQuestionsList] = useState<Question[]>([]);
  const [selectedQuestionDetails, setselectedQuestionDetails] =
    useState<Question>();
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const divRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer
  );
  const urlExtension = location.pathname.split("/").filter(Boolean);

  const { data: questions, isLoading: isLoadingQuestions } =
    useFetchSecurityQuestions({
      token: getToken(),
    });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (TransactionPin.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const getQuestionId = (question: string) => {
    const result = questionLists.filter((questions) => {
      const foundQuestion = questions.question === question;
      return foundQuestion
        ? setselectedQuestionDetails({
            ...selectedQuestionDetails,
            id: questions.id,
          })
        : null;
    });

    return result;
  };

  useEffect(() => {
    if (selectedQuestionDetails?.question) {
      getQuestionId(selectedQuestionDetails?.question);
    }
  }, [selectedQuestionDetails?.question]);

  useEffect(() => {
    setQuestionsList(questions);
  }, [isLoadingQuestions]);

  const onSubmit = async ({ securityAnswer, pin }: FormData) => {
    try {
      setCurrentStep(2);
      const reqBody = {
        aggregator_id: parseInt(userInfo?.aggregatorId),
        customer_id: parseInt(userInfo?.customerId),
        question_id: Number(selectedQuestionDetails?.id),
        answer: securityAnswer,
        pin,
      };
      await postData(apiEndpoints.customerResetPin, reqBody);
      setCurrentStep(3);

      dispatch(
        updateWebSettingsAction({
          errorMsg: "",
        })
      );
    } catch (error) {
      setCurrentStep(4);

      setTimeout(() => {
        dispatch(
          updateWebSettingsAction({
            errorMsg: error.response?.data?.message || "Failed to reset pin",
          })
        );
      }, 0);

      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (TransactionPin.tsx)",
        },
      });
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setselectedQuestionDetails({
      ...selectedQuestionDetails,
      question: value,
    });

    setFilteredQuestionsList(
      questionLists.filter(
        (questions) => questions.question?.toLowerCase() === value.toLowerCase()
      )
    );

    setShowDropdown(true);
  };

  const handleSelectBank = (question: Question) => {
    setselectedQuestionDetails({
      ...selectedQuestionDetails,
      question: question.question,
    });

    setShowDropdown(false);
  };

  const handleClose = (e: any) => {
    if (divRef?.current && !divRef?.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, []);

  return (
    <div className={styles.resetPin_container}>
      {webStateData.errorMsg && urlExtension[1] !== "profile" && (
        <div
          style={{ width: "100%", paddingTop: "50px", paddingBottom: "20px" }}
        >
          <Alert
            message={webStateData.errorMsg}
            type="error"
            showIcon
            closable
            onClose={() => dispatch(updateWebSettingsAction({ errorMsg: "" }))}
          />
        </div>
      )}
      <header className={styles.resetPin_header}>
        <h3>Reset Transaction Pin</h3>
        <p>Complete the steps below to reset your transaction pin.</p>
      </header>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`${styles.resetPin_form} ${
          location.pathname === `/${userInfo?.aggregator_name}` && styles.left
        }`}
      >
        <div ref={divRef} className={styles.selectQuestionContainer}>
          <label className="label-txt">Question</label>
          <input
            type="text"
            name="securityQuestion"
            ref={register}
            placeholder={
              isLoadingQuestions ? "loading, please wait" : "Select question"
            }
            value={selectedQuestionDetails?.question}
            onChange={handleInputChange}
            onFocus={() => setShowDropdown(true)}
            className={styles.selectedQuestionField}
            disabled={isLoadingQuestions}
          />
          {showDropdown && (
            <ul className={styles.questionDropdown}>
              {filteredQuestionsList?.length > 0
                ? filteredQuestionsList?.map((question) => (
                    <li
                      key={question.id}
                      onClick={() => handleSelectBank(question)}
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      {question.question}
                    </li>
                  ))
                : questionLists?.map((question) => (
                    <li
                      key={question.id}
                      onClick={() => handleSelectBank(question)}
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      {question.question}
                    </li>
                  ))}
            </ul>
          )}
          <span className={styles.question_error}>
            {errors.bankCode?.message}
          </span>
        </div>

        <CustomInputField
          label="Answer"
          name="securityAnswer"
          type="text"
          placeholder="Enter answer"
          reference={register}
          errors={errors.securityAnswer}
        />

        <CustomInputField
          label="New PIN"
          type="password"
          reference={register}
          placeholder="****"
          hasActionButton={true}
          name="pin"
          errors={errors.pin}
          maxLength={4}
        />

        <button type="submit" className={styles.submit_button}>
          Proceed
        </button>
      </form>
    </div>
  );
}

export default ResetPin;
