import React, { useState } from "react";
import { enforceMaxLength } from "../../helpers/enforceMaxLength";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
import { BsEye, BsEyeSlash } from "react-icons/bs";


type CustomInputFieldProps = {
  type: string;
  name?: string;
  placeholder?: string;
  label?: string;
  errors?: any;
  maxLength?: undefined | number;
  max?: number | string;
  defaultValue?: string | number;
  minLength?: undefined | number;
  onChange?: any;
  hasActionButton?: boolean;
  step?: number | string | undefined;
  reference?: any;
  hasPrepend?: boolean;
  PrependComponent?: React.ComponentProps<"div">;
};

const CustomInputField = ({
  type,
  name,
  placeholder,
  reference,
  label,
  errors,
  maxLength,
  max,
  defaultValue,
  minLength,
  onChange,
  hasActionButton,
  step,
  PrependComponent,
  hasPrepend,
  ...otherProps
}: CustomInputFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="mb-4">
      <label className="label-txt">{label}</label>
      <div className="input-group">
        {hasPrepend && PrependComponent}
        <input
          className={`form-control ${hasActionButton && "border-right-0"}`}
          type={
            showPassword ? (showPassword === true ? "text" : "password") : type
          }
          name={name}
          max={max}
          placeholder={placeholder}
          ref={reference}
          onKeyPress={enforceMaxLength}
          maxLength={maxLength}
          minLength={minLength}
          defaultValue={defaultValue}
          onChange={onChange}
          step={step}
          {...otherProps}
        />
        {hasActionButton && (
          <div
            className="input-group-append cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            <span className="input-group-text text-capitalize">
              {showPassword ? <BsEyeSlash /> : <BsEye/>}
            </span>
          </div>
        )}
      </div>
      <div className="text-left mb-2 mt-1">
        <ErrorHandler errors={errors} />
      </div>
    </div>
  );
};

export default CustomInputField;
