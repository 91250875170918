import React, { Dispatch, SetStateAction, useEffect } from "react";
import styles from "./LoanRequest.module.scss";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { MixPanel } from "../../../../helpers/mixpanel";

interface LoanRequestProps {
  onNextStep: () => void;
  onPrevStep: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  walletDetails: any;
}

function LoanRequestStepTwo({
  onNextStep,
  onPrevStep,
  walletDetails,
}: LoanRequestProps) {
  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (LoanRequestStepTwo.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: LoanRequestStepTwo.tsx`, {
      page: "LoanRequestStepTwo.tsx - (Web)",
    });
  }, []);

  return (
    <div className={styles.stepTwo_container}>
      <img
        src={arrowLeft}
        alt=""
        className={styles.arrow}
        onClick={onPrevStep}
      />
      <header className={styles.stepTwo_header}>
        <h3>Loan Request</h3>
        <p>
          Funds will be disbursed into this wallet when your loan is approved
        </p>
      </header>

      <div className={styles.loanAmount_container}>
        <span className={styles.title}>Wallet Balance</span>
        {walletDetails?.availableBalance
          .toLocaleString()
          .split("")
          .includes(".") ? (
          <span className={styles.credit_balance}>
            ₦
            <strong>
              {walletDetails?.availableBalance?.toLocaleString() ?? 0}
            </strong>
          </span>
        ) : (
          <span className={styles.credit_balance}>
            ₦
            <strong>
              {walletDetails?.availableBalance?.toLocaleString() ?? 0}.
            </strong>
            00
          </span>
        )}
      </div>

      <button className={styles.submit_button} onClick={onNextStep}>
        Proceed
      </button>
    </div>
  );
}

export default LoanRequestStepTwo;
