import { IMonoWidgetData } from "../../interfaces/monoWidgetData";
import { ReduxActionModel } from "../../interfaces/redux-interface";
import { MONO_WIDGET_DATA } from "../actions/types";

let initialState: IMonoWidgetData = {
    opened: false,
    opened_dateTimeStamp: null,
    customer_email: null,
    is_institution_selected: false,
    selected_institution_name: null,
    institution_selected_dateTimeStamp: null,
};

export const updateMonoWidgetDataReducer = (
  state = initialState,
  action: ReduxActionModel
) => {
  const { type, payload } = action;
  switch (type) {
    case MONO_WIDGET_DATA:
      return { ...state, ...payload };
    default:
      return state;
  }
};
