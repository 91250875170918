import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import { message } from "antd";
import { errorHandler } from "../helpers/errorHandler";

const fetchBanks = async ({ country_code }: { country_code: string }) => {
  try {
    const res = await getData(
      `${apiEndpoints.customerBanks}?country_code=${country_code}`,
    );
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data);
    message.error(errorHandler(error));
  }
};

export default function useFetchBanks({
  country_code,
}: {
  country_code: string;
}) {
  return useQuery([{ country_code }, "fetchBanks"], fetchBanks, {
    refetchOnWindowFocus: true,
    enabled: country_code ? true : false,
  });
}
