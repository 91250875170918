import { getData } from "../apis/apiMethods";
import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";

interface ExistingUserProps {
  identity_number: string;
  phone_number: string;
  aggregator_id: number;
  email: string;
  business_registration_number: string;
}

const fetchExistingUser = async ({
  identity_number,
  phone_number,
  aggregator_id,
  email,
  business_registration_number,
}: ExistingUserProps) => {
  const res = await getData(
    `${apiEndpoints.checkCustomerExistsWeb}?identity_number=${identity_number}&phone_number=${phone_number}&aggregator_id=${aggregator_id}&email=${email}&business_registration_number=${business_registration_number}`
  );

  return res;
};

export default function useFetchUserExists({
  identity_number,
  phone_number,
  aggregator_id,
  email,
  business_registration_number,
}: ExistingUserProps) {
  return useQuery(
    [
      {
        identity_number,
        phone_number,
        aggregator_id,
        email,
        business_registration_number,
      },
      "fetchExistingUser",
    ],
    fetchExistingUser,
    {
      enabled: aggregator_id,
    }
  );
}
