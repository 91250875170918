import React from "react";
import closeIcon from "../../../../assets/web/close-square.svg";
import styles from "./DateRangeSelector.module.scss";
import { SearchQuery } from "../../dashboard/mobileTransactionView/MobileTransactionView";
import { Button } from "antd";

interface DateRangeSelectorProps {
  setShowDateRangeSelector: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirmSelection: () => void;
  searchQuery: SearchQuery;
  setSearchQuery: React.Dispatch<React.SetStateAction<SearchQuery>>;
}

function DateRangeSelector({
  setShowDateRangeSelector,
  handleConfirmSelection,
  searchQuery,
  setSearchQuery,
}: DateRangeSelectorProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    setSearchQuery({
      ...searchQuery,
      [name]: value,
    });
  };

  const handleClose = () => {
    setShowDateRangeSelector(false);
  };

  return (
    <div className={styles.modal_container}>
      <div className={styles.modal_wrapper}>
        <header className={styles.header}>
          <h4>Select Period</h4>
          <img
            src={closeIcon}
            alt=""
            className={styles.cancel_button}
            onClick={handleClose}
          />
        </header>

        <div className={styles.form}>
          <div className={styles.startDate}>
            <label>Start Date</label>
            <input
              type="date"
              name="startDate"
              value={searchQuery.startDate}
              placeholder="Select Date"
              onChange={handleChange}
            />
          </div>

          <div className={styles.endDate}>
            <label>End Date</label>
            <input
              type="date"
              name="endDate"
              value={searchQuery.endDate}
              placeholder="Select Date"
              onChange={handleChange}
            />
          </div>

          <Button
            type="primary"
            ghost
            className={styles.clearBtn}
            onClick={() => setSearchQuery({ startDate: "", endDate: "" })}
          >
            Clear filter
          </Button>

          <div className={styles.btn}>
            <button onClick={handleConfirmSelection}>Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DateRangeSelector;
