import React, { Fragment } from "react";
import styles from "./WidgetCustomTitle.module.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useHistory } from "react-router-dom";

type WidgetCustomTitleProps = {
  title: string;
  step: number;
  totalSteps?: number;
  extraText?: string;
  showDismissButton?: boolean;
};
export default function WidgetCustomTitle({
  title,
  step,
  totalSteps,
  extraText,
  showDismissButton,
}: WidgetCustomTitleProps) {
  const history = useHistory();
  return (
    <div className={styles.widgetCustomTitle}>
      <div className={styles.top}>
        <div className={styles.title}>{title}</div>
        {showDismissButton ? (
          <div onClick={() => history.goBack()}>
            <i className="ri-close-fill ri-lg cursor-pointer"></i>
          </div>
        ) : (
          <Fragment>
            {step && totalSteps && (
              <div className={styles.progressBar}>
                <CircularProgressbar
                  value={step}
                  text={`${step}/${totalSteps}`}
                  minValue={0}
                  maxValue={totalSteps}
                  styles={buildStyles({
                    textSize: "24px",
                    pathColor: "#2C6EEC",
                    textColor: "#fff",
                    trailColor: "#F0F0F0",
                    backgroundColor: "#2C6EEC",
                  })}
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
      {extraText && <div>{extraText}</div>}
    </div>
  );
}
