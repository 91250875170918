import React, { useEffect, useState } from "react";
import styles from "./Topup.module.scss";
import TopUpStepTwo from "./components/TopUpStepTwo";
import logo from "../../../../assets/advancly-logo-full.png";
import loader from "../../../../assets/loadinggif.gif";
import success from "../../../../assets/success.svg";
import failure from "../../../../assets/fail.svg";
import { ROUTES } from "../../../../helpers/ROUTES";
import { useHistory } from "react-router-dom";
import { getDataWithKey } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { errorHandler, errorToast } from "../../../../helpers/errorHandler";
import { appInsights } from "../../../AppInsight/AppInsight";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { useSelector } from "react-redux";
import { MixPanel } from "../../../../helpers/mixpanel";
import TopUpStepOne from "./components/TopUpStepOne";

export interface IFetchWallet {
  encryptedKey?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  bvn_number?: number;
}

const TopUpComponent = () => {
  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );
  const { customer_id, aggregator_id, email } = borrowerDetails;
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [topUpAmount, setTopUpAmount] = useState<string | null>(null);

  const [walletDetails, setWalletDetails] = useState<any>();

  useEffect(() => {
    appInsights.trackPageView({
      name: "TopUp.component.tsx",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: TopUp.tsx`, {
      page: "TopUp.tsx - (Widget)",
    });
  }, []);

  async function fetchWallet() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getWallet}?borrower_id=${customer_id}&aggregator_id=${aggregator_id}`,
      );
      setWalletDetails(response.data);
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: TopUpComponent },
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (customer_id && aggregator_id) {
      fetchWallet();
    }
    //eslint-disable-next-line
  }, [customer_id, aggregator_id]);

  const handleGoBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      history.push(ROUTES.widgetDashboard);
    }
  };

  const handleNext = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const handleValidateTransfer = () => {
    setShowFailure(false);
    setShowSuccess(false);
    setIsLoading(true);
    setTimeout(() => {
      MixPanel.track("Wallet Top Up", {
        distinct_id: `${email}`,
        amount: topUpAmount,
        status: "TopUp SuccessFul, Awaiting Confirmation",
        product: "Embed Widget",
      });
      setIsLoading(false);
      setShowSuccess(true);
    }, 500);
  };

  const steps = [
    <TopUpStepOne
      handleNext={handleNext}
      handleGoBack={handleGoBack}
      walletDetails={walletDetails}
      setTopUpAmount={setTopUpAmount}
    />,
    <TopUpStepTwo
      walletDetails={walletDetails}
      handleGoBack={handleGoBack}
      handleNext={handleNext}
      topUpAmount={topUpAmount}
      handleValidateTransfer={handleValidateTransfer}
    />,
  ];

  const successModal = () => {
    return (
      <>
        <div className="d-flex justify-content-center">
          <img
            src={success}
            alt=""
            className="img-fluid"
            style={{
              height: "138px",
              width: "137px",
              objectFit: "contain",
            }}
          />
        </div>

        <p className="title text-center">Transaction Complete</p>
        <p className="text-center">
          Your balance will reflect your recent top-up once your payment has
          been completed and processed by your bank
        </p>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary btn-radius"
            onClick={() => history.push(ROUTES.widgetDashboard)}
          >
            Done
          </button>
        </div>
      </>
    );
  };

  const failureModal = () => {
    return (
      <>
        <div className="d-flex justify-content-center">
          <img
            src={failure}
            alt=""
            className="img-fluid"
            style={{
              height: "138px",
              width: "137px",
              objectFit: "contain",
            }}
          />
        </div>

        <p className="title text-center">Transaction Failed</p>
        <p className="text-center">Something went wrong</p>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary btn-radius"
            onClick={handleValidateTransfer}
          >
            Try again
          </button>
        </div>
      </>
    );
  };

  return (
    <div className={styles.topup}>
      {isLoading ? (
        <>
          <br />
          <div className="d-flex justify-content-center">
            <img
              src={logo}
              alt=""
              className="img-fluid"
              style={{
                height: "24px",
                width: "126.53px",
                objectFit: "contain",
              }}
            />
          </div>
          <br />
          <br />
          <div className="d-flex justify-content-center">
            <img
              src={loader}
              alt=""
              className="img-fluid"
              style={{
                height: "20%",
                width: "50%",
                objectFit: "contain",
              }}
            />
          </div>
          <br />
          <br />
          {/*<p className="text-center">Checking transaction status...</p>*/}
        </>
      ) : showSuccess ? (
        successModal()
      ) : showFailure ? (
        failureModal()
      ) : (
        steps[currentStep]
      )}
    </div>
  );
};

export default TopUpComponent;
