import React from 'react'
import styles from "./Failed.module.scss"

function Failed() {
  return (
    <div className={styles.failed_container}>
        <div className={styles.verification_failed}>
            <div className={styles.box}></div>
            <h3>Operation Failed</h3>
            <p>Something went wrong</p>
        </div>
    </div>
  )
}

export default Failed