import React from "react";
import styles from "./WidgetLoader.module.scss";
import ButtonLoader from "../../ButtonLoader/ButtonLoader.component";
import loader from "../../../assets/loadinggif.gif";
import progresslogo from "../../../assets/ProgressLogo.png";
export default function WidgetLoader({ showWidgetAnimation }) {
  return (
    <div className={styles.widgetLoader}>
      {showWidgetAnimation ? (
        // <img src={AdvanclyLogo} alt="Powered by Advancly" height="30" />
        <div className="d-flex flex-column justify-content-center m-0 p-0">
          <img
            src={progresslogo}
            alt="advancly-logo"
            className="d-flex justify-content-center"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              height: "10%",
              width: "10%",
              objectFit: "contain",
            }}
          />
          <img
            src={loader}
            alt="advancly-animated-loader"
            className="img-fluid"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              height: "60%",
              width: "60%",
              objectFit: "contain",
            }}
          />
          <p
            className="text-center"
            style={{
              fontSize: "1rem",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Loading Resource
          </p>
        </div>
      ) : (
        <ButtonLoader type="lg" noMarginLeft={true} />
      )}
    </div>
  );
}
