import React from 'react'
import Loader from '../../../../../components/Loader/Loader'
import styles from "./Spinner.module.scss"

interface SpinnerProps {
  currentStep: number
}

function Spinner({currentStep}: SpinnerProps) {
  return (
    <div className={styles.spinner_container}>
        <Loader message="Please wait..." />
    </div>
  )
}

export default Spinner