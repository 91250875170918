import React, { useRef, useState } from 'react'
import styles from "./OtpInput.module.scss"

interface OtpInputProps {
    length?: number
    onComplete: (pin: string) => void
}

function OtpInput({length=5, onComplete}: OtpInputProps) {
    const [otp, setOtp] = useState<string[]>(Array(length).fill(""))
    const inputRef = useRef<HTMLInputElement[]>(Array(length).fill(null))

    const handleTextChange = (input: string, index: number) => {
        const newPin = [...otp]
        newPin[index] = input
        setOtp(newPin)

        if (input.length === 1 && index < length - 1) {
            inputRef.current[index + 1]?.focus();
        }
    
        if (input.length === 0 && index > 0) {
            inputRef.current[index - 1]?.focus();
        }
    
        if (newPin.every((digit) => digit !== '')) {
            onComplete(newPin.join(''));
        }
    }
    
  return (
    <div className={styles.otp_container}>
        {Array.from({length}, (_, index) => (
            <input key={index}
            type="password"
            maxLength={1}
            value={otp[index]}
            onChange={(e) => handleTextChange(e.target.value, index)}
            ref={(ref) => (inputRef.current[index] = ref as HTMLInputElement)}
            style={{ marginRight: index === length - 1 ? '0' : '10px' }} className={styles.code} />
        ))}
    </div>
  )
}

export default OtpInput