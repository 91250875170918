export interface IBorrowerData {
  first_name: string;
  customer_id: number;
  last_name: string;
  email: string;
  borrower_phone: string;
  aggregator_id: number;
  aggregator_name?: string;
  aggregator_domain: string;
  first_time_borrower: boolean;
  bvn_number: string;
  bank_code: string;
  bank_account_number: string;
  bank_name?: string;
  returningUserDetails: any | null; // You can replace 'any' with a more specific type if needed
  customer_type: string;
  company_name: string;
  customer_category?: string;
  isApi?: boolean;
  business_registration_number: string;
  country_code: string;
  currency?: CurrencyProp;
  bank_statement_provider?: any | null; // You can replace 'any' with a more specific type if needed
  bank_statement_provider_id?: string | null;
  bank_statement_connected: boolean | null;
  password?: string;
  phoneNoCountryDialCode?: string;
  gender?: string;
  isEmailVerified?: boolean;
  dob?: string;
  isBvnValidated?: boolean;
  partner?: string;
  country?: string;
  session_timeout?: any;
  wallet_id: string;
  isDefault?: boolean;
  accountStatus?: string;
  accountName?: string;
  transferCharge?: number;
  isTransactionPinSet?: boolean;
  isSecurityQuestionSet?: boolean;
  firstTimeLogin?: boolean;
  identity_number?: string;
  customer_phone?: string;
  customStyles?: {
    primaryColor: string;
    primaryDeepColor: string;
    primaryLightColor: string;
  };
  courseAmount: any;
  courseDescription: string | undefined;
}

export interface IWidgetState {
  edit_widget: boolean;
  finishedFirstInitializations: boolean;
  showWidgetAnimation: boolean;
  bankStatementWidgetOpened: boolean;
  use_wallet: boolean;
  allow_contact_us: boolean;
  allow_withdrawal: boolean;
  allow_pin_change: boolean;
  allow_sms_notification: boolean;
  allow_bank_details: boolean;
  allow_view_balance: boolean;
  bank_details_input_enabled: boolean;
  canCloseWidget: boolean;
  public_key: string;
  state_token: string;
  encryptedKey: string;
  bankList: any[]; // You can replace 'any' with a more specific type if needed
  worldCountries: any[]; // You can replace 'any' with a more specific type if needed
  customStyles: {
    primaryColor: string;
    primaryDeepColor: string;
    primaryLightColor: string;
  };
  showNewLoanOffer: boolean;
  showLoanDetails: boolean;
  isDirectDebit: boolean;
  isGlobalLoader: boolean;
}

export interface Setting {
  setting_id: number;
  aggregator_id: number;
  country_code: string;
  setting_name: string;
  setting_value: string;
}

export interface WebAggregatorSettingsState {
  data: Setting[];
}

export const defaultCustomStyles = {
  primaryColor: "#713fff", // Can be string or the color code
  primaryDeepColor: "#29067d", // Can be string or the color code
  primaryLightColor: "#E3D9FF", // Can be string or the color code
};

export interface DataFromAggregatorProps {
  isApi?: boolean;
  aggregator_id: number;
  aggregator_loan_ref: string;
  amount: number;
  bank_account_number: string;
  bank_code: string;
  borrower_phone: string;
  identity_number: string;
  city: string;
  company_name: string;
  country_code: string;
  customer_type: "1" | "2" | "";
  customer_category: string;
  email: string;
  first_name: string;
  gender: string;
  last_name: string;
  photo_url: null;
  product_id: number;
  public_key: string;
  business_registration_number: string;
  residence_address: string;
  state: string;
  tenure: number;
  customer_phone: string;
  bank_name: string;
  customStyles: {
    primaryColor: string;
    primaryDeepColor: string;
    primaryLightColor: string;
  };
}

export interface WorldCountriesProp {
  name: string;
  iso3: string;
  iso2: string;
  dialCode: string;
  flag: string;
  id: string;
}

export type CurrencyProp = "NGN" | "KSH" | string;
