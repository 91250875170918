import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import { useQuery } from "react-query";

const fetchNextRepayment = async ({ customerId }: { customerId: number }) => {
  try {
    const res = await getData(
      `${apiEndpoints.nextRepayment}?customerId=${customerId}`,
    );

    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export default function useFetchNextRepayment({
  customerId,
}: {
  customerId: number;
}) {
  return useQuery([{ customerId }, "fetchNextRepayment"], fetchNextRepayment, {
    refetchOnWindowFocus: true,
  });
}
