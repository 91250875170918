import { CurrencyProp } from "../WidgetRevampv2/types";

export const WIDGET_RESPONSE_STATUSES = {
  success: "onboarding_successful",
  error: "error",
  cancel: "cancel",
};
export const WIDGET_RESPONSE_LOAN_STATUSES = {
  loan_request: "loan_request_successful",
};

export const localCurrency = (isoCode: string): CurrencyProp | "" => {
  if (isoCode) {
    if (isoCode === "NG") {
      return "NGN";
    } else {
      return "KSH";
    }
  } else {
    return "";
  }
};
