import React from "react";
import loader from "../../../../assets/loadinggif.gif";
import logo from "../../../../assets/advancly-logo-full.png";
export default function NewLoader() {
  return (
    <>
      <div className="d-flex justify-content-center">
        <img
          src={logo}
          alt=""
          className="img-fluid mt-5"
          style={{
            width: "40%",
            objectFit: "contain",
          }}
        />
      </div>
      <div className="d-flex justify-content-center">
        <img
          src={loader}
          alt=""
          className="img-fluid"
          style={{
            height: "80%",
            width: "80%",
            objectFit: "contain",
          }}
        />
      </div>
    </>
  );
}
