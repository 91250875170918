import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const fetchWallet = async () => {
  try {
    const res = await getData(apiEndpoints.customerWalletDetails);

    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export default function useFetchCustomerWalletDetails() {
  return useQuery(["fetchWallet"], fetchWallet, {
    refetchInterval: 5 * 1000 * 60,
    refetchOnWindowFocus: true,
  });
}
