import { getDataWithKey } from "../apis/apiMethods";
import { apiEndpoints } from "../apis/apiEndpoints";
import { useQuery } from "react-query";
import { message } from "antd";
import { errorHandler } from "../helpers/errorHandler";

interface IProps {
  aggregator_id: number;
  public_key: string;
}

const getGeneratedAggregatorPrivateKey = async ({
  aggregator_id,
  public_key,
}: IProps) => {
  try {
    const data = await getDataWithKey(
      `${apiEndpoints.getAggregatorKey}?aggregator_id=${aggregator_id}&public_key=${public_key}`,
    );
    return data.data;
  } catch (error) {
    message.error(errorHandler(error));
  }
};
export default function useGenerateAggregatorPrivateKey({
  aggregator_id,
  public_key,
}: IProps) {
  return useQuery(
    [{ aggregator_id, public_key }, "getGeneratedAggregatorPrivateKey"],
    getGeneratedAggregatorPrivateKey,
    {
      enabled: aggregator_id && public_key ? true : false,
    },
  );
}
