import React, { useEffect, useState } from 'react'
import styles from "./WebTransactionView.module.scss";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import { WalletTransactions } from "../../../../interfaces/transactions";
import moment from "moment";
import Loader from "../../../../components/Loader/Loader";
import { Button } from "antd";
import { paginate, PaginateFunction } from "../../../../helpers/paginate";
import view from "../../../../assets/web/arrow-circle-right.svg";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { appInsights } from '../../../../components/AppInsight/AppInsight';

interface WebTransactionViewProps {
  transactions: WalletTransactions[];
  isTransactionLoading: boolean;
  setTransactionDetail: React.Dispatch<
    React.SetStateAction<WalletTransactions[] | null>
  >;
}

function WebTransactionView({
  transactions,
  isTransactionLoading,
  setTransactionDetail,
}: WebTransactionViewProps) {
  const [pageNumber, setPageNumber] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Dashboard  - (WebTransactionView.tsx)",
      isLoggedIn: true,
    });
  }, []);


  const goBack = () => {
    dispatch(updateWebSettingsAction({ showTransactions: false }));
  };

  const { nextPage, prevPage, pageSize }: PaginateFunction = paginate(
    pageNumber,
    startIndex,
    setPageNumber,
    setStartIndex
  );

  const visibleLoans = transactions?.slice(startIndex, startIndex + pageSize);

  const handleViewTransactionDetail = (id: string) => {
    const result = transactions.filter(
      (transaction) => transaction.transactionId === id
    );

    setTransactionDetail(result);
    dispatch(updateWebSettingsAction({ showTransactionDetail: true }));
  };

  return (
    <div className={styles.transaction_container}>
      <header className={styles.transaction_header}>
        <button onClick={goBack}>
          <img src={arrowLeft} alt="" />
          <span>Go back</span>
        </button>
        <h2 className={styles.title}>Transactions</h2>
      </header>

      <div className={styles.transaction_Content}>
        <table>
          <thead className={styles.table_header}>
            <tr>
              <th>Transaction Type</th>
              <th>Amount</th>
              <th>Date & Time</th>
            </tr>
          </thead>

          <tbody className={isTransactionLoading ? styles.content : ""}>
            {isTransactionLoading ? (
              <tr
                style={{
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Loader />
              </tr>
            ) : visibleLoans?.length === 0 ? (
              <p>No transactions yet</p>
            ) : (
              visibleLoans?.map((transaction) => (
                <tr
                  key={transaction.transactionId}
                  className={styles.viewInfo}
                  onClick={() =>
                    handleViewTransactionDetail(transaction.transactionId)
                  }
                >
                  <td className={styles.transaction_type}>
                    {transaction.transactionType}
                  </td>
                  {transaction.transactionType === "Debit" ? (
                    <td className={styles.transaction_amount__debit}>
                      - ₦{transaction.debitAmount?.toLocaleString()}
                    </td>
                  ) : (
                    <td className={styles.transaction_amount__credit}>
                      ₦{transaction.amount?.toLocaleString()}
                    </td>
                  )}
                  <td className={styles.transaction_date}>
                    {moment(transaction.transactionDate).format(
                      "MMM DD YYYY, hh:mma"
                    )}
                  </td>
                  <td
                    onClick={() =>
                      handleViewTransactionDetail(transaction.transactionId)
                    }
                  >
                    <img src={view} alt="" />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        <div className={styles.pagination_controls}>
          <Button onClick={prevPage} disabled={startIndex === 0}>
            Prev
          </Button>
          <button>{pageNumber}</button>
          <Button
            onClick={nextPage}
            disabled={startIndex >= (transactions?.length || 0) - pageSize}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

export default WebTransactionView;
