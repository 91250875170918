import React from "react";
import styles from "./QuickActions.module.scss";
import ActionButtons from "../actionButtons/ActionButtons";
import { wallet, addCircle, send, repay, contact } from "./Index";

interface QuickActionsProps {
  scrollToTransactions: (elementRef: React.RefObject<HTMLDivElement>) => void;
  transactionHistoryRef: React.RefObject<HTMLDivElement>;
  pendingLoanOffer: any;
}

function QuickActions({
  scrollToTransactions,
  transactionHistoryRef,
  pendingLoanOffer,
}: QuickActionsProps) {
  return (
    <div className={styles.quick_actions}>
      <header className={styles.header}>
        <h2 className={styles.header_title}>Quick Actions</h2>
        <button
          className={styles.button}
          onClick={() => scrollToTransactions(transactionHistoryRef)}
        >
          View transactions
        </button>
      </header>

      <div className={styles.button_container}>
        <ActionButtons
          icon={wallet}
          title="Get Loan"
          buttonBg="#713FFF"
          showLoanOptionsFlag={true}
          pendingLoanOffer={pendingLoanOffer}
        />
        <ActionButtons
          icon={repay}
          title="Repay Loans"
          buttonBg="#200865"
          showQuickRepayment={true}
        />
        <ActionButtons
          icon={addCircle}
          title="Top-Up"
          buttonBg="#27BE63"
          setShowTopUp={true}
        />
        <ActionButtons
          icon={send}
          title="Withdraw"
          buttonBg="#FF5E2E"
          setShowWithdraw={true}
        />
        <ActionButtons
          icon={contact}
          title="Contact Us"
          buttonBg="#0E45FB"
          setShowSupport={true}
        />
      </div>
    </div>
  );
}

export default QuickActions;
