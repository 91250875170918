import React, { useEffect } from "react";
import styles from "./Begin.module.scss";
import TransactionPINSVG from "../../../../../assets/web/set-transaction-pin.svg";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";

interface BeginProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  handleCancel: () => void;
}

function Begin({ setCurrentStep, handleCancel }: BeginProps) {
  const isUserFirstTime = localStorage.getItem("firstTimeLogin");
  const firstTimeLogin = isUserFirstTime && JSON.parse(isUserFirstTime);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web SetTransactionPin  - (Begin.tsx)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className={styles.stepOne_container}>
      <div className={styles.content_wrap}>
        <div className={styles.featureIcon}>
          <img src={TransactionPINSVG} alt="" />
        </div>
        <div className={styles.content}>
          <h2 className={styles.title}>Set Up Transaction PIN</h2>
          <p className={styles.content_body}>
            Create a transaction pin to perform wallet transactions on your
            account.
          </p>
        </div>
      </div>

      <button
        className={styles.submit_button}
        onClick={() => setCurrentStep((prev) => prev + 1)}
      >
        Begin Setup
      </button>
      {firstTimeLogin && (
        <span className={styles.skip_button} onClick={handleCancel}>
          Skip
        </span>
      )}
    </div>
  );
}

export default Begin;
