import React, { useEffect, useState } from "react";
import {
  getDataWithKey,
  postDataWithKey,
} from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import MastercardIcon from "../../../../../../assets/mastercard.svg";
import VisaIcon from "../../../../../../assets/visa.svg";
import VerveIcon from "../../../../../../assets/verve.svg";
import { BsCheck2All } from "react-icons/bs";
import { Button, message } from "antd";
import AddCard from "../../../components/AddCard";
import { CardProp } from "../../../../../../web/components/profile/card/addCard/AddCardForm";
import Loader from "../../../../../Loader/Loader";
import {
  errorHandler,
  errorToast,
} from "../../../../../../helpers/errorHandler";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";
import { useSelector } from "react-redux";

interface IProps {
  updatePayStackStatus: () => void;
  handleRepaymentWithCard: () => void;
}

const WidgetAddNewCard = ({
  updatePayStackStatus,
  handleRepaymentWithCard,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [walletDetails, setWalletDetails] = useState();
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer
  );
  const [cards, setCards] = useState<CardProp[]>([]);
  const { customer_id, aggregator_id, email } = borrowerDetails;
  useEffect(() => {
    appInsights.trackPageView({
      name: "WidgetAddNewCard.tsx",
      isLoggedIn: true,
    });
  }, []);

  const cardImage = (type: string) => {
    const cardType = type.toLowerCase().trim();
    return cardType?.includes("mastercard") ? (
      <img
        src={MastercardIcon}
        alt="logo"
        style={{ width: "40px", height: "40px" }}
      />
    ) : cardType?.includes("visa") ? (
      <img
        src={VisaIcon}
        alt="logo"
        style={{ width: "40px", height: "40px" }}
      />
    ) : cardType?.includes("verve") ? (
      <img
        src={VerveIcon}
        alt="logo"
        style={{ width: "40px", height: "40px" }}
      />
    ) : null;
  };

  async function fetchWallet() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getWallet}?borrower_id=${customer_id}&aggregator_id=${aggregator_id}`
      );

      setWalletDetails(response.data);
    } catch (error: any) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: WidgetAddNewCard },
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (customer_id && aggregator_id) {
      fetchWallet();
    }

    //eslint-disable-next-line
  }, [customer_id, aggregator_id]);

  const fetchAddedCards = async () => {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.fetchCards}?borrower_id=${customer_id}&aggregator_id=${aggregator_id}`
      );
      setCards(response.data);
    } catch (error: any) {
      message.error(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: WidgetAddNewCard },
      });
    }
    setIsLoading(false);
  };

  async function makeCardDefault(item: CardProp) {
    setIsLoading(true);
    try {
      await postDataWithKey(
        apiEndpoints.setDefaultCard,
        {
          aggregator_id: aggregator_id,
          borrower_id: customer_id,
          card_id: item.card_id,
        },
        // @ts-ignore
        wallet?.encryptedKey
      );
      await fetchAddedCards();
    } catch (error: any) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: WidgetAddNewCard },
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchAddedCards();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <h4>Cards</h4>

      {cards?.length === 0 ? (
        <>
          <h4 className="text-center">No available card</h4>
        </>
      ) : (
        cards?.map((item) => (
          <div key={item.card_id}>
            <div className="card">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className=" mr-3">{cardImage(item.card_type)}</div>
                  <div>
                    <p className="font-weight-bold text-dark padding-none">
                      {`**** **** **** ${item.last_four_digits}`}
                      <small className="ml-2 text-success">
                        {item.is_default && (
                          <span
                            className="text-success"
                            style={{ marginTop: "-4rem" }}
                          >
                            <BsCheck2All size="1.3rem" />
                          </span>
                        )}
                      </small>
                    </p>
                    <small className="text-muted padding-none">
                      {`${item.expiry_month}/${item.expiry_year}`}
                    </small>
                  </div>
                </div>

                {!item.is_default && (
                  <p
                    className="cursor padding-none"
                    onClick={() => makeCardDefault(item)}
                  >
                    Set Default
                  </p>
                )}
              </div>
            </div>
            <br />
          </div>
        ))
      )}

      <div className="d-flex justify-content-end" style={{ marginTop: "10%" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          {cards.length >= 1 && (
            <Button type="primary" ghost onClick={handleRepaymentWithCard}>
              Pay with card
            </Button>
          )}
          <AddCard
            email={email}
            updatePayStackStatus={updatePayStackStatus}
            fetchWallet={fetchWallet}
            fetchAddedCards={fetchAddedCards}
          />
        </div>
      </div>
    </div>
  );
};

export default WidgetAddNewCard;
