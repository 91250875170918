import React, { useEffect, Dispatch, SetStateAction } from "react";
import styles from "./WebTransactionView.module.scss";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import { LoanHistory } from "../../../../interfaces/loan";
import moment from "moment";
import { Button } from "antd";
// import { paginate, PaginateFunction } from "../../../../helpers/paginate"
import view from "../../../../assets/web/arrow-circle-right.svg";
import Loader from "../../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  updateLoanDetailsAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { appInsights } from '../../../../components/AppInsight/AppInsight';

interface WebTransactionViewProps {
  loanHistory: LoanHistory;
  isRefetchingLoanDetails: () => void;
  setPageNumber: Dispatch<SetStateAction<number>>;
  pageNumber: number;
  isLoanHistoryLoading: boolean;
}
enum Status {
  Active = "active",
  Repaid = "repaid",
  Approved = "approved",
  Rejected = "rejected",
  Overdue = "overdue",
  AwaitingApproval = "awaiting approval",
  SubmittedAndAwaitingApproval = "submitted_and_awaiting_approval",
  WithdrawnByClient = "withdrawnByClient",
  Closed = "closed",
  WrittenOff = "writtenOff",
  Overpaid = "overpaid",
}


function WebTransactionView({
  loanHistory,
  isRefetchingLoanDetails,
  setPageNumber,
  pageNumber,
  isLoanHistoryLoading,
}: WebTransactionViewProps) {
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Loan Dashboard  - (WebTransactionView.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const goBack = () => {
    dispatch(
      updateWebSettingsAction({
        showTransactions: !webStateData?.showTransactions,
      })
    );

    setPageNumber(1);
  };

  const selectLoan = (id: number) => {
    loanHistory?.data?.find((loan) =>
      loan?.id === id
        ? dispatch(updateLoanDetailsAction({ loan_ref: loan.loanRef }))
        : ""
    );
    setTimeout(() => {
      isRefetchingLoanDetails();
      dispatch(updateWebSettingsAction({ showLoanDetails: true }));
    }, 0);
  };

  // const { nextPage, prevPage, pageSize }: PaginateFunction = paginate(
  //   pageNumber,
  //   // startIndex,
  //   setPageNumber,
  //   // setStartIndex
  // );

  return (
    <div className={styles.transaction_container}>
      <header className={styles.transaction_header}>
        <button onClick={goBack}>
          <img src={arrowLeft} alt="" />
          <span>Go back</span>
        </button>
        <h2 className={styles.title}>Transactions</h2>
      </header>

      <div className={styles.transaction_Content}>
        <table>
          <thead className={styles.table_header}>
            <tr>
              <th>Ref No</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Maturity Date</th>
            </tr>
          </thead>

          {isLoanHistoryLoading ? (
            <tbody className={styles.isLoading_container}>
              <tr>
                <td>
                  <Loader message="Loading loan data..." />
                </td>
              </tr>
            </tbody>
          ) : loanHistory?.data?.length > 0 ? (
            <tbody>
              {loanHistory?.data?.length > 0 &&
                loanHistory?.data?.map((loan) => (
                  <tr
                    key={loan?.id}
                    onClick={() => selectLoan(loan.id)}
                    className={styles.viewInfo}
                  >
                    <td className={styles.transaction_date}>{loan?.loanRef}</td>
                    <td className={styles.transaction_amount__credit}>
                      ₦{loan?.loanAmount?.toLocaleString()}
                    </td>
                    <td
                      className={
                        loan?.status.toLowerCase() ===
                        Status.SubmittedAndAwaitingApproval
                          ? styles.flagPending
                          : loan?.status.toLowerCase() === Status.Active ||
                            loan?.status.toLowerCase() === Status.Approved
                          ? styles.flagActive
                          : loan?.status.toLowerCase() === Status.Rejected
                          ? styles.flagRejected
                          : styles.flagStale
                      }
                    >
                      {loan?.status === "Submitted_and_awaiting_approval"
                        ? "Awaiting approval"
                        : loan?.status}
                    </td>
                    <td className={styles.transaction_date}>
                      {moment(loan?.loanMaturityDate).format("D/MM/YYYY")}
                    </td>
                    <td>
                      <img src={view} alt="" />
                    </td>
                  </tr>
                ))}
            </tbody>
          ) : (
            <tbody>
              <tr style={{ marginTop: "20px" }}>
                <td>
                  <p>No transactions yet</p>
                </td>
              </tr>
            </tbody>
          )}
        </table>

        <div className={styles.pagination_controls}>
          <Button
            onClick={() => setPageNumber((prev) => prev - 1)}
            disabled={pageNumber === 1}
          >
            Prev
          </Button>
          <button>{pageNumber}</button>
          <Button
            disabled={pageNumber >= loanHistory?.totalPages}
            onClick={() => setPageNumber((prev) => prev + 1)}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

export default WebTransactionView;
