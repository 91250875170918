import React, { useMemo } from "react";
import styles from "./SidebarMenu.module.scss";
import { RiHome5Fill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { FaCoins } from "react-icons/fa";
import { FaHeadphones } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux-interface";
import { updateWebSettingsAction } from "../../../redux/actions";
import mixpanel from "mixpanel-browser";
import { Setting } from "../../../interfaces/borrower";

function SidebarMenu() {
  const location = useLocation();
  const history = useHistory();
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);

  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer,
  );

  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );

  const webAggregatorState = useSelector(
    (state: ReduxStoreModel) => state.webAggregatorSettingsReducer,
  );

  const { courseDescription, courseAmount } = borrowerDetails;

  const requireEquityContribution = useMemo(() => {
    const setting = webAggregatorState?.data?.find(
      (settings: Setting) =>
        settings.setting_name === "require_equity_contribution",
    );
    return setting?.setting_value === "1";
  }, [webAggregatorState]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("firstTimeLogin");
    history.push(`/${userInfo?.aggregator_name}/login`);
    mixpanel.reset();
  };

  const handleDashboardNavigateAction = () => {
    if (webStateData?.showTransactions) {
      dispatch(updateWebSettingsAction({ showTransactions: false }));
    }
  };

  return (
    <div className={styles.sidebar_container}>
      <nav className={styles.sidebar}>
        <ul className={styles.list_container}>
          <li className={styles.list_child}>
            <NavLink
              to={
                requireEquityContribution && courseDescription && courseAmount
                  ? `/${userInfo?.aggregator_name}/${courseDescription}/${courseAmount}`
                  : `/${userInfo?.aggregator_name}`
              }
              activeClassName={
                location.pathname === `/${userInfo?.aggregator_name}` ||
                location.pathname ===
                  `/${userInfo?.aggregator_name}/${courseDescription}/${courseAmount}`
                  ? styles.selected
                  : ""
              }
              onClick={handleDashboardNavigateAction}
            >
              <div className={styles.list_child_wrap}>
                <RiHome5Fill className={styles.icon} />
                <span>Home</span>
              </div>
            </NavLink>
          </li>
          <li className={styles.list_child}>
            <NavLink
              to={
                requireEquityContribution && courseDescription && courseAmount
                  ? `/${userInfo?.aggregator_name}/${courseDescription}/${courseAmount}/loan`
                  : `/${userInfo?.aggregator_name}/loan`
              }
              activeClassName={styles.selected}
              onClick={handleDashboardNavigateAction}
            >
              <div className={styles.list_child_wrap}>
                <FaCoins className={styles.icon} />
                <span>Loans</span>
              </div>
            </NavLink>
          </li>
          <li className={styles.list_child}>
            <NavLink
              to={
                requireEquityContribution && courseDescription && courseAmount
                  ? `/${userInfo?.aggregator_name}/${courseDescription}/${courseAmount}/profile`
                  : `/${userInfo?.aggregator_name}/profile`
              }
              activeClassName={styles.selected}
            >
              <div className={styles.list_child_wrap}>
                <FaUser className={styles.icon} />
                <span>Profile</span>
              </div>
            </NavLink>
          </li>
          <li className={styles.list_child}>
            <NavLink
              to={
                requireEquityContribution && courseDescription && courseAmount
                  ? `/${userInfo?.aggregator_name}/${courseDescription}/${courseAmount}/contact-us`
                  : `/${userInfo?.aggregator_name}/contact-us`
              }
              activeClassName={styles.selected}
            >
              <div className={styles.list_child_wrap}>
                <FaHeadphones className={styles.icon} />
                <span>Contact Us</span>
              </div>
            </NavLink>
          </li>
        </ul>
      </nav>

      <button onClick={handleLogout}>
        <IoMdLogOut className={styles.icon} />
        <span>Logout</span>
      </button>
    </div>
  );
}

export default SidebarMenu;
