import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import CustomInputField from "../../../../components/CustomHTMLElements/EmbedCustomInputField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import { Alert, Button } from "antd";
import styles from "./Password.module.scss";
import { useLocation } from "react-router-dom";
import { PasswordSection } from "./Password";
import { postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { useDispatch, useSelector } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

interface EmailProps {
  setCurrentStep: Dispatch<SetStateAction<PasswordSection>>;
  setUserEmail: Dispatch<SetStateAction<string>>;
}

const schema = yup.object().shape({
  email: yupValidators.email,
});

function EmailVerification({ setCurrentStep, setUserEmail }: EmailProps) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const location = useLocation();
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer
  );

  const urlExtension = location.pathname.split("/").filter(Boolean);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Profile  - (EmailVerification.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const onSubmit = async ({ email }: { email: string }) => {
    setIsLoading(true);
  
    try {
      await postData(
        `${apiEndpoints.generate_otp}?email=${email}&countryCode=${userInfo?.country}`
      );
      setUserEmail(email);
      setCurrentStep(PasswordSection.OtpVerification);
      dispatch(
        updateWebSettingsAction({
          errorMsg: "",
        })
      );
    } catch (error) {
      dispatch(
        updateWebSettingsAction({
          errorMsg: error.response?.data?.message ?? error.message,
        })
      );
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Profile  - (EmailVerification.tsx)",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {webStateData.errorMsg && urlExtension[1] !== "profile" && (
        <div style={{ paddingTop: "20px" }}>
          <Alert
            message={webStateData.errorMsg}
            type="error"
            showIcon
            closable
            onClose={() => dispatch(updateWebSettingsAction({ errorMsg: "" }))}
          />
        </div>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`${styles.stepOne_container} ${
          location.pathname === `/${userInfo?.aggregator_name}` && styles.align
        }`}
      >
        <header className={styles.header}>
          <h3>Enter the email linked to your account to proceed.</h3>
        </header>

        <CustomInputField
          type="email"
          placeholder="example@gmail.com"
          name="email"
          label="Email address"
          reference={register}
          errors={errors.email}
        />

        <div
          className={`${styles.submit__btn} ${
            location.pathname === `/${userInfo?.aggregator_name}` && styles.btn
          }`}
        >
          <Button type="primary" htmlType="submit" disabled={isLoading}>
            {isLoading ? "Processing..." : "Proceed"}
          </Button>
        </div>
      </form>
    </>
  );
}

export default EmailVerification;
