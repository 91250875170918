import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../../svg/close-icon.svg";
import AppCard from "../../components/AppCard";
// import { RiFileCopyFill } from "react-icons/all";
import copy from "copy-text-to-clipboard";
import { useQueryCache } from "react-query";
import { ReduxStoreModel } from "../../../../../interfaces/redux-interface";
import { useSelector } from "react-redux";

interface IcompleteVirtualAccountModal {
  WalletConsentDetails?: {
    status: boolean;
    url: string;
  };
}

const CompleteVirtualAccountModal = ({
  WalletConsentDetails,
}: IcompleteVirtualAccountModal) => {
  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer
  );
  const { customer_id, aggregator_id, bvn_number } = borrowerDetails;
  // const [successCopyMessage, setSuccessCopyMessage] = useState<string>("");
  const [iframeKey, setIframeKey] = useState(0);
  const queryCache = useQueryCache();
  const closeVirtualModal = () => {
    setIframeKey(iframeKey + 1);
    if (customer_id && aggregator_id) {
      queryCache.invalidateQueries([
        { customer_id, aggregator_id },
        "getFetchWalletDetails",
      ]);
    }
  };

  const handleCopy = () => {
    const content = document?.getElementById(
      "BvnNumberforConsent"
    )?.textContent;
    if (content) {
      copy(content);
      // setSuccessCopyMessage("BVN copied");
      // setTimeout(() => {
      //   setSuccessCopyMessage("");
      // }, 3000);
    }
  };

  return (
    <div
      className="modal fade"
      id="completeVirtualAccountModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="completeVirtualAccountLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog  "
        role="document"
        style={{
          height: "100%",
          maxWidth: "28%",
          // marginTop: "5rem",
        }}
      >
        <div
          className="modal-content"
          style={{ height: "95%", padding: "5px" }}
        >
          <div className=" d-flex justify-content-between p-3">
            <h5 className="modal-title" id="completeVirtualAccountLabel">
              Customer Verification
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeVirtualModal}
            >
              <CloseIcon />
            </button>
          </div>
          {/* <div className="modal-body"> */}
          <>
            {/* {successCopyMessage && (
                <div className="d-flex justify-content-center  p-2">
                  <p className="color-green">{successCopyMessage}</p>
                </div>
              )} */}
            <AppCard
              className="d-flex justify-content-between"
              handleCopy={handleCopy}
            >
              <p>BVN</p>
              <div className="d-flex align-items-center">
                <p
                  id="BvnNumberforConsent"
                  className="font-weight-bold mr-1 text-primary-dark"
                >
                  {bvn_number}
                </p>
                {/* <div className="cursor-pointer mb-2" title="Copy">
                    <RiFileCopyFill className="color-mid-blue" />
                  </div> */}
              </div>
            </AppCard>
          </>

          <iframe
            key={iframeKey}
            src={WalletConsentDetails?.url}
            title="IGREE consent"
            width="100%"
            height="100%"
            style={{ border: "0" }}
          ></iframe>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              className={` pt-3 btn`}
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeVirtualModal}
              style={{
                borderRadius: "5px",
                color: " #fff",
                backgroundColor: "#713fff",
              }}
            >
              Close
            </button>
          </div>
          {/* </div> */}
          {/* <div className=" modal-footer p-2">
            <button
              type="button"
              className="btn btn-secondary w-100"
              data-dismiss="modal"
            >
              Close
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CompleteVirtualAccountModal;
