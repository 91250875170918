import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import CustomInputField from "../../../../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { WalletProp } from "../../Dashboard/Dashboard.component";
import { appInsights } from "../../../../AppInsight/AppInsight";

export type TopUpComponentProps = {
  handleNext?: () => void;
  handleGoBack: () => void;
  handleValidateTransfer?: () => void;
  setTopUpAmount?: Dispatch<SetStateAction<string | null>>;
  topUpAmount?: string | null;
  walletDetails: {
    customerId: number;
    walletProvider: string;
    walletProviderId: string;
    bankName: string;
    currency: string;
    accountNumber: string;
    walletId: string;
    accountName: string;
    isDefault: boolean;
    availableBalance: number;
    ledgerBalance: number;
  };
};

const schema = yup.object().shape({
  topUpAmount: yup.string().required("Please enter the amount"),
});
const TopUpStepOne = ({
  setTopUpAmount,
  handleNext,
  handleGoBack,
}: TopUpComponentProps) => {
  let location = useLocation();
  const history = useHistory();

  useEffect(() => {
    appInsights.trackPageView({ name: "TopUpStepOne.tsx", isLoggedIn: true });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: any) => {
    if (setTopUpAmount && handleNext) {
      setTopUpAmount(data.topUpAmount);

      handleNext();
    }
  };

  return (
    <div>
      <div className="title">Top-up Amount</div>
      <p>Enter your top-up amount</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInputField
          type="number"
          reference={register}
          name="topUpAmount"
          placeholder=""
          label="Amount"
          errors={errors.loanAmount}
        />
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "40%" }}
        >
          <div className="d-flex align-items-center">
            <p
              className="color-mid-purple padding-none mr-3 cursor-pointer"
              onClick={handleGoBack}
            >
              Go Back
            </p>
            <button className="btn advancly-btn btn-radius">Next</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TopUpStepOne;
