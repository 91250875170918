import styles from "./NewOfferPopUp.module.scss";
import infoCircle from "../../assets/info-circle-orange.svg";
import React from "react";

interface NewOfferPopUpProps {
  viewNewLoanOfferHandler?: () => void;
  newLoanOfferRouteHandler?: () => void;
}

function NewOfferPopUp({
  viewNewLoanOfferHandler,
  newLoanOfferRouteHandler,
}: NewOfferPopUpProps) {
  function handleNewLoanOfferView() {
    if (newLoanOfferRouteHandler) {
      newLoanOfferRouteHandler();
    } else if (viewNewLoanOfferHandler) {
      viewNewLoanOfferHandler();
    }
  }

  return (
    <div className={styles.new_loan_popup_container}>
      <div className={styles.new_loan_popup}>
        <img src={infoCircle} alt="" />
        <span className={styles.popup_text}>New loan offer</span>
        <button className={styles.popup_btn} onClick={handleNewLoanOfferView}>
          View Details
        </button>
      </div>
    </div>
  );
}

export default NewOfferPopUp;
