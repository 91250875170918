import React, { useEffect, useState } from "react";
import embedLogo from "../../../assets/web/Embed.svg";
import { Link } from "react-router-dom";
import arrowLeft from "../../../assets/web/arrow-left.svg";
import CustomInputField from "../../../components/CustomHTMLElements/EmbedCustomInputField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../helpers/yupValidators";
import styles from "./ResetPassword.module.scss";
import { ResetPasswordData } from "./ResetPassword";
import { Alert, Button } from "antd";
import { postData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux-interface";
import { updateWebSettingsAction } from "../../../redux/actions";
import { appInsights } from "../../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  password: yupValidators.password,
  confirmPassword: yupValidators.confirmPassword,
});

interface FormData {
  password: string;
  confirmPassword: string;
}

interface StepThreeProps {
  onPrevStep: () => void;
  aggregatorShortName: string;
  resetPasswordData: ResetPasswordData;
}

function StepThree({
  onPrevStep,
  resetPasswordData,
  aggregatorShortName,
}: StepThreeProps) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer,
  );

  const data = localStorage.getItem("savedHeaders");
  const savedHeaders = data && JSON.parse(data);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Step3  - (ResetPassword.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const onSubmit = async ({ password, confirmPassword }: FormData) => {
    setIsLoading(true);
    const reqBody = {
      reset_step: "third_step",
      password1: password,
      password2: confirmPassword,
      state_token: resetPasswordData.stateToken,
    };
    try {
      const res = await postData(
        apiEndpoints.resetPasswordUrl,
        reqBody,
        savedHeaders,
      );

      if (res.status) {
        dispatch(
          updateWebSettingsAction({
            errorMsg: "",
            successMsg: {
              bodyText: res.message,
            },
          }),
        );
        setTimeout(() => {
          setIsLoading(false);
          history.push(`/${aggregatorShortName}/login`);

          dispatch(
            updateWebSettingsAction({
              successMsg: {
                bodyText: "",
              },
            }),
          );
        }, 3000);
      }
    } catch (error) {
      dispatch(
        updateWebSettingsAction({
          errorMsg: error.response?.data?.message ?? error.message,
        }),
      );
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Step3 - (ResetPassword.tsx)",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.reset_password__Step_three}>
      {webStateData.errorMsg && (
        <div
          style={{ width: "100%", paddingTop: "50px", paddingBottom: "20px" }}
        >
          <Alert
            message={webStateData.errorMsg}
            type="error"
            showIcon
            closable
            onClose={() => dispatch(updateWebSettingsAction({ errorMsg: "" }))}
          />
        </div>
      )}
      {webStateData.successMsg.bodyText && (
        <div
          style={{ width: "100%", paddingTop: "50px", paddingBottom: "20px" }}
        >
          <Alert
            message={webStateData.successMsg.bodyText}
            type="success"
            showIcon
            closable
            onClose={() =>
              dispatch(
                updateWebSettingsAction({ successMsg: { bodyText: "" } }),
              )
            }
          />
        </div>
      )}
      <header className={styles.logo__header}>
        <div>
          <img src={embedLogo} alt="" />
          <Link to="/" className={styles.logo}>
            embed
          </Link>
        </div>

        <button onClick={onPrevStep}>
          <img src={arrowLeft} alt="" />
          <span>Go back</span>
        </button>
      </header>

      <section>
        <div>
          <header>
            <h1>Reset Pasword</h1>
            <p>
              Must be 6 or more characters, contain at least a number and a
              special character
            </p>
          </header>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <CustomInputField
              type="password"
              name="password"
              placeholder="********"
              label="Password"
              hasActionButton={true}
              reference={register}
              errors={errors.password}
            />

            <CustomInputField
              type="password"
              placeholder="********"
              label="Confirm Password"
              hasActionButton={true}
              name="confirmPassword"
              reference={register}
              errors={errors.confirmPassword}
            />

            <div className={styles.submit__btn}>
              <Button type="primary" htmlType="submit" disabled={isLoading}>
                {isLoading ? " Processing..." : "Proceed"}
              </Button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default StepThree;
