import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Form, Input, InputNumber, message, Select } from "antd";
import { ROUTES } from "../../../../helpers/ROUTES";
import user from "../../../../assets/profile/user.svg";
import SetPinComponent from "../SetPinComponent";
import { SuccessComponent } from "../SuccessComponent";
import { getDataWithKey, postDataWithKey } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import CONFIG from "../../../../helpers/config";
import { errorHandler, errorToast } from "../../../../helpers/errorHandler";
import { appInsights } from "../../../AppInsight/AppInsight";
import styles from "./Withdrawal.module.scss";
import CompleteVirtualAccountModal from "../Dashboard/components/CompleteVirtualAccountModal";
import useFetchWalletDetails from "../../../../custom-hooks/useFetchWallet";
import { formatMoney } from "../../../../helpers/formatCurrency";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import ResidentialInformation from "../Loans/components/LoanRequests/ResidentialInformation";
import { useFetchCustomerResidentialInfoStatusWidget } from "../../../../custom-hooks/useFetchCustomerResidentialInfoStatus";
import advLogo from "../../../../assets/advancly-logo-full.png";
import loader from "../../../../assets/loadinggif.gif";
import { MixPanel } from "../../../../helpers/mixpanel";

const WithdrawalComponent = () => {
  const widgetSettings = useSelector(
    (state: ReduxStoreModel) => state.widgetSettingsReducer,
  );
  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );
  const history = useHistory();
  const [form] = Form.useForm();
  const [amount, setAmount] = useState("");
  const [withdrawalData, setWithdrawalData] = useState({
    recipient_bank_code: "",
    recipient_account_number: "",
    pin: "",
    comment: "",
  });
  const [accountName, setAccountName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingAccount, setLoadingAccount] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showBank, setShowBank] = useState(false);
  const [showResidentialInfo, setShowResidentialInfo] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [WalletConsentIsLoading, setWalletConsentIsLoading] = useState(false);
  const [WalletConsentDetails, setWalletConsentDetails] = useState<any>();
  const [withdrawalError, setWithdrawalError] = useState("");
  const [pinCount, setPinCount] = useState("");

  const {
    data: fetchWalletDetailsdata,
    isLoading: isLoadingFetchWalletDetails,
    refetch: refetchFetchWalletdetails,
  } = useFetchWalletDetails({
    borrowerId: borrowerDetails?.customer_id,
    aggregatorId: borrowerDetails?.aggregator_id,
  });

  const {
    data: residentialInfoStatus,
    isLoading: isLoadingResidentialInfoStatus,
  } = useFetchCustomerResidentialInfoStatusWidget({
    customer_id: borrowerDetails?.customer_id,
    aggregator_id: borrowerDetails?.aggregator_id,
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Withdrawal.component.tsx",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: Withdrawal.tsx`, {
      page: "Withdrawal.tsx - (Widget)",
    });
  }, []);

  async function fetchWalletConsent() {
    setWalletConsentIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getWalletConsent}?borrower_id=${borrowerDetails.customer_id}&aggregator_id=${borrowerDetails.aggregator_id}`,
      );
      if (response.data) {
        setWalletConsentDetails(response.data);
      }
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: WithdrawalComponent },
      });
    }
    setWalletConsentIsLoading(false);
  }

  useEffect(() => {
    if (borrowerDetails.customer_id && borrowerDetails.aggregator_id) {
      refetchFetchWalletdetails();
      fetchWalletConsent();
    }
    // eslint-disable-next-line
  }, [borrowerDetails.customer_id, borrowerDetails.aggregator_id]);

  const fetchAccountName = async () => {
    setLoadingAccount(true);
    try {
      const response = await fetch(
        CONFIG.BASE_URL + "/account/validate_bank_account/",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Content-Security-Policy":
              "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
          },
          body: JSON.stringify({
            bank_code: withdrawalData.recipient_bank_code,
            bank_account_num: withdrawalData.recipient_account_number,
          }),
        },
      );
      if (!response.ok) {
        setWithdrawalError(
          "Bank account number verification failed. Please check and try again.",
        );
      }
      const data = await response.json();
      setAccountName(data.bank_account_name);
    } catch (error) {
      setWithdrawalError(errorHandler(error?.message));
    } finally {
      setLoadingAccount(false);
    }
  };

  useEffect(() => {
    if (
      withdrawalData.recipient_bank_code !== "" &&
      withdrawalData.recipient_account_number?.length === 10
    ) {
      fetchAccountName();
    }
    // eslint-disable-next-line
  }, [
    withdrawalData.recipient_account_number,
    withdrawalData.recipient_bank_code,
  ]);

  async function handleWithdraw() {
    setIsLoading(true);

    try {
      await postDataWithKey(
        apiEndpoints.withdrawFunds,
        {
          sender_account_number: fetchWalletDetailsdata?.accountNumber,
          sender_wallet_id: fetchWalletDetailsdata?.walletId,
          aggregator_id: borrowerDetails?.aggregator_id,
          borrower_id: borrowerDetails?.customer_id,
          recipient_account_number: withdrawalData?.recipient_account_number,
          recipient_bank_code: withdrawalData?.recipient_bank_code,
          amount: Number(amount),
          pin: withdrawalData?.pin,
          wallet_provider: fetchWalletDetailsdata?.walletProviderId,
          comment: withdrawalData.comment,
        },
        // @ts-ignore
        borrowerDetails?.returningUserDetails?.encryptedKey,
      );
      MixPanel.track("Wallet Withdrawal", {
        distinct_id: `${borrowerDetails?.email}`,
        amount: Number(amount),
        status: "Withdrawal SuccessFul",
        product: "Embed Widget",
      });
      setShowPin(false);

      setShowSuccess(true);
    } catch (error) {
      // message.error(errorHandler(error));
      setWithdrawalError(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: WithdrawalComponent },
      });

      MixPanel.track("Wallet Withdrawal", {
        distinct_id: `${borrowerDetails?.email}`,
        amount: Number(amount),
        status: `Failed: ${errorHandler(error)}`,
        product: "Embed Widget",
      });
    }
    setIsLoading(false);
  }

  if (
    isLoading ||
    isLoadingFetchWalletDetails ||
    isLoadingResidentialInfoStatus
  ) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  if (showResidentialInfo) {
    return (
      <ResidentialInformation
        setError={setWithdrawalError}
        error={withdrawalError}
        aggregator_id={borrowerDetails.aggregator_id}
        customer_id={borrowerDetails.customer_id}
        encryptedKey={widgetSettings.encryptedKey}
        setShowResidentialInfo={setShowResidentialInfo}
        setShowPin={setShowPin}
      />
    );
  }

  if (showPin) {
    return (
      <SetPinComponent
        withdrawalError={withdrawalError}
        setWithdrawalError={setWithdrawalError}
        pinCount={pinCount}
        setPinCount={setPinCount}
        handleNext={() => {
          handleWithdraw();
        }}
        handleGoBack={() => setShowPin(false)}
        setValue={(value) =>
          setWithdrawalData({ ...withdrawalData, pin: value?.toString() })
        }
        label=""
      />
    );
  }

  const handleFinish = () => {
    if (!withdrawalError) {
      if (!residentialInfoStatus) {
        setShowResidentialInfo(true);
      } else {
        setShowPin(true);
      }
    }
  };

  if (showSuccess) {
    return (
      <SuccessComponent
        title="Transaction Complete"
        body="You have successfully made a withdrawal from your wallet"
        handler={() => history.push(ROUTES.widgetDashboard)}
        btnText="Done"
        type="success"
      />
    );
  }

  const withdrawAmount = () => {
    return (
      <div>
        {fetchWalletDetailsdata?.accountStatus === "InActive" && (
          <div className={styles.warningCompleteVirtualAccount}>
            {WalletConsentIsLoading ? (
              <p className="color-mid-blue">Loading...</p>
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <p className="d-flex align-items-center">
                  {" "}
                  <i className="fa fa-exclamation-circle"></i>
                  <span className="pl-2">
                    Complete your virtual account setup <br /> before you can
                    make withdrawal
                  </span>
                </p>
                <div>
                  {WalletConsentDetails?.status === false && (
                    <span
                      className={`${styles.proceedNumber} cursor-pointer`}
                      data-toggle="modal"
                      data-target="#completeVirtualAccountModal"
                    >
                      Proceed <i className="fa fa-angle-right"></i>
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        <Form layout="vertical" onFinish={() => setShowBank(true)}>
          {withdrawalError && (
            <Alert
              message={withdrawalError}
              type="error"
              showIcon
              closable
              onClose={() => setWithdrawalError("")}
            />
          )}
          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            initialValue={amount}
            name="amount"
            label="Withdrawal Amount"
            rules={[{ required: true, message: "Required field" }]}
          >
            {/*<Input onChange={(e) => setAmount(e.target.value)} />*/}
            <div>
              <InputNumber
                controls={false}
                size="large"
                formatter={(value) =>
                  ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                onChange={(amount: any) => setAmount(amount)}
                style={{ width: "100%" }}
                value={amount}
              />
              {amount > fetchWalletDetailsdata?.availableBalance && (
                <small className="text-danger">
                  Total amount is bigger than available balance
                </small>
              )}
              <p className="py-1">
                Available Balance:{" "}
                {fetchWalletDetailsdata?.availableBalance === 0 ||
                fetchWalletDetailsdata?.availableBalance > 0 ? (
                  <span className="font-weight-bold">
                    {formatMoney(
                      fetchWalletDetailsdata?.availableBalance,
                      fetchWalletDetailsdata?.currency,
                    )}
                  </span>
                ) : (
                  "Unavailable"
                )}
              </p>
              <small>
                Transaction Charge:{" "}
                {fetchWalletDetailsdata?.transferCharge
                  ? formatMoney(
                      fetchWalletDetailsdata?.transferCharge,
                      fetchWalletDetailsdata?.currency,
                    )
                  : "Unavailable"}
              </small>
            </div>
          </Form.Item>

          <div
            className="d-flex justify-content-end"
            style={{ marginTop: "40%" }}
          >
            <div className="d-flex align-items-center">
              <p
                className="text-primary padding-none mr-3 cursor-pointer"
                onClick={() => history.push(ROUTES.widgetDashboard)}
              >
                Go Back
              </p>
              <button
                className="btn btn-primary btn-radius"
                disabled={
                  fetchWalletDetailsdata?.accountStatus === "InActive" ||
                  amount > fetchWalletDetailsdata?.availableBalance ||
                  !amount
                }
              >
                Next
              </button>
            </div>
          </div>
        </Form>
        <CompleteVirtualAccountModal
          WalletConsentDetails={WalletConsentDetails}
        />
      </div>
    );
  };

  const bank = () => {
    return (
      <div>
        {withdrawalError && (
          <Alert
            message={withdrawalError}
            type="error"
            showIcon
            closable
            onClose={() => setWithdrawalError("")}
          />
        )}
        <p className="padding-none">Where would you like to withdraw into?</p>
        <Form layout="vertical" form={form} onFinish={handleFinish}>
          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            initialValue=""
            name="recipient_bank_code"
            label="Bank Name"
            rules={[{ required: true, message: "Required field" }]}
          >
            <Select
              placeholder="Select"
              allowClear
              onChange={(value) => {
                setWithdrawalData({
                  ...withdrawalData,
                  recipient_bank_code: value,
                });
                setWithdrawalError("");
              }}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={
                widgetSettings?.bankList &&
                widgetSettings?.bankList.map((item) => ({
                  label: item.name,
                  value: item.code,
                }))
              }
            >
              {widgetSettings?.bankList &&
                widgetSettings?.bankList?.length > 0 &&
                widgetSettings?.bankList.map((item) => (
                  <Select.Option value={item.code}>{item.name}</Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            initialValue=""
            name="recipient_account_number"
            label="Account Number"
            rules={[
              { required: true, message: "Required field" },
              {
                min: 10,
                message: "Account number must be 10 digits",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                setWithdrawalData({
                  ...withdrawalData,
                  recipient_account_number: e.target.value,
                });
                setWithdrawalError("");
              }}
            />
          </Form.Item>

          <div className="d-flex align-items-center">
            <img
              src={user}
              className="img-fluid mr-2"
              alt=""
              style={{
                width: "11.48px",
                height: "13.2px",
                objectFit: "contain",
              }}
            />
            <small className="color-mid-purple account__name">
              {loadingAccount ? "Loading...." : accountName}
            </small>
          </div>

          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            initialValue=""
            name="narration"
            label="Narration"
            rules={[{ required: true, message: "Required field" }]}
          >
            <Input
              onChange={(e) =>
                setWithdrawalData({
                  ...withdrawalData,
                  comment: e.target.value,
                })
              }
            />
          </Form.Item>

          <div
            className="d-flex justify-content-end"
            style={{ marginTop: "40%" }}
          >
            <div className="d-flex align-items-center">
              <p
                role="button"
                aria-roledescription="button"
                className="text-primary padding-none mr-3 cursor-pointer"
                onClick={() => {
                  setWithdrawalError("");
                  setShowBank(false);
                }}
              >
                Go Back
              </p>
              <button
                className="btn btn-primary btn-radius"
                disabled={isLoading || !accountName}
              >
                Next
              </button>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  return (
    <div>
      <h4>Withdraw</h4>
      {!showBank ? withdrawAmount() : bank()}
    </div>
  );
};

export default WithdrawalComponent;
