import React, { useEffect } from "react";
import styles from "./Card.module.scss";
import AddCardForm from "./addCard/AddCardForm";
import { useLocation } from "react-router-dom";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

enum ModalProfile {
  ModalProfileNavigator = "ModalProfileNavigator",
  ProfileInformation = "ProfileInformation",
  Card = "Card",
  DirectDebitAccountWeb = "DirectDebitAccountWeb",
  BankStatement = "BankStatement",
  Transaction = "Transaction",
  Password = "Password",
}

interface CardProps {
  setCurrentSection?: React.Dispatch<React.SetStateAction<ModalProfile>>;
}

function Card({ setCurrentSection }: CardProps) {
  const location = useLocation();
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Profile  - (Card.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleSelection = () => {
    if (setCurrentSection) {
      setCurrentSection(ModalProfile.ModalProfileNavigator);
    }
  };

  return (
    <div
      className={`${styles.card_container} ${
        location.pathname === `/${userInfo?.aggregator_name}` &&
        styles.alignment
      }`}
    >
      {location.pathname === `/${userInfo?.aggregator_name}` && (
        <img
          src={arrowLeft}
          alt=""
          className={styles.left}
          onClick={handleSelection}
        />
      )}
      <AddCardForm updatePayStackStatus={() => null} />
    </div>
  );
}

export default Card;
