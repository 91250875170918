import React from "react";
import { useHistory } from "react-router-dom";

export default function WidgetBackButton({ onClick, text }) {
  const history = useHistory();
  return (
    <div
      className="cursor-pointer color-orange mr-3"
      onClick={() => {
        onClick ? onClick() : history.goBack();
      }}
    >
      <div className="font-weight-bold">{text ? text : "Go Back"}</div>
    </div>
  );
}
