import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import CustomInputField from "../../../../components/CustomHTMLElements/EmbedCustomInputField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import useFetchBanks from "../../../../custom-hooks/useFetchBanks";
import { Bank } from "../../../../interfaces/banks";
import user from "../../../../assets/profile/user.svg";
import { postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import ExistingDirectDebitAccountsWeb from "./ExistingDirectDebitAccountsWeb";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Button } from "antd";
import styles from "./DirectDebitWeb.module.scss";
import { useQueryCache } from "react-query";

import { useLocation } from "react-router-dom";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

enum ModalProfile {
  ModalProfileNavigator = "ModalProfileNavigator",
  ProfileInformation = "ProfileInformation",
  Card = "Card",
  DirectDebitAccountWeb = "DirectDebitAccountWeb",
  BankStatement = "BankStatement",
  Transaction = "Transaction",
  Password = "Password",
}

const schema = yup.object().shape({
  bankCode: yupValidators.bankName,
  accountNumber: yupValidators.accountNumber,
});

interface DirectDebitAccountWebProps {
  setCurrentSection?: React.Dispatch<React.SetStateAction<ModalProfile>>;
}

interface IBank {
  name?: string;
  code?: number | string;
}

const DirectDebitAccountWeb = ({
  setCurrentSection,
}: DirectDebitAccountWebProps) => {
  const [showBankAccounts, setShowBankAccounts] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCustomerName, setIsLoadingCustomerName] = useState(false);
  const [customerName, setCustomerName] = useState<any>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredBankList, setFilteredBankList] = useState<Bank[]>([]);
  const [bankList, setBankList] = useState<Bank[]>([]);
  const [bankDetails, setBankDetails] = useState<IBank>();
  const [recipientAccountNumber, setRecipientAccountNumber] = useState("");
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const directDebitUrl = `/customer/${userInfo.customerId}/direct_debit_accounts`;
  const queryCache = useQueryCache();
  const customerId = userInfo.customerId;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { data: BankData, isLoading: isLoadingBanks } = useFetchBanks({
    country_code: "NG",
  });
  const location = useLocation();
  const divRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (DirectDebitAccountWeb.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleSelection = () => {
    if (setCurrentSection) {
      setCurrentSection(ModalProfile.ModalProfileNavigator);
    }
  };

  useEffect(() => {
    setBankList(BankData);
  }, [isLoadingBanks]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setBankDetails({
      ...bankDetails,
      name: value,
    });
    setFilteredBankList(
      bankList.filter((bank) =>
        bank.name.toLowerCase().includes(value.toLowerCase())
      )
    );

    setShowDropdown(true);
  };

  const handleClose = (e: any) => {
    if (divRef?.current && !divRef?.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, []);

  const getBankCode = (name: string) => {
    const result = bankList.filter((bank) => {
      const foundBank = bank.name.toLowerCase() === name.toLowerCase();
      return foundBank
        ? setBankDetails({ ...bankDetails, code: bank.code })
        : null;
    });

    return result;
  };

  useEffect(() => {
    if (bankDetails?.name) {
      getBankCode(bankDetails?.name);
    }
  }, [bankDetails?.name]);

  // const findBankName = BankData?.find(({ code }: { code: string }) => {
  //   return code === directDebitBankDetails?.recipient_bank_code;
  // });
  const handleSelectBank = (bank: Bank) => {
    setBankDetails({
      ...bankDetails,
      name: bank.name,
    });

    setShowDropdown(false);
  };

  const fetchCustomerName = async () => {
    setIsLoadingCustomerName(true);
    try {
      const reqBody = {
        bank_code: bankDetails?.code,
        bank_account_num: recipientAccountNumber,
      };

      const userDataResponse = await postData(
        apiEndpoints.customerValidateBankAccount,
        reqBody
      );

      setCustomerName(userDataResponse);
      dispatch(
        updateWebSettingsAction({
          errorMsg: "",
        })
      );
    } catch (error: any) {
      dispatch(
        updateWebSettingsAction({
          errorMsg: error.response?.data?.message ?? error.message,
        })
      );
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (DirectDebitAccountWeb.tsx)",
        },
      });
    } finally {
      setIsLoadingCustomerName(false);
    }
  };

  useEffect(() => {
    if (bankDetails?.code && recipientAccountNumber?.length === 10) {
      fetchCustomerName();
    }
  }, [bankDetails?.code, recipientAccountNumber]);

  const handleSubmitDirectAccountDetails = async ({
    accountNumber,
    bankCode,
  }: {
    accountNumber: string;
    bankCode: string;
  }) => {
    const reqBody = {
      account_name: customerName?.bank_account_name,
      account_number: accountNumber,
      bank_code: bankDetails?.code,
      bank_name: bankDetails?.name,
      currency: "NGN",
    };
    setIsLoading(true);

    try {
      let response = await postData(directDebitUrl, reqBody);
      dispatch(
        updateWebSettingsAction({ successMsg: { bodyText: response?.message } })
      );
      queryCache.invalidateQueries([
        { customerId },
        "getDirectDebitExistingAccountsWeb",
      ]);
    } catch (error: any) {
      dispatch(
        updateWebSettingsAction({
          errorMsg: error.response?.data?.message ?? error.message,
        })
      );
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (DirectDebitAccountWeb.tsx)",
        },
      });
    } finally {
      setIsLoading(false);

      setTimeout(() => {
        dispatch(
          updateWebSettingsAction({
            errorMsg: "",
            successMsg: { bodyText: "" },
          })
        );
      }, 3000);
    }
  };

  return (
    <div className={styles.directDebit_container}>
      {location.pathname === `/${userInfo?.aggregator_name}` && (
        <img
          src={arrowLeft}
          alt=""
          className={styles.left}
          onClick={handleSelection}
        />
      )}
      <div
        className={`d-flex justify-content-between ${
          location.pathname === `/${userInfo?.aggregator_name}`
            ? styles.marginTop
            : ""
        }`}
      >
        <h3>Direct Debit Accounts</h3>
        <p
          role="button"
          aria-roledescription="button"
          className="font-weight-bold   cursor-pointer"
          style={{
            color: "#713FFF",
          }}
          onClick={() => setShowBankAccounts(!showBankAccounts)}
        >
          {" "}
          View accounts{" "}
          {showBankAccounts ? (
            <IoIosArrowUp size="24px" />
          ) : (
            <IoIosArrowDown size="24px" />
          )}
        </p>
      </div>
      {showBankAccounts && <ExistingDirectDebitAccountsWeb />}
      <form
        onSubmit={handleSubmit(handleSubmitDirectAccountDetails)}
        className={`pt-2 ${styles.alignLeft}`}
      >
        <div ref={divRef} className={styles.selectBankContainer}>
          <label className="label-txt">Bank Name</label>
          <input
            type="text"
            name="bankCode"
            ref={register}
            placeholder={
              isLoadingBanks
                ? "loading, please wait"
                : "E.g. Guaranty Trust Bank"
            }
            value={bankDetails?.name}
            onChange={handleInputChange}
            onFocus={() => setShowDropdown(true)}
            className={styles.selectedBankField}
            disabled={isLoadingBanks}
          />
          {showDropdown && (
            <ul className={styles.bankNameDropdown}>
              {filteredBankList?.length > 0
                ? filteredBankList?.map((bank) => (
                    <li
                      key={bank.id}
                      onClick={() => handleSelectBank(bank)}
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      {bank.name}
                    </li>
                  ))
                : bankList?.map((bank) => (
                    <li
                      key={bank.id}
                      onClick={() => handleSelectBank(bank)}
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      {bank.name}
                    </li>
                  ))}
            </ul>
          )}
          <span className={styles.bank_error}>{errors.bankCode?.message}</span>
        </div>

        <CustomInputField
          type="number"
          label="Account Number"
          placeholder="0000000000"
          name="accountNumber"
          errors={errors.accountNumber}
          reference={register}
          maxLength={10}
          onChange={(e: any) => setRecipientAccountNumber(e.target.value)}
        />

        <div className="d-flex align-items-center">
          <img
            src={user}
            className="img-fluid mr-2"
            alt=""
            style={{
              width: "11.48px",
              height: "13.2px",
              objectFit: "contain",
            }}
          />
          <small className="color-mid-purple account__name">
            {isLoadingCustomerName
              ? "Loading...."
              : customerName?.bank_account_name}
          </small>
        </div>
        <div className={styles.submit__btn}>
          <Button type="primary" htmlType="submit" disabled={isLoading}>
            {isLoading ? "Loading..." : "Submit New Account"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default DirectDebitAccountWeb;
