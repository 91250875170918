import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData, getDotNetDataWithKey } from "../apis/apiMethods";

type LinkBankAccountProps = {
    aggregator_id: number,
    customer_id: number
}

const fetchLinkedBankAccountOnWeb = async ({customerId}: {customerId: number}) => {
    const response = await getData(`${apiEndpoints.getLinkedBankAccountDetailsWeb}?customerId=${customerId}`)

    return response.data
}

export function useGetLinkedBankAccountWeb({customerId}: {customerId: number}) {
    return useQuery([{customerId}, "fetchLinkedBankAccountOnWeb"], fetchLinkedBankAccountOnWeb, {
        enabled: customerId ? true : false
    })
}

const fetchLinkedBankAccountOnWidget = async ({aggregator_id, customer_id}: LinkBankAccountProps) => {
    const response = await getDotNetDataWithKey(`${apiEndpoints.getLinkedBankAccountDetailsWidget}?aggregator_id=${aggregator_id}&customer_id=${customer_id}`)

    return response.data
}


export function useGetLinkedBankAccountWidget({aggregator_id, customer_id}: LinkBankAccountProps) {
    return useQuery([{aggregator_id, customer_id}, "fetchLinkedBankAccountOnWidget"], fetchLinkedBankAccountOnWidget, {
        enabled: aggregator_id && customer_id ? true : false
    })
}