import React, { useState } from "react";
import {
  showTransactionEye,
  hideTransactionEye,
  arrowRight,
  bgIcon,
  copy,
} from "./Index";
import styles from "./OverviewComp.module.scss";
import nouser from "../../../../assets/web/nouser.svg";
import Card from "../../card/Card";
import QuickActions from "../quickActions/QuickActions";
import TransactionHistoryOverview from "../TransactionOverview/TransactionOverview";
import { IoIosArrowDown } from "react-icons/io";
import MobileTransactionView from "../mobileTransactionView/MobileTransactionView";
import { WalletTransactions } from "../../../../interfaces/transactions";
import { WalletDetails } from "../../../../interfaces/wallet";
import { Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { useFetchPendingLoanOffer } from "../../../../custom-hooks/useFetchPendingLoanOffer";
import NewOfferPopUp from "../../../../web-widget-reuseable-components/newOfferPopUp/NewOfferPopUp";

interface OverviewCompProps {
  transactionHistoryRef: React.RefObject<HTMLDivElement>;
  scrollToTransactions: (elementRef: React.RefObject<HTMLDivElement>) => void;
  scrollToTop: (elementRef: React.RefObject<HTMLDivElement>) => void;
  overviewRef: React.RefObject<HTMLDivElement>;
  transactions: WalletTransactions[];
  isTransactionLoading: boolean;
  walletConsentDetails: any;
  setTransactionDetail: React.Dispatch<
    React.SetStateAction<WalletTransactions[] | null>
  >;
  walletDetails: WalletDetails;
  refetchFetchWalletdetails: () => void;
  isLoadingWalletDetails: boolean;
  isLoadingUserExist: boolean;
  userExistsData: any;
}

export interface TextToCopyProps {
  accountBankName: string;
  accountNumber: string;
  accountName: string;
}

function OverviewComp({
  transactionHistoryRef,
  scrollToTransactions,
  scrollToTop,
  overviewRef,
  transactions,
  walletConsentDetails,
  isTransactionLoading,
  setTransactionDetail,
  isLoadingWalletDetails,
  refetchFetchWalletdetails,
  walletDetails,
  userExistsData,
  isLoadingUserExist,
}: OverviewCompProps) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const data = localStorage.getItem("currentUser");
  const userinfo = data && JSON.parse(data);
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer,
  );

  const copyToClipboard = async ({
    accountBankName,
    accountNumber,
    accountName,
  }: TextToCopyProps) => {
    try {
      const textToCopy = `Bank Name: ${accountBankName}\nAccount Number: ${accountNumber}\nAccount Name: ${accountName}`;
      await navigator.clipboard.writeText(textToCopy);
      setSuccess("Account details copied to clipboard");
    } catch (err) {
      console.error("Failed to copy text: ", err);
      setError("Failed to copy");
    }
  };

  // console.log("Wallet Consent:", walletDetails?.accountStatus)

  const handleConsent = () => {
    document.body.style.overflow = "hidden";

    dispatch(updateWebSettingsAction({ showVirtualAcctCompletionModal: true }));
  };

  const viewNewLoanOfferHandler = () => {
    dispatch(updateWebSettingsAction({ showNewLoanOffer: true }));
  };

  const { data: pendingLoanOffer } = useFetchPendingLoanOffer({
    customer_id: userinfo?.customerId,
  });

  return (
    <>
      {walletDetails?.accountStatus === "InActive" && (
        <div className={styles.warningCompleteVirtualAccount}>
          {webStateData?.walletConsentIsLoading ? (
            <p className="color-mid-blue">Loading...</p>
          ) : (
            <div className={styles.prompt}>
              <p>
                <i className="fa fa-exclamation-circle"></i>
                <span>Complete your virtual account setup</span>
              </p>
              <div>
                {walletConsentDetails?.status === false && (
                  <span
                    className={styles.proceedNumber}
                    onClick={handleConsent}
                  >
                    Proceed <i className="fa fa-angle-right"></i>
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <header className={styles.overview_header}>
        <img
          src={userinfo?.photo_url ?? nouser}
          alt=""
          className={`${userinfo?.photo_url ? styles.avatar : styles.noAvatar}`}
        />
        <h1 className={styles.greeting}>Hi {userinfo?.first_name}👋🏽</h1>
      </header>

      {error && (
        <div style={{ width: "100%", paddingBottom: "20px" }}>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}

      {success && (
        <div style={{ width: "100%", paddingBottom: "20px" }}>
          <Alert
            message={success}
            type="success"
            showIcon
            closable
            onClose={() => setSuccess("")}
          />
        </div>
      )}

      <div className={styles.dashboard_content}>
        {pendingLoanOffer?.length > 0 && (
          <NewOfferPopUp viewNewLoanOfferHandler={viewNewLoanOfferHandler} />
        )}

        <div className={styles.card_container}>
          <Card
            title="Wallet Balance"
            icon={showTransactionEye}
            icon2={hideTransactionEye}
            walletBalance
            bgColor="#200865"
            amount={walletDetails?.availableBalance ?? 0}
            isLoadingWalletDetails={isLoadingWalletDetails}
            refetchFetchWalletdetails={refetchFetchWalletdetails}
          />

          <Card
            content="Apply for a low-interest loan in minutes"
            action="Get started"
            icon={arrowRight}
            bgColor="#DEFADE"
            bgIcon={bgIcon}
            setShowLoanRequestModal={true}
            userExistsData={userExistsData}
            isLoadingUserExist={isLoadingUserExist}
          />

          <Card
            accountnumber={walletDetails?.accountNumber}
            accountName={walletDetails?.accountName}
            icon={copy}
            accountDetails
            bgColor="#F8F5FF"
            bankName={walletDetails?.bankName}
            copyToClipboard={copyToClipboard}
          />
        </div>

        <QuickActions
          scrollToTransactions={scrollToTransactions}
          transactionHistoryRef={transactionHistoryRef}
          pendingLoanOffer={pendingLoanOffer}
        />

        <div className={styles.transactions}>
          <TransactionHistoryOverview
            transactions={transactions}
            isTransactionLoading={isTransactionLoading}
            setTransactionDetail={setTransactionDetail}
          />
        </div>

        <div
          className={styles.dashboardAction}
          onClick={() => scrollToTransactions(transactionHistoryRef)}
        >
          <span>Tap to scroll to transactions</span>
          <IoIosArrowDown className={styles.arrowDown} />
        </div>

        <MobileTransactionView
          transactionHistoryRef={transactionHistoryRef}
          scrollToTop={scrollToTop}
          overviewRef={overviewRef}
          transactions={transactions}
          isTransactionLoading={isTransactionLoading}
          setTransactionDetail={setTransactionDetail}
        />
      </div>
    </>
  );
}

export default OverviewComp;
