import React, { useEffect, useState } from "react";
import styles from "./NewLoanOffer.module.scss";
import Loader from "../../../../components/Loader/Loader";
import closeIcon from "../../../../assets/web/close-square.svg";
import { formatCurrency } from "../../../../helpers/formatCurrency";
import { Alert, Button } from "antd";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { useHistory } from "react-router-dom";
import { LoanOfferStatus } from "../../../../helpers/shared";

import { useDispatch, useSelector } from "react-redux";
import {
  updateLoanDetailsAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import successSVG from "../../../../assets/web/success.svg";
import { useFetchPendingLoanOffer } from "../../../../custom-hooks/useFetchPendingLoanOffer";
import { postData } from "../../../../apis/apiMethods";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { MixPanel } from "../../../../helpers/mixpanel";

export interface LoanOfferProps {
  currency_code: string;
  id: number;
  offer_amount: number;
  offer_tenure: number;
  repayment_type: string;
  requested_amount: number;
  requested_tenure: number;
  status: string;
  requested_loan_id: number;
  comment: string;
  created_at: string;
  created_by: string;
}

function NewLoanOffer() {
  const [currentStep, setCurrentStep] = useState(0);
  const [mostRecentLoanOffer, setMostRecentLoanOffer] =
    useState<LoanOfferProps | null>(null);
  const [error, setError] = useState("");
  const data = localStorage.getItem("currentUser");
  const userInfo = data ? JSON.parse(data) : null;
  const dispatch = useDispatch();
  const loanData = useSelector(
    (state: ReduxStoreModel) => state.loanDetailsReducer,
  );

  const history = useHistory();

  const {
    data: pendingLoanOffer,
    isLoading: isLoadingPendingLoanOffer,
    refetch: refetchPendingLoanOffer,
  } = useFetchPendingLoanOffer({
    customer_id: userInfo?.customerId,
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (NewLoanOffer.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: NewLoanOffer.tsx`, {
      page: "NewLoanOffer.tsx - (Web)",
    });
  }, []);

  function handleCloseModal(): void {
    document.body.style.overflow = "auto";

    refetchPendingLoanOffer().finally(() => {
      dispatch(updateWebSettingsAction({ showNewLoanOffer: false }));
      dispatch(
        updateLoanDetailsAction({
          new_loan_offer: {
            offer_amount: null,
            offer_tenor: null,
          },
        }),
      );
    });
  }

  function generateContactUsUrl() {
    refetchPendingLoanOffer().finally(() => {
      dispatch(updateWebSettingsAction({ showNewLoanOffer: false }));

      dispatch(
        updateLoanDetailsAction({
          new_loan_offer: {
            offer_amount: null,
            offer_tenor: null,
          },
        }),
      );

      const aggregatorName = userInfo?.aggregator_name;
      history.push(`/${aggregatorName}/contact-us`);
    });
  }

  useEffect(() => {
    if (!isLoadingPendingLoanOffer && pendingLoanOffer) {
      const recentLoanOffer = pendingLoanOffer[pendingLoanOffer?.length - 1];
      setMostRecentLoanOffer(recentLoanOffer);
    }
  }, [pendingLoanOffer, isLoadingPendingLoanOffer]);

  async function newOfferAcceptanceHandler() {
    setCurrentStep(3);

    dispatch(
      updateLoanDetailsAction({
        new_loan_offer: {
          offer_amount: mostRecentLoanOffer?.offer_amount,
          offer_tenor: mostRecentLoanOffer?.offer_tenure,
        },
      }),
    );

    const payload = {
      loan_offer_id: mostRecentLoanOffer?.id,
      status: LoanOfferStatus.Accepted,
    };

    try {
      const response = await postData(
        apiEndpoints.updateNewLoanOfferStatus,
        payload,
      );

      if (response?.status) {
        MixPanel.track("New Offer Accepted", {
          distinct_id: `${userInfo?.email}`,
          Comment: `${mostRecentLoanOffer?.comment}`,
          "Created At": `${mostRecentLoanOffer?.created_at}`,
          "Created By": `${mostRecentLoanOffer?.created_by}`,
          "Offer Amount": `${mostRecentLoanOffer?.offer_amount}`,
          "Offer Tenor": `${mostRecentLoanOffer?.offer_tenure}`,
          "Repayment Type": `${mostRecentLoanOffer?.repayment_type}`,
          "Requested Amount": `${mostRecentLoanOffer?.requested_amount}`,
          "Requested Tenor": `${mostRecentLoanOffer?.requested_tenure}`,
        });
        setCurrentStep(1);
      }
    } catch (error) {
      setCurrentStep(0);
      setError(error?.response?.data?.message ?? error?.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (NewLoanOffer.tsx)",
        },
      });
    }
  }

  async function newOfferRejectionHandler() {
    setCurrentStep(3);

    dispatch(
      updateLoanDetailsAction({
        new_loan_offer: {
          offer_amount: mostRecentLoanOffer?.offer_amount,
          offer_tenor: mostRecentLoanOffer?.offer_tenure,
        },
      }),
    );

    const payload = {
      loan_offer_id: mostRecentLoanOffer?.id,
      status: LoanOfferStatus.Rejected,
    };

    try {
      const response = await postData(
        apiEndpoints.updateNewLoanOfferStatus,
        payload,
      );

      if (response.status) {
        MixPanel.track("New Offer Rejected", {
          distinct_id: `${userInfo?.email}`,
          Comment: `${mostRecentLoanOffer?.comment}`,
          "Created At": `${mostRecentLoanOffer?.created_at}`,
          "Created By": `${mostRecentLoanOffer?.created_by}`,
          "Offer Amount": `${mostRecentLoanOffer?.offer_amount}`,
          "Offer Tenor": `${mostRecentLoanOffer?.offer_tenure}`,
          "Repayment Type": `${mostRecentLoanOffer?.repayment_type}`,
          "Requested Amount": `${mostRecentLoanOffer?.requested_amount}`,
          "Requested Tenor": `${mostRecentLoanOffer?.requested_tenure}`,
        });
        setCurrentStep(2);
      }
    } catch (error) {
      setCurrentStep(0);
      setError(error?.response?.data?.message ?? error?.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (NewLoanOffer.tsx)",
        },
      });
    }
  }

  function renderContent() {
    switch (currentStep) {
      case 0:
        return (
          <div className={styles.stepOne_container}>
            <header className={styles.stepOne_header}>
              <h3>Loan Offer</h3>
              {mostRecentLoanOffer?.offer_amount !==
                mostRecentLoanOffer?.requested_amount ||
              (mostRecentLoanOffer?.offer_amount !==
                mostRecentLoanOffer?.requested_amount &&
                mostRecentLoanOffer?.offer_tenure !==
                  mostRecentLoanOffer?.requested_tenure) ? (
                <p>
                  After careful review, your loan has been approved for an
                  amount different from your original request. This adjustment
                  is due to eligibility criteria. Here are the details of your
                  revised loan:
                </p>
              ) : (
                <p>
                  After careful review, your loan has been approved for a tenor
                  different from your original request. This adjustment is due
                  to eligibility criteria. Here are the details of your revised
                  loan:
                </p>
              )}
            </header>

            <div className={styles.loanOffer_container}>
              {isLoadingPendingLoanOffer ? (
                <div style={{ textAlign: "center", padding: "20px" }}>
                  <Loader />
                </div>
              ) : (
                <div className={styles.loanOffer_overview}>
                  <div className={styles.loan_amount_outer_container}>
                    <div className={styles.loan_amount_container}>
                      <span>
                        ₦
                        {formatCurrency(
                          Number(
                            mostRecentLoanOffer?.offer_amount ||
                              loanData?.new_loan_offer?.offer_amount,
                          ),
                        )}
                      </span>
                    </div>
                  </div>

                  <div className={styles.more_loan_details}>
                    <div className={styles.loan_detail}>
                      <span>Currency</span>
                      <span>
                        {mostRecentLoanOffer?.currency_code === "NGN"
                          ? "Naira"
                          : null}
                      </span>
                    </div>
                    <div className={styles.loan_detail}>
                      <span>Repayment Frequency</span>
                      <span style={{ textTransform: "capitalize" }}>
                        {mostRecentLoanOffer?.repayment_type}
                      </span>
                    </div>
                    <div className={styles.loan_detail}>
                      <span>Tenor</span>
                      <span>{mostRecentLoanOffer?.offer_tenure} days</span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={styles.buttons}>
              <Button type="primary" onClick={newOfferAcceptanceHandler}>
                Accept
              </Button>

              <Button type="primary" ghost onClick={newOfferRejectionHandler}>
                Decline
              </Button>
            </div>
          </div>
        );
      case 1:
        return (
          <div className={styles.stepTwo_container}>
            <div className={styles.verification_success}>
              <img src={successSVG} alt="" />
              <h3>Loan Accepted!</h3>
              <p>
                Congratulations! Your loan of{" "}
                {`₦${formatCurrency(Number(mostRecentLoanOffer?.offer_amount || loanData?.new_loan_offer?.offer_amount))}`}{" "}
                will be processed. The funds will be deposited into your account
                shortly.
              </p>

              <Button
                type="primary"
                className={styles.submit_button}
                onClick={handleCloseModal}
              >
                Done
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className={styles.stepThree_container}>
            <div className={styles.verification_failed}>
              <div className={styles.box}></div>
              <h3>Loan Declined</h3>
              <p>
                You have chosen to decline the loan offer of{" "}
                {`₦${formatCurrency(Number(mostRecentLoanOffer?.offer_amount || loanData?.new_loan_offer?.offer_amount))}`}
                . If you need assistance or have any questions, please contact
                our support team.
              </p>
            </div>

            <div className={styles.buttons}>
              <Button type="primary" onClick={handleCloseModal}>
                Done
              </Button>

              <Button
                type="primary"
                ghost
                role="link"
                aria-label="link"
                onClick={generateContactUsUrl}
              >
                Contact Support
              </Button>
            </div>
          </div>
        );
      case 3:
        return (
          <div style={{ height: "100%", marginTop: "40%" }}>
            <Loader message="Processing request..." />
          </div>
        );
    }
  }

  return (
    <>
      {error && (
        <div style={{ width: "100%", paddingTop: "60px" }}>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}
      <div className={styles.overlay}></div>
      <div className={styles.modal_container}>
        <div className={styles.modal_steps_wrapper}>
          {renderContent()}
          <img
            src={closeIcon}
            alt=""
            className={styles.cancel_button}
            onClick={handleCloseModal}
          />
        </div>
      </div>
    </>
  );
}

export default NewLoanOffer;
