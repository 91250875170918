import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Alert, Form, Input, Select, message } from "antd";
import { SuccessComponent } from "../../SuccessComponent";
import {
  getDataWithKey,
  postDataWithKey,
} from "../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../../helpers/errorHandler";
import { appInsights } from "../../../../AppInsight/AppInsight";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../helpers/ROUTES";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../interfaces/redux-interface";
import advLogo from "../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../assets/loadinggif.gif";

type SecurityQuestion = {
  id: number;
  question: string;
  code: string;
};

const TransactionPinOnboarding = () => {
  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );
  const { customer_id } = borrowerDetails;
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [showDone, setShowDone] = useState(false);
  const [error, setError] = useState("");
  const [showPinPage, setShowPinPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [securityQuestions, setSecurityQuestions] = useState<
    SecurityQuestion[]
  >([]);
  const [securityData, setSecurityData] = useState({
    borrowerId: customer_id,
    question_id: "",
    answer: "",
  });

  const history = useHistory();
  useEffect(() => {
    appInsights.trackPageView({
      name: "TransactionPinOnboarding.tsx",
      isLoggedIn: true,
    });
  }, []);

  async function fetchSecurityQuestions() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(apiEndpoints.getSecurityQuestions);
      setSecurityQuestions(response.data);
    } catch (error) {
      setError(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: TransactionPinOnboarding },
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function submitSecurityAnswer() {
    setIsLoading(true);
    try {
      await postDataWithKey(
        apiEndpoints.saveSecurityQuestionAndAnswer,
        securityData,
        //@ts-ignore
        borrowerDetails?.returningUserDetails?.encryptedKey,
      );
      setIsLoading(false);

      setShowPinPage(true);
    } catch (error) {
      setError(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore

        exception: error,
        properties: { fileName: TransactionPinOnboarding },
      });
    }
    setIsLoading(false);
  }

  async function saveCustomerPin(data: { pin: string; confirmPin: string }) {
    setIsLoading(true);
    try {
      await postDataWithKey(
        apiEndpoints.saveCustomerPin,
        {
          borrowerId: customer_id,
          pin: data.pin,
          type: 5,
        },
        //@ts-ignore
        borrowerDetails?.returningUserDetails?.encryptedKey,
      );
      message.success("Pin set successfully");

      setIsLoading(false);
      window.location.reload();

      //   history.push(ROUTES.widgetDashboard);
    } catch (error) {
      setError(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore

        exception: error,
        properties: { fileName: TransactionPinOnboarding },
      });

      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (customer_id) {
      fetchSecurityQuestions();
    }
    //eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  if (showPinPage) {
    return (
      <>
        {error && (
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        )}
        <h4>Security</h4>
        <p>
          {
            " Kindly set a security question before creating your transaction pin."
          }
        </p>
        <Form
          layout="vertical"
          form={form2}
          onFinish={(data) => saveCustomerPin(data)}
        >
          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            name="pin"
            label="New PIN"
            rules={[
              { required: true, message: "Required field" },
              {
                pattern: /^\d*[.]?\d*$/,
                message: "Enter numbers from 0 - 9",
              },
              {
                max: 4,
                message: "Enter maximum of 4 digits",
              },
              {
                min: 4,
                message: "Enter a minimum of 4 digits",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            name="confirmPin"
            label="Confirm PIN"
            dependencies={["newPin"]}
            rules={[
              { required: true, message: "Required field" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("pin") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two pins that you entered do not match!"),
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div
            className="d-flex justify-content-end"
            style={{ marginTop: "10%" }}
          >
            <div className="d-flex align-items-center">
              <p
                className="text-primary padding-none mr-3 cursor-pointer"
                onClick={() => setShowPinPage(false)}
              >
                Go Back
              </p>
              <button className="btn btn-primary btn-radius">Save</button>
            </div>
          </div>
        </Form>
      </>
    );
  }

  if (showDone) {
    return (
      <SuccessComponent
        title="Transaction PIN Set"
        body="You have successfully set your transaction pin"
        handler={() => history.push(ROUTES.widgetDashboard)}
        btnText="Done"
        type="success"
      />
    );
  }
  return (
    <>
      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          closable
          onClose={() => setError("")}
        />
      )}
      <h4>Security</h4>
      <p>
        Kindly set a security question before creating your transaction pin.
      </p>
      <Form
        layout="vertical"
        form={form}
        onFinish={() => {
          submitSecurityAnswer();
        }}
      >
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="question_id"
          label="Security Question"
          rules={[{ required: true, message: "Required field" }]}
        >
          <Select
            onChange={(value) =>
              setSecurityData({ ...securityData, question_id: value })
            }
            placeholder="Select"
            allowClear
          >
            {securityQuestions?.map((item) => (
              <Select.Option value={item.id}>{item.question}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="answer"
          label="Security Question Answer"
          rules={[{ required: true, message: "Required field" }]}
        >
          <Input
            onChange={(e) =>
              setSecurityData({ ...securityData, answer: e.target.value })
            }
          />
        </Form.Item>
        <div
          className={`d-flex align-items-center ${" justify-content-between"}`}
          style={{ marginTop: "10%" }}
        >
          <div className="d-flex align-items-center">
            <button className="btn btn-primary btn-radius">Next</button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default TransactionPinOnboarding;
