import { useQuery } from "react-query";
import { getData } from "../apis/apiMethods";
import { apiEndpoints } from "../apis/apiEndpoints";

interface OutstandingLoanBalanceProps {
  currency: string;
  customerId: string;
}

export const fetchUserOutstandingLoanBalance = async ({
  currency,
  customerId,
}: OutstandingLoanBalanceProps) => {
  const res = await getData(
    `${apiEndpoints.customerOutstandingLoanBalance}?currency=${currency}&customerId=${customerId}`
  );

  return res.data;
};

export default function useFetchOutstandingLoanBalance({
  currency,
  customerId,
}: OutstandingLoanBalanceProps) {
  return useQuery(
    [{ currency, customerId }, "fetchUserOutstandingLoanBalance"],
    fetchUserOutstandingLoanBalance,
    {
      refetchOnWindowFocus: true,
      enabled: false,
    }
  );
}
