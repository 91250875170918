import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import CustomInputField from "../../../../components/CustomHTMLElements/EmbedCustomInputField";
import { Alert, Button } from "antd";
import styles from "./Password.module.scss";
import { useLocation } from "react-router-dom";
import { PasswordSection } from "./Password";
import { postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { useDispatch, useSelector } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

interface OtpProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<PasswordSection>>;
  userEmail: string;
}

const schema = yup.object().shape({
  otp: yupValidators.otp,
});

function OtpVerification({ setCurrentStep, userEmail }: OtpProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const location = useLocation();
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer
  );
  const urlExtension = location.pathname.split("/").filter(Boolean);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Profile  - (OtpVerification.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const cleanPhoneSavedPhoneNo = () => {
    let phoneNo;
    if (userInfo && userInfo.country === "NG") {
      phoneNo = userInfo.phoneNumber.replace(/^234/, "0");
    } else if (userInfo && userInfo.country === "KE") {
      phoneNo = userInfo.phoneNumber.replace(/^254/, "0");
    } else if (userInfo && userInfo.country === "GH") {
      phoneNo = userInfo.phoneNumber.replace(/^233/, "0");
    } else if (userInfo && userInfo.country === "RW") {
      phoneNo = userInfo.phoneNumber.replace(/^250/, "0");
    } else if (userInfo && userInfo.country === "UG") {
      phoneNo = userInfo.phoneNumber.replace(/^256/, "0");
    } else if (userInfo && userInfo.country === "US") {
      phoneNo = userInfo.phoneNumber.replace(/^1/, "0");
    }

    return phoneNo;
  };

  const onSubmit = async ({ otp }: { otp: string }) => {
    setIsLoading(true);
    try {
      const reqBody = {
        otp,
        email: userEmail,
        phone: cleanPhoneSavedPhoneNo,
      };
      await postData(apiEndpoints.validate_sign_up_otp, reqBody);
      setCurrentStep(PasswordSection.ChangePassword);
    } catch (error: any) {
      dispatch(
        updateWebSettingsAction({ errorMsg: "OTP verification failed" })
      );
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Profile  - (OtpVerification.tsx)",
        },
      });
    } finally {
      setIsLoading(false);

      setTimeout(() => {
        dispatch(
          updateWebSettingsAction({
            errorMsg: "",
            successMsg: { bodyText: "" },
          })
        );
      }, 3000);
    }
  };

  const resendOtp = async () => {
    setOtpLoading(true);
    try {
      await postData(
        `${apiEndpoints.generate_otp}?email=${userEmail}&countryCode=${userInfo?.country}`
      );
      dispatch(
        updateWebSettingsAction({
          successMsg: {
            bodyText: "A new OTP has been sent to your email address.",
          },
        })
      );
    } catch (error: any) {
      dispatch(updateWebSettingsAction({ errorMsg: "Operation failed" }));
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Profile  - (OtpVerification.tsx)",
        },
      });
    } finally {
      setOtpLoading(false);

      setTimeout(() => {
        dispatch(
          updateWebSettingsAction({
            errorMsg: "",
            successMsg: { bodyText: "" },
          })
        );
      }, 3000);
    }
  };

  return (
    <Fragment>
      {webStateData.errorMsg && urlExtension[1] !== "profile" && (
        <div style={{ paddingTop: "20px" }}>
          <Alert
            message={webStateData.errorMsg}
            type="error"
            showIcon
            closable
            onClose={() => dispatch(updateWebSettingsAction({ errorMsg: "" }))}
          />
        </div>
      )}
      {webStateData.successMsg.bodyText && urlExtension[1] !== "profile" && (
        <div style={{ paddingTop: "20px" }}>
          <Alert
            message={webStateData.errorMsg}
            type="success"
            showIcon
            closable
            onClose={() =>
              dispatch(
                updateWebSettingsAction({ successMsg: { bodyText: "" } })
              )
            }
          />
        </div>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`${styles.stepTwo_container} ${
          location.pathname === `/${userInfo?.aggregator_name}` && styles.align
        }`}
      >
        <header className={styles.header}>
          <h3>A one time password was sent to {userEmail}</h3>
        </header>

        <CustomInputField
          type="text"
          name="otp"
          label="OTP"
          reference={register}
          placeholder="Enter OTP"
          maxLength={6}
          errors={errors.otp}
        />

        <Button
          onClick={resendOtp}
          className={styles.otp}
          disabled={otpLoading || isLoading}
        >
          {otpLoading ? "Please wait..." : "Resend OTP"}
        </Button>

        <div className={styles.submit__btn}>
          <Button type="primary" htmlType="submit" disabled={isLoading}>
            {isLoading ? "Processing..." : "Proceed"}
          </Button>
        </div>
      </form>
    </Fragment>
  );
}
export default OtpVerification;
