import React from "react";
import styles from "../Profile.module.scss";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../helpers/ROUTES";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useGetLinkedBankAccountWidget } from "../../../../../custom-hooks/useGetLinkedBankAccount";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../interfaces/redux-interface";
import useBankStatementProvider from "../../../../../custom-hooks/useBankStatementProvider";

function BankStatement() {
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer
  );
  const { customer_id, aggregator_id, bank_statement_connected } =
    borrowerDetails;
  const { handleLaunchOkraWidget, monoConnect, isSavingProvider } =
    useBankStatementProvider({
      borrowerDetails,
      bankStatementLinkedStatus: bank_statement_connected,
    });

  const handleBankStatement = () => {
    if (!borrowerDetails?.bank_statement_connected) {
      if (borrowerDetails?.bank_statement_provider?.toLowerCase() === "okra") {
        handleLaunchOkraWidget();
      } else {
        monoConnect();
      }
    }
  };

  const history = useHistory();
  const { data: linkedAccount, isLoading: isLoadingLinkedAccount } =
    useGetLinkedBankAccountWidget({
      aggregator_id: aggregator_id ? Number(aggregator_id) : 0,
      customer_id: customer_id ? customer_id : 0,
    });
  return (
    <>
      <div
        onClick={() => history.push(ROUTES.widgetProfile)}
        className="cursor"
      >
        <IoMdArrowRoundBack size="26px" />
      </div>
      <br />
      <h4>Bank Statement</h4>

      {isSavingProvider || isLoadingLinkedAccount ? (
        <div>
          <span className={`spinner-border spinner-border-lg mr-2 `}></span>
          <span className={styles.validating_text}>
            Please wait, checking if account exists...
          </span>
        </div>
      ) : linkedAccount?.length > 0 && bank_statement_connected ? (
        <div>
          <p className={styles.bankStatement_body}>
            Providing us with more financial information improves your credit
            eligibility.
          </p>

          <table className="table">
            <thead>
              <tr>
                <th>Account Name</th>
                <th>Account No</th>
                <th>Bank Name</th>
              </tr>
            </thead>
            <tbody>
              {linkedAccount?.map((account: any) => (
                <tr key={account.accountNo}>
                  <td>{account.accountName}</td>
                  <td>{account.accountNo}</td>
                  <td>{account.bankName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (!linkedAccount || linkedAccount.length === 0) && bank_statement_connected ? (
          <div className={styles.bankStatement_body}>
             <p>An error occurred while fetching your linked bank statement! Please contact support.</p>
          </div>
      ) : (
        <div className={styles.bankStatement_body}>
          <p>
            Providing us with more financial information improves your credit
            eligibility. To link your bank statement click the button below 
          </p>

          <button onClick={handleBankStatement}>Link Account</button>
        </div>
      )}
    </>
  );
}

export default BankStatement;
