import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import logo from "../../../../../../assets/loans/loanHistory.svg";
import { formatMoney } from "../../../../../../helpers/formatCurrency";
import moment from "moment";
import LoanRepaymentSchedule from "./LoanRepaymentSchedule";
import LoanRepayments from "./LoanRepayments";
import { LoanProp } from "./LoanHistory";
import { accurateQuantifier } from "../../../../../../helpers/accurateQuantifier";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import styles from "../../Loans.module.scss";
import { useFetchWidgetLoanTransactionDetails } from "../../../../../../custom-hooks/useFetchLoanTransactionDetails";
import { Alert, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";
import NewLoanOfferWidget from "../NewLoanOfferWidget/NewLoanOffer";
import { updateWidgetSettingsAction } from "../../../../../../redux/actions";
import { useFetchPendingWidgetLoanOffer } from "../../../../../../custom-hooks/useFetchPendingLoanOffer";
import { LoanOfferProps } from "../../../../../../web/components/features/newLoanOffer/NewLoanOffer";

enum Status {
  Active = "active",
  Repaid = "repaid",
  Approved = "approved",
  Rejected = "rejected",
  Overdue = "overdue",
  AwaitingApproval = "awaiting approval",
  SubmittedAndAwaitingApproval = "submitted_and_awaiting_approval",
  WithdrawnByClient = "withdrawnByClient",
  Closed = "closed",
  WrittenOff = "writtenOff",
  Overpaid = "overpaid",
}

const LoanHistoryDetails = ({ loanRef, onCancel }: LoanProp) => {
  const widgetSettings = useSelector(
    (state: ReduxStoreModel) => state.widgetSettingsReducer,
  );
  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );
  const widgetData = useSelector(
    (state: ReduxStoreModel) => state.widgetSettingsReducer,
  );
  const { aggregator_id } = borrowerDetails;
  const { encryptedKey } = widgetSettings;
  let location = useLocation();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [showSchedule, setShowSchedule] = useState(false);
  const [showRepayment, setShowRepayment] = useState(false);
  const [mostRecentLoanOffer, setMostRecentLoanOffer] =
    useState<LoanOfferProps | null>(null);
  const [error, setError] = useState<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanHistoryDetails.tsx",
      isLoggedIn: true,
    });
  }, []);

  const { data: pendingLoanOffer, isLoading: isLoadingPendingLoanOffer } =
    useFetchPendingWidgetLoanOffer({
      customer_id: borrowerDetails?.customer_id,
      aggregator_id: borrowerDetails?.aggregator_id,
    });

  const {
    data: widgetLoanDetails,
    isLoading: isLoadingWidgetLoanDetails,
    error: widgetLoanDetailError,
  } = useFetchWidgetLoanTransactionDetails({
    loanRef: loanRef ? loanRef : "",
    aggregator_id: aggregator_id ? aggregator_id : 0,
  });

  useEffect(() => {
    if (!isLoadingPendingLoanOffer && pendingLoanOffer) {
      const recentLoanOffer = pendingLoanOffer[pendingLoanOffer?.length - 1];
      setMostRecentLoanOffer(recentLoanOffer);
    }
  }, [pendingLoanOffer, isLoadingPendingLoanOffer]);

  useEffect(() => {
    if (widgetLoanDetailError) {
      setError(widgetLoanDetailError);
    }
  }, [widgetLoanDetailError, isLoadingWidgetLoanDetails]);

  if (showSchedule) {
    return (
      <LoanRepaymentSchedule
        loan_ref={loanRef}
        onCancel={() => {
          setShowSchedule(false);
        }}
        currency={widgetLoanDetails?.currency || ""}
      />
    );
  }

  if (showRepayment) {
    return (
      <LoanRepayments
        loan_ref={loanRef}
        onCancel={() => setShowRepayment(false)}
        currency={widgetLoanDetails?.currency || ""}
      />
    );
  }

  if (widgetData.showNewLoanOffer) {
    return <NewLoanOfferWidget />;
  }

  const getStatusStyle = (status: string | undefined) => {
    switch (status?.toLowerCase()) {
      case Status.Active:
      case Status.Repaid:
      case Status.Approved:
        return styles.active;
      case Status.Rejected:
      case Status.Overdue:
        return styles.rejected;
      case Status.AwaitingApproval:
      case Status.SubmittedAndAwaitingApproval:
        return styles.awaiting;
      default:
        return "";
    }
  };

  if (isLoadingWidgetLoanDetails) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  return (
    <div>
      {error && (
        <div style={{ paddingBottom: "20px", paddingTop: "10px" }}>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => {
              if (setError) {
                setError("");
              }
            }}
          />
        </div>
      )}
      <div className="d-flex justify-content-between align-items-center">
        <div onClick={() => (onCancel ? onCancel() : null)} className="cursor">
          <IoMdArrowRoundBack className="cursor__back" />
        </div>

        {widgetLoanDetails?.status?.toLowerCase() !==
          Status.AwaitingApproval && (
          <div className="d-flex align-items-center ">
            {pendingLoanOffer?.length > 0 &&
            mostRecentLoanOffer?.requested_loan_id ===
              widgetLoanDetails?.loan_id ? (
              <Button
                type="text"
                className="padding-none mr-3 cursor-pointer"
                onClick={() => {
                  setShowSchedule(false);
                  setShowRepayment(false);
                  dispatch(
                    updateWidgetSettingsAction({ showNewLoanOffer: true }),
                  );
                }}
                style={{ color: "orange" }}
              >
                View new offer
              </Button>
            ) : null}
            <button
              className="btn btn-primary btn-radius"
              onClick={() => {
                history.push({
                  pathname: ROUTES.widgetLoanRepaymentAmount,
                  state: {
                    loan_id: widgetLoanDetails?.loan_id,
                    loan_ref: widgetLoanDetails?.loan_ref,
                  },
                });
              }}
              disabled={
                widgetLoanDetails?.status?.toLowerCase() ===
                  Status.AwaitingApproval ||
                widgetLoanDetails?.status?.toLowerCase() ===
                  Status.SubmittedAndAwaitingApproval ||
                widgetLoanDetails?.status?.toLowerCase() === Status.Closed ||
                widgetLoanDetails?.status?.toLowerCase() ===
                  Status.WithdrawnByClient ||
                widgetLoanDetails?.has_repaid
              }
            >
              Repay loan
            </button>
          </div>
        )}
      </div>
      <br />

      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className=" mr-3">
              <img
                src={logo}
                alt=""
                className="img-fluid"
                style={{ width: "40px", height: "40px" }}
              />
            </div>
            <div>
              <p className="font-weight-bold text-dark padding-none">
                {formatMoney(
                  widgetLoanDetails?.loan_amount || 0,
                  widgetLoanDetails?.currency || "",
                )}
                <small
                  className={`ml-2 ${getStatusStyle(
                    widgetLoanDetails?.status,
                  )}`}
                >
                  {widgetLoanDetails?.status?.toLowerCase() ===
                  Status.SubmittedAndAwaitingApproval
                    ? "Awaiting approval"
                    : widgetLoanDetails?.status}
                </small>
              </p>
              <small className="text-muted padding-none">
                {moment(widgetLoanDetails?.pub_date).format(
                  "D/MM/YYYY, h:mm a",
                )}
              </small>
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-weight-bold padding-none text-dark">
                {widgetLoanDetails?.loan_id}
              </p>
              <p className="font-weight-bold padding-none text-dark">
                {accurateQuantifier(widgetLoanDetails?.loan_tenure, "day")}
              </p>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <p className=" padding-none text-muted">ID</p>
              <p className=" padding-none text-muted">Tenor</p>
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-weight-bold padding-none text-dark">
                {moment(widgetLoanDetails?.pub_date).format("DD MMM, YYYY")}
              </p>
              <p className="font-weight-bold padding-none text-dark">
                {loanRef}
              </p>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <p className=" padding-none text-muted">Request date</p>
              <p className=" padding-none text-muted">Reference Number</p>
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              {/* <p className={`font-weight-bold padding-none ${status?.toLowerCase() === "approved" ? styles.approved : status?.toLowerCase() === "rejected" ? styles.rejected : status?.toLowerCase() === "submitted_and_awaiting_approval" ? styles.awaiting : ""}`}>
                {status ? status : "N/A"}
              </p> */}
              {widgetLoanDetails?.status?.toLowerCase() !==
                Status.AwaitingApproval && (
                <p className="font-weight-bold padding-none text-dark">
                  {moment(widgetLoanDetails?.modified_date).format(
                    "DD MMM, YYYY",
                  )}
                </p>
              )}
            </div>

            <div className="d-flex justify-content-between align-items-center">
              {/* <p className=" padding-none text-muted">Disbursement Status</p> */}
              {widgetLoanDetails?.status?.toLowerCase() !==
                Status.AwaitingApproval && (
                <p className=" padding-none text-muted">Approval date</p>
              )}
            </div>
          </div>
          <hr />

          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-weight-bold padding-none text-dark">
                {formatMoney(
                  widgetLoanDetails?.loan_amount || 0,
                  widgetLoanDetails?.currency || "",
                )}
              </p>

              {widgetLoanDetails?.status?.toLowerCase() !==
                Status.AwaitingApproval && (
                <p className="font-weight-bold padding-none text-danger">
                  {moment(widgetLoanDetails?.loan_due_date).format(
                    "DD MMM, YYYY",
                  )}
                </p>
              )}
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <p className=" padding-none text-muted">Amount</p>
              {widgetLoanDetails?.status?.toLowerCase() !==
                Status.AwaitingApproval && (
                <p className=" padding-none text-muted">Maturity date</p>
              )}
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-weight-bold padding-none text-dark">
                {formatMoney(
                  widgetLoanDetails?.loan_interest_amount || 0,
                  widgetLoanDetails?.currency || "",
                )}
              </p>
              {widgetLoanDetails?.status?.toLowerCase() !==
                Status.AwaitingApproval && (
                <p className="font-weight-bold padding-none text-dark">
                  {formatMoney(
                    widgetLoanDetails?.loan_outstanding || 0,
                    widgetLoanDetails?.currency || "",
                  )}
                </p>
              )}
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <p className=" padding-none text-muted">Interest</p>
              {widgetLoanDetails?.status?.toLowerCase() !==
                Status.AwaitingApproval && (
                <p className=" padding-none text-muted">Outstanding</p>
              )}
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-weight-bold padding-none text-dark">
                {formatMoney(
                  Number(widgetLoanDetails?.loan_amount || 0) +
                    Number(widgetLoanDetails?.loan_interest_amount || 0) || 0,
                  widgetLoanDetails?.currency || "",
                )}
              </p>

              {widgetLoanDetails?.status?.toLowerCase() !==
                Status.AwaitingApproval && (
                <p className="font-weight-bold padding-none text-dark">
                  {formatMoney(
                    widgetLoanDetails?.total_loan_repaid || 0,
                    widgetLoanDetails?.currency || "",
                  )}
                </p>
              )}
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <p className=" padding-none text-muted">Total Repayment Amount</p>
              {widgetLoanDetails?.status?.toLowerCase() !==
                Status.AwaitingApproval && (
                <p className=" padding-none text-muted">Amount repaid</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <br />
      {widgetLoanDetails?.status?.toLowerCase() !== Status.AwaitingApproval && (
        <div className="d-flex align-items-center justify-content-between">
          <button
            className="btn btn-primary btn-radius"
            onClick={() => {
              setShowRepayment(true);
              setShowSchedule(false);
              dispatch(updateWidgetSettingsAction({ showNewLoanOffer: false }));
            }}
            disabled={
              widgetLoanDetails?.status?.toLowerCase() ===
                Status.AwaitingApproval ||
              widgetLoanDetails?.status?.toLowerCase() ===
                Status.SubmittedAndAwaitingApproval ||
              widgetLoanDetails?.status?.toLowerCase() === Status.Rejected ||
              widgetLoanDetails?.status?.toLowerCase() ===
                Status.WithdrawnByClient
            }
          >
            View repayments
          </button>

          <Button
            type="text"
            onClick={() => {
              setShowSchedule(true);
              setShowRepayment(false);
              dispatch(updateWidgetSettingsAction({ showNewLoanOffer: false }));
            }}
            className="text-primary padding-none cursor-pointer"
          >
            View repayments schedule
          </Button>
        </div>
      )}
    </div>
  );
};

export default LoanHistoryDetails;
