import { ROUTES } from "../../../../../../helpers/ROUTES";
import { IoMdArrowRoundBack } from "react-icons/io";
import {
  formatCurrency,
  formatMoney,
} from "../../../../../../helpers/formatCurrency";
import styles from "../../Loans.module.scss";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFetchPendingWidgetLoanOffer } from "../../../../../../custom-hooks/useFetchPendingLoanOffer";
import { useSelector, useDispatch } from "react-redux";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";
import { LoanOfferProps } from "../../../../../../web/components/features/newLoanOffer/NewLoanOffer";
import { Alert, Button } from "antd";
import {
  updateLoanDetailsAction,
  updateWidgetSettingsAction,
} from "../../../../../../redux/actions";
import { postDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import { LoanOfferStatus } from "../../../../../../helpers/shared";
import NewOfferAcceptedScreen from "./NewOfferAcceptedScreen";
import NewOfferRejectedScreen from "./NewOfferRejectedScreen";
import mixpanel from "mixpanel-browser";
import { MixPanel } from "../../../../../../helpers/mixpanel";

function NewLoanOfferWidget() {
  const [mostRecentLoanOffer, setMostRecentLoanOffer] =
    useState<LoanOfferProps | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showOfferAcceptedScreen, setShowOfferAcceptedScreen] = useState(false);
  const [showOfferRejectedScreen, setShowOfferRejectedScreen] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );
  const loanData = useSelector(
    (state: ReduxStoreModel) => state.loanDetailsReducer,
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Widget  - (NewLoanOffer.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: NewLoanOffer.tsx`, {
      page: "NewLoanOffer.tsx - (Widget)",
    });
  }, []);

  const {
    data: pendingLoanOffer,
    isLoading: isLoadingPendingLoanOffer,
    refetch: refetchPendingLoanOffer,
  } = useFetchPendingWidgetLoanOffer({
    customer_id: borrowerDetails?.customer_id,
    aggregator_id: borrowerDetails?.aggregator_id,
  });

  useEffect(() => {
    if (!isLoadingPendingLoanOffer && pendingLoanOffer) {
      const recentLoanOffer = pendingLoanOffer[pendingLoanOffer?.length - 1];
      setMostRecentLoanOffer(recentLoanOffer);
    }
  }, [pendingLoanOffer, isLoadingPendingLoanOffer]);

  function navigationHandler() {
    if (history.location.pathname === "/create-loan-account/loan/history") {
      dispatch(updateWidgetSettingsAction({ showNewLoanOffer: false }));
    } else {
      history.push(ROUTES.widgetDashboard);
    }
  }

  async function newOfferAcceptanceHandler() {
    setIsLoading(true);

    dispatch(
      updateLoanDetailsAction({
        new_loan_offer: {
          offer_amount: mostRecentLoanOffer?.offer_amount,
          offer_tenor: mostRecentLoanOffer?.offer_tenure,
        },
      }),
    );

    const payload = {
      loan_offer_id: mostRecentLoanOffer?.id,
      status: LoanOfferStatus.Accepted,
    };

    try {
      const response = await postDataWithKey(
        `${
          apiEndpoints.updateNewLoanOfferStatusWidget
        }?aggregator_id=${borrowerDetails?.aggregator_id}`,
        payload,
      );

      if (response?.status) {
        setShowOfferAcceptedScreen(true);
        mixpanel.track("New Offer Accepted", {
          distinct_id: `${borrowerDetails?.email}`,
          Comment: `${mostRecentLoanOffer?.comment}`,
          CreatedAt: `${mostRecentLoanOffer?.created_at}`,
          CreatedBy: `${mostRecentLoanOffer?.created_by}`,
          OfferAmount: `${mostRecentLoanOffer?.offer_amount}`,
          OfferTenure: `${mostRecentLoanOffer?.offer_tenure}`,
          RepaymentType: `${mostRecentLoanOffer?.repayment_type}`,
          RequestedAmount: `${mostRecentLoanOffer?.requested_amount}`,
          RequestedTenure: `${mostRecentLoanOffer?.requested_tenure}`,
        });
      }
    } catch (error) {
      setError(error?.response?.data?.message ?? error?.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Widget - (NewLoanOfferWidget.tsx)",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function newOfferRejectionHandler() {
    setIsLoading(true);
    dispatch(
      updateLoanDetailsAction({
        new_loan_offer_widget: {
          offer_amount: mostRecentLoanOffer?.offer_amount,
          offer_tenor: mostRecentLoanOffer?.offer_tenure,
        },
      }),
    );

    const payload = {
      loan_offer_id: mostRecentLoanOffer?.id,
      status: LoanOfferStatus.Rejected,
    };

    try {
      const response = await postDataWithKey(
        `${apiEndpoints.updateNewLoanOfferStatusWidget}?aggregator_id=${borrowerDetails?.aggregator_id}`,
        payload,
      );

      if (response.status) {
        mixpanel.track("New Offer Rejected", {
          distinct_id: `${borrowerDetails?.email}`,
          Comment: `${mostRecentLoanOffer?.comment}`,
          CreatedAt: `${mostRecentLoanOffer?.created_at}`,
          CreatedBy: `${mostRecentLoanOffer?.created_by}`,
          OfferAmount: `${mostRecentLoanOffer?.offer_amount}`,
          OfferTenure: `${mostRecentLoanOffer?.offer_tenure}`,
          RepaymentType: `${mostRecentLoanOffer?.repayment_type}`,
          RequestedAmount: `${mostRecentLoanOffer?.requested_amount}`,
          RequestedTenure: `${mostRecentLoanOffer?.requested_tenure}`,
        });
        setShowOfferRejectedScreen(true);
      }
    } catch (error) {
      setError(error?.response?.data?.message ?? error?.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Widget - (NewLoanOfferWidget.tsx)",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  if (showOfferAcceptedScreen) {
    return (
      <NewOfferAcceptedScreen
        mostRecentLoanOffer={mostRecentLoanOffer}
        loanData={loanData}
        refetchPendingLoanOffer={refetchPendingLoanOffer}
      />
    );
  }

  if (showOfferRejectedScreen) {
    return (
      <NewOfferRejectedScreen
        mostRecentLoanOffer={mostRecentLoanOffer}
        loanData={loanData}
        refetchPendingLoanOffer={refetchPendingLoanOffer}
      />
    );
  }

  if (isLoadingPendingLoanOffer || isLoading) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }
  return (
    <div style={{ maxWidth: "480px" }}>
      {error && (
        <div style={{ width: "100%" }}>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}
      <div onClick={navigationHandler} className="cursor">
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>Loan Offer</h4>
      {mostRecentLoanOffer?.offer_amount !==
        mostRecentLoanOffer?.requested_amount ||
      (mostRecentLoanOffer?.offer_amount !==
        mostRecentLoanOffer?.requested_amount &&
        mostRecentLoanOffer?.offer_tenure !==
          mostRecentLoanOffer?.requested_tenure) ? (
        <p>
          After careful review, your loan has been approved for an amount
          different from your original request. This adjustment is due to
          eligibility criteria. Here are the details of your revised loan:
        </p>
      ) : (
        <p>
          After careful review, your loan has been approved for a tenor
          different from your original request. This adjustment is due to
          eligibility criteria. Here are the details of your revised loan:
        </p>
      )}

      <div className={styles.loanOffer_container}>
        <div className={styles.loanOffer_overview}>
          <div className={styles.loan_amount_outer_container}>
            <div className={styles.loan_amount_container}>
              <span>
                ₦
                {formatCurrency(
                  Number(
                    mostRecentLoanOffer?.offer_amount ||
                      loanData?.new_loan_offer_widget?.offer_amount,
                  ),
                )}
              </span>
            </div>
          </div>

          <div className={styles.more_loan_details}>
            <div className={styles.loan_detail}>
              <span>Currency</span>
              <span>
                {mostRecentLoanOffer?.currency_code === "NGN" ? "Naira" : null}
              </span>
            </div>
            <div className={styles.loan_detail}>
              <span>Repayment Frequency</span>
              <span style={{ textTransform: "capitalize" }}>
                {mostRecentLoanOffer?.repayment_type}
              </span>
            </div>
            <div className={styles.loan_detail}>
              <span>Tenor</span>
              <span>{mostRecentLoanOffer?.offer_tenure} days</span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.buttons}>
        <Button type="primary" onClick={newOfferAcceptanceHandler}>
          Accept
        </Button>
        <Button type="primary" ghost onClick={newOfferRejectionHandler}>
          Decline
        </Button>
      </div>
    </div>
  );
}

export default NewLoanOfferWidget;
