import React, { useEffect } from 'react'
import styles from "./TransactionOverview.module.scss";
import emptyBox from "../../../../assets/web/dashboard/noItem.png";
import moment from "moment";
import { LoanHistory } from "../../../../interfaces/loan";
import view from "../../../../assets/web/arrow-circle-right.svg";
import { useDispatch } from "react-redux";
import {
  updateLoanDetailsAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";
import { appInsights } from '../../../../components/AppInsight/AppInsight'

interface TransactionOverviewProps {
  loanHistory: LoanHistory;
  isRefetchingLoanDetails: () => void;
}

enum Status {
  Active = "active",
  Repaid = "repaid",
  Approved = "approved",
  Rejected = "rejected",
  Overdue = "overdue",
  AwaitingApproval = "awaiting approval",
  SubmittedAndAwaitingApproval = "submitted_and_awaiting_approval",
  WithdrawnByClient = "withdrawnByClient",
  Closed = "closed",
  WrittenOff = "writtenOff",
  Overpaid = "overpaid",
}

function TransactionOverview({
  loanHistory,
  isRefetchingLoanDetails,
}: TransactionOverviewProps) {
  const dispatch = useDispatch();
  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Loan Dashboard  - (TransactionOverview.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const selectLoan = (id: number) => {
    loanHistory?.data?.find((loan) =>
      loan?.id === id
        ? dispatch(updateLoanDetailsAction({ loan_ref: loan.loanRef }))
        : ""
    );

    setTimeout(() => {
      isRefetchingLoanDetails();
      dispatch(updateWebSettingsAction({ showLoanDetails: true }));
    }, 0);
  };

  const getStatusStyle = (status: string | undefined) => {
    switch (status?.toLowerCase()) {
      case Status.Active:
      case Status.Approved:
      case Status.Repaid:
        return styles.flagActive;
      case Status.AwaitingApproval:
      case Status.SubmittedAndAwaitingApproval:
        return styles.flagPending;
      case Status.Overdue:
      case Status.Rejected:
        return styles.flagRejected;
      default:
        return styles.flagStale;
    }
  };

  return (
    <div className={styles.transaction_container}>
      <header className={styles.transaction_header}>
        <h2 className={styles.title}>Loan History</h2>
        {loanHistory?.data?.length > 0 && (
          <button
            className={styles.button}
            onClick={() =>
              dispatch(updateWebSettingsAction({ showTransactions: true }))
            }
          >
            See all
          </button>
        )}
      </header>

      <table>
        <thead className={styles.table_header}>
          <tr>
            <th>Ref No</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Maturity Date</th>
          </tr>
        </thead>

        {loanHistory?.data?.length > 0 ? (
          <tbody>
            {loanHistory?.data?.length > 0 &&
              loanHistory?.data?.slice(0, 4).map((loan) => (
                <tr
                  key={loan.id}
                  onClick={() => selectLoan(loan.id)}
                  className={styles.viewInfo}
                >
                  <td className={styles.transaction_date}>{loan.loanRef}</td>
                  <td className={styles.transaction_amount__credit}>
                    ₦{loan?.loanAmount?.toLocaleString()}
                  </td>
                  <td
                    className={`
                    ${getStatusStyle(loan?.status)}
                  `}
                  >
                    {loan?.status === "Submitted_and_awaiting_approval"
                      ? "Awaiting approval"
                      : loan?.status}
                  </td>
                  <td className={styles.transaction_date}>
                    {moment(loan?.loanMaturityDate).format("D/MM/YYYY")}
                  </td>
                  <td>
                    <img src={view} alt="" />
                  </td>
                </tr>
              ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={3}>
                <div className={styles.noTransaction_container}>
                  <img src={emptyBox} alt="" />
                  <h3>No transactions yet</h3>
                  <p>
                    Your transactions will appear here when you initiate one
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
}

export default TransactionOverview;
