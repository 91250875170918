import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData, getDataWithKey } from "../apis/apiMethods";

export const fetchLoanTransactions = async ({
  customerId,
  page,
}: {
  customerId: string;
  page: number;
}) => {
  const res = await getData(
    `${apiEndpoints.customerLoanHistory}/${customerId}?page=${page}`,
  );
};
export const fetchWebLoanTransactions = async ({
  customerId,
  page,
}: {
  customerId: string;
  page: number;
}) => {
  const res = await getData(
    `${apiEndpoints.customerLoanHistory}/${customerId}?page=${page}`,
  );

  return res.data;
};

export default function useFetchLoanHistory({
  customerId,
  page,
}: {
  customerId: string;
  page: number;
}) {
  return useQuery(
    [{ customerId }, "fetchLoanTransaction"],
    fetchLoanTransactions,
    {
      refetchOnWindowFocus: true,
      enabled: false,
    },
  );
}

export const fetchWidgetLoanTransactions = async ({
  customerId,
  aggregator_id,
  page,
}: {
  customerId: number;
  aggregator_id: number;
  page: number;
}) => {
  const res = await getDataWithKey(
    `${apiEndpoints.getBorrowerLoansInWidget}/${customerId}?aggregator_id=${aggregator_id}&page=${page}`,
  );

  return res.data;
};

export function useFetchWidgetLoanHistory({
  customerId,
  aggregator_id,
  page,
}: {
  customerId: number;
  aggregator_id: number;
  page: number;
}) {
  return useQuery(
    [{ customerId, aggregator_id, page }, "fetchWidgetLoanTransactions"],
    fetchWidgetLoanTransactions,
    {
      refetchOnWindowFocus: true,
      enabled: true,
    },
  );
}
