import { getData } from "../apis/apiMethods";
import { apiEndpoints } from "../apis/apiEndpoints";
import { useQuery } from "react-query";

async function fetchTermsAndConditions({
  aggregatorId,
}: {
  aggregatorId: string;
}) {
  const res = await getData(
    `${apiEndpoints.getTermsAndCondition}?aggregatorId=${aggregatorId}`,
  );

  return res.data;
}

export default function useFetchTermsAndConditions({
  aggregatorId,
}: {
  aggregatorId: string;
}) {
  return useQuery(
    [{ aggregatorId }, "fetchTermsAndConditions"],
    fetchTermsAndConditions,
    {
      refetchOnWindowFocus: false,
    },
  );
}
