import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const fetchRepayments = async ({ loan_ref }: { loan_ref: string }) => {
  const res = await getData(
    `${apiEndpoints.getCustomerRepayments}?loan_ref=${loan_ref}`,
  );

  return res.data;
};

export default function useFetchLoanRepayments({
  loan_ref,
}: {
  loan_ref: string;
}) {
  return useQuery([{ loan_ref }, "fetchRepayments"], fetchRepayments);
}
