import React, {
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import styles from "../Withdraw.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../../helpers/yupValidators";
import CustomInputField from "../../../../../components/CustomHTMLElements/EmbedCustomInputField";
import useFetchSecurityQuestions from "../../../../../custom-hooks/useFetchSecurityQuestion";
import { Question } from "../../../../../interfaces/question";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { postData } from "../../../../../apis/apiMethods";
import { getToken } from "../../../../../helpers/appHelpers";
import { Alert, Button } from "antd";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  securityQuestion: yupValidators.securityQuestion,
  securityAnswer: yupValidators.securityAnswer,
});

interface FormData {
  securityQuestion: string;
  securityAnswer: string;
}

interface SecurityQuestionProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  success: string;
  setSuccess: Dispatch<SetStateAction<string>>;
}

function SecurityQuestion({
  setCurrentStep,
  error,
  setError,
  success,
  setSuccess,
}: SecurityQuestionProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredQuestionsList, setFilteredQuestionsList] = useState<
    Question[]
  >([]);
  const [questionLists, setQuestionsList] = useState<Question[]>([]);
  const [selectedQuestionDetails, setselectedQuestionDetails] =
    useState<Question>();
  const [isLoading, setIsLoading] = useState(false);
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Withdraw  - (SecurityQuestion.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const { data: questions, isLoading: isLoadingQuestions } =
    useFetchSecurityQuestions({
      token: getToken(),
    });

  const getQuestionId = (question: string) => {
    const result = questionLists.filter((questions) => {
      const foundQuestion = questions.question === question;
      return foundQuestion
        ? setselectedQuestionDetails({
            ...selectedQuestionDetails,
            id: questions.id,
          })
        : null;
    });

    return result;
  };

  useEffect(() => {
    if (selectedQuestionDetails?.question) {
      getQuestionId(selectedQuestionDetails?.question);
    }
  }, [selectedQuestionDetails?.question]);

  useEffect(() => {
    setQuestionsList(questions);
  }, [isLoadingQuestions]);

  const onSubmit = async ({ securityQuestion, securityAnswer }: FormData) => {
    setIsLoading(true);
    setError("");
    setSuccess("");
    let successTimeout: NodeJS.Timeout;
    let errorTimeout: NodeJS.Timeout;
    try {
      setCurrentStep(2);
      const reqBody = {
        customer_id: parseInt(userInfo?.customerId),
        question_id: Number(selectedQuestionDetails?.id),
        answer: securityAnswer,
      };
      await postData(apiEndpoints.customerSecurityQuestionAnswer, reqBody);
      setSuccess("Your security question has been saved");

      successTimeout = setTimeout(() => {
        setCurrentStep(7);
      }, 1500);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Withdraw  - (SecurityQuestion.tsx)",
        },
      });
      setCurrentStep(6);

      errorTimeout = setTimeout(() => {
        setError(
          error.response?.data?.message ||
            "Failed to save security question and answer",
        );
      }, 0);
    } finally {
      setIsLoading(false);
    }

    return () => {
      clearTimeout(successTimeout);
      clearTimeout(errorTimeout);
    };
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setselectedQuestionDetails({
      ...selectedQuestionDetails,
      question: value,
    });

    setFilteredQuestionsList(
      questionLists.filter(
        (questions) =>
          questions.question?.toLowerCase() === value.toLowerCase(),
      ),
    );

    setShowDropdown(true);
  };

  const handleSelectQuestion = (question: Question) => {
    setselectedQuestionDetails({
      ...selectedQuestionDetails,
      question: question.question,
    });

    setShowDropdown(false);
  };

  const handleClose = (e: any) => {
    if (divRef?.current && !divRef?.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, []);

  return (
    <div className={styles.stepSix_container}>
      {error && (
        <div
          style={{ width: "100%", paddingTop: "40px", paddingBottom: "20px" }}
        >
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}
      {success && (
        <div
          style={{ width: "100%", paddingTop: "40px", paddingBottom: "10px" }}
        >
          <Alert
            message={success}
            type="success"
            showIcon
            closable
            onClose={() => setSuccess("")}
          />
        </div>
      )}
      <header className={styles.stepSix_header}>
        <div className={styles.title}>
          <h3>Security Question</h3>
        </div>
        <p>
          Kindly set a security question before creating your transaction pin.
        </p>
      </header>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.stepSix_form}>
        <div ref={divRef} className={styles.selectQuestionContainer}>
          <label className="label-txt">Question</label>
          <input
            type="text"
            name="securityQuestion"
            ref={register}
            placeholder={
              isLoadingQuestions ? "loading, please wait" : "Select question"
            }
            value={selectedQuestionDetails?.question}
            onChange={handleInputChange}
            onFocus={() => setShowDropdown(true)}
            className={styles.selectedQuestionField}
            disabled={isLoadingQuestions}
          />
          {showDropdown && (
            <ul className={styles.questionDropdown}>
              {filteredQuestionsList?.length > 0
                ? filteredQuestionsList?.map((question) => (
                    <li
                      key={question.id}
                      onClick={() => handleSelectQuestion(question)}
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      {question.question}
                    </li>
                  ))
                : questionLists?.map((question) => (
                    <li
                      key={question.id}
                      onClick={() => handleSelectQuestion(question)}
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      {question.question}
                    </li>
                  ))}
            </ul>
          )}
          <span className={styles.errorStyles}>
            {errors.securityQuestion?.message}
          </span>
        </div>

        <CustomInputField
          label="Answer"
          name="securityAnswer"
          type="text"
          placeholder="Enter answer"
          reference={register}
          errors={errors.securityAnswer}
        />

        <div className={styles.submit_button}>
          <Button type="primary" htmlType="submit" disabled={isLoading}>
            Proceed
          </Button>
        </div>
      </form>
    </div>
  );
}

export default SecurityQuestion;
