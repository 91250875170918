export type PaginateFunction = {
    nextPage: () => void;
    prevPage: () => void;
    pageSize: number
};
  
export const paginate = (
    pageNumber: number,
    startIndex: number,
    setPageNumber: React.Dispatch<React.SetStateAction<number>>,
    setStartIndex: React.Dispatch<React.SetStateAction<number>>,
    pageSize: number = 5
  ): PaginateFunction => {
    const nextPage = () => {
      setPageNumber((prev) => prev + 1);
      setStartIndex((prev) => prev + pageSize);
    };
  
    const prevPage = () => {
      if (pageNumber === 1) return;
      setPageNumber((prev) => prev - 1);
      setStartIndex((prev) => prev - pageSize);
    };
  
    return {
      nextPage,
      prevPage,
      pageSize
    };
};
  