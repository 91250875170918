import { useQuery } from "react-query";
import { getData } from "../apis/apiMethods";

const getDirectDebitExistingAccountsWeb = async ({
  customerId,
}: {
  customerId: number;
}) => {
  const directDebitBanksUrl = `/customer/${customerId}/direct_debit_accounts`;

  const data = await getData(`${directDebitBanksUrl}`);
  return data.data;
};

export default function useDirectDebitExistingAccountsWeb({
  customerId,
}: {
  customerId: number;
}) {
  return useQuery(
    [{ customerId }, "getDirectDebitExistingAccountsWeb"],
    getDirectDebitExistingAccountsWeb,
    {
      refetchOnWindowFocus: true,
    },
  );
}
