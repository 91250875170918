import React, { useEffect, useMemo, useState } from "react";
import styles from "./LoanRequest.module.scss";
import closeIcon from "../../../../assets/web/close-square.svg";
import Loader from "../../../../components/Loader/Loader";
import AddCardForm from "../../profile/card/addCard/AddCardForm";
import LoanRequestStepOne from "./LoanRequestStepOne";
import LoanRequestStepTwo from "./LoanRequestStepTwo";
import LoanRequestStepThree from "./LoanRequestStepThree";
import LoanRequestStepFour from "./LoanRequestStepFour";
import LoanRequestSuccess from "./LoanRequestSuccess";
import LoanRequestFailed from "./LoanRequestFailed";
import { getData } from "../../../../apis/apiMethods";
import LoanRequestDirectDebitWeb from "./LoanRequestDirectDebitWeb";
import LoanRequestAddNewDirectDebit from "./LoanRequestAddNewDirectDebit";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import useDirectDebitExistingAccountsWeb from "../../../../custom-hooks/useDirectDebitExistingAccountsWeb";
import BankStatementErrorScreen from "./BankStatementErrorScreen";
import AlternativeLoanCompleteScreen from "./AlternativeLoanCompleteScreen";
import { ISuccess } from "../../../../interfaces/success";
import { WalletDetails } from "../../../../interfaces/wallet";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import {
  updateLoanDetailsAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";
import ResidentialInformation from "../../../pages/signUp/residentialInformation/ResidentialInformation";
import { useFetchCustomerResidentialInfoStatus } from "../../../../custom-hooks/useFetchCustomerResidentialInfoStatus";
import advLogo from "../../../../assets/advancly-logo-full.png";
import loader from "../../../../assets/loadinggif.gif";

import { useFetchProducts } from "../../../../custom-hooks/useFetchProducts";
import { MixPanel } from "../../../../helpers/mixpanel";
import ProductdiveLoanInitialStep from "./ProductdiveLoanInitialStep";
import { Setting } from "../../../../interfaces/borrower";

interface SetLoanRequestProps {
  walletDetails: WalletDetails;
  userExistsData: any;
  isLoadingUserExist: boolean;
  bankStatementLinkedStatus: any;
  isLoadingBankStatementLinkedStatus: boolean;
}
interface GetCardProps {
  borrower_id: number;
  aggregator_id: number;
  isWidget: boolean;
}

export interface LoanProduct {
  id: number;
  product_name: string;
  maximum_tenor: number | null;
  maximum_amount: number | null;
  aggregator_id: number;
  amount?: number | string;
  repayment_type: number;
}

function LoanRequest({
  walletDetails,
  userExistsData,
  isLoadingUserExist,
  bankStatementLinkedStatus,
  isLoadingBankStatementLinkedStatus,
}: SetLoanRequestProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState<ISuccess>({
    headerText: "",
    bodyText: "",
  });
  const [cards, setCards] = useState<[]>([]);
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer,
  );

  const webAggregatorState = useSelector(
    (state: ReduxStoreModel) => state.webAggregatorSettingsReducer,
  );

  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );

  const { courseDescription, courseAmount } = borrowerDetails;

  const requireEquityContribution = useMemo(() => {
    const setting = webAggregatorState?.data?.find(
      (settings: Setting) =>
        settings.setting_name === "require_equity_contribution",
    );
    return setting?.setting_value === "1";
  }, [webAggregatorState]);

  useEffect(() => {
    MixPanel.track(`Page View: Loan.tsx`, {
      page: "Loan.tsx - (Web)",
    });
  }, []);

  useEffect(() => {
    if (requireEquityContribution && courseAmount && courseDescription) {
      setCurrentStep(13);
    }
  }, [requireEquityContribution]);

  const fetchCards = async ({
    borrower_id,
    aggregator_id,
    isWidget,
  }: GetCardProps) => {
    try {
      const res = await getData(
        `${apiEndpoints.fetchCards}?borrower_id=${borrower_id}&aggregator_id=${aggregator_id}&isWidget=${isWidget}`,
      );

      setCards(res.data);
    } catch (error) {
      console.error(error.response?.data?.message ?? error.message);
    }
  };

  const {
    data: existingDirectDebitAccountData,
    isLoading: directDebitLoading,
    error: directdebitError,
  } = useDirectDebitExistingAccountsWeb({ customerId: userInfo?.customerId });

  const { data: products, isLoading: isLoadingProducts } = useFetchProducts({
    countryCode: userInfo?.country,
    aggregatorId: userInfo?.aggregatorId,
  });

  const {
    data: residentialInfoStatus,
    isLoading: isLoadingResidentialInfoStatus,
  } = useFetchCustomerResidentialInfoStatus({
    customer_id: userInfo?.customerId,
  });

  useEffect(() => {
    fetchCards({
      borrower_id: userInfo?.customerId,
      aggregator_id: userInfo?.aggregatorId,
      isWidget: false,
    }).finally();
  }, [userInfo?.customerId, userInfo?.aggregatorId]);

  const handleNextButton = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleCancel = () => {
    setCurrentStep(0);

    dispatch(
      updateLoanDetailsAction({
        id: 0,
        product_name: "",
        maximum_tenor: null,
        maximum_amount: null,
        aggregator_id: 0,
        amount: 0,
        repayment_type: "",
      }),
    );
  };

  const closeModal = () => {
    document.body.style.overflow = "auto";

    setError("");
    setSuccess({
      headerText: "",
      bodyText: "",
    });

    dispatch(updateWebSettingsAction({ showLoanRequestModal: false }));

    dispatch(
      updateLoanDetailsAction({
        id: 0,
        product_name: "",
        maximum_tenor: null,
        maximum_amount: null,
        aggregator_id: 0,
        amount: 0,
        repayment_type: "",
      }),
    );
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_container}>
        {webStateData?.isSavingProvider || isLoadingResidentialInfoStatus ? (
          <>
            <br />
            <div className="d-flex justify-content-center">
              <img
                src={advLogo}
                alt=""
                className="img-fluid"
                style={{
                  height: "24px",
                  width: "126.53px",
                  objectFit: "contain",
                }}
              />
            </div>
            <br />
            <br />
            <div className="d-flex justify-content-center">
              <img
                src={loader}
                alt=""
                className="img-fluid"
                style={{
                  height: "20%",
                  width: "50%",
                  objectFit: "contain",
                }}
              />
            </div>
            <br />
            <br />
          </>
        ) : (
          <div className={styles.modal_steps_wrapper}>
            {currentStep === 0 && (
              <LoanRequestStepOne
                products={products}
                isLoadingProducts={isLoadingProducts}
                setCurrentStep={setCurrentStep}
                error={error}
                setError={setError}
              />
            )}
            {currentStep === 1 && (
              <LoanRequestStepTwo
                onPrevStep={handlePreviousStep}
                onNextStep={handleNextButton}
                setCurrentStep={setCurrentStep}
                walletDetails={walletDetails}
              />
            )}
            {currentStep === 2 && (
              <LoanRequestStepThree
                products={products}
                handleCancel={handleCancel}
                setCurrentStep={setCurrentStep}
                cards={cards}
                existingDirectDebitAccountData={existingDirectDebitAccountData}
                residentialInfoStatus={residentialInfoStatus}
              />
            )}
            {currentStep === 3 && (
              <LoanRequestStepFour
                onPrevStep={handlePreviousStep}
                handleCancel={handleCancel}
                setCurrentStep={setCurrentStep}
                userExistsData={userExistsData}
                isLoadingUserExist={isLoadingUserExist}
                bankStatementLinkedStatus={bankStatementLinkedStatus}
                isLoadingBankStatementLinkedStatus={
                  isLoadingBankStatementLinkedStatus
                }
                setError={setError}
                setSuccess={setSuccess}
                success={success}
                aggregator_id={userInfo?.aggregatorId}
                customer_id={userInfo?.customerId}
                bank_statement_provider_id={
                  userExistsData?.bank_statement_provider_id
                }
                error={error}
              />
            )}
            {currentStep === 4 && (
              <div style={{ height: "100%", marginTop: "40%" }}>
                <Loader message="Processing transaction" />
              </div>
            )}
            {currentStep === 5 && (
              <LoanRequestSuccess
                success={success}
                setSuccess={setSuccess}
                setCurrentStep={setCurrentStep}
                closeModal={closeModal}
              />
            )}
            {currentStep === 6 && <LoanRequestFailed error={error} />}
            {currentStep === 7 && (
              <AddCardForm
                updatePayStackStatus={() => null}
                residentialInfoStatus={residentialInfoStatus}
                setCurrentStep={setCurrentStep}
              />
            )}
            {currentStep === 8 && (
              <LoanRequestDirectDebitWeb
                setCurrentStep={setCurrentStep}
                existingDirectDebitAccountData={existingDirectDebitAccountData}
                directDebitLoading={directDebitLoading}
                directdebitError={directdebitError}
                error={error}
                setError={setError}
                residentialInfoStatus={residentialInfoStatus}
              />
            )}
            {currentStep === 9 && (
              <BankStatementErrorScreen
                onNextStep={handleNextButton}
                handleCancel={handleCancel}
                setCurrentStep={setCurrentStep}
                userExistsData={userExistsData}
                isLoadingUserExist={isLoadingUserExist}
                bankStatementLinkedStatus={bankStatementLinkedStatus}
                isLoadingBankStatementLinkedStatus={
                  isLoadingBankStatementLinkedStatus
                }
                setError={setError}
                setSuccess={setSuccess}
                error={error}
                success={success}
              />
            )}
            {currentStep === 10 && (
              <AlternativeLoanCompleteScreen
                onPrevStep={handlePreviousStep}
                setCurrentStep={setCurrentStep}
                setSuccess={setSuccess}
                setError={setError}
              />
            )}
            {currentStep === 11 && (
              <LoanRequestAddNewDirectDebit
                setCurrentStep={setCurrentStep}
                setSuccess={setSuccess}
                existingDirectDebitAccountData={existingDirectDebitAccountData}
                error={error}
                setError={setError}
              />
            )}

            {currentStep === 12 && (
              <ResidentialInformation
                setCurrentStep={setCurrentStep}
                error={error}
                setError={setError}
                isLoanRequest={true}
              />
            )}
            {currentStep === 13 && (
              <ProductdiveLoanInitialStep
                onPrevStep={handlePreviousStep}
                setCurrentStep={setCurrentStep}
              />
            )}

            <img
              src={closeIcon}
              alt=""
              className={styles.cancel_button}
              onClick={closeModal}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default LoanRequest;
