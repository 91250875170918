import React, { ChangeEventHandler } from "react";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";

type ICustomSelectDropdownProps = {
  name?: string;
  placeholder?: string;
  label?: string;
  errors?: any;
  required?: boolean;
  className?: string;
  disabled?: boolean | any;
  maxLength?: undefined | number;
  defaultValue?: string | number;
  value?: string | number;
  minLength?: undefined | number;
  onChange?: ChangeEventHandler;
  min?: number;
  showRequiredIcon?: boolean;
  hasActionButton?: boolean;
  actionButtonText?: string;
  onClickActionButton?: () => void;
  children?: React.ReactNode;
  extraLabel?: any;
  style?: React.CSSProperties;
  customSelectStyle?: React.CSSProperties | any;
  readonly?: boolean;
  reference?: any
}
const CustomSelectDropdown: React.FC<ICustomSelectDropdownProps> = React.forwardRef(
  (
    {
      name,
      children,
      label,
      errors,
      value,
      onChange,
      showRequiredIcon,
      extraLabel,
      required,
      className,
      disabled,
      defaultValue,
      style,
      customSelectStyle,
      reference,
      ...otherProps
    }: ICustomSelectDropdownProps,
  ) => {
    return (
        <div className={`form-group ${className} `} style={style}>
          <div className="d-flex align-items-center">
            <label className={`form_label`}>{label}</label>
            {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
            {extraLabel && extraLabel.length && (
              <label className="form-label ml-2">
                <small className="crimson">{extraLabel}</small>
              </label>
            )}
          </div>
          <select
            className="form-control-embed"
            name={name}
            ref={reference}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
            required={required}
            disabled={disabled}
            {...otherProps}
            style={{ borderRadius: "10px", ...customSelectStyle }}
          >
            {children}
          </select>
          {/* <img src={arrowDown} alt='' /> */}
          <ErrorHandler errors={errors} />
        </div>
    );
  }
);

export default CustomSelectDropdown;
