import React from "react";
import ButtonLoader from "../../ButtonLoader/ButtonLoader.component";
import styles from "./WidgetInnerPageLoader.module.scss";

export default function WidgetInnerPageLoader() {
  return (
    <div className={styles.widgetInnerPageLoader}>
      <ButtonLoader type="lg" noMarginLeft={true} />
    </div>
  );
}
