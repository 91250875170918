import React, { useEffect } from "react";
import embedLogo from "../../../assets/web/Embed.svg";
import mandateConfirmedSVG from "../../../assets/web/mandate_confirmed.svg";
import requestFailedSVG from "../../../assets/web/failed.svg"
import { useLocation } from "react-router-dom";
import styles from "./DirectdebitConfirmationPage.module.scss";
import Loader from "../../../components/Loader/Loader";
import useFetchMandateConfirmation from "../../../custom-hooks/useFetchMandateConfirmation";
import { appInsights } from "../../../components/AppInsight/AppInsight";

type ErrorMessage = {
  response: {
    data: {
      message: string;
    };
  };
};

function DirectdebitConfirmationPage() {
  const location = useLocation();
  // const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (DirectdebitConfirmationPage.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const mandateRef = location.pathname.substring(13);

  const {
    data: mandateConfirmationResponse,
    isLoading: isLoadingMandateConfirmationResponse,
    error: mandateConfirmationError,
    isError: isMandateError,
  } = useFetchMandateConfirmation({
    mandate_reference_code: mandateRef,
  });

  return (
    <div className={styles.direct_debit__container}>
      <header className={styles.logo__header}>
        <img src={embedLogo} alt="" />
        <span className={styles.logo}>embed</span>
      </header>

      <section className={styles.body}>
        {isLoadingMandateConfirmationResponse ? (
          <Loader message="Please wait..." />
        ) : isMandateError ? (
          <div>
            <img
              src={requestFailedSVG}
              alt=""
              className={styles.mandateConfirmedSVG}
            />

            <p className={styles.message}>
              {
                (mandateConfirmationError as ErrorMessage)?.response?.data
                  .message
              }
            </p>
          </div>
        ) : (
          <div>
            <img
              src={mandateConfirmedSVG}
              alt=""
              className={styles.mandateConfirmedSVG}
            />

            <p className={styles.message}>{mandateConfirmationResponse}</p>
          </div>
        )}
      </section>
    </div>
  );
}

export default DirectdebitConfirmationPage;
