import React, { useEffect } from "react";
import styles from "./RepaymentSchedule.module.scss";
import tickCircle from "../../../../assets/web/tick-circle.svg";
import closeCircle from "../../../../assets/web/close-circle.svg";
import closeIcon from "../../../../assets/web/close-square.svg";
import arrowBack from "../../../../assets/web/arrow-left.svg";
import Loader from "../../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  updateLoanDetailsAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

interface RepaymentScheduleProps {
  repaymentScheduleData: RepaymentScheduleData[];
  isLoadingRepaymentSchedule: boolean;
  setCurrentLoanOptionStep: React.Dispatch<React.SetStateAction<number>>
}

export interface RepaymentScheduleData {
  dueDate: number[];
  totalDueForPeriod: number;
  complete: boolean;
}

function RepaymentSchedule({
  repaymentScheduleData,
  isLoadingRepaymentSchedule,
  setCurrentLoanOptionStep
}: RepaymentScheduleProps) {
  const dispatch = useDispatch();
  const loanDataStore = useSelector(
    (state: ReduxStoreModel) => state.loanDetailsReducer
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (RepaymentSchedule.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const goBack = () => {
    dispatch(updateWebSettingsAction({ showRepaymentSchedule: false }));
    dispatch(updateWebSettingsAction({ showLoanDetails: true }));
  };

  const handleClose = () => {
    document.body.style.overflow = "auto";

    dispatch(updateLoanDetailsAction({ loan_ref: "" }));
    dispatch(updateWebSettingsAction({ showRepaymentSchedule: false }));

    setCurrentLoanOptionStep(0)
  }

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_container}>
        <img
          src={arrowBack}
          alt=""
          className={styles.back_button}
          onClick={goBack}
        />

        <header className={styles.modal_header}>
          <h3>Repayment Schedule</h3>
        </header>
        <>
          {isLoadingRepaymentSchedule ? (
            <div className={styles.isLoading_container}>
              <Loader message="Loading schedule..." />
            </div>
          ) : repaymentScheduleData?.length > 0 ? (
            <table>
              <tbody>
                {repaymentScheduleData?.map((data, index) => (
                  <tr key={index}>
                    <td>₦{data.totalDueForPeriod.toLocaleString() ?? 0}</td>
                    <td>
                      <div className={styles.date}>
                        <span className={styles.dueDate}>Due Date</span>
                        <span>{`${data?.dueDate[2]}/${data?.dueDate[1]}/${data.dueDate[0]}`}</span>
                      </div>
                      <img
                        src={
                          new Date(
                            String(loanDataStore?.loan_effective_date)
                          ).getMonth() +
                            1 ===
                            Number(data?.dueDate[1]) &&
                          data.totalDueForPeriod === 0
                            ? tickCircle
                            : new Date(
                                String(loanDataStore?.loan_effective_date)
                              ).getMonth() +
                                1 <=
                                Number(data?.dueDate[1]) &&
                              data.totalDueForPeriod > 0 &&
                              !data.complete
                            ? closeCircle
                            : new Date(
                                String(loanDataStore?.loan_effective_date)
                              ).getMonth() +
                                1 <=
                                Number(data?.dueDate[1]) &&
                              data.totalDueForPeriod > 0 &&
                              data.complete
                            ? tickCircle
                            : ""
                        }
                        alt=""
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data available</p>
          )}
        </>

        <img
          src={closeIcon}
          alt=""
          className={styles.cancel_button}
          onClick={handleClose}
        />
      </div>
    </>
  );
}

export default RepaymentSchedule;
