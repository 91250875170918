import {getData, getDataWithKey} from "../apis/apiMethods";
import { apiEndpoints } from "../apis/apiEndpoints";
import { useQuery } from "react-query";

async function fetchActiveLoans(loanStatus: string, customerId: number) {
  const response = await getData(
    `${apiEndpoints.fetchActiveLoans}/${loanStatus}?customerId=${customerId}`
  );

  return response?.data;
}

async function fetchActiveWidgetLoans(loanStatus: string, customerId: number, aggregatorId: number) {
  const response = await getDataWithKey(
      `${apiEndpoints.fetchActiveLoansWidget}/${loanStatus}?customerId=${customerId}&aggregatorId=${aggregatorId}`)

  return response?.data;
}

export function useFetchActiveLoans(
  loanStatus: number,
  customerId: number
) {
  return useQuery(
    [loanStatus, customerId, "fetchActiveLoans"],
    fetchActiveLoans
  );
}

export function useFetchActiveWidgetLoans(
    loanStatus: number,
    customerId: number,
    aggregatorId: number
) {
  return useQuery(
      [loanStatus, customerId, aggregatorId, "fetchActiveWidgetLoans"],
      fetchActiveWidgetLoans
  );
}
