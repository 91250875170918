import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getCountryList = async () => {
  const { data } = await getData(`${apiEndpoints.getCountries}`);

  return data;
};

export default function useFetchCountries() {
  return useQuery(["getCountryList"], getCountryList, {
    refetchOnWindowFocus: false,
  });
}
