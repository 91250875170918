import React, { useState } from "react";
import styles from "./SetTransactionPin.module.scss";
import closeIcon from "../../../../assets/web/close-square.svg";
import Begin from "./begin/Begin";
import SecurityQuestion from "./securityQuestion/SecurityQuestion";
import Pin from "./pin/Pin";
import Spinner from "./spinner/Spinner";
import Success from "./success/Success";
import { useQueryCache } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { updateWebSettingsAction } from "../../../../redux/actions";

interface SetTransactionPinProps {
  setStepOnComplete?: any;
  stepOnComplete?: any;
}

function SetTransactionPin({
  stepOnComplete,
  setStepOnComplete,
}: SetTransactionPinProps) {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer
  );

  const handleCancel = () => {
    localStorage.removeItem("firstTimeLogin");
    queryCache.invalidateQueries(["fetchWallet"]);
    if (webStateData?.showPinModal) {
      dispatch(
        updateWebSettingsAction({
          showPinModal: false,
        })
      );
    }
  };

  return (
    <div className={styles.modal_container}>
      <div className={styles.modal_steps_wrapper}>
        {currentStep === 0 && (
          <Begin
            setCurrentStep={setCurrentStep}
            handleCancel={handleCancel}
          />
        )}
        {currentStep === 1 && (
          <SecurityQuestion setCurrentStep={setCurrentStep} />
        )}
        {currentStep === 2 && <Pin setCurrentStep={setCurrentStep} />}
        {currentStep === 3 && <Spinner currentStep={currentStep} />}
        {currentStep === 4 && (
          <Success
            stepOnComplete={stepOnComplete}
            setStepOnComplete={setStepOnComplete}
            handleClose={handleCancel}
          />
        )}

        <img
          src={closeIcon}
          alt=""
          className={styles.cancel_button}
          onClick={handleCancel}
        />
      </div>
    </div>
  );
}

export default SetTransactionPin;
