import { getData, getDataWithKey } from "../apis/apiMethods";
import { apiEndpoints } from "../apis/apiEndpoints";
import { useQuery } from "react-query";

async function fetchPendingLoanOffer({ customer_id }: { customer_id: number }) {
  const response = await getData(
    `${apiEndpoints.fetchPendingLoanOffer}?customer_id=${customer_id}`,
  );
  return response.data;
}

async function fetchPendingWidgetLoanOffer({
  customer_id,
  aggregator_id,
}: {
  customer_id: number;
  aggregator_id: number;
}) {
  const response = await getDataWithKey(
    `${apiEndpoints.fetchPendingLoanOfferWidget}?customer_id=${customer_id}&aggregator_id=${aggregator_id}`,
  );

  return response.data;
}

export function useFetchPendingLoanOffer({
  customer_id,
}: {
  customer_id: number;
}) {
  return useQuery(
    [{ customer_id }, "fetchPendingLoanOffer"],
    fetchPendingLoanOffer,
    {
      enabled: customer_id ? true : false,
    },
  );
}

export function useFetchPendingWidgetLoanOffer({
  customer_id,
  aggregator_id,
}: {
  customer_id: number;
  aggregator_id: number;
}) {
  return useQuery(
    [{ customer_id, aggregator_id }, "fetchPendingWidgetLoanOffer"],
    fetchPendingWidgetLoanOffer,
    {
      enabled: customer_id && aggregator_id ? true : false,
    },
  );
}
