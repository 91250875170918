import React, { useEffect, useState } from "react";

type Props = {
  children?: React.ReactNode;
  handleCopy?: () => void;
  className?: string;
};

const AppCard = ({ children, handleCopy, className }: Props) => {
  const [background] = useState<{
    primaryColor: string;
    primaryDeepColor: string;
    primaryLightColor: string;
  }>({
    primaryColor: "",
    primaryLightColor: "",
    primaryDeepColor: "",
  });

  useEffect(() => {
    document.body.style.setProperty(
      "--primary-deep-color",
      background.primaryDeepColor || "#29067d",
    );
    document.body.style.setProperty(
      "--primary-color",
      background.primaryColor || "#713fff",
    );
    document.body.style.setProperty(
      "--primary-light-color",
      background.primaryLightColor || "#E3D9FF",
    );
  }, [background]);

  return (
    <div
      className={className}
      style={{
        width: "100%",
        backgroundColor: background.primaryLightColor || "#E3D9FF",
        borderRadius: "16px",
        padding: "17px 19.75px",
      }}
      onClick={handleCopy}
    >
      {children}
    </div>
  );
};

export default AppCard;
