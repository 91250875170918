import React, { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/all";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../helpers/ROUTES";
import DirectDebit from "../../../DirectDebit/DirectDebit";
import DirectDebitExistingAccounts from "../../../DirectDebit/DirectDebitExistingAccounts";
import useDirectDebitExistingAccounts from "../../../../../custom-hooks/useDirectDebitExistingAccounts";
import { ISuccess } from "../../../../../interfaces/success";
import { ReduxStoreModel } from "../../../../../interfaces/redux-interface";
import { useSelector } from "react-redux";

const DirectDebitProfile = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState<ISuccess>({
    headerText: "",
    bodyText: "",
  });
  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer
  );
  const widgetSettings = useSelector(
    (state: ReduxStoreModel) => state.widgetSettingsReducer
  );
  const { bankList } = widgetSettings;
  const { returningUserDetails, customer_id } = borrowerDetails;
  const { data: existingDirectDebitAccountData, error: directdebitError } =
    useDirectDebitExistingAccounts({
      customerId:
        customer_id || returningUserDetails?.customer_id
          ? customer_id || returningUserDetails?.customer_id
          : 0,
    });

  return (
    <div>
      <div
        onClick={() => history.push(ROUTES.widgetProfile)}
        className="cursor"
      >
        <IoMdArrowRoundBack size="26px" />
      </div>
      {existingDirectDebitAccountData?.bank_accounts?.length > 0 && (
        <DirectDebitExistingAccounts
          existingDirectDebitAccountData={existingDirectDebitAccountData}
          directdebitError={directdebitError}
        />
      )}
      <DirectDebit
        customerId={customer_id}
        bankList={bankList}
        error={error}
        setError={setError}
        setSuccess={setSuccess}
        success={success}
      />
    </div>
  );
};

export default DirectDebitProfile;
