import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData, getDataWithKey } from "../apis/apiMethods";

interface GetCardProps {
  borrower_id: number;
  aggregator_id: number;
  isWidget: boolean;
}

const getCards = async ({
  borrower_id,
  aggregator_id,
  isWidget,
}: GetCardProps) => {
  const { data } = await getData(
    `${apiEndpoints.fetchCards}?borrower_id=${borrower_id}&aggregator_id=${aggregator_id}&isWidget=${isWidget}`
  );

  return data;
};

export default function useFetchCards({
  borrower_id,
  aggregator_id,
  isWidget,
}: GetCardProps) {
  return useQuery(
    [{ borrower_id, aggregator_id, isWidget }, "getCards"],
    getCards,
    {
      refetchOnWindowFocus: true,
      // enabled: borrower_id && aggregator_id && isWidget ? true : false,
    }
  );
}

const getWidgetCards = async ({
  borrower_id,
  aggregator_id,
}: {
  borrower_id: number;
  aggregator_id: number;
}) => {
  const { data } = await getDataWithKey(
    `${apiEndpoints.fetchCards}?borrower_id=${borrower_id}&aggregator_id=${aggregator_id}`
  );

  return data;
};

export function useFetchWidgetCards({
  borrower_id,
  aggregator_id,
}: {
  borrower_id: number;
  aggregator_id: number;
}) {
  return useQuery(
    [{ borrower_id, aggregator_id }, "getWidgetCards"],
    getWidgetCards,
    {
      refetchOnWindowFocus: true,
      // enabled: borrower_id && aggregator_id && isWidget ? true : false,
    }
  );
}