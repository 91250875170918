import { WebAggregatorSettingsState } from "../../interfaces/borrower";
import { ReduxActionModel } from "../../interfaces/redux-interface";
import { WEB_AGGREGATOR_SETTINGS } from "../actions/types";

let initialState: WebAggregatorSettingsState = {
  data: [
    {
      setting_id: 0,
      aggregator_id: 0,
      country_code: "",
      setting_name: "",
      setting_value: "",
    },
  ],
};

export const updateWebAggregatorSettingsReducer = (
  state = initialState,
  action: ReduxActionModel,
) => {
  const { type, payload } = action;
  switch (type) {
    case WEB_AGGREGATOR_SETTINGS: {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
};
