export const ROUTES = {
  // resetPassword: "/reset_password",
  login: "/login",
  changePassword: "/change-password",

  // Widget Routes
  widget: "/create-loan-account",
  widgetEmbedApiSecurity: "/widget",
  widgetUpdateCard: "/create-loan-account/update-card-details",
  widgetPayments: "/create-loan-account/payments",
  widgetMakePayment: "/create-loan-account/make-payment",
  widgetViewRepayments: "/create-loan-account/repayments",
  widgetLoan: "/create-loan-account/loan",
  widgetLoanRequest: "/create-loan-account/loan/request",
  widgetLoanHistory: "/create-loan-account/loan/history",
  widgetAllLoans: "/create-loan-account/loan/all-loans",
  widgetALoanDetails: "/create-loan-account/loan/all-loans/details",
  widgetLoanOffer: "/create-loan-account/offer",
  widgetLoanRepaymentAmount: "/create-loan-account/loan/repayment/amount",
  widgetLoanRepaymentMethods: "/create-loan-account/loan/repayment/methods",
  widgetLoanRepaymentViaWallet: "/create-loan-account/loan/repayment/wallet",
  widgetLoanRepaymentViaNewCard: "/create-loan-account/loan/repayment/new-card",
  widgetLoanRepaymentViaDirectDebit:
    "/create-loan-account/loan/repayment/direct-debit",
  widgetLoanRepaymentProof: "/create-loan-account/loan/repayment/proof",
  widgetDashboard: "/create-loan-account/dashboard",
  widgetTopUp: "/create-loan-account/topup",
  widgetProfile: "/create-loan-account/profile",
  widgetQuickLoanRepayment: "/create-loan-account/quick-loan-repayment",
  widgetQuickLoanRepaymentAmount:
    "/create-loan-account/quick-loan-repayment-amount",
  widgetProfileInfo: "/create-loan-account/profile/info",
  widgetDirectDebitProfile: "/create-loan-account/profile/directdebit",
  widgetBankStatement: "/create-loan-account/profile/bankstatement",
  widgetCard: "/create-loan-account/profile/card",
  widgetPin: "/create-loan-account/profile/pin",
  widgetPinOnboarding: "/create-loan-account/onboarding/pin",
  widgetTransactions: "/create-loan-account/profile/transactions",
  widgetWithdrawal: "/create-loan-account/profile/withdrawal",
  widgetSetPin: "/create-loan-account/profile/setPin",
  widgetContactUs: "/create-loan-account/contact-us",
  widgetLoanRepaymentOptionView: "/create-loan-account/loan/repayment/options",
  widgetLoanRepaymentMpesa: "/create-loan-account/loan/repayment/options/mpesa",
  widgetNewLoanOffer: "/create-loan-account/loan/new-offer",

  // Web Routes
  WEB_LOGIN: "/:partnerName/login",

  // New Routing system
  WEB_LOGIN_PRODUCTDIVE: "/:partnerName/:courseDescription/:courseAmount/login",

  WEB_SIGN_UP: "/:partnerName/sign-up",

  WEB_SIGN_UP_PRODUCTDIVE:
    "/:partnerName/:courseDescription/:courseAmount/sign-up",

  RESET_PASSWORD: "/:partnerName/reset-password",

  RESET_PASSWORD_PRODUCTDIVE:
    "/:partnerName/:courseDescription/:courseAmount/reset-password",

  WEB_DASHBOARD: "/:partnerName",

  WEB_DASHBOARD_PRODUCTDIVE: "/:partnerName/:courseDescription/:courseAmount",

  WEB_LOAN: "/:partnerName/loan",

  WEB_LOAN_PRODUCTDIVE: "/:partnerName/:courseDescription/:courseAmount/loan",

  WEB_PROFILE: "/:partnerName/profile",

  WEB_PROFILE_PRODUCTDIVE:
    "/:partnerName/:courseDescription/:courseAmount/profile",

  WEB_CONTACTUS: "/:partnerName/contact-us",

  WEB_CONTACTUS_PRODUCTDIVE:
    "/:partnerName/:courseDescription/:courseAmount/contact-us",

  WEB_DIRECT_DEBIT_CONFIRMATION: "/directdebit/:id",

  WEB_INVALIDPARTNER: "/:partnerName/page-not-found",
};
