import React, { useState } from 'react'
import styles from "./Password.module.scss";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import {useLocation} from "react-router-dom";
import EmailVerification from "./EmailVerification";
import OtpVerification from "./OtpVerification";
import ChangePassword from "./ChangePassword";
import SuccessMessage from "./SuccessMessage";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";

enum ModalProfile {
    ModalProfileNavigator = "ModalProfileNavigator",
    ProfileInformation = "ProfileInformation",
    Card = "Card",
    DirectDebitAccountWeb = "DirectDebitAccountWeb",
    BankStatement = "BankStatement",
    Transaction = "Transaction",
    Password = "Password",
}

interface PasswordProps {
  setCurrentSection?: React.Dispatch<React.SetStateAction<ModalProfile>>;
}

export enum PasswordSection {
  EmailVerification = "EmailVerification",
  OtpVerification = "OtpVerification",
  ChangePassword = "ChangePassword",
  SuccessMessage = "SuccessMessage",
}

function Password({ setCurrentSection }: PasswordProps) {
  const [currentStep, setCurrentStep] = useState<PasswordSection>(
    PasswordSection.EmailVerification
  );
  const [userEmail, setUserEmail] = useState("");
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleGoBack = () => {
    if (setCurrentSection) {
      setCurrentSection(ModalProfile.ModalProfileNavigator);

      dispatch(
        updateWebSettingsAction({ errorMsg: "", successMsg: { bodyText: "" } })
      );
    }
  };

  const renderPasswordSection = () => {
    switch (currentStep) {
      case PasswordSection.EmailVerification:
        return (
          <EmailVerification
            setCurrentStep={setCurrentStep}
            setUserEmail={setUserEmail}
          />
        );
      case PasswordSection.OtpVerification:
        return (
          <OtpVerification
            setCurrentStep={setCurrentStep}
            userEmail={userEmail}
          />
        );
      case PasswordSection.ChangePassword:
        return <ChangePassword setCurrentStep={setCurrentStep} />;
      case PasswordSection.SuccessMessage:
        return <SuccessMessage />;
    }
  };

  return (
    <div className={`${styles.password_container} ${styles.alignment}`}>
      {location.pathname === `/${userInfo?.aggregator_name}` && (
        <img
          src={arrowLeft}
          alt=""
          className={styles.left}
          onClick={handleGoBack}
        />
      )}
      {renderPasswordSection()}
    </div>
  );
}

export default Password;
