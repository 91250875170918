import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData, getDataWithKey } from "../apis/apiMethods";

const directDebitsApprovedAccts = async ({
  loanRef,
  clientRequest,
}: {
  loanRef: string;
  clientRequest: boolean;
}) => {
  try {
    const response = await getData(
      `${apiEndpoints.directDebitEmandatesAccts}?loan_ref=${loanRef}&isClientRequest=${clientRequest}`,
    );
    return response.data;
  } catch (error: any) {
    console.error(error.response.data.message);
  }
};

const widgetDirectDebitsApprovedAccts = async ({
  loanRef,
  clientRequest, isWidget, aggregator_id
}: {
  loanRef: string;
  clientRequest: boolean; isWidget: boolean; aggregator_id: number
}) => {
  try {
    const response = await getDataWithKey(
      `${apiEndpoints.directDebitEmandatesAccts}?loan_ref=${loanRef}&isClientRequest=${clientRequest}&isWidget=${isWidget}&aggregator_id=${aggregator_id}`
    );
    return response.data;
  } catch (error: any) {
    console.error(error.response.data.message);
  }
};

export function useDirectDebitsApprovedAcct({
  loanRef,
  clientRequest,
}: {
  loanRef: string;
  clientRequest: boolean;
}) {
  return useQuery(
    [{ loanRef, clientRequest }, "directDebitsApprovedAccts"],
    directDebitsApprovedAccts,
    {
      refetchOnWindowFocus: false,
      enabled: loanRef && clientRequest ? true : false,
    },
  );
}

export function useWidgetDirectDebitsApprovedAcct({
  loanRef,
  clientRequest, isWidget, aggregator_id
}: {
  loanRef: string;
  clientRequest: boolean; isWidget: boolean; aggregator_id: number
}
) {
  return useQuery(
    [{ loanRef, clientRequest, isWidget, aggregator_id }, "widgetDirectDebitsApprovedAccts"],
    widgetDirectDebitsApprovedAccts,
    {
      refetchOnWindowFocus: false,
      // enabled: loanRef && clientRequest ? true : false,
    }
  );
}
