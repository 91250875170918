import { useQuery } from "react-query";
import { getData } from "../apis/apiMethods";
import { apiEndpoints } from "../apis/apiEndpoints";

async function fetchMandateConfirmation({mandate_reference_code}: {mandate_reference_code: string}) {
    const res = await getData(`${apiEndpoints.getMandateConfirmation}?mandate_reference_code=${mandate_reference_code}`)

    return res.message
}

export default function useFetchMandateConfirmation({mandate_reference_code}: {mandate_reference_code: string}) {
    return useQuery([{mandate_reference_code}, "fetchMandateConfirmation"], fetchMandateConfirmation, {
        refetchOnWindowFocus: false
    })
}