import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./QuickLoanRepayment.module.scss";
import closeIcon from "../../../../assets/web/close-square.svg";
import { Button } from "antd";
import { Alert } from "antd";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import adv from "../../../../assets/web/a.svg";
import Loader from "../../../../components/Loader/Loader";
import { getData, postData } from "../../../../apis/apiMethods";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { CardProp } from "../../profile/card/addCard/AddCardForm";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import wallet from "../../../../assets/web/wallet-3.svg";
import arrowRight from "../../../../assets/web/arrow-right.svg";
import card from "../../../../assets/web/card.svg";
import AddNewCard from "../repayLoan/AddNewCard";
import successSVG from "../../../../assets/web/success.svg";
import addCircle from "../../../../assets/web/add-circle.svg";
import { useFetchActiveLoans } from "../../../../custom-hooks/useFetchActiveLoans";
import useFetchCustomerWalletDetails from "../../../../custom-hooks/useFetchCustomerWalletDetails";
import moment from "moment";
import useFetchCards from "../../../../custom-hooks/useFetchCard";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { MixPanel } from "../../../../helpers/mixpanel";

const schema = yup.object().shape({
  amount: yupValidators.amount,
});

export enum XLendaLoanStatus {
  Submitted_and_awaiting_approval = 100,
  Approved = 200, // when the loan has been approved by all approving levels/officers
  Active = 300, // this is when the loan has been booked in Corebanking
  WithdrawnByClient = 400,
  Rejected = 500,
  Closed = 600,
  WrittenOff = 601,
  Rescheduled = 602,
  Overpaid = 700,
}

export interface ILoanData {
  loanAmount: number | null;
  status: string | null;
  loanRef: string | null;
  id: number | null;
}

function QuickLoanRepayment() {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [error, setError] = useState("");
  const [loanScheduleError, setLoanScheduleError] = useState("");
  const [success, setSuccess] = useState("");
  const [repaymentValue, setRepaymentValue] = useState<number>();
  const [approvedDirectDebitData, setApprovedDirectDebitData] = useState([]);
  const [approvedDirectDebitAccounts, setApprovedDirectDebitAccounts] =
    useState(null);
  const [isLoadingDirectDebitData, setIsLoadingDirectDebitData] =
    useState(false);
  const [formattedValue, setFormattedValue] = useState("");
  const [dueLoan, setDueLoan] = useState({
    dueAmount: "",
    dueDate: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [debitCard, setDebitCard] = useState<CardProp[]>([]);
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const [loanData, setLoanData] = useState<ILoanData | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web - (QuickLoanRepayment.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: QuickLoanRepayment.tsx`, {
      page: "QuickLoanRepayment.tsx - (Web)",
    });
  }, []);

  const { data: activeLoans, isLoading: isLoadingActiveLoans } =
    useFetchActiveLoans(XLendaLoanStatus.Active, userInfo?.customerId);

  const {
    data: walletDetails,
    isLoading: isLoadingWalletDetails,
    refetch: refetchFetchWalletdetails,
  } = useFetchCustomerWalletDetails();

  const { data: value } = useFetchCards({
    borrower_id: userInfo?.customerId,
    aggregator_id: userInfo?.aggregatorId,
    isWidget: false,
  });

  const handleSelectedLoan = async (loanInfo: any) => {
    setLoanScheduleError("");
    setCurrentStep(5);
    setLoanData((prev) => ({
      ...prev,
      loanRef: loanInfo?.loanRef,
      loanAmount: loanInfo?.loanAmount,
      id: loanInfo?.id,
      status: loanInfo?.status,
    }));

    try {
      const response = await getData(
        `${apiEndpoints.getWebLoanRepaymentSchedule}?loan_ref=${loanInfo?.loanRef}`,
      );

      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for today

      let dueLoanPeriod = response?.data?.periods?.find((loan: any) => {
        const loanDueDate = new Date(loan?.dueDate);
        loanDueDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for the loan due date

        return (
          loan?.totalDueForPeriod > 0 &&
          loanDueDate.getTime() >= today.getTime() &&
          loan?.complete === false
        );
      });

      if (!dueLoanPeriod) {
        const pastDueLoans = response?.data?.periods?.filter((loan: any) => {
          const loanDueDate = new Date(loan?.dueDate);
          loanDueDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for the loan due date

          return (
            loan?.totalDueForPeriod > 0 &&
            loanDueDate.getTime() < today.getTime() &&
            loan?.complete === false
          );
        });

        pastDueLoans?.sort((a: any, b: any) => {
          const dateA = new Date(a.dueDate).getTime();
          const dateB = new Date(b.dueDate).getTime();
          return dateB - dateA;
        });

        dueLoanPeriod = pastDueLoans?.[0]; // The first element is the most recent unpaid loan
      }

      if (dueLoanPeriod) {
        const dueDate = new Date(dueLoanPeriod.dueDate);
        const formattedDueDate = moment(dueDate).format("D/MM/YYYY");

        setDueLoan((prevState) => ({
          ...prevState,
          dueAmount:
            dueLoanPeriod.totalOutstandingForPeriod > 0
              ? dueLoanPeriod.totalOutstandingForPeriod
              : dueLoanPeriod.totalDueForPeriod,
          dueDate: formattedDueDate,
        }));
      }

      setCurrentStep(1);
    } catch (error) {
      setLoanScheduleError(
        error.response.data.message ??
          "Failed to fetch loan repayment schedule",
      );
      setCurrentStep(0);
    }
  };

  const directDebitsApprovedAccts = async (loan_ref: string) => {
    setIsLoadingDirectDebitData(true);
    try {
      const response = await getData(
        `${
          apiEndpoints.directDebitEmandatesAccts
        }?loan_ref=${loan_ref}&isClientRequest=${true}`,
      );
      setApprovedDirectDebitData(response.data);
    } catch (error) {
      console.error(error.response.data.message);
      appInsights.trackException({
        exception: error,
        properties: {
          filename: "Web - (QuickLoanRepayment.tsx)",
        },
      });
    } finally {
      setIsLoadingDirectDebitData(false);
    }
  };

  useEffect(() => {
    if (loanData?.loanRef) {
      directDebitsApprovedAccts(loanData.loanRef);
    }
  }, [loanData?.loanRef]);

  useEffect(() => {
    setDebitCard(value);
  }, [value, currentStep]);

  useEffect(() => {
    if (approvedDirectDebitData?.length > 0) {
      approvedDirectDebitData?.map((data: any) =>
        setApprovedDirectDebitAccounts(data?.id),
      );
    }
  }, [isLoadingDirectDebitData]);

  const onSubmit = ({ amount }: { amount: string }) => {
    const amountWithoutComma = amount.replace(/,/g, "");
    const convertedToNumber = parseFloat(amountWithoutComma);
    setRepaymentValue(convertedToNumber);

    setCurrentStep((prev) => prev + 1);
  };

  const handleRepaymentWithWallet = async () => {
    setCurrentStep(5);
    try {
      const response = await postData(apiEndpoints.repayloan, {
        aggregator_id: userInfo?.aggregatorId,
        borrower_id: userInfo.customerId,
        borrower_repayment_mode: "Wallet",
        loan_id: loanData?.id,
        amount: repaymentValue,
      });

      if (response.status) {
        setSuccess(response?.message);
        MixPanel.track("Repay loan", {
          distinct_id: `${userInfo?.email}`,
          "amount paid": `${repaymentValue}`,
          "repayment mode": `Wallet`,
          "repayment status": `Repaid`,
        });
        setCurrentStep(6);
      } else {
        setError(response?.message);
        appInsights.trackException({
          exception: response.message,
          properties: {
            fileName: "Web - (QuickLoanRepayment.tsx)",
          },
        });

        MixPanel.track("Repay loan", {
          distinct_id: `${userInfo?.email}`,
          "amount paid": `${repaymentValue}`,
          "repayment mode": `Wallet`,
          "repayment status": `Failed: ${response?.message}`,
        });
        setCurrentStep(7);
      }
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (QuickLoanRepayment.tsx)",
        },
      });

      MixPanel.track("Repay loan", {
        distinct_id: `${userInfo?.email}`,
        "amount paid": `${repaymentValue}`,
        "repayment mode": `Wallet`,
        "repayment status": `Failed: ${error.response?.data?.message ?? error.message}`,
      });
      setCurrentStep(7);
    }
  };

  const handleRepaymentWithDirectDebit = async () => {
    setCurrentStep(5);
    try {
      const response = await postData(apiEndpoints.repayloan, {
        aggregator_id: userInfo?.aggregatorId,
        borrower_id: userInfo.customerId,
        loan_direct_debit_mandate_id: approvedDirectDebitAccounts,
        borrower_repayment_mode: "DirectDebit",
        loan_id: loanData?.id,
        amount: repaymentValue,
      });

      if (response.status) {
        setSuccess(response?.message);
        MixPanel.track("Repay loan", {
          distinct_id: `${userInfo?.email}`,
          "amount paid": `${repaymentValue}`,
          "repayment mode": `Direct Debit`,
          "repayment status": `Repaid`,
        });
        setCurrentStep(6);
      } else {
        setError(response?.message);
        appInsights.trackException({
          exception: response.message,
          properties: {
            fileName: "Web - (QuickLoanRepayment.tsx)",
          },
        });

        MixPanel.track("Repay loan", {
          distinct_id: `${userInfo?.email}`,
          "amount paid": `${repaymentValue}`,
          "repayment mode": `Direct Debit`,
          "repayment status": `Failed: ${response?.message}`,
        });
        setCurrentStep(7);
      }
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (QuickLoanRepayment.tsx)",
        },
      });

      MixPanel.track("Repay loan", {
        distinct_id: `${userInfo?.email}`,
        "amount paid": `${repaymentValue}`,
        "repayment mode": `Direct Debit`,
        "repayment status": `Failed: ${error.response?.data?.message ?? error.message}`,
      });
      setCurrentStep(7);
    }
  };

  async function handleRepaymentWithCard() {
    if (debitCard?.length === 0) {
      setCurrentStep(4);
    } else {
      setCurrentStep(5);
      try {
        const response = await postData(apiEndpoints.repayloan, {
          aggregator_id: userInfo?.aggregatorId,
          borrower_id: userInfo.customerId,
          borrower_repayment_mode: "Card",
          loan_id: loanData?.id,
          amount: repaymentValue,
        });

        if (response.status) {
          setSuccess(response?.message);
          MixPanel.track("Repay loan", {
            distinct_id: `${userInfo?.email}`,
            "amount paid": `${repaymentValue}`,
            "repayment mode": `Card`,
            "repayment status": `Repaid`,
          });
          setCurrentStep(6);
        } else {
          setError(response?.message);
          appInsights.trackException({
            exception: response.message,
            properties: {
              fileName: "Web - (QuickLoanRepayment.tsx)",
            },
          });

          MixPanel.track("Repay loan", {
            distinct_id: `${userInfo?.email}`,
            "amount paid": `${repaymentValue}`,
            "repayment mode": `Card`,
            "repayment status": `Failed: ${response?.message}`,
          });
          setCurrentStep(7);
        }
      } catch (error) {
        setError(error.response?.data?.message ?? error.message);
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Web - (QuickLoanRepayment.tsx)",
          },
        });

        MixPanel.track("Repay loan", {
          distinct_id: `${userInfo?.email}`,
          "amount paid": `${repaymentValue}`,
          "repayment mode": `Card`,
          "repayment status": `Failed: ${error.response?.data?.message ?? error.message}`,
        });
        setCurrentStep(7);
      }
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Allow digits and at most one decimal point
    const value = e.target.value.replace(/[^0-9.]/g, "");

    // Ensure there's only one decimal point
    const parts = value.split(".");
    let formattedValue;
    if (parts.length > 2) {
      // More than one decimal point, ignore this input
      formattedValue = parts[0] + "." + parts[1];
    } else {
      formattedValue = value;
    }

    let formattedAmount: string;
    if (formattedValue === "") {
      formattedAmount = "";
    } else {
      const [integerPart, decimalPart] = formattedValue.split(".");
      if (decimalPart !== undefined) {
        formattedAmount =
          new Intl.NumberFormat("en-US").format(Number(integerPart)) +
          "." +
          decimalPart;
      } else {
        formattedAmount = new Intl.NumberFormat("en-US").format(
          Number(integerPart),
        );
      }
    }

    setFormattedValue(formattedAmount);
  };

  const handleClose = () => {
    document.body.style.overflow = "auto";
    refetchFetchWalletdetails().finally();

    dispatch(
      updateWebSettingsAction({
        showQuickRepayment: false,
      }),
    );
  };

  const goBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const renderContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className={styles.stepOne_container}>
            <header className={styles.stepOne_header}>
              {activeLoans?.length > 1 ? (
                <h3>Your Active Loans</h3>
              ) : (
                <h3>Your Active Loan</h3>
              )}
            </header>

            {isLoadingActiveLoans ? (
              <div className={styles.isLoading_container}>
                <Loader message="Loading your active loans..." />
              </div>
            ) : (
              <div className={styles.history_container}>
                {activeLoans?.length === 0 ? (
                  <h4 className="text-center text-muted">
                    You currently have no loan records
                  </h4>
                ) : (
                  <>
                    {activeLoans?.map((loan: ILoanData) => (
                      <div
                        key={loan?.id}
                        className={styles.loan_card}
                        onClick={() => handleSelectedLoan(loan)}
                      >
                        <div className={styles.logoAndAmount}>
                          <span className={styles.icon_container}>
                            <img src={adv} alt="" />
                          </span>
                          <div className={styles.amountAndDate}>
                            <span className={styles.amount}>
                              ₦{loan?.loanAmount?.toLocaleString()}
                            </span>

                            <span className={styles.date}>{loan?.loanRef}</span>
                          </div>
                        </div>

                        <span className={styles.repayBtn}>Repay</span>
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        );
      case 1:
        return (
          <div className={styles.stepTwo_container}>
            <img
              src={arrowLeft}
              alt=""
              className={styles.backArrow}
              onClick={goBack}
            />
            <header className={styles.stepTwo_header}>
              <h3>Loan Repayment</h3>
              <p>Enter the amount you would like to pay</p>
            </header>

            <div className={styles.dueAmountContainer}>
              <span className={styles.title}>Amount Due</span>
              {dueLoan?.dueAmount?.toLocaleString().split("").includes(".") ? (
                <span className={styles.credit_balance}>
                  ₦<strong>{dueLoan?.dueAmount.toLocaleString()}</strong>
                </span>
              ) : (
                <span className={styles.credit_balance}>
                  ₦<strong>{dueLoan?.dueAmount.toLocaleString()}.</strong>00
                </span>
              )}
              <span>Due Date: {dueLoan.dueDate}</span>
            </div>
            <form
              className={styles.stepTwo_form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={styles.input_container}>
                <div className={styles.input_label_container}>
                  <label>Enter amount to repay</label>
                  <input
                    type="text"
                    placeholder="₦"
                    name="amount"
                    ref={register}
                    className={`${styles.input_field}
                            }`}
                    value={formattedValue}
                    onChange={handleChange}
                  />
                </div>
                <span>{errors.amount?.message}</span>
              </div>

              <Button
                className={styles.submit_button}
                type="primary"
                htmlType="submit"
                disabled={!formattedValue || Number(formattedValue) <= 0}
              >
                Proceed
              </Button>
            </form>
          </div>
        );
      case 2:
        return (
          <div className={styles.stepThree_container}>
            <img
              src={arrowLeft}
              alt=""
              className={styles.backArrow}
              onClick={goBack}
            />
            <header className={styles.stepTwo_header}>
              <h3>Payment Method</h3>
              <p>Select a payment method to proceed</p>
            </header>

            <div className={styles.role}>
              <div
                className={styles.wallet}
                onClick={() => setCurrentStep((prev) => prev + 1)}
              >
                <div className={styles.group1}>
                  <div className={styles.walletContainer}>
                    <img src={wallet} alt="" />
                  </div>
                  <div className={styles.text}>
                    <p>Pay with wallet</p>
                    <p>₦{walletDetails?.availableBalance?.toLocaleString()}</p>
                  </div>
                </div>
                <img src={arrowRight} alt="" />
              </div>
              {approvedDirectDebitData?.length > 0 && (
                <div
                  className={styles.wallet}
                  onClick={() => setCurrentStep(8)}
                >
                  <div className={styles.group1}>
                    <div className={styles.walletContainer}>
                      <img src={wallet} alt="" />
                    </div>
                    <div className={styles.text}>
                      <p>Pay with Direct Debit</p>
                      <p>{""}</p>
                    </div>
                  </div>
                  <img src={arrowRight} alt="" />
                </div>
              )}

              <div className={styles.card} onClick={handleRepaymentWithCard}>
                <div className={styles.group2}>
                  <div className={styles.cardContainer}>
                    <img src={card} alt="" />
                  </div>
                  <div className={styles.text}>
                    <h4 className={styles.onlyChild}>Pay with existing card</h4>
                  </div>
                </div>
                <img src={arrowRight} alt="" />
              </div>
            </div>

            <button
              className={styles.addCard}
              onClick={() => setCurrentStep((prev) => prev + 2)}
            >
              <img src={addCircle} alt="" />
              <span>Pay with a new card</span>
            </button>
          </div>
        );
      case 3:
        return (
          <div className={styles.stepFour_container}>
            <img
              src={arrowLeft}
              alt=""
              className={styles.arrow}
              onClick={goBack}
            />

            <header className={styles.stepThree_header}>
              <h3>Pay with wallet</h3>
              <p>
                You are about to make your loan repayment of ₦
                {repaymentValue?.toLocaleString()} using your Advancly Wallet
              </p>
            </header>

            {isLoadingWalletDetails ? (
              <Loader message="Loading wallet..." />
            ) : (
              <div className={styles.loanAmount_container}>
                <span className={styles.title}>Wallet Balance</span>
                {walletDetails?.availableBalance
                  .toLocaleString()
                  .split("")
                  .includes(".") ? (
                  <span className={styles.credit_balance}>
                    ₦
                    <strong>
                      {walletDetails?.availableBalance?.toLocaleString() ?? 0}
                    </strong>
                  </span>
                ) : (
                  <span className={styles.credit_balance}>
                    ₦
                    <strong>
                      {walletDetails?.availableBalance?.toLocaleString() ?? 0}.
                    </strong>
                    00
                  </span>
                )}

                <small style={{ marginTop: "10px" }}>Fee: 0.00</small>
              </div>
            )}

            <Button
              className={styles.submit_button}
              type="primary"
              htmlType="submit"
              onClick={handleRepaymentWithWallet}
            >
              Proceed
            </Button>
          </div>
        );
      case 4:
        return (
          <div className={styles.stepFive_container}>
            <img
              src={arrowLeft}
              alt=""
              className={styles.backArrow}
              onClick={() => setCurrentStep(2)}
            />
            <AddNewCard
              updatePayStackStatus={() => null}
              handleRepaymentWithCard={handleRepaymentWithCard}
            />
          </div>
        );

      case 5:
        return (
          <div className={styles.stepSix_container}>
            <Loader message="Processing transaction..." />
          </div>
        );
      case 6:
        return (
          <div className={styles.stepSeven_container}>
            <div className={styles.verification_success}>
              <img src={successSVG} alt="" />
              <h3>Repayment Successful</h3>
              <p>{success}</p>
            </div>
          </div>
        );
      case 7:
        return (
          <div className={styles.stepEight_container}>
            <div className={styles.verification_failed}>
              <div className={styles.box}></div>
              <h3>Repayment Failed</h3>
              <p>{error}</p>
            </div>
          </div>
        );

      case 8:
        return (
          <div className={styles.stepNine_container}>
            <img
              src={arrowLeft}
              alt=""
              className={styles.backArrow}
              onClick={() => setCurrentStep(2)}
            />
            <h5>Choose a Direct Debit account</h5>

            {isLoadingDirectDebitData ? (
              <Loader message="Loading..." />
            ) : (
              approvedDirectDebitData?.map((debitDetails: any) => {
                const {
                  customer_bank_account_number,
                  customer_bank_account_name,
                  customer_bank_name,
                  amount: directAmount,
                  direct_debit_mandate_status,
                  id,
                } = debitDetails;
                return (
                  <>
                    <div
                      key={id}
                      className="d-flex flex-column align-items-start justify-content-start my-3"
                      onClick={() => {
                        setApprovedDirectDebitAccounts(id);
                      }}
                      style={{
                        backgroundColor:
                          approvedDirectDebitAccounts === id
                            ? "#713efe"
                            : "#f7f5ff",
                        border:
                          approvedDirectDebitAccounts === id
                            ? "1px solid #713efe"
                            : "1px solid #f7f5ff",
                        borderRightColor:
                          approvedDirectDebitAccounts === id
                            ? "#cfbefe"
                            : "#f7f5ff",
                        borderRightWidth:
                          approvedDirectDebitAccounts === id ? "5px" : "1px",

                        borderRadius: "10px",
                        padding: "10px",
                        cursor: "pointer",
                        color: approvedDirectDebitAccounts === id ? "#fff" : "",
                      }}
                    >
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <span className="mr-1">Account Number: {""} </span>
                        <span>{""}</span>
                        <span style={{ fontWeight: "bold" }}>
                          {customer_bank_account_number}
                        </span>
                      </div>
                      <div className="d-flex justify-content-end">
                        <span className="mr-1">Account Name: {""} </span>
                        <span style={{ fontWeight: "bold" }}>
                          {customer_bank_account_name}
                        </span>
                      </div>
                      <div className="d-flex justify-content-end">
                        <span className="mr-1">Bank Name: {""}</span>
                        <span style={{ fontWeight: "bold" }}>
                          {customer_bank_name}
                        </span>
                      </div>
                      <div className="d-flex justify-content-end">
                        <span className="mr-1">Amount: {""}</span>
                        <span style={{ fontWeight: "bold" }}>
                          {directAmount}
                        </span>
                      </div>
                      <div className="d-flex justify-content-end">
                        <span className="mr-1">Mandate Status: {""}</span>
                        <span style={{ fontWeight: "bold" }}>
                          {direct_debit_mandate_status}
                        </span>
                      </div>
                    </div>
                  </>
                );
              })
            )}

            <Button
              className={styles.submit_button}
              type="primary"
              htmlType="submit"
              onClick={handleRepaymentWithDirectDebit}
            >
              Confirm
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_container}>
        {loanScheduleError && (
          <div className={styles.alert}>
            <Alert
              message={loanScheduleError}
              type="error"
              showIcon
              closable
              onClose={() => setLoanScheduleError("")}
            />
          </div>
        )}
        <div className={styles.modal_steps_wrapper}>
          {renderContent()}

          <img
            src={closeIcon}
            alt=""
            className={styles.cancel_button}
            onClick={handleClose}
          />
        </div>
      </div>
    </>
  );
}

export default QuickLoanRepayment;
