import success from "../../../assets/success.svg";
import fail from "../../../assets/fail.svg";
import React, { SetStateAction, Dispatch } from "react";
import { ISuccess } from "../../../interfaces/success";

export type SuccessFailureProp = {
  title: string;
  body: string;
  handler: () => void;
  btnText: "Done" | "Try Again" | "Ok" | "Proceed";
  type: "success" | "failure";
  setSuccess?: Dispatch<SetStateAction<ISuccess>>
};

export const SuccessComponent = ({
  handler,
  body,
  title,
  btnText,
  type,
  setSuccess
}: SuccessFailureProp) => {
  const handleAction = () => {
    if(setSuccess) {
      setSuccess((prev) => ({...prev, headerText: "", bodyText: ""}))
    }
    handler()
  }
  return (
    <>
      <div className="d-flex justify-content-center">
        <img
          src={type ? success : fail}
          alt=""
          className="img-fluid"
          style={{
            height: "138px",
            width: "137px",
            objectFit: "contain",
          }}
        />
      </div>

      <p className="title text-center">{title}</p>
      <p className="text-center">{body}</p>
      <br />
      <br />
      <div className="d-flex justify-content-center">
        <button className="btn btn-primary btn-radius" onClick={handleAction}>
          {btnText}
        </button>
      </div>
    </>
  );
};
