import React from "react";
import styles from "./Withdraw.module.scss";
import Loader from "../../../../components/Loader/Loader";

function Spinner() {
  return (
    <div className={styles.stepThree_container}>
      <Loader message="Checking transaction status..." />
    </div>
  );
}

export default Spinner;
