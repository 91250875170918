
import React, { useEffect } from "react";
import styles from "./TransactionOverview.module.scss";
import emptyBox from "../../../../assets/web/dashboard/noItem.png";
import { WalletTransactions } from "../../../../interfaces/transactions";
import Loader from "../../../../components/Loader/Loader";
import moment from "moment";
import view from "../../../../assets/web/arrow-circle-right.svg";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

interface TransactionOverviewProps {
  transactions: WalletTransactions[];
  isTransactionLoading: boolean;
  setTransactionDetail: React.Dispatch<
    React.SetStateAction<WalletTransactions[] | null>
  >;
}

function TransactionOverview({
  transactions,
  isTransactionLoading,
  setTransactionDetail,
}: TransactionOverviewProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Dashboard  - (TransactionOverview.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleViewTransactions = () => {
    dispatch(
      updateWebSettingsAction({
        showTransactions: true,
      })
    );
  };

  const handleViewTransactionDetail = (id: string) => {
    document.body.style.overflow = "hidden";
    const result = transactions.filter(
      (transaction) => transaction.transactionId === id
    );

    setTransactionDetail(result);
    dispatch(updateWebSettingsAction({ showTransactionDetail: true }));
  };

  return (
    <div className={styles.transaction_container}>
      <header className={styles.transaction_header}>
        <h2 className={styles.title}>Recent Transactions</h2>
        {transactions?.length > 0 && (
          <button className={styles.button} onClick={handleViewTransactions}>
            See all
          </button>
        )}
      </header>

      <table>
        <thead className={styles.table_header}>
          <tr>
            <th>Transaction Type</th>
            <th>Amount</th>
            <th>Date & Time</th>
          </tr>
        </thead>

        <tbody className={isTransactionLoading ? styles.content : ""}>
          {isTransactionLoading ? (
            <tr
              style={{
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                textAlign: "center",
                width: "100%",
              }}
            >
              <td>
                <Loader />
              </td>
          </tr>
          ) : transactions?.length > 0 ? (
            transactions?.slice(0, 4)?.map((transaction) => (
              <tr
                key={transaction.transactionId}
                className={styles.viewInfo}
                onClick={() =>
                  handleViewTransactionDetail(transaction.transactionId)
                }
              >
                <td className={styles.transaction_type}>
                  {transaction.transactionType}
                </td>
                {transaction.transactionType === "Debit" ? (
                  <td className={styles.transaction_amount__debit}>
                    - ₦{transaction.debitAmount?.toLocaleString()}
                  </td>
                ) : (
                  <td className={styles.transaction_amount__credit}>
                    ₦{transaction.amount?.toLocaleString()}
                  </td>
                )}
                <td className={styles.transaction_date}>
                  {moment(transaction.transactionDate).format(
                    "MMM DD YYYY, hh:mma"
                  )}
                </td>
                <td>
                  <img src={view} alt="" />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3}>
                <div className={styles.noTransaction_container}>
                  <img src={emptyBox} alt="" />
                  <h3>No transactions yet</h3>
                  <p>
                    Your transactions will appear here when you initiate one
                  </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TransactionOverview;
