import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { RiFileCopyFill } from "react-icons/all";
import styles from "./DirectDebit.module.scss";
import copy from "copy-text-to-clipboard";
import { Alert } from "antd";

interface DirectDebitExistingAccountsProps {
  existingDirectDebitAccountData: any;
  directdebitError: any;
}

const DirectDebitExistingAccounts = ({
  existingDirectDebitAccountData,
  directdebitError,
}: DirectDebitExistingAccountsProps) => {
  const [copiedSuccessMessage, setCopiedSuccessMessage] = useState("");

  const [showBankAccounts, setShowBankAccounts] = useState(false);
  const handleCopy = () => {
    const content = document?.getElementById("accountNumber")?.textContent;
    if (content) {
      copy(content);
    }
  };

  return (
    <div>
      {copiedSuccessMessage && (
        <div
          style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}
        >
          <Alert
            message={copiedSuccessMessage}
            type="success"
            showIcon
            closable
            onClose={() => setCopiedSuccessMessage("")}
          />
        </div>
      )}
      <h4>Direct Debit Accounts</h4>
      <p
        className="font-weight-bold  text-primary cursor-pointer"
        onClick={() => setShowBankAccounts((prev) => !prev)}
      >
        {" "}
        View accounts{" "}
        {showBankAccounts ? (
          <IoIosArrowUp size="24px" />
        ) : (
          <IoIosArrowDown size="24px" />
        )}
      </p>

      {showBankAccounts && (
        <div>
          {directdebitError && (
            <small className="text-danger">{directdebitError}</small>
          )}
          {existingDirectDebitAccountData?.bank_accounts?.length > 0 ? (
            existingDirectDebitAccountData?.bank_accounts?.map(
              ({
                account_name,
                account_number,
                bank_name,
              }: {
                account_name: string;
                account_number: string;
                bank_name: any;
              }) => (
                <div
                  className={`d-flex justify-content-between align-items-center mt-2 ${styles.container_accounts}`}
                >
                  <div className="d-flex align-items-center">
                    <div>
                      <p
                        id="accountNumber"
                        className={`${styles.account_number}`}
                      >
                        {account_number}
                      </p>
                      <small className={`${styles.account_name}`}>
                        {" "}
                        {account_name}
                      </small>
                    </div>
                    <div
                      style={{ marginTop: "-6px" }}
                      className="cursor-pointer pl-3"
                      onClick={() => {
                        handleCopy();
                        setCopiedSuccessMessage("copied");
                        setTimeout(() => {
                          setCopiedSuccessMessage("");
                        }, 3000);
                      }}
                    >
                      <RiFileCopyFill className="text-primary-dark" />
                    </div>
                  </div>
                  <p className={`${styles.bank_name}`}>
                    {bank_name ? bank_name : " bank name unavailable"}
                  </p>
                </div>
              )
            )
          ) : (
            <div>
              <p>No Data Available</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DirectDebitExistingAccounts;
