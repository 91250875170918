import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithKey } from "../apis/apiMethods";

type IWidgetAggregatorSettings = {
  countryCode: any;
  aggregatorId: any;
  httpKey?: any;
};

type IWidgetAggregatorSettingsKey = {
  countryCode: any;
  aggregatorId: any;
  httpKey: any;
};

const getWidgetAggregatorSettings = async ({
  countryCode,
  aggregatorId,
}: IWidgetAggregatorSettings) => {
  const data = await getDataWithKey(
    `${apiEndpoints.getWidgetSettings}?AggregatorId=${aggregatorId}&CountryCode=${countryCode}`,
  );
  
  return data?.data;
};

const getWidgetAggregatorSettingsWithHttpKey = async ({
  countryCode,
  aggregatorId,
  httpKey,
}: IWidgetAggregatorSettingsKey) => {
  const data = await getDataWithKey(
    `${apiEndpoints.getWidgetSettings}?AggregatorId=${aggregatorId}&CountryCode=${countryCode}`,
  );
  return data.data;
};

export default function useWidgetAggregatorSettings({
  countryCode,
  aggregatorId,
}: IWidgetAggregatorSettings) {
  return useQuery(
    [{ countryCode, aggregatorId }, "getWidgetAggregatorSettings"],
    getWidgetAggregatorSettings,
    {
      enabled: countryCode && aggregatorId ? true : false,
    },
  );
}

export function useWidgetAggregatorSettingsWithHttpKey({
  countryCode,
  aggregatorId,
  httpKey,
}: IWidgetAggregatorSettings) {
  return useQuery(
    [
      { countryCode, aggregatorId, httpKey },
      "getWidgetAggregatorSettingsWithHttpKey",
    ],
    getWidgetAggregatorSettingsWithHttpKey,
    {
      enabled: httpKey && countryCode && aggregatorId ? true : false,
    },
  );
}
