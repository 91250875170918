import React, { useEffect, Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import { CustomPinInputField } from "../../CustomHTMLElements/CustomPinInputField.component";
import { appInsights } from "../../AppInsight/AppInsight";
import { Alert } from "antd";

type SetPinComponentProps = {
  handleNext: () => void;
  handleGoBack: () => void;
  setValue: (value: string) => void;
  label: string;
  withdrawalError: string;
  setWithdrawalError: Dispatch<SetStateAction<string>>;
  pinCount: string;
  setPinCount: Dispatch<SetStateAction<string>>;
};

const SetPinComponent = ({
  handleNext,
  label,
  setValue,
  handleGoBack,
  withdrawalError,
  setWithdrawalError,
  pinCount,
  setPinCount,
}: SetPinComponentProps) => {
  const history = useHistory();
  useEffect(() => {
    appInsights.trackPageView({
      name: "SetPinComponent.tsx",
      isLoggedIn: true,
    });
  }, []);

  const handleAction = () => {
    if (pinCount && pinCount.length === 4) {
      handleNext();
      setTimeout(() => {
        setPinCount("");
      }, 4000);
    }
  };
  return (
    <div>
      {withdrawalError && (
        <Alert
          message={withdrawalError}
          type="error"
          showIcon
          closable
          onClose={() => setWithdrawalError("")}
        />
      )}
      <h4>Transaction PIN</h4>
      <p className="padding-none">Enter your transaction PIN to proceed</p>
      <br />
      <br />
      <CustomPinInputField
        setValue={(value) => {
          if (value) {
            setPinCount(value);
          }
          setValue(value);
        }}
        length={4}
        label={label}
      />
      <div className="d-flex justify-content-end" style={{ marginTop: "40%" }}>
        <div className="d-flex align-items-center">
          <p
            className="text-primary padding-none mr-3 cursor-pointer"
            onClick={() => {
              handleGoBack ? handleGoBack() : history.goBack();
              setWithdrawalError("");
            }}
          >
            Go Back
          </p>
          <button
            className="btn btn-primary btn-radius"
            onClick={handleAction}
            disabled={pinCount.length < 4}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SetPinComponent;
