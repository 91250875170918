import React, { Dispatch, SetStateAction, useEffect } from "react";
import styles from "./Withdraw.module.scss";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
// import OtpInput from "../../otpComp/OtpInput";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { postData } from "../../../../apis/apiMethods";
import { decrypt } from "../../../../helpers/encryptor";
import PinInput from "react-pin-input";
import { Alert, Button } from "antd";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { MixPanel } from "../../../../helpers/mixpanel";

interface WithdrawalStepTwoProps {
  handlePreviousStep: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setError: Dispatch<SetStateAction<string>>;
  details: any;
  pin: string;
  setPin: Dispatch<SetStateAction<string>>;
  bankCode: string;
  error: string;
}

function WithdrawalStepTwo({
  handlePreviousStep,
  setCurrentStep,
  setError,
  details,
  pin,
  setPin,
  bankCode,
  error,
}: WithdrawalStepTwoProps) {
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Withdraw  - (WithdrawalStepTwo.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: WithdrawalStepTwo.tsx`, {
      page: "WithdrawalStepTwo.tsx - (Web)",
    });
  }, []);

  const handlePinSubmit = async () => {
    setError("");
    try {
      setCurrentStep(2);

      const reqBody = {
        sender_account_number: userInfo?.accountNumber,
        customer_id: parseInt(userInfo?.customerId),
        recipient_account_number: details.accountNumber,
        recipient_bank_code: bankCode,
        amount: Number(details?.amount),
        pin,
        comment: details.description,
        currency: userInfo?.currency,
        sender_wallet_id: decrypt(userInfo?.walletId),
      };

      await postData(apiEndpoints.customerWithdrawFunds, reqBody);

      MixPanel.track("Wallet Withdrawal", {
        distinct_id: `${userInfo?.email}`,
        amount: Number(details?.amount),
        status: "Withdrawal SuccessFul",
        product: "Embed Web",
      });

      setCurrentStep(3);
    } catch (error) {
      setError(`${error.response?.data?.message || "An error occurred"}`);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Withdraw - (WithdrawalStepTwo.tsx)",
        },
      });
      MixPanel.track("Wallet Withdrawal", {
        distinct_id: `${userInfo?.email}`,
        amount: Number(details?.amount),
        status: `Failed: ${error.response?.data?.message || "An error occurred"} `,
        product: "Embed Web",
      });
      setCurrentStep(4);
    }
  };

  return (
    <div className={styles.stepTwo_container}>
      <img
        src={arrowLeft}
        alt=""
        className={styles.backArrow}
        onClick={handlePreviousStep}
      />
      {error && (
        <div
          style={{ width: "100%", marginTop: "-20px", paddingBottom: "30px" }}
        >
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}
      <header className={`${styles.stepTwo_header}`}>
        <h3>Enter your transaction PIN</h3>
        <p>Enter your secure 4 digit PIN to proceed with this transaction</p>
      </header>

      <div>
        <PinInput
          length={4}
          secret
          secretDelay={100}
          initialValue=""
          focus
          onChange={(value, index) => {
            setPin(value);
          }}
          type="numeric"
          inputMode="number"
          autoSelect={true}
          regexCriteria={/^[ 0-9]*$/}
        />
      </div>

      <div className={styles.submit_button}>
        <Button
          type="primary"
          onClick={handlePinSubmit}
          disabled={pin.length < 4}
        >
          Proceed
        </Button>
      </div>
    </div>
  );
}

export default WithdrawalStepTwo;
