import React, { useEffect, Dispatch, SetStateAction } from "react";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import styles from "./BankStatement.module.scss";
import { useLocation } from "react-router-dom";

import { useGetLinkedBankAccountWeb } from "../../../../custom-hooks/useGetLinkedBankAccount";
import { Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

enum ModalProfile {
  ModalProfileNavigator = "ModalProfileNavigator",
  ProfileInformation = "ProfileInformation",
  Card = "Card",
  DirectDebitAccountWeb = "DirectDebitAccountWeb",
  BankStatement = "BankStatement",
  Transaction = "Transaction",
  Password = "Password",
}

interface BankStatementProps {
  setCurrentSection?: Dispatch<SetStateAction<ModalProfile>>;
  handleBankStatement: () => any;
  isLoadingBankStatementLinkedStatus: boolean;
  bankStatementLinkedStatus: any;
}

function BankStatement({
  setCurrentSection,
  handleBankStatement,
  bankStatementLinkedStatus,
  isLoadingBankStatementLinkedStatus,
}: BankStatementProps) {
  const location = useLocation();
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (BankStatement.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleGoBack = () => {
    if (setCurrentSection) {
      setCurrentSection(ModalProfile.ModalProfileNavigator);
    }

    dispatch(
      updateWebSettingsAction({ errorMsg: "", successMsg: { bodyText: "" } })
    );
  };

  const urlExtension = location.pathname.split("/").filter(Boolean);

  const { data: linkedAccount, isLoading: isLoadingLinkedAccount } =
    useGetLinkedBankAccountWeb({
      customerId: userInfo.customerId,
    });

  return (
    <div
      className={`${styles.bankStatement_container} ${
        location.pathname === `/${userInfo?.aggregator_name}` &&
        styles.alignment
      }`}
    >
      {location.pathname === `/${userInfo?.aggregator_name}` && (
        <img
          src={arrowLeft}
          alt=""
          className={styles.left}
          onClick={handleGoBack}
        />
      )}

      {webStateData.errorMsg && urlExtension[1] !== "profile" && (
        <div className={styles.alert}>
          <Alert
            message={webStateData.errorMsg}
            type="error"
            showIcon
            closable
            onClose={() => dispatch(updateWebSettingsAction({ errorMsg: "" }))}
          />
        </div>
      )}
      {webStateData.successMsg.bodyText && urlExtension[1] !== "profile" && (
        <div className={styles.alert}>
          <Alert
            message={webStateData.successMsg.bodyText}
            type="success"
            showIcon
            closable
            onClose={() =>
              dispatch(
                updateWebSettingsAction({ successMsg: { bodyText: "" } })
              )
            }
          />
        </div>
      )}

      <header className={styles.header}>
        <h3>Bank Statement</h3>
      </header>

      {isLoadingLinkedAccount || isLoadingBankStatementLinkedStatus ? (
        <div className={styles.validate_aggregator}>
          <span className={`spinner-border spinner-border-lg mr-2 `}></span>
          <span className={styles.validating_text}>
            Please wait, checking if account exists...
          </span>
        </div>
      ) : linkedAccount?.length > 0 && bankStatementLinkedStatus.status ? (
        <div>
          <p
            className={`${
              location.pathname === `/${userInfo?.aggregator_name}` &&
              styles.alignLeft
            }`}
          >
            Providing us with more financial information improves your credit
            eligibility.
          </p>

          <table className="table">
            <thead>
              <tr>
                <th>Account Name</th>
                <th>Account No</th>
                <th>Bank Name</th>
              </tr>
            </thead>
            <tbody>
              {linkedAccount?.map((account: any) => (
                <tr key={account.accountNo}>
                  <td>{account.accountName}</td>
                  <td>{account.accountNo}</td>
                  <td>{account.bankName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (!linkedAccount || linkedAccount?.length === 0) && bankStatementLinkedStatus.status ? (
          <div className={styles.body}>
            <p className={`${
                location.pathname === `/${userInfo?.aggregator_name}` &&
                styles.alignLeft
            }`}>An error occurred while fetching your linked bank statement! Please contact support.</p>
          </div>
      ) : (
        <div className={styles.body}>
          <p
            className={`${
              location.pathname === `/${userInfo?.aggregator_name}` &&
              styles.alignLeft
            }`}
          >
            Providing us with more financial information improves your credit
            eligibility. To link your bank statement click the button below 
          </p>
          <button onClick={handleBankStatement}>Link Account</button>
        </div>
      )}
    </div>
  );
}

export default BankStatement;
