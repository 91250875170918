import React, { ReactElement } from "react";
import loader from "../../assets/loadinggif.gif";
import embed from "../../assets/embed.svg";

interface ButtonLoaderProps {
  type?: string;
  noMarginLeft?: boolean;
}

export default function ButtonLoader({
  type,
  noMarginLeft,
}: ButtonLoaderProps): ReactElement {
  return (
    <div className="d-flex flex-column justify-content-center">
      <img
        src={embed}
        alt=""
        style={{
          alignSelf: "center",
          height: "20%",
          width: "20%",
        }}
      />
      <img
        src={loader}
        alt=""
        className="img-fluid"
        style={{
          alignSelf: "center",
          height: "80%",
          width: "80%",
          objectFit: "contain",
        }}
      />
    </div>
  );
}
