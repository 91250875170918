import React, { useState } from "react";
import { useHistory } from "react-router";
import AppCard from "../../../components/AppCard";
import upload from "../../../../../../assets/document-upload.svg";
import { SuccessComponent } from "../../../SuccessComponent";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { message, Upload, UploadProps } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { postDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import { useLocation } from "react-router-dom";
// import { UserProp } from "../../../Dashboard/Dashboard.component";
import { UploadFile } from "antd/lib/upload/interface";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";

const LoanRepaymentProof = () => {
  let location = useLocation<any>();
  const history = useHistory();
  const [done, setDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [base64_file_string, setBase64_file_string] = useState<unknown>("");
  const [filename_with_extension, setFilename_with_extension] = useState("");

  const widgetSettings = useSelector(
    (state: ReduxStoreModel) => state.widgetSettingsReducer
  );
  const { encryptedKey } = widgetSettings;
  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const props: UploadProps = {
    name: "file",
    action: undefined,
    async onChange(info) {
      if (info.file.status !== "removed" && info.file.status !== "uploading") {
        // console.log({ file: info.file.originFileObj?.name });
        try {
          setFilename_with_extension(info.file.originFileObj?.name || "");
          const result = await toBase64(info.file.originFileObj);
          if (typeof result === "string") {
            setBase64_file_string(result.split(",")[1]);
          }
        } catch (error) {
          // console.error(error);
          return;
        }
      }
    },
  };

  const handleChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    // console.log({ file: info.file.originFileObj?.name });
    try {
      setFilename_with_extension(info.file.name || "");
      const result = await toBase64(info.file);
      if (typeof result === "string") {
        setBase64_file_string(result.split(",")[1]);
      }
    } catch (error) {
      // console.error(error);
      return;
    }
  };

  const handleSubmitRepayment = async () => {
    setIsLoading(true);
    const reqBody = {
      loan_id: location?.state?.loan_id,
      repayment_amount: Number(location?.state?.amount),
      repayment_date: new Date().toISOString(),
      base64_file_string,
      filename_with_extension,
    };
    try {
      const response = await postDataWithKey(
        apiEndpoints.offlineRepayment,
        reqBody,
        //@ts-ignore
        encryptedKey
      );
      console.log({ response });
      setDone(true);
    } catch (error: any) {
      console.log(error);
      message.error(error.message || "Something went wrong please try again!");
    } finally {
      setIsLoading(false);
    }
  };

  if (done) {
    return (
      <SuccessComponent
        btnText="Done"
        body="Your loan repayment will be confirmed in the next 24 hours"
        title="Repayment Proof Submitted"
        handler={() => history.push(ROUTES.widgetLoan)}
        type="success"
      />
    );
  }
  return (
    <div>
      <div className="title">Upload Proof of payment</div>
      <p>Kindly upload the proof of payment for your loan repayment</p>

      <AppCard handleCopy={() => null}>
        <div className="w-100">
          <div className=" proof__upload">
            <Upload
              {...props}
              accept=".png,.jpg,.jpeg"
              maxCount={1}
              onRemove={() => {
                console.log("removing");
                setFilename_with_extension("");
                setBase64_file_string("");
              }}
              beforeUpload={() => {
                return false;
              }}
              onChange={handleChange}
            >
              <img
                src={upload}
                alt="Proof of payment"
                style={{ width: "3rem", height: "3rem" }}
              />
            </Upload>
          </div>
          <p className="font-weight-bold text-dark text-center m-0">
            Upload Proof of payment
          </p>
          <p className="text-center">Drag & drop your file here or browse</p>
        </div>
      </AppCard>

      <div className="d-flex justify-content-end" style={{ marginTop: "10%" }}>
        <div className="d-flex align-items-center">
          <p
            className="text-primary padding-none mr-3 cursor-pointer"
            onClick={() => history.goBack()}
          >
            Go Back
          </p>
          <button
            className="btn btn-primary btn-radius"
            onClick={handleSubmitRepayment}
            disabled={!base64_file_string || !filename_with_extension}
          >
            {isLoading ? (
              <span className="spinner-border text-white" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoanRepaymentProof;
