import { useQuery } from "react-query";
import { getData, getDataWithKey } from "../apis/apiMethods";
import { apiEndpoints } from "../apis/apiEndpoints";

async function checkResidentialInfoStatus({
  customer_id,
}: {
  customer_id: number;
}) {
  const response = await getData(
    `${apiEndpoints.confirmResidentialAddress}?customer_id=${customer_id}`,
  );

  return response.data;
}

async function checkResidentialInfoStatusWidget({
  customer_id,
  aggregator_id,
}: {
  customer_id: number;
  aggregator_id: number;
}) {
  const response = await getDataWithKey(
    `${apiEndpoints.confirmResidentialAddressWidget}?customer_id=${customer_id}&aggregator_id=${aggregator_id}`,
  );

  return response.data;
}

export function useFetchCustomerResidentialInfoStatus({
  customer_id,
}: {
  customer_id: number;
}) {
  return useQuery(
    [{ customer_id }, "checkResidentialInfoStatus"],
    checkResidentialInfoStatus,
    {
      enabled: customer_id ? true : false,
    },
  );
}

export function useFetchCustomerResidentialInfoStatusWidget({
  customer_id,
  aggregator_id,
}: {
  customer_id: number;
  aggregator_id: number;
}) {
  return useQuery(
    [{ customer_id, aggregator_id }, "checkResidentialInfoStatusWidget"],
    checkResidentialInfoStatusWidget,
    {
      enabled: customer_id && aggregator_id ? true : false,
    },
  );
}
