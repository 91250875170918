import React from "react";
import styles from "./WidgetInitialError.module.scss";
interface IWidgetInitialError {
  checkFirstTimeBorrowerError?: string;
  checkBankStatementAuthStatusError?: string;
  ValidateAggregatorKeyError?: string;
  invalidAggregatorIdError?: string;
  unexpectedError?: boolean;
}
const WidgetInitialError = ({
  checkFirstTimeBorrowerError,
  checkBankStatementAuthStatusError,
  ValidateAggregatorKeyError,
  unexpectedError,
  invalidAggregatorIdError,
}: IWidgetInitialError) => {
  return (
    <div className={styles.widgetErrorContainer}>
      {checkFirstTimeBorrowerError && (
        <div
          className={styles.subContainer}
        >{`Error : ${checkFirstTimeBorrowerError}!!!`}</div>
      )}
      {checkBankStatementAuthStatusError && (
        <div className={styles.subContainer}>
          {` Error :${checkBankStatementAuthStatusError} !!!`}
        </div>
      )}
      {ValidateAggregatorKeyError && (
        <div
          className={styles.subContainer}
        >{`Error : ${ValidateAggregatorKeyError} !!!`}</div>
      )}
      {unexpectedError && (
        <div
          className={styles.subContainer}
        >{`Error : Unexpected error occurred !!!`}</div>
      )}{" "}
      {invalidAggregatorIdError && (
        <div
          className={styles.subContainer}
        >{`Error : ${invalidAggregatorIdError} !!!`}</div>
      )}
      <button
        className="btn btn-sm mt-5"
        onClick={() => window.location.reload()}
      >
        Refresh
      </button>
    </div>
  );
};

export default WidgetInitialError;
