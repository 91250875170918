import React, { Fragment, useEffect, useState } from "react";
import styles from "./WidgetFlow.module.scss";
import { useHistory, withRouter } from "react-router-dom";
import WidgetLoader from "./WidgetLoader/WidgetLoader.component";
import { ROUTES } from "../../helpers/ROUTES";
import { localCurrency, WIDGET_RESPONSE_STATUSES } from "./utils";
import { deviceType } from "react-device-detect";
import { RiCloseCircleFill } from "react-icons/ri";
import { appInsights } from "../AppInsight/AppInsight";
import CONFIG from "../../helpers/config";
import {
  getHTTPKey,
  handleGetEncryptedKey,
  storeHTTPKey,
} from "../../helpers/appHelpers";
import { errorHandler } from "../../helpers/errorHandler";
import WidgetInitialError from "./WidgetRevampv2/Dashboard/components/WidgetInitialError/WidgetInitialError";
import { useDispatch, useSelector } from "react-redux";
import {
  updateBorrowerDetailsAction,
  updateWidgetSettingsAction,
} from "../../redux/actions";
import { ReduxStoreModel } from "../../interfaces/redux-interface";
import useFetchBanks from "../../custom-hooks/useFetchBanks";
import useFetchCountries from "../../custom-hooks/useFetchCountries";
import { useWidgetAggregatorSettingsWithHttpKey } from "../../custom-hooks/useWidgetAggregatorSettings";
import useFetchUserExists from "../../custom-hooks/useFetchUserExists";
import useFetchBankStatementProviderStatus from "../../custom-hooks/useFetchBankStatementProviderLinked";
import useGenerateAggregatorPrivateKey from "../../custom-hooks/useGenerateAggregatorPrivateKey";
import { sendResponseToClient } from "../../helpers";
import WidgetRoutes from "./WidgetRoutes";

// const theme = {
//   primaryColor: "#713fff",
//   primaryDeepColor: "#29067d",
//   primaryLightColor: "#E3D9FF",
// };

const WidgetFlow = () => {
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [invalidAggregatorIdError, setInvalidAggregatorIdError] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const widgetState = useSelector(
    (store: ReduxStoreModel) => store.widgetSettingsReducer,
  );
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer,
  );

  // useState<DataFromAggregatorProps | null>(null);

  const {
    data: BankData,
    isLoading: isLoadingBankData,
    error: bankDataError,
  } = useFetchBanks({
    country_code: borrowerDetails?.country_code ?? "NG",
  });

  const {
    data: countryLists,
    isLoading: isLoadingCountryLists,
    error: countryListsError,
  } = useFetchCountries();

  const {
    data: aggregatorKeyData,
    error: ValidateAggregatorKeyError,
    isLoading: isLoadingAggregatorKeyData,
  } = useGenerateAggregatorPrivateKey({
    aggregator_id: borrowerDetails?.aggregator_id,
    public_key: widgetState?.public_key,
  });

  const {
    data: widgetAggregatorSettingsData,
    isLoading: isLoadingWidgetAggregatorSettingsData,
    error: widgetAggregatorSettingsDataError,
  } = useWidgetAggregatorSettingsWithHttpKey({
    countryCode: borrowerDetails?.country_code,
    aggregatorId: borrowerDetails?.aggregator_id,
    httpKey: widgetState?.encryptedKey,
  });

  const {
    data: userExistsData,
    isLoading: isLoadingUserExist,
    error: checkFirstTimeBorrowerError,
  } = useFetchUserExists({
    identity_number: borrowerDetails?.bvn_number,
    phone_number: borrowerDetails?.borrower_phone,
    aggregator_id: borrowerDetails?.aggregator_id,
    email: borrowerDetails?.email,
    business_registration_number: borrowerDetails?.business_registration_number,
  });

  const {
    data: bankStatementLinkedStatus,
    isLoading: isLoadingCheckBankStatementAuthStatus,
    error: checkBankStatementAuthStatusError,
  } = useFetchBankStatementProviderStatus({
    aggregator_id: borrowerDetails?.aggregator_id,
    customer_id: borrowerDetails?.customer_id,
    bank_statement_provider_id:
      borrowerDetails?.bank_statement_provider_id ??
      userExistsData?.bank_statement_provider_id,
  });

  useEffect(() => {
    console.log({
      isLoadingCheckBankStatementAuthStatus,
      checkBankStatementAuthStatusError,
      isLoadingUserExist,
      checkFirstTimeBorrowerError,
      isLoadingWidgetAggregatorSettingsData,
      widgetAggregatorSettingsDataError,
      isLoadingAggregatorKeyData,
      ValidateAggregatorKeyError,
      isLoadingCountryLists,
      countryListsError,
      isLoadingBankData,
      bankDataError,
    });

    // Check if all isLoading flags are false
    const isLoadingFlags = [
      isLoadingCheckBankStatementAuthStatus,
      isLoadingUserExist,
      isLoadingWidgetAggregatorSettingsData,
      isLoadingAggregatorKeyData,
      isLoadingCountryLists,
      isLoadingBankData,
    ];
    const allLoadingFalse = isLoadingFlags.every((flag) => !flag);

    // Check if any error flag is truthy
    const errorFlags = [
      checkBankStatementAuthStatusError,
      checkFirstTimeBorrowerError,
      widgetAggregatorSettingsDataError,
      ValidateAggregatorKeyError,
      countryListsError,
      bankDataError,
    ];
    const anyError = errorFlags.some((flag) => flag);
    // Set isLoading state based on conditions
    if (allLoadingFalse || anyError) {
      setIsLoading(false);
    }
  }, [
    isLoadingCheckBankStatementAuthStatus,
    checkBankStatementAuthStatusError,
    isLoadingUserExist,
    checkFirstTimeBorrowerError,
    isLoadingWidgetAggregatorSettingsData,
    widgetAggregatorSettingsDataError,
    isLoadingAggregatorKeyData,
    ValidateAggregatorKeyError,
    isLoadingCountryLists,
    countryListsError,
    isLoadingBankData,
    bankDataError,
  ]);

  useEffect(() => {
    // Listen to messages from the parent window
    window.addEventListener("message", async (e: any) => {
      const { data } = e;
      // Check if the received data is an object and has the expected properties
      if (data && data.hasOwnProperty("aggregator_id")) {
        await setInitialData(data, document.location.origin);
        if (
          CONFIG.BASE_URL !==
            "https://advancly-api-master.test.vggdev.com/api/v1" &&
          CONFIG.BASE_URL !==
            "https://advancly-api-master.staging.vggdev.com/api/v1"
        ) {
          data.public_key = "PUBLIC_KEY_HIDDEN_IN_PROD_ENV";
        }
        //Below appInsights is tracking the data coming in from the widget HTML
        appInsights.trackEvent({ name: "WidgetHTMLData", properties: data });
      }
    });

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", () =>
        console.log("Listener removed"),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Disable Scroll on this page
    if (window && window.document) {
      const bodyElement = window.document.querySelector("body");
      if (bodyElement) {
        bodyElement.style.overflow = "hidden";
      }
    }
    if (aggregatorKeyData?.private_key) {
      getEncryptedKey().finally();
      dispatch(
        updateWidgetSettingsAction({
          edit_widget: aggregatorKeyData?.allow_widget_edit,
        }),
      );
    }
    if (widgetAggregatorSettingsData) {
      getWidgetSettings().finally();
    }
    if (BankData) {
      dispatch(
        updateWidgetSettingsAction({
          bankList: BankData,
        }),
      );
    }
    if (countryLists) {
      dispatch(updateWidgetSettingsAction({ worldCountries: countryLists }));
    }
    if (userExistsData) {
      checkFirstTimeBorrower().finally();
    }

    if (bankStatementLinkedStatus) {
      dispatch(
        updateWidgetSettingsAction({
          finishedFirstInitializations: true,
        }),
      );
      dispatch(
        updateBorrowerDetailsAction({
          bank_statement_connected: bankStatementLinkedStatus?.status,
        }),
      );
      if (userExistsData?.data?.customer_id) {
        history.push(ROUTES.widgetDashboard);
      }
    }
    //eslint-disable-next-line
  }, [
    widgetAggregatorSettingsData,
    aggregatorKeyData?.allow_widget_edit,
    aggregatorKeyData?.private_key,
    userExistsData?.data?.customer_id,
    bankStatementLinkedStatus?.status,
  ]);

  useEffect(() => {
    setShowError(false);
  }, []);

  function handleShowError(error: any) {
    setIsLoading(false);
    // setShowError(true);
    return appInsights.trackException({
      exception: new Error(error),
      properties: { fileName: "WidgetFlow.jsx" },
    });
    // return message.error(errorHandler(error || "Something went wrong"));
  }

  const setInitialData = async (messageFromAggregator: any, origin: string) => {
    if (messageFromAggregator && typeof messageFromAggregator === "object") {
      // Convert aggregator_id to a number for the check
      const aggregatorId = Number(messageFromAggregator?.aggregator_id);
      // const aggregatorId = messageFromAggregator?.aggregator_id;
      if (
        messageFromAggregator &&
        messageFromAggregator?.aggregator_id &&
        !Number.isSafeInteger(aggregatorId)
      ) {
        setIsLoading(false);
        setInvalidAggregatorIdError(
          `Invalid Aggregator Id : ${messageFromAggregator?.aggregator_id}`,
        );
        appInsights.trackException({
          exception: new Error(
            `Invalid Aggregator Id : ${messageFromAggregator?.aggregator_id}`,
          ),
          properties: { fileName: "WidgetFlow.jsx" },
        });
        return;
      } else {
        const {
          customStyles,
          public_key,
          aggregator_id,
          identity_number,
          bank_code,
          bank_account_number,
          customer_phone,
          email,
          first_name,
          last_name,
          customer_type,
          company_name,
          business_registration_number,
          country_code,
          canCloseWidget,
        } = messageFromAggregator;

        // if (!company_name || !first_name || !last_name || !customer_phone) {
        if (!company_name || !first_name || !last_name) {
          console.log(`sending error`);
          console.log("line 279");
          handleShowError(
            new Error(
              "Missing one of the following required fields : Company name, First Name, Last Name, Customer Phone",
            ),
          );
          appInsights.trackException({
            exception: new Error(
              "Missing one of the following required fields : Company name, First Name, Last Name, Customer Phone",
            ),
            properties: { fileName: "WidgetFlow.jsx" },
          });
        }
        messageFromAggregator.borrower_phone = customer_phone;

        dispatch(
          updateWidgetSettingsAction({
            canCloseWidget,
            public_key,
            customStyles,
          }),
        );

        dispatch(updateBorrowerDetailsAction(messageFromAggregator));

        const user = {
          borrower_phone: customer_phone,
          aggregator_domain: origin,
          bvn_number: identity_number,
          aggregator_id,
          bank_code,
          bank_account_number,
          first_name,
          last_name,
          email,
          customer_type,
          company_name,
          business_registration_number,
          country_code,
          bank_statement_connected: false,
          bank_statement_provider: "",
          bank_statement_provider_id: "",
          currency: localCurrency(country_code),
          first_time_borrower: false,
          returningUserDetails: messageFromAggregator,
          customer_id: 0,
        };
        dispatch(updateBorrowerDetailsAction(user));
      }
    }
  };

  const getEncryptedKey = async () => {
    const encryptedKey = handleGetEncryptedKey(
      widgetState?.public_key,
      aggregatorKeyData?.private_key,
    );
    storeHTTPKey(encryptedKey);
    dispatch(
      updateWidgetSettingsAction({
        encryptedKey,
      }),
    );
  };

  const getWidgetSettings = async (): Promise<void> => {
    try {
      const settingKeys: string[] = [
        "use_wallet",
        "allow_contact_us",
        "allow_sms_notification",
        "allow_withdrawal",
        "allow_pin_change",
        "allow_bank_details",
        "view_wallet_balance",
        "bank_details_input_enabled",
      ];

      interface Setting {
        setting_name: string;
        setting_value?: string;
      }

      const settings: { [key: string]: Setting | undefined } =
        settingKeys.reduce(
          (acc: { [key: string]: Setting | undefined }, key: string) => {
            acc[key] = widgetAggregatorSettingsData.find(
              (setting: Setting) => setting.setting_name === key,
            );
            return acc;
          },
          {},
        );

      // Helper function to determine the boolean value based on setting_value
      const resolveSetting = (setting: Setting | undefined): boolean => {
        return (
          setting?.setting_value === "1" || setting?.setting_value === undefined
        );
      };

      // Dispatch the action with cleaned-up parameters
      dispatch(
        updateWidgetSettingsAction({
          use_wallet: resolveSetting(settings["use_wallet"]),
          allow_contact_us: resolveSetting(settings["allow_contact_us"]),
          allow_sms_notification: resolveSetting(
            settings["allow_sms_notification"],
          ),
          allow_withdrawal: resolveSetting(settings["allow_withdrawal"]),
          allow_pin_change: resolveSetting(settings["allow_pin_change"]),
          allow_bank_details: resolveSetting(settings["allow_bank_details"]),
          allow_view_balance: resolveSetting(settings["view_wallet_balance"]),
          bank_details_input_enabled: resolveSetting(
            settings["bank_details_input_enabled"],
          ),
        }),
      );
    } catch (error) {
      console.error({ getWidgetSettingsError: error });
      appInsights.trackException({
        exception: new Error(JSON.stringify(error)),
        properties: { fileName: "WidgetFlow.tsx" },
      });
      // handleShowError(error);
    }
  };

  // check for first time borrowers
  const checkFirstTimeBorrower = async () => {
    if (
      userExistsData?.message?.includes("No match found") ||
      userExistsData?.status === false
    ) {
      dispatch(
        updateBorrowerDetailsAction({
          ...borrowerDetails,
          first_time_borrower: true,
        }),
      );
    } else {
      userExistsData.data.aggregator_id = borrowerDetails?.aggregator_id;
      userExistsData.data.encryptedKey = getHTTPKey();
      dispatch(
        updateBorrowerDetailsAction({
          first_time_borrower: false,
          returningUserDetails: userExistsData?.data,
          customer_type: userExistsData?.data?.customer_type,
          customer_id: userExistsData?.data?.customer_id,
          first_name: userExistsData?.data?.first_name,
          last_name: userExistsData?.data?.last_name,
          bvn_number: userExistsData?.data?.bvn_number,
          business_registration_number:
            userExistsData?.data?.business_registration_number,
          borrower_phone: userExistsData?.data?.borrower_phone,
          company_name: userExistsData?.data?.company_name,
          email: userExistsData?.data?.email,
          bank_statement_provider:
            userExistsData?.data?.bank_statement_provider,
          bank_statement_provider_id:
            userExistsData?.data?.bank_statement_provider_id,
        }),
      );

      dispatch(
        updateWidgetSettingsAction({
          showWidgetAnimation: false,
        }),
      );
    }

    localStorage.setItem(
      "userData",
      JSON.stringify({
        customer_phone: userExistsData?.data?.borrower_phone,
        email: userExistsData?.data?.email,
        customer_type:
          userExistsData?.data?.customer_type === "1"
            ? "Individual"
            : "Corporate",
        first_name: userExistsData?.data?.first_name,
        last_name: userExistsData?.data?.last_name,
        customer_id: userExistsData?.data?.customer_id,
      }),
    );
  };

  return (
    <Fragment>
      <div id={deviceType} className={styles.widgetMainWrapper}>
        <div className={styles.widgetWrapper}>
          {!isLoading &&
            showError &&
            !widgetState?.bankStatementWidgetOpened && (
              <WidgetInitialError unexpectedError />
            )}
          {isLoading ? (
            <WidgetLoader showWidgetAnimation />
          ) : !isLoading &&
            !widgetState?.bankStatementWidgetOpened &&
            (checkFirstTimeBorrowerError ||
              ValidateAggregatorKeyError ||
              checkBankStatementAuthStatusError ||
              invalidAggregatorIdError) ? (
            <WidgetInitialError
              checkFirstTimeBorrowerError={errorHandler(
                checkFirstTimeBorrowerError,
              )}
              ValidateAggregatorKeyError={errorHandler(
                ValidateAggregatorKeyError,
              )}
              checkBankStatementAuthStatusError={errorHandler(
                checkBankStatementAuthStatusError,
              )}
              invalidAggregatorIdError={invalidAggregatorIdError}
            />
          ) : null}
          {widgetState?.encryptedKey &&
            borrowerDetails?.first_name &&
            !isLoading &&
            !showError &&
            !checkFirstTimeBorrowerError &&
            !ValidateAggregatorKeyError &&
            !checkBankStatementAuthStatusError &&
            !invalidAggregatorIdError && (
              <div>
                <div className={styles.widget}>
                  <div id="widget-page-top"></div>

                  <div className="w-100">
                    <div
                      className={styles.cancelIcon}
                      onClick={() => {
                        sendResponseToClient({
                          status: WIDGET_RESPONSE_STATUSES.cancel,
                          data: { message: "User cancelled" },
                        });
                      }}
                    >
                      <RiCloseCircleFill />
                    </div>
                    <WidgetRoutes />
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </Fragment>
  );
};
export default withRouter(WidgetFlow);
