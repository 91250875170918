import React from "react";
import { RiFileCopyFill } from "react-icons/all";
import copy from "copy-text-to-clipboard";
import styles from "./DirectDebitWeb.module.scss";
import useDirectDebitExistingAccountsWeb from "../../../../custom-hooks/useDirectDebitExistingAccountsWeb";
import Loader from "../../../../components/Loader/Loader";
const ExistingDirectDebitAccountsWeb = () => {
  const data = localStorage.getItem("currentUser");
  const userinfo = data && JSON.parse(data);
  const handleCopy = () => {
    const content = document?.getElementById("accountNumber")?.textContent;
    if (content) {
      copy(content);
    }
  };
  const { data: existingDirectDebitAccountData, error: directdebitError, isLoading: isLoadingDirectDebit } =
    useDirectDebitExistingAccountsWeb({ customerId: userinfo.customerId });
  return (
    <div>
      <div>
        {directdebitError && (
          <small className="text-danger">{directdebitError}</small>
        )}
        {isLoadingDirectDebit ? <Loader /> : existingDirectDebitAccountData?.bank_accounts.length > 0 ? (
          existingDirectDebitAccountData?.bank_accounts?.map(
            ({
              account_name,
              account_number,
              bank_name,
            }: {
              account_name: string;
              account_number: string;
              bank_name: any;
            }) => (
              <div
                className={`d-flex justify-content-between align-items-center mt-2 cursor-pointer ${styles.container_accounts}`}
              >
                <div className="d-flex align-items-center">
                  <div>
                    <p className={`${styles.account_number}`}>
                      {account_number}
                    </p>
                    <small className={`${styles.account_name}`}>
                      {" "}
                      {account_name}
                    </small>
                  </div>
                  <div
                    style={{ marginTop: "-6px" }}
                    className="cursor-pointer pl-3"
                    onClick={handleCopy}
                  >
                    <RiFileCopyFill className="text-primary-dark" />
                  </div>
                </div>
                <p className={`${styles.bank_name}`}>
                  {bank_name ? bank_name : " bank name unavailable"}
                </p>
              </div>
            ),
          )
        ) : (
          <div>
            <p>No Data Available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExistingDirectDebitAccountsWeb;
