import React, { Dispatch, SetStateAction } from "react";

import styles from "./WelcomeScreen.module.scss";
import successSVG from "../../../../assets/web/success.svg";
import {Button} from "antd";

interface WelcomeScreenProps {
    setCurrentStep: Dispatch<SetStateAction<number>>;
}

function WelcomeScreen(
    {
        setCurrentStep
    }: WelcomeScreenProps) {

    function skipToFinalSignUpPageHandler() {
        setCurrentStep(7);
    }

    function nextStepHandler() {
        setCurrentStep(prevState => prevState + 1);
    }

    return (
        <div className={styles.welcome_screen_outer_container}>
            <div className={styles.welcome_screen_container}>
                <img src={successSVG} alt=''/>
                <h3>Welcome to Advancly!</h3>
                <p>To get the full value of your new account,
                    we suggest you take some time to do some additional verification.
                </p>

                <div className={styles.buttons}>
                    <Button
                        type="primary"
                        onClick={nextStepHandler}
                    >
                        Proceed
                    </Button>

                    <Button
                        type="primary"
                        ghost
                        onClick={skipToFinalSignUpPageHandler}
                    >
                        I’ll do this later
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default WelcomeScreen;