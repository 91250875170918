import React, { useEffect } from "react";
import styles from "./LoanRepayments.module.scss";
import closeIcon from "../../../../assets/web/close-square.svg";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import moment from "moment";
import { Button } from "antd";
import credit from "../../../../assets/web/withdrawIcon.svg";
import { RepaymentsProps } from "../../../../interfaces/repayments";
import Loader from "../../../../components/Loader/Loader";
import useFetchLoanRepayments from "../../../../custom-hooks/useFetchLoanRepayments";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import {
  updateLoanDetailsAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

enum Status {
  Active = "active",
  Repaid = "repaid",
  Approved = "approved",
  Rejected = "rejected",
  Overdue = "overdue",
  AwaitingApproval = "awaiting approval",
  SubmittedAndAwaitingApproval = "submitted_and_awaiting_approval",
  WithdrawnByClient = "withdrawnByClient",
  Closed = "closed",
  WrittenOff = "writtenOff",
  Overpaid = "overpaid",
}

interface LoanRepaymentsProps {
  setCurrentLoanOptionStep: React.Dispatch<React.SetStateAction<number>>;
}

function LoanRepayments({ setCurrentLoanOptionStep }: LoanRepaymentsProps) {
  const dispatch = useDispatch();
  const loanDataStore = useSelector(
    (state: ReduxStoreModel) => state.loanDetailsReducer
  );

  const { data: repayments, isLoading: isLoadingRepayments } =
    useFetchLoanRepayments({
      loan_ref: loanDataStore?.loan_ref,
    });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (LoanRepayments.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleRepayment = () => {
    dispatch(updateWebSettingsAction({ showLoanDetails: false }));
    dispatch(updateWebSettingsAction({ showLoanRepayments: false }));
    dispatch(updateWebSettingsAction({ showLoanRepaymentModal: true }));
  };

  const goback = () => {
    dispatch(updateWebSettingsAction({ showLoanRepayments: false }));
    dispatch(updateWebSettingsAction({ showLoanDetails: true }));
  };

  const handleClose = () => {
    document.body.style.overflow = "auto";

    dispatch(updateLoanDetailsAction({ loan_ref: "" }));

    dispatch(updateWebSettingsAction({ showLoanRepayments: false }));

    setCurrentLoanOptionStep(0);
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_container}>
        <img
          src={arrowLeft}
          alt=""
          className={styles.back_button}
          onClick={goback}
        />

        <div className={styles.container}>
          <header className={styles.container_header}>
            <h3>Repayments</h3>
          </header>

          {isLoadingRepayments ? (
            <Loader />
          ) : repayments?.length > 0 ? (
            <>
              {repayments.map((amount: RepaymentsProps) => (
                <div className={styles.transaction} key={amount.repayment_id}>
                  <div className={styles.icon_amount_wrap}>
                    <span className={styles.withdrawalIcon_wrap}>
                      <img src={credit} alt="" className={styles.icon} />
                    </span>
                    <div className={styles.amount_date}>
                      <span className={styles.withdrawal_amount}>
                        ₦{amount?.amount_paid.toLocaleString()}
                      </span>
                      <span className={styles.transaction_date}>
                        {moment(amount?.repayment_date).format("DD MMM, YYYY")}
                      </span>
                    </div>
                  </div>

                  <p
                    className={
                      amount.repayment_status === "success"
                        ? styles.success_status
                        : styles.other_status
                    }
                  >
                    {amount.repayment_status}
                  </p>
                </div>
              ))}

              <div className={styles.submit_button}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    loanDataStore?.status?.toLowerCase() ===
                      Status.SubmittedAndAwaitingApproval ||
                    loanDataStore?.status?.toLowerCase() ===
                      Status.WithdrawnByClient ||
                    loanDataStore?.status?.toLowerCase() === Status.Rejected ||
                    loanDataStore?.status?.toLowerCase() === Status.Closed ||
                    loanDataStore?.status?.toLowerCase() ===
                      Status.WrittenOff ||
                    loanDataStore?.status?.toLowerCase() === Status.Overpaid
                  }
                  onClick={handleRepayment}
                >
                  Repay Loan
                </Button>
              </div>
            </>
          ) : (
            <p>No data available</p>
          )}
          <img
            src={closeIcon}
            alt=""
            className={styles.cancel_button}
            onClick={handleClose}
          />
        </div>
      </div>
    </>
  );
}

export default LoanRepayments;
