export default function cleanPhoneSavedPhoneNo(user: any) {
    let phoneNo;
    if (user && user?.country === "NG") {
      phoneNo = user?.phoneNumber.replace(/^234/, "0");
    } else if (user && user?.country === "KE") {
      phoneNo = user?.phoneNumber.replace(/^254/, "0");
    } else if (user && user?.country === "GH") {
      phoneNo = user?.phoneNumber.replace(/^233/, "0");
    } else if (user && user?.country === "RW") {
      phoneNo = user?.phoneNumber.replace(/^250/, "0");
    } else if (user && user?.country === "UG") {
      phoneNo = user?.phoneNumber.replace(/^256/, "0");
    } else if (user && user?.country === "US") {
      phoneNo = user?.phoneNumber.replace(/^1/, "0");
    }

    return phoneNo;
  }