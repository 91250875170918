import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import styles from "./LoanRequest.module.scss";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import useFetchTermsAndConditions from "../../../../custom-hooks/useFetchTermsAndConditions";
import { postData, putData } from "../../../../apis/apiMethods";
import Okra from "okra-js";
import MonoConnect from "@mono.co/connect.js";
import Loader from "../../../../components/Loader/Loader";
import CONFIG from "../../../../helpers/config";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { useQueryCache } from "react-query";
import { decrypt } from "../../../../helpers/encryptor";
import { Alert, Button } from "antd";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { useDispatch, useSelector } from "react-redux";
import { ISuccess } from "../../../../interfaces/success";
import {
  updateMonoWidgetDataAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import DOMPurify from "dompurify";
import { MixPanel } from "../../../../helpers/mixpanel";

interface LoanRequestStepFourProps {
  onPrevStep: () => void;
  handleCancel: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  userExistsData: any;
  isLoadingUserExist: any;
  bankStatementLinkedStatus: any;
  isLoadingBankStatementLinkedStatus: boolean;
  setError: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<ISuccess>>;
  aggregator_id: number;
  customer_id: number;
  bank_statement_provider_id: number;
  error: string;
  success: ISuccess;
  // handleBankStatementOrCreateLoan: () => void;
}

function createMarkup(html: any) {
  return {
    __html: DOMPurify.sanitize(html),
  };
}

function LoanRequestStepFour({
  onPrevStep,
  handleCancel,
  setCurrentStep,
  userExistsData,
  isLoadingUserExist,
  bankStatementLinkedStatus,
  isLoadingBankStatementLinkedStatus,
  setError,
  setSuccess,
  customer_id,
  aggregator_id,
  bank_statement_provider_id,
  error,
  success,
}: LoanRequestStepFourProps) {
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const accountLinkSuccessRef = useRef<boolean | null>(null);

  const dispatch = useDispatch();
  const loanStateData = useSelector(
    (state: ReduxStoreModel) => state.loanDetailsReducer,
  );
  const monoWidgetData = useSelector(
    (state: ReduxStoreModel) => state.monoWidgetDataReducer,
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (LoanRequestStepFour.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: LoanRequestFinalStep.tsx`, {
      page: "LoanRequestFinalStep.tsx - (Web)",
    });
  }, []);

  const queryCache = useQueryCache();

  function handleLaunchOkraWidget() {
    if (!CONFIG.OKRA_PUBLIC_KEY || !CONFIG.OKRA_CLIENT_TOKEN) {
      return setError("Update Config for Okra");
    }

    Okra.buildWithOptions({
      name: "ADVANCLY",
      env: CONFIG.OKRA_ENV,
      app_id: "", // app_id from your app builder
      key: CONFIG.OKRA_PUBLIC_KEY,
      token: CONFIG.OKRA_CLIENT_TOKEN,
      products: ["auth", "transactions"], //others are ['auth','identity','balance','transactions', 'income'](in lowercase)
      onSuccess: function (data: any) {
        console.log("options success", data);
        handleSaveCustomerCode(data?.customer_id, "okra");
      },
      onClose: function () {
        console.log("options close");
      },
    });
  }

  const monoConnect = React.useMemo(() => {
    if (!CONFIG.MONO_PUBLIC_KEY) {
      return setError("Update Config for Mono");
    }
    const customer = {
      name: `${userInfo?.first_name} ${userInfo?.last_name}`,
      email: userInfo?.email,
    };
    const monoInstance = new MonoConnect({
      onClose: () => {
        if (!accountLinkSuccessRef.current) {
          setCurrentStep(9);
          console.log("closed");
        } else {
          console.log("Widget closed successfully");
        }
        //Below appinsights is tracking the widget data
        appInsights.trackEvent({
          name: "MonoWidgetData",
          properties: {
            opened: monoWidgetData?.opened,
            opened_dateTimeStamp: monoWidgetData?.opened_dateTimeStamp,
            customer_email: monoWidgetData?.customer_email,
            is_institution_selected: monoWidgetData?.is_institution_selected,
            selected_institution_name:
              monoWidgetData?.selected_institution_name,
            institution_selected_dateTimeStamp:
              monoWidgetData?.institution_selected_dateTimeStamp,
            AccountLinkStatus: accountLinkSuccessRef.current,
          },
        });
      },
      onLoad: () => console.log("Loaded successfully"),
      key: CONFIG.MONO_PUBLIC_KEY,
      data: { customer },
      onSuccess: async (data: { code: string }) => {
        accountLinkSuccessRef.current = true;
        handleSaveCustomerCode(data?.code, "mono");
      },
      onEvent: async (eventName: string, data: any) => {
        if (eventName === "OPENED") {
          console.log("Widget is open!");
          dispatch(
            updateMonoWidgetDataAction({
              opened: true,
              opened_dateTimeStamp: data?.timestamp,
              customer_email: userInfo?.email,
            }),
          );
        } else if (eventName === "INSTITUTION_SELECTED") {
          dispatch(
            updateMonoWidgetDataAction({
              is_institution_selected: true,
              selected_institution_name: data.institution.name,
              institution_selected_dateTimeStamp: data?.timestamp,
            }),
          );
        }
      },
    });

    monoInstance.setup();

    return monoInstance;
    //eslint-disable-next-line
  }, []);

  const handleSaveCustomerCode = async (
    code: string,
    provider: "okra" | "mono",
  ) => {
    //Call d api to store the code
    console.log({ customerId: code });
    const reqBody = {
      bank_statement_provider_id: provider === "okra" ? 1 : 2,
      bank_statement_provider_customer_code: provider === "okra" ? code : "",
      authentication_code: provider === "mono" ? code : "",
      customer_id: userInfo?.customerId,
      aggregator_id: userInfo?.aggregatorId,
    };
    try {
      dispatch(updateWebSettingsAction({ isSavingProvider: true }));
      await putData(apiEndpoints.saveBankstatementProviderWeb, reqBody);
      setSuccess({
        ...success,
        bodyText: "Bank provider details saved successfully",
      });
      queryCache.invalidateQueries([
        { aggregator_id, customer_id, bank_statement_provider_id },
        "fetchBankStatementProviderStatus",
      ]);
      handleCreateLoan().finally();
    } catch (error) {
      setError("Something went wrong please try again");
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (LoanRequestStepFour.tsx)",
        },
      });
    } finally {
      dispatch(updateWebSettingsAction({ isSavingProvider: false }));
    }
  };

  const handleBankStatementOrCreateLoan = () => {
    if (!isLoadingUserExist && !isLoadingBankStatementLinkedStatus) {
      if (!bankStatementLinkedStatus?.status && handleLaunchOkraWidget) {
        if (userExistsData?.bank_statement_provider?.toLowerCase() === "okra") {
          handleLaunchOkraWidget();
        } else {
          //@ts-ignore
          monoConnect.open();
        }
      } else {
        handleCreateLoan().finally();
      }
    }
  };

  async function handleCreateLoan() {
    setSuccess({ ...success, bodyText: "" });
    setCurrentStep(4);
    try {
      const res = await postData(apiEndpoints.customerProcessCustomerLoan, {
        loan_amount: loanStateData?.loan_amount,
        loan_name: loanStateData?.loan_name,
        loan_tenure: loanStateData?.loan_tenure,
        product_id: loanStateData?.product_id,
        repayment_start_date: loanStateData?.repayment_start_date,
        bvn_checker: userInfo?.isBvnVerified,
        annual_interest_rate: loanStateData?.annual_interest_rate,
        aggregator_loan_ref: Date.now().toString(),
        bvn_number: decrypt(userInfo?.bvn),
        business_registration_number:
          loanStateData?.business_registration_number,
        phone_number: userInfo?.phoneNumber,
        country_code: userInfo?.country,
        customer_id: userInfo?.customerId,
        aggregator_id: userInfo?.aggregatorId,
        customer_bank_account: loanStateData?.customer_bank_account,
        is_bankstatement_linkage_failed: false,
        loan_tenor_input_type: loanStateData?.loan_tenor_input_type,
        total_cost_amount: loanStateData?.total_cost_amount
          ? loanStateData?.total_cost_amount
          : null,
        equity_contribution_amount: loanStateData?.equity_contribution_amount
          ? loanStateData?.equity_contribution_amount
          : null,
        description: loanStateData?.description
          ? loanStateData?.description
          : null,
        bank_statement_provider_response: monoWidgetData?.opened
          ? JSON.stringify({
              opened: monoWidgetData?.opened,
              customer_email: monoWidgetData?.customer_email,
              opened_dateTimeStamp: monoWidgetData?.opened_dateTimeStamp,
              is_institution_selected: monoWidgetData?.is_institution_selected,
              selected_institution_name:
                monoWidgetData?.selected_institution_name,
              institution_selected_dateTimeStamp:
                monoWidgetData?.institution_selected_dateTimeStamp,
            })
          : "linked",
      });
      setSuccess({
        headerText: "Request Submitted Successfully",
        bodyText: res.message,
      });
      MixPanel.track("Get Loan", {
        distinct_id: `${userInfo?.email}`,
        "loan amount": `${loanStateData?.loan_amount}`,
        "loan name": `${loanStateData?.loan_name}`,
        "loan tenor": `${loanStateData?.loan_tenure}`,
        "product name": `${loanStateData?.product_name}`,
        "loan status": `Application success`,
        "bank statement linked status": `linked`,
      });
      setCurrentStep(5);
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (LoanRequestStepFour.tsx)",
        },
      });

      MixPanel.track("Get Loan", {
        distinct_id: `${userInfo?.email}`,
        "loan amount": `${loanStateData?.loan_amount}`,
        "loan name": `${loanStateData?.loan_name}`,
        "loan tenor": `${loanStateData?.loan_tenure}`,
        "product name": `${loanStateData?.product_name}`,
        "loan status": `Application failed: ${error.response?.data?.message ?? error.message}`,
      });
      setCurrentStep(6);
    }
  }

  const { data: termsAndConditions, isLoading: isLoadingTermsAndConditions } =
    useFetchTermsAndConditions({
      aggregatorId: String(userInfo?.aggregatorId),
    });

  return (
    <div className={styles.stepFour_container}>
      {error && (
        <div style={{ width: "100%", paddingTop: "50px" }}>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}
      {success.bodyText && (
        <div style={{ width: "100%", paddingTop: "50px" }}>
          <Alert
            message={success.bodyText}
            type="success"
            showIcon
            closable
            onClose={() =>
              setSuccess({
                ...success,
                bodyText: "",
              })
            }
          />
        </div>
      )}
      <img
        src={arrowLeft}
        alt=""
        className={styles.arrow}
        onClick={onPrevStep}
      />
      <header className={styles.stepFour_header}>
        <h3>Terms and agreement</h3>
      </header>

      {isLoadingTermsAndConditions ? (
        <Loader message="Loading terms and condition..." />
      ) : (
        <div className={styles.loanAggreement_container}>
          <div
            className={styles.unorderedList}
            dangerouslySetInnerHTML={createMarkup(
              termsAndConditions.termsAndConditions,
            )}
          ></div>
        </div>
      )}

      <div className={styles.buttons}>
        <Button type="primary" onClick={handleBankStatementOrCreateLoan}>
          I understand
        </Button>

        <Button type="primary" ghost onClick={handleCancel}>
          Cancel my request
        </Button>
      </div>
    </div>
  );
}

export default LoanRequestStepFour;
