import React, { useEffect, Dispatch, SetStateAction, RefObject } from "react";
import styles from "./MobileTransactionView.module.scss";
import { IoMdArrowRoundUp } from "react-icons/io";
import debit from "../../../../assets/web/depositIcon.svg";
import emptyBox from "../../../../assets/web/dashboard/noItem.png";
import { LoanHistory } from "../../../../interfaces/loan";
import moment from "moment";
import { Button } from "antd";
import Loader from "../../../../components/Loader/Loader";
import { useDispatch } from "react-redux";
import {
  updateLoanDetailsAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

interface MobileTransactionViewProps {
  transactionHistoryRef: RefObject<HTMLDivElement>;
  scrollToTop: (elementRef: RefObject<HTMLDivElement>) => void;
  overviewRef: RefObject<HTMLDivElement>;
  loanHistory: LoanHistory;
  isRefetchingLoanDetails: () => void;
  setPageNumber: Dispatch<SetStateAction<number>>;
  pageNumber: number;
  isLoanHistoryLoading: boolean;
}

function MobileTransactionView({
  transactionHistoryRef,
  scrollToTop,
  overviewRef,
  loanHistory,
  isRefetchingLoanDetails,
  setPageNumber,
  pageNumber,
  isLoanHistoryLoading,
}: MobileTransactionViewProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (MobileTransactionView.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const selectLoan = (id: number) => {
    document.body.style.overflow = "hidden";

    loanHistory?.data?.find((loan) =>
      loan?.id === id
        ? dispatch(updateLoanDetailsAction({ loan_ref: loan.loanRef }))
        : ""
    );

    setTimeout(() => {
      isRefetchingLoanDetails();
      dispatch(updateWebSettingsAction({ showLoanDetails: true }));
    }, 0);
  };

  return (
    <>
      <div className={styles.container} ref={transactionHistoryRef}>
        <header className={styles.transaction_header}>
          <button
            className={styles.button}
            onClick={() => scrollToTop(overviewRef)}
          >
            <IoMdArrowRoundUp className={styles.arrow} />
            <span>Go up</span>
          </button>
          <h2 className={styles.title}>Transactions</h2>
        </header>

        {isLoanHistoryLoading ? (
          <div className={styles.isLoading_container}>
            <Loader message="Loading loan data..." />
          </div>
        ) : loanHistory?.data?.length > 0 ? (
          <div className={styles.transactions}>
            {loanHistory?.data?.slice(0, 10).map((loan) => (
              <div
                className={styles.transaction}
                key={loan.id}
                onClick={() => selectLoan(loan.id)}
              >
                <div className={styles.icon_title_wrap}>
                  <span className={styles.depositIcon_wrap}>
                    <img src={debit} alt="" className={styles.icon} />
                  </span>
                  <div className={styles.deposit_date}>
                    <p className={styles.transaction_type}>
                      ₦{loan?.loanAmount?.toLocaleString()}
                    </p>
                    <p className={styles.transaction_date}>
                      {moment(loan?.loanMaturityDate).format("D/MM/YYYY")}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    className={
                      loan?.status === "Submitted_and_awaiting_approval"
                        ? styles.flagPending
                        : loan?.status === "Active" ||
                          loan?.status === "Approved"
                        ? styles.flagActive
                        : loan?.status === "Rejected"
                        ? styles.flagRejected
                        : styles.flagStale
                    }
                  >
                    {loan?.status === "Submitted_and_awaiting_approval"
                      ? "Awaiting approval"
                      : loan.status}
                  </span>
                  <span>{loan.loanRef}</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.noTransaction_container}>
            <img src={emptyBox} alt="" />
            <h3>No transactions yet</h3>
            <p>Your transactions will appear here when you initiate one</p>
          </div>
        )}

        <div className={styles.pagination_controls}>
          <Button
            onClick={() => setPageNumber((prev) => prev - 1)}
            disabled={pageNumber === 1}
          >
            Prev
          </Button>
          <button>{pageNumber}</button>
          <Button
            disabled={pageNumber >= loanHistory?.totalPages}
            onClick={() => setPageNumber((prev) => prev + 1)}
          >
            Next
          </Button>
        </div>
      </div>
      {/* {showDateRangeSelector && <DateRangeSelector setShowDateRangeSelector={setShowDateRangeSelector}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      handleConfirmSelection={handleConfirmSelection} 
      /> */}
    </>
  );
}

export default MobileTransactionView;
