import React from 'react';
import { Link } from "react-router-dom";

const Terms = () => {

        return (
            <div className="container">
                <div className="row mt-3 mt-sm-5">
                    <div className="col-md-12 space-top-lg-1">
                        <div className="ml-lg-auto text-right">
                            <Link className="btn btn-sm btn-soft-primary transition-3d-hover" to="/">
                                <span className="fas fa-arrow-left small mr-1"></span>
                                    Return to Dashboard
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row mb-10">
                    <h1 className="h1 text-dark font-weight-medium">Advancly Terms of Use</h1>

                    <div class="mt-3">
                        <p className="h3 text-dark">About Advancly</p>
                        <p>Advancly is a matched based lending platform. It matches funders to beneficiaries through Aggregators. It is designed to allow borrowers access higher loan amounts with a flexible repayment schedule. We take your privacy seriously. Please see our Privacy Policy for more details.</p>
                    </div>

                    <div class="mt-3">
                        <p className="h3 text-dark">Terms</p>
                        <p>
                            <ol type="A">
                                <li className="list-padding">(Terms of Use) Personal Data that we collect about you.
                                    <div className="mb-2">Advancly is a product of Venture Garden Nigeria Limited.
                                    Please read these Terms of Use and our Privacy Policy carefully before using this website. These Terms of Use govern your access and use of this website. By accessing or using the Advancly Platform, you agree to be bound by these Terms of Use and our Privacy Policy. Venture Garden Nigeria (referred to as “VGN”, "Company," "us," "our," or "we") provides the https://www.advancly.com portal (“Site” or “Platform”) and reserves the right to make changes to the Platform and to these Terms of Use at any time without prior notice. You should review these Terms of Use each time you access this Platform.
                                    </div>
                                    
                                    <div>
                                        <ol>
                                            <li className="list-padding"><b>About Us</b>
                                                <div className="mb-3">
                                                    <span>Venture Garden Nigeria Limited is a technology solutions company which provides a range of products and services including, Advancly.</span>                                                 
                                                </div>
                                            </li>

                                            <li className="list-padding"><b>The Agreement</b>
                                                <div className="mb-3">  
                                                    <span>These Terms of Use is an agreement between you and us. It details our obligations to you. It also governs your use of the Platform and the services available on the Platform. It highlights certain risks on using our services and you must consider such risks carefully as you will be bound by the provision of this Terms of Use through your use of this website, mobile applications or any of our services.</span>
                                                </div>
                                            </li>

                                            <li className="list-padding"><b>Registration</b>
                                                <div className="mb-3">
                                                    <span>To access certain features on the Platform, you may be required to provide personal and/or demographic information as part of the registration process. You agree to provide true, accurate, current and complete information about yourself as prompted by the applicable registration and you are responsible for keeping such information up-to date (this includes your contact information, so that we can reliably contact you). Also, you are to protect the confidentiality of your account password as we are not liable for any loss or damage arising from your failure to protect your password or account information.</span>
                                                </div>
                                            </li>

                                            <li className="list-padding"><b>User Representation</b>
                                                <div className="mb-3">
                                                    <span>You represent, warrant and agree that no materials of any kind submitted through your account or otherwise posted or shared by you through the Platform will violate or infringe upon the rights of any third party, including copyright, trademark, privacy, publicity or other personal or proprietary rights; or contain libellous, defamatory, fraudulent or otherwise unlawful material. You further agree not to collect email addresses or other contact information of Users from the Platform or the Site by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications. Additionally, you agree not to use automated scripts to collect information from the Platform or the Site or for any other purpose. You further agree that you may not use the Platform or the Site in any unlawful manner or in any other manner that could damage, disable, overburden or impair the Platform. In addition, you agree not to use the Platform or the Site to:</span>
                                                </div>

                                                <div className="mb-3">
                                                    <ol>
                                                        <li className="list-padding">upload, post, transmit, share, store or otherwise make available any content that we deem to be harmful, threatening, unlawful, defamatory, infringing, abusive, inflammatory, harassing, vulgar, obscene, fraudulent, invasive of privacy or publicity rights, hateful, or racially, ethnically or otherwise objectionable;</li>
                                                        <li className="list-padding">register for more than one User account, or register for a User account on behalf of any group or entity.</li>
                                                        <li className="list-padding">impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your age or your affiliation with or authority to act on behalf of any person or entity;</li>
                                                        <li className="list-padding">upload, post, transmit, share or otherwise make available any unsolicited or unauthorized advertising, solicitations, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation;</li>
                                                        <li className="list-padding">upload, post, transmit, share, store or otherwise make publicly available on the Platform any private information of any third party, including, without limitation, addresses, phone numbers, email addresses and credit card numbers;</li>
                                                        <li className="list-padding">upload, post, transmit, share or otherwise make available any material that contains software viruses or any other malicious or tracking computer code, files or programs designed to track, interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment or any information that infringes the intellectual property rights of others;</li>
                                                        <li className="list-padding">intimidate or harass another User;</li>
                                                        <li className="list-padding">upload, post, transmit, share, store or otherwise make available content that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party, or that would otherwise create liability or violate any state, national or international law;</li>
                                                        <li className="list-padding">use or attempt to use another's account, service or system without authorization from that person and the Company, or create a false identity on the Platform.</li>
                                                    </ol>
                                                </div>
                                            </li>


                                            <li className="list-padding"><b>Age Restriction</b>
                                                <div className="mb-3">
                                                    <span>In order to register for our services on the Platform, you represent and warrant that: You are at least 18 years of age and are legally able to enter into a binding contract.</span>
                                                </div>
                                            </li>
                                            
                                            <li className="list-padding"><b>Identity Authentication</b>
                                                <div className="mb-3">
                                                    <span>You authorize us, directly or through third parties, to make any inquiries we consider necessary to validate your identity. This may include asking you for further information, requiring you to provide a biometric verification number (BVN), requiring you to take steps to confirm ownership of your email address or financial instruments, ordering a credit report, or verifying your Information against third party databases or through other sources.</span>
                                                </div>
                                            </li>

                                            <li className="list-padding"><b>Assignment</b>
                                                <div className="mb-3">
                                                    <span>You may not transfer or assign any rights or obligations you have under this Terms of Use without our prior written consent in its sole and absolute discretion. We reserve the right to transfer or assign this Terms of Use or any right or obligation under this Terms of Use at any time.</span>
                                                </div>
                                            </li>

                                            <li className="list-padding"><b>General Disclaimer</b>
                                                <div className="mb-3">
                                                    <span>The services provided under this Platform are provided to you "as is," without any representation or warranty, express or implied, of any kind, including, but not limited to, warranties of merchantability, non-infringement, or fitness for any particular purpose. We do not warrant the accuracy or completeness of the service, the suitability of the content or the reliability of any advice, opinion, statement or other information displayed or distributed through the Platform. You acknowledge that any reliance on any such opinion, advice, statement, memorandum, or information shall be at your sole risk. We do not warrant that the functions contained in the materials will be uninterrupted or error-free, that defects will be corrected, or that the Portal is free of viruses or other harmful components.</span>
                                                </div>
                                            </li>

                                            <li className="list-padding"><b>Communications and Notices to you</b>
                                                <div className="mb-3">
                                                    <span>By accepting these Terms of Use, you expressly consent to be contacted by us, our agents, representatives, affiliates, or anyone calling on our behalf for any and all purposes, at any telephone number, or physical or electronic address you provide or at which you may be reached. You agree we may contact you in any way, including SMS messages (including text messages), calls using pre-recorded messages or artificial voice, and calls and messages delivered using auto telephone dialling system or an automatic texting system. Automated messages may be played when the telephone is answered, whether by you or someone else. In the event that an agent or representative calls, he or she may also leave a message on your answering machine, voice mail, or send one via text. You also agree that we may contact people in your network should we try to reach you to no avail.</span>
                                                </div>
                                            </li>

                                            <li className="list-padding"><b>Consent to Electronic Transactions and Disclosures</b>
                                                <div className="mb-3">
                                                    <span>Because the Platform operates via the Internet, whether you choose to participate on our Platform as a Borrower, Funder, Aggregator or in any other manner, it is necessary for you to consent to transact business with us online and electronically. As part of doing business with us, we also need you to consent to our giving you certain disclosures electronically, either via our Platform or to the email address you provide to us. By agreeing to the Terms of Use, you agree to receive electronically all documents, communications, notices, contracts, and agreements arising from or relating to your use of the Platform and Service, including any loans you may request or receive, your registration as a Borrower, Aggregator or Funder on our Platform, any loans you may give, your use of this service, and the servicing of your loan, if funded, as either a borrower or funder member of the Platform.</span>
                                                </div>
                                            </li>

                                            <li className="list-padding"><b>Taxes</b>
                                                <div className="mb-3">
                                                    <span>The applicable tax laws in Nigeria as related to our BORROWERS, AGGREGATORS and FUNDERS are Value Added Tax and Withholding Tax;
                                                        <br></br>Interests earned by Lenders are liable to 10% WHT on the INTEREST EARNED alone.
                                                        <br></br>BORROWERS are charged 7.5% VAT on service charges as applicable. We are obliged by the applicable laws to collect these taxes on behalf of the government and remit as at when due, failure of which attracts severe penalties.. We are not to be held responsible for advising, estimating, calculating and/or remitting any other appropriate taxes that apply to you in Nigeria and abroad outside of our transactions here.
                                                        <br></br>You agree to indemnify us to the full extent of applicable law in the event that any tax authority holds us responsible for any taxes directly or indirectly arising from your use of the Platform.
                                                    </span>
                                                </div>
                                            </li>

                                            <li className="list-padding"><b>Suspension or Termination of Access</b>
                                                <div className="mb-3">
                                                    <span>We have the right to deny your access to, and suspend or terminate your access to, the Platform for any reason, including for any violation by you of these Terms of Use.</span>
                                                </div>
                                            </li>

                                            <li className="list-padding"><b>Ownership of Site Contents</b>
                                                <div className="mb-3">
                                                    <span>We own the intellectual property rights in the content and materials displayed on the Platform. You may not modify, copy, reproduce, republish, upload, post, transmit, translate, sell, create derivative works, exploit, or distribute in any manner or medium (including by email or other electronic means) any material from the Platform unless explicitly authorized in these Terms of Use.</span>
                                                </div>
                                            </li>

                                            <li className="list-padding"><b>Copyright and Trademarks</b>
                                                <div className="mb-3">
                                                    <span>The Company name, the term “Advancly”, the Advancly logo, and all related names, logos, product and service names, designs and slogans are copyright and trademarks of the Company. You must not use such marks without the prior written permission of the Company or as otherwise set out in these Terms of Use. All other names, logos, product and service names, designs and slogans on this platform are the trademarks of their respective owners.</span>
                                                </div>
                                            </li>

                                            <li className="list-padding"><b>Limitation of Damages</b>
                                                <div className="mb-3">
                                                    <span>To the extent permitted by applicable law, our liability to you for any cause whatsoever, regardless of the form of the action will not exceed ₦2,500.00. If the dispute is pertaining to a lending transaction, our liability to you shall not exceed the total amount of fees earned as commission by the Company from the lending transaction As permitted by applicable law, we exclude all conditions, warranties, representations or other terms which may apply to our Platform or any content on it, whether express or implied. We will not be liable to any user for any loss or damage, even if foreseeable, arising under or in connection with the use of, or inability to use our Platform.</span>
                                                </div>
                                            </li>

                                            <li className="list-padding"><b>Disclaimer</b>
                                                <div className="mb-3">
                                                    <span>We do not guarantee the accuracy of any User Content or Third-Party Content. Although we provide rules for User conduct and postings, we do not control and are not responsible for what Users post on the Platform and are not responsible for any offensive, inappropriate, obscene, unlawful or otherwise objectionable content you may encounter on the Platform or in connection with any User Content or ThirdParty Content. We are not responsible for the conduct, whether online or offline, of any User of the service via the Platform.
                                                        <div>We cannot guarantee and do not promise any specific results from use of our services via the Platform.</div>
                                                        <div>The Platform and the service may be temporarily unavailable from time to time for maintenance or other reasons. We assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, User communications. We are not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of email or players on account of technical problems or traffic congestion on the Internet or on the Platform or combination thereof, including injury or damage to Users or to any other person's computer related to or resulting from participating or downloading materials in connection with the web and/or in connection with the service provided via the Platform.</div>
                                                    </span>
                                                </div>
                                            </li>

                                            <li className="list-padding"><b>Dispute Resolution</b>
                                                <div className="mb-3">
                                                    <span>If a dispute or difference arises as to the validity, interpretation, effects or rights and obligations of the Parties under this Agreement, the Parties shall use their best endeavor to reach an amicable settlement of dispute.
                                                        <br></br>If any such dispute is not settled between the Parties within thirty (30) Business Days, the Parties agree that such dispute will be resolved by a Single Arbitrator appointed jointly by the parties in accordance with the provisions of the Arbitration and Conciliation Act Cap A18 LFN 2004 (ACA).
                                                        <br></br>Where the Parties fail to agree on a single Arbitrator, within a period of ten (10) days from the date a notice of Arbitration filed by either Party, the President of the Chartered Institute of Arbitrators UK (Nigeria Branch) shall nominate an Arbitrator on the application of either Party, the decision of the Arbitrator shall be  binding on the Parties, and shall include an order as to the costs of such arbitration, which each Party shall pay such costs.
                                                    </span>
                                                </div>
                                            </li>

                                            <li className="list-padding">
                                                <b>Applicable Law</b>
                                                <div className="mb-3">
                                                    <span>These Terms of Use shall be interpreted and governed by the laws in force in the Federal Republic of Nigeria.</span>
                                                </div>
                                            </li>

                                            <li className="list-padding">
                                                <b>Waiver and Severability</b>
                                                <div className="mb-3">
                                                    <span>Our failure to enforce a provision of these Terms is not a waiver of our right to do so later. If any provision of these Terms is found unenforceable, the remaining provisions of the Terms will remain in full effect and an enforceable term or terms will be substituted reflecting our intent as closely as possible.</span>
                                                </div>
                                            </li>

                                            <li className="list-padding">
                                                <b>Contact Us</b>
                                                <div className="mb-3">
                                                    <span>Any question regarding the use of the Platform, or comments about the services provided via the Platform or its contents should be directed to &#123;support@advancly.com&#125;.</span>
                                                </div></li>

                                            <li className="list-padding">
                                                <b>Modifications</b>
                                                <div className="mb-3">
                                                    <span>We may modify and update the Terms of Use at any time. Your continued use of the Platform after any modifications to these Terms of Use shall mean you accept those modifications. Any aspect of the Platform or the services provided by the Platform may be amended, changed, modified, supplemented, deleted or updated without notice at our sole discretion.</span>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>

                                </li>
                            </ol>
                        </p>
                    </div>
                </div>
            </div>
        )

    }
export default Terms;