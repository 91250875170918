import { Alert, Form, Select } from "antd";
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import plus from "../../../../../../assets/plus.svg";
import Loader from "../../../../../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { updateLoanDetailsAction } from "../../../../../../redux/actions";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import { MixPanel } from "../../../../../../helpers/mixpanel";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";

interface ILoanRequestdirectDebit {
  handleNext: () => void;
  handleGoBack: () => void;
  existingDirectDebitAccountData: any;
  directDebitLoading: boolean;
  directdebitError: any;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  residentialInfoStatus: any;
  isLoadingResidentialInfoStatus: boolean;
}
const LoanRequestDirectDebit = ({
  handleNext,
  handleGoBack,
  existingDirectDebitAccountData,
  directDebitLoading,
  directdebitError,
  error,
  setError,
  setCurrentStep,
  residentialInfoStatus,
  isLoadingResidentialInfoStatus,
}: ILoanRequestdirectDebit) => {
  const [form] = Form.useForm();
  const [accountDetails, setAccountDetails] = useState("");
  const dispatch = useDispatch();
  const [bankDetails, setBankDetails] = useState({
    account_name: "",
    account_number: "",
    bank_name: "",
  });
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer,
  );
  const selectedLoanDetails = useSelector(
    (store: ReduxStoreModel) => store.loanDetailsReducer,
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRequestDirectDebitWidget.tsx",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: LoanRequestDirectDebitWidget.tsx`, {
      page: "LoanRequestDirectDebitWidget.tsx - (Widget)",
    });
  }, []);

  useEffect(() => {
    if (existingDirectDebitAccountData?.bank_accounts.length === 1) {
      const { account_name, account_number, bank_name } =
        existingDirectDebitAccountData?.bank_accounts[0];
      setBankDetails({
        ...bankDetails,
        account_name: account_name,
        account_number: account_number,
        bank_name: bank_name,
      });

      setAccountDetails(account_number);
    }
  }, [directDebitLoading, existingDirectDebitAccountData]);

  const handleselectedDebitAccount = (val: string) => {
    setAccountDetails(val);
  };

  const handleNextFn = () => {
    dispatch(
      updateLoanDetailsAction({
        customer_bank_account: accountDetails,
      }),
    );

    MixPanel.track("Get Loan", {
      distinct_id: `${borrowerDetails?.email}`,
      "loan amount": `${selectedLoanDetails.loan_amount}`,
      "loan tenor": `${selectedLoanDetails.loan_tenure}`,
      "product name": `${selectedLoanDetails.product_name}`,
      "loan status": `Direct Debit Account Selected`,
      step: "LoanRequestDirectDebit",
    });

    setError("");
    if (residentialInfoStatus) {
      setCurrentStep!(4);
    } else {
      handleNext();
    }
  };

  return (
    <>
      {error && (
        <div style={{ width: "100%", paddingTop: "50px" }}>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError!("")}
          />
        </div>
      )}
      <h4>Direct Debit Account</h4>
      <Fragment>
        <p>
          Select an account you want us to debit for <br /> your repayment
        </p>
        {directDebitLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loader />
          </div>
        ) : existingDirectDebitAccountData?.bank_accounts?.length > 1 ? (
          <Form layout="vertical" form={form}>
            <Form.Item
              className="mb-3 mb-md-0 mt-2"
              name="productId"
              label="Direct Debit Account"
              rules={[{ required: true, message: "Required field" }]}
            >
              <Select
                placeholder="Select Direct Debit Account"
                allowClear
                onChange={(value) => {
                  handleselectedDebitAccount(value);
                }}
              >
                {existingDirectDebitAccountData?.bank_accounts?.map(
                  ({
                    account_name,
                    account_number,
                    bank_name,
                  }: {
                    account_name: string;
                    account_number: string;
                    bank_name: any;
                  }) => (
                    <Select.Option value={account_number} key={account_number}>
                      {account_name}
                      {`  - ${bank_name}  ${account_number}`}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Form>
        ) : existingDirectDebitAccountData?.bank_accounts?.length === 1 ? (
          <div className="mb-4-embed">
            <label>Direct Debit Account</label>
            <input
              type="text"
              className="form-control-embed"
              name="directdebitAccount"
              value={`${bankDetails.account_name} - ${bankDetails.bank_name}  ${bankDetails.account_number}`}
              readOnly={
                existingDirectDebitAccountData?.bank_accounts?.length === 1
              }
            />
          </div>
        ) : null}
        {directdebitError && setError(`${directdebitError}`)}
        <p
          className="cursor-pointer"
          role="button"
          aria-roledescription="button"
          onClick={() => {
            setCurrentStep(7);
          }}
        >
          {" "}
          <img src={plus} className="" alt="" /> {""}
          Add a new account
        </p>
      </Fragment>
      <div className="d-flex justify-content-end" style={{ marginTop: "20%" }}>
        <div className="d-flex align-items-center">
          <p
            role="button"
            aria-roledescription="button"
            className="text-primary padding-none mr-3 cursor-pointer"
            onClick={handleGoBack}
          >
            Go Back
          </p>
          <button
            className="btn btn-primary btn-radius"
            onClick={handleNextFn}
            disabled={!accountDetails || isLoadingResidentialInfoStatus}
          >
            {isLoadingResidentialInfoStatus ? "Please wait..." : "Next"}
          </button>
        </div>
      </div>
    </>
  );
};

export default LoanRequestDirectDebit;
