import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Form, Input } from "antd";
import { ROUTES } from "../../../../../helpers/ROUTES";
import { appInsights } from "../../../../AppInsight/AppInsight";
import { postData } from "../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../../helpers/errorHandler";
import useFetchWalletDetails from "../../../../../custom-hooks/useFetchWallet";
import { ReduxStoreModel } from "../../../../../interfaces/redux-interface";
import { useSelector } from "react-redux";

const ProfileDetails = () => {
  const widgetSettings = useSelector(
    (state: ReduxStoreModel) => state.widgetSettingsReducer
  );
  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer
  );

  const {
    first_name,
    last_name,
    borrower_phone,
    email,
    customer_type,
    company_name,
    business_registration_number,
    bvn_number,
    returningUserDetails,
    customer_id,
    aggregator_id,
    country_code,
    bank_code,
    bank_account_number,
    bank_name,
  } = borrowerDetails;
  const { allow_bank_details } = widgetSettings;

  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [bankAccountName, setBankAccountName] = useState();

  const {
    data: fetchWalletDetailsdata,
    //@ts-ignore
    refetch: refetchFetchWalletdetails,
  } = useFetchWalletDetails({
    borrowerId: customer_id || returningUserDetails?.customer_id,
    aggregatorId: aggregator_id || returningUserDetails?.aggregator_id,
  });
  useEffect(() => {
    if (
      (customer_id || returningUserDetails?.customer_id) &&
      (aggregator_id || returningUserDetails?.aggregator_id)
    ) {
      refetchFetchWalletdetails();
    }

    //eslint-disable-next-line
  }, [customer_id, aggregator_id]);

  const validateAccount = async () => {
    setLoading(true);
    try {
      const response = await postData(apiEndpoints.validateBankAccount, {
        bank_code: bank_code || fetchWalletDetailsdata?.bankCode,
        bank_account_num:
          bank_account_number || fetchWalletDetailsdata?.accountNumber,
        country_code: country_code,
      });
      setBankAccountName(response.bank_account_name);
    } catch (error) {
      setError(errorHandler(error));
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "Widget-ProfileDetails.tsx",
      isLoggedIn: true,
    });
  }, []);
  useEffect(() => {
    if (
      bank_account_number &&
      bank_code &&
      country_code &&
      allow_bank_details
    ) {
      validateAccount();
    }
    //eslint-disable-next-line
  }, [bank_account_number, bank_code, country_code, allow_bank_details]);

  return (
    <div>
      {error ? <p className="alert alert-warning small">{error}</p> : null}

      <div
        onClick={() => history.push(ROUTES.widgetProfile)}
        className="cursor"
      >
        <IoMdArrowRoundBack size="26px" />
      </div>
      <br />
      <h4>Profile Information</h4>
      <Form layout="vertical" form={form}>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue={first_name}
          name="firstName"
          label="First Name"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue={last_name}
          name="lastName"
          label="Last Name"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue={email}
          name="email"
          label="Email"
          required
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue={borrower_phone}
          name="phone"
          label="Phone Number"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue={bvn_number}
          name="bvn_number"
          label="Identity Number"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue={customer_type === "1" ? "Individual" : "Corporate"}
          name="customer_type"
          label="Customer Type"
        >
          <Input readOnly />
        </Form.Item>
        {customer_type !== "1" && (
          <>
            <Form.Item
              className="mb-3 mb-md-0 mt-2"
              initialValue={company_name}
              name="company_name"
              label="Company Name"
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              className="mb-3 mb-md-0 mt-2"
              initialValue={business_registration_number}
              name="business_registration_number"
              label="Business Registration Number"
            >
              <Input readOnly />
            </Form.Item>
          </>
        )}

        {allow_bank_details && (
          <>
            {loading ? (
              <div className="d-flex justiify-content-center">
                <p>Loading...</p>
              </div>
            ) : (
              <>
                <Form.Item
                  className="mb-3 mb-md-0 mt-2"
                  initialValue={
                    bank_account_number || fetchWalletDetailsdata?.accountNumber
                  }
                  name="bank_account_number"
                  label=" Bank Account Number"
                  rules={[{ required: true, message: "Required field" }]}
                >
                  <Input readOnly />
                </Form.Item>
                <br />
                <Form.Item
                  className="mb-3 mb-md-0 mt-2"
                  initialValue={bank_name || fetchWalletDetailsdata?.bankName}
                  name="bank_name"
                  label="Bank Name"
                  rules={[{ required: true, message: "Required field" }]}
                >
                  <Input readOnly />
                </Form.Item>
                <br />
                <Form.Item
                  className="mb-3 mb-md-0 mt-2"
                  initialValue={bankAccountName}
                  name="bank_account_name"
                  label="Bank Account Name"
                  rules={[{ required: true, message: "Required field" }]}
                >
                  <Input readOnly={!!bank_code} />
                </Form.Item>
              </>
            )}
          </>
        )}
      </Form>
    </div>
  );
};

export default ProfileDetails;
