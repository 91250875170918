import React from "react";
import { Route } from "react-router-dom";
import { ROUTES } from "../../helpers/ROUTES";
import StepOneComponent from "./Step1/StepOne.component";
import DashboardComponent from "./WidgetRevampv2/Dashboard/Dashboard.component";
import ProfileComponent from "./WidgetRevampv2/Profile/Profile.component";
import ProfileDetails from "./WidgetRevampv2/Profile/components/ProfileDetails";
import TopUpComponent from "./WidgetRevampv2/Topup/TopUpComponent";
import CardInformation from "./WidgetRevampv2/Profile/components/CardInformation";
import TransactionPin from "./WidgetRevampv2/Profile/components/TransactionPin";
import TransactionPinOnboarding from "./WidgetRevampv2/Profile/components/TransactionPinOnboarding";
import TransactionsComponent from "./WidgetRevampv2/Transactions/Transactions.component";
import WithdrawalComponent from "./WidgetRevampv2/Withdrawal/Withdrawal.component";
import SetPinComponent from "./WidgetRevampv2/SetPinComponent";
import UpdateCardDetails from "./UpdateCardDetails/UpdateCardDetails.component";
import LoansComponent from "./WidgetRevampv2/Loans/Loans.component";
import LoanRequest from "./WidgetRevampv2/Loans/components/LoanRequests/LoanRequest";
import LoanHistory from "./WidgetRevampv2/Loans/components/LoanHistory/LoanHistory";
import DirectDebitProfile from "./WidgetRevampv2/Profile/components/DirectDebitProfile";
import LoanRepaymentAmount from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepaymentAmount";
import LoanRepaymentViaMpesa from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepaymentMpesa";
import LoanRepaymentMethods from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepaymentMethods";
import LoanRepaymentViaWallet from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepaymentViaWallet";
import LoanRepaymentViaNewCard from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepaymentViaNewCard";
import LoanRepaymentProof from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepaymentProof";
import ContactUs from "./WidgetRevampv2/Dashboard/components/ContactUs";
import LoanRepayments from "./WidgetRevampv2/Loans/components/LoanHistory/LoanRepayments";
import BankStatement from "./WidgetRevampv2/Profile/components/BankStatement";
import LoanRepaymentViaDirectDebit from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepaymentViaDirectDebit";
import QuickLoanRepayment from "./WidgetRevampv2/QuickLoanRepayment/QuickLoanRepayment";
import QuickLoanRepaymentAmount from "./WidgetRevampv2/QuickLoanRepayment/components/QuickLoanRepaymentAmount";
import NewLoanOfferWidget from "./WidgetRevampv2/Loans/components/NewLoanOfferWidget/NewLoanOffer";

const WidgetRoutes = () => {
  return (
    <div>
      <Route path={ROUTES.widget} exact component={StepOneComponent} />
      <Route
        exact
        path={ROUTES.widgetDashboard}
        component={DashboardComponent}
      />
      <Route
        exact
        path={ROUTES.widgetQuickLoanRepayment}
        component={QuickLoanRepayment}
      />
      <Route
        exact
        path={ROUTES.widgetQuickLoanRepaymentAmount}
        component={QuickLoanRepaymentAmount}
      />
      <Route exact path={ROUTES.widgetProfile} component={ProfileComponent} />
      <Route exact path={ROUTES.widgetProfileInfo} component={ProfileDetails} />
      <Route exact path={ROUTES.widgetTopUp} component={TopUpComponent} />

      <Route exact path={ROUTES.widgetCard} component={CardInformation} />

      <Route exact path={ROUTES.widgetPin} component={TransactionPin} />

      <Route
        exact
        path={ROUTES.widgetPinOnboarding}
        component={TransactionPinOnboarding}
      />

      <Route
        exact
        path={ROUTES.widgetTransactions}
        component={TransactionsComponent}
      />

      <Route
        exact
        path={ROUTES.widgetWithdrawal}
        component={WithdrawalComponent}
      />

      <Route
        exact
        path={ROUTES.widgetNewLoanOffer}
        component={NewLoanOfferWidget}
      />

      <Route exact path={ROUTES.widgetSetPin} component={SetPinComponent} />

      <Route
        path={ROUTES.widgetUpdateCard}
        exact
        component={UpdateCardDetails}
      />

      <Route exact path={ROUTES.widgetLoan} component={LoansComponent} />

      <Route exact path={ROUTES.widgetLoanRequest} component={LoanRequest} />

      <Route exact path={ROUTES.widgetLoanHistory} component={LoanHistory} />

      <Route
        exact
        path={ROUTES.widgetDirectDebitProfile}
        component={DirectDebitProfile}
      />

      <Route
        exact
        path={ROUTES.widgetLoanRepaymentAmount}
        component={LoanRepaymentAmount}
      />

      <Route
        exact
        path={ROUTES.widgetLoanRepaymentOptionView}
        component={LoanRepayments}
      />

      <Route
        exact
        path={ROUTES.widgetBankStatement}
        component={BankStatement}
      />

      <Route
        exact
        path={ROUTES.widgetLoanRepaymentMpesa}
        component={LoanRepaymentViaMpesa}
      />

      <Route
        exact
        path={ROUTES.widgetLoanRepaymentMethods}
        component={LoanRepaymentMethods}
      />

      <Route
        exact
        path={ROUTES.widgetLoanRepaymentViaWallet}
        component={LoanRepaymentViaWallet}
      />

      <Route
        exact
        path={ROUTES.widgetLoanRepaymentViaDirectDebit}
        component={LoanRepaymentViaDirectDebit}
      />

      <Route
        exact
        path={ROUTES.widgetLoanRepaymentViaNewCard}
        component={LoanRepaymentViaNewCard}
      />
      <Route
        exact
        path={ROUTES.widgetLoanRepaymentProof}
        component={LoanRepaymentProof}
      />
      <Route exact path={ROUTES.widgetContactUs} component={ContactUs} />
    </div>
  );
};

export default WidgetRoutes;
