import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdArrowForwardIos } from "react-icons/md";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import logo from "../../../../../../assets/loans/loanHistory.svg";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import { formatMoney } from "../../../../../../helpers/formatCurrency";
import LoanHistoryDetails from "./LoanHistoryDetails";
import moment from "moment";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import styles from "../../Loans.module.scss";
import {
  fetchWidgetLoanTransactions,
  useFetchWidgetLoanHistory,
} from "../../../../../../custom-hooks/useFetchLoanHistory";
import { useQueryCache } from "react-query";
import { Alert, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";
import loader from "../../../../../../assets/loadinggif.gif";
import redDot from "../../../../../../assets/red-dot.svg";
import { useFetchPendingWidgetLoanOffer } from "../../../../../../custom-hooks/useFetchPendingLoanOffer";
import { LoanOfferProps } from "../../../../../../web/components/features/newLoanOffer/NewLoanOffer";
import { updateWidgetSettingsAction } from "../../../../../../redux/actions";

export type LoanProp = {
  loanRef?: string;
  status?: string;
  borrower_id?: number;
  encryptedKey?: string;
  aggregator_id?: number;
  onCancel?: () => void;
  currency?: string;
  loanAmount?: number;
  loanMaturityDate?: string;
  id?: number;
};

enum Status {
  Active = "active",
  Repaid = "repaid",
  Approved = "approved",
  Rejected = "rejected",
  Overdue = "overdue",
  AwaitingApproval = "awaiting approval",
  SubmittedAndAwaitingApproval = "submitted_and_awaiting_approval",
  Closed = "closed",
  Withdrawn = "withdrawn",
}

const LoanHistory = () => {
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedLoan, setSelectedLoan] = useState<LoanProp | null>(null);
  const [error, setError] = useState<any>();
  const [mostRecentLoanOffer, setMostRecentLoanOffer] =
    useState<LoanOfferProps | null>(null);
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const widgetState = useSelector(
    (store: ReduxStoreModel) => store.widgetSettingsReducer,
  );
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer,
  );
  const { customer_id, aggregator_id } = borrowerDetails;
  const { encryptedKey, showLoanDetails } = widgetState;

  useEffect(() => {
    appInsights.trackPageView({ name: "LoanHistory.tsx", isLoggedIn: true });
  }, []);

  const getStatusStyle = (status: string | undefined) => {
    switch (status?.toLowerCase()) {
      case Status.Active:
      case Status.Repaid:
      case Status.Approved:
        return styles.active;
      case Status.Rejected:
      case Status.Overdue:
        return styles.rejected;
      case Status.AwaitingApproval:
      case Status.SubmittedAndAwaitingApproval:
        return styles.awaiting;
      default:
        return "";
    }
  };

  const { data: pendingLoanOffer, isLoading: isLoadingPendingLoanOffer } =
    useFetchPendingWidgetLoanOffer({
      customer_id: borrowerDetails?.customer_id,
      aggregator_id: borrowerDetails?.aggregator_id,
    });

  const {
    data: widgetLoanHistory,
    isLoading: isLoadingLoanHistory,
    error: widgetLoanHistoryError,
  } = useFetchWidgetLoanHistory({
    customerId: customer_id ? customer_id : 0,
    aggregator_id: aggregator_id ? aggregator_id : 0,
    page: pageNumber,
  });

  useEffect(() => {
    if (pageNumber < widgetLoanHistory?.totalPages) {
      const nextPage = pageNumber + 1;
      queryCache.prefetchQuery(
        [
          {
            customerId: customer_id && customer_id,
            aggregator_id: aggregator_id && aggregator_id,
            page: nextPage,
          },
          "fetchWidgetLoanTransactions",
        ],
        fetchWidgetLoanTransactions,
      );
    }
  }, [
    pageNumber,
    queryCache,
    customer_id,
    aggregator_id,
    widgetLoanHistory?.totalPages,
  ]);

  useEffect(() => {
    if (widgetLoanHistoryError) {
      setError(widgetLoanHistoryError);
    }
  }, [widgetLoanHistoryError, isLoadingLoanHistory]);

  useEffect(() => {
    if (!isLoadingPendingLoanOffer && pendingLoanOffer) {
      const recentLoanOffer = pendingLoanOffer[pendingLoanOffer?.length - 1];
      setMostRecentLoanOffer(recentLoanOffer);
    }
  }, [pendingLoanOffer, isLoadingPendingLoanOffer]);

  function loanOptionsNavigationHandler() {
    dispatch(
      updateWidgetSettingsAction({
        showLoanDetails: false,
        showNewLoanOffer: false,
      }),
    );
    history.push(ROUTES.widgetLoan);
  }

  function viewLoanDetailsHandler(item: any) {
    setSelectedLoan(item);
    dispatch(updateWidgetSettingsAction({ showLoanDetails: true }));
  }

  function loanDetailsbBckNavigationHandler() {
    dispatch(updateWidgetSettingsAction({ showLoanDetails: false }));
  }

  if (showLoanDetails) {
    return (
      <LoanHistoryDetails
        loanRef={selectedLoan?.loanRef}
        onCancel={loanDetailsbBckNavigationHandler}
        currency={selectedLoan?.currency || "NGN"}
      />
    );
  }

  if (isLoadingLoanHistory) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  return (
    <div>
      {error && (
        <div style={{ paddingBottom: "20px", paddingTop: "10px" }}>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => {
              if (setError) {
                setError("");
              }
            }}
          />
        </div>
      )}
      <div onClick={loanOptionsNavigationHandler} className="cursor">
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>Loan History</h4>
      <br />
      {widgetLoanHistory?.data?.length === 0 ? (
        <h4 className="text-center">No Data Available</h4>
      ) : (
        widgetLoanHistory?.data?.map((item: LoanProp) => (
          <div key={item.id}>
            <div
              className="card cursor"
              onClick={() => viewLoanDetailsHandler(item)}
            >
              <div className="card-body d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className=" mr-3">
                    <img
                      src={logo}
                      alt=""
                      className="img-fluid"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                  <div>
                    <p className="font-weight-bold text-dark padding-none">
                      {formatMoney(item?.loanAmount || 0, "NGN")}
                      <small className={`ml-2 ${getStatusStyle(item?.status)}`}>
                        {item?.status?.toLowerCase() ===
                        Status.SubmittedAndAwaitingApproval
                          ? "Awaiting approval"
                          : item?.status}
                      </small>
                    </p>
                    {item?.status?.toLowerCase() === Status.Active ||
                    item?.status?.toLowerCase() === Status.Approved ||
                    item?.status?.toLowerCase() === Status.Overdue ? (
                      <small className="text-muted padding-none">
                        {moment(item?.loanMaturityDate).format("D/MM/YYYY")}
                      </small>
                    ) : null}
                  </div>
                  {pendingLoanOffer?.length > 0 &&
                  mostRecentLoanOffer?.requested_loan_id === item?.id ? (
                    <img src={redDot} alt="" className={styles.red_dot} />
                  ) : null}
                </div>

                <MdArrowForwardIos />
              </div>
            </div>
            <br />
          </div>
        ))
      )}
      {/* Temporarily  commented out  */}
      {/* {currency === "NGN" ? (
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "10%" }}
        >
          <div className="d-flex align-items-center">
            <p
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={() => history.push(ROUTES.widgetProfile)}
            >
              Update card details
            </p>
          </div>
        </div>
      ) : null} */}

      <div className={styles.pagination_controls}>
        <Button
          onClick={() => setPageNumber((prev) => prev - 1)}
          disabled={pageNumber === 1}
        >
          Prev
        </Button>
        <button>{pageNumber}</button>
        <Button
          disabled={pageNumber >= widgetLoanHistory?.totalPages}
          onClick={() => setPageNumber((prev) => prev + 1)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default LoanHistory;
