import React, { Fragment } from "react";
import PinInput from "react-pin-input";
import { numberRegExp } from "../../helpers/yupValidators";

type CustomPinInputFieldProps = {
  focus?: boolean;
  length: number;
  hide?: boolean;
  setValue: (value: string) => void;
  initialValue?: string;
  label: string;
};

export const CustomPinInputField = ({
  focus,
  length,
  hide,
  setValue,
  initialValue,
  label,
}: CustomPinInputFieldProps) => {
  return (
    <Fragment>
      <div className="mb-4">
        <p className="text-left w-100 text-muted">{label}</p>
        <PinInput
          // id="pin"
          length={length}
          initialValue={initialValue || ""}
          secret={true || hide}
          onChange={(value) => {
            setValue(value);
          }}
          type="custom"
          inputMode="string"
          regexCriteria={numberRegExp}
          autoSelect={true}
          focus={focus}
          inputStyle={{
            border: "1px solid #D7DCE0",
            borderRadius: "10px",
          }}
        />
      </div>
    </Fragment>
  );
};
