import { getData } from "../apis/apiMethods";
import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";

interface BankStatementLinkedProps {
  aggregator_id: number;
  customer_id: number;
  bank_statement_provider_id: number;
}

const fetchBankStatementProviderStatus = async ({
  aggregator_id,
  customer_id,
  bank_statement_provider_id,
}: BankStatementLinkedProps) => {
  const res = await getData(
    `${apiEndpoints.checkBankStatementProviderIsLinked}?aggregator_id=${aggregator_id}&customer_id=${customer_id}&bank_statement_provider_id=${bank_statement_provider_id}`
  );

  return res;
};

export default function useFetchBankStatementProviderStatus({
  aggregator_id,
  customer_id,
  bank_statement_provider_id,
}: BankStatementLinkedProps) {
  return useQuery(
    [
      { aggregator_id, customer_id, bank_statement_provider_id },
      "fetchBankStatementProviderStatus",
    ],
    fetchBankStatementProviderStatus,
    {
      enabled: aggregator_id && customer_id ? true : false,
    }
  );
}