import React, { Fragment, ReactElement } from "react";
import ButtonLoader from "../ButtonLoader/ButtonLoader.component";

interface CustomWidgetButtonProps {
  disabled?: boolean;
  customStyles?: {
    buttonBackgroundColor?: string;
    buttonTextColor?: string;
  };
  loading?: boolean;
  value: string | ReactElement;
  onClick: () => void;
  type?: "button" | "submit" | "reset";
  name?: string;
  buttonType?: string;
  width?: string;
}

export default function CustomWidgetButton({
  disabled,
  customStyles,
  loading,
  value,
  onClick,
  type = "button",
  name,
  buttonType,
  width,
}: CustomWidgetButtonProps): ReactElement {
  return (
    <Fragment>
      <button
        type={type}
        className={`btn btn-sm font-weight-bold ${
          buttonType ? buttonType : "btn-primary"
        } ${width}`}
        onClick={onClick}
        disabled={disabled}
        name={name}
        style={{
          backgroundColor: customStyles?.buttonBackgroundColor,
          borderColor: customStyles?.buttonBackgroundColor,
          color: customStyles?.buttonTextColor,
        }}
      >
        {loading ? <ButtonLoader /> : value}
      </button>
    </Fragment>
  );
}
