import { IWebState } from "../../interfaces/loanData";
import { ReduxActionModel } from "../../interfaces/redux-interface";
import { WEB_SETTINGS } from "../actions/types";

let initialState: IWebState = {
  showPinModal: false,
  showLoanRequestModal: false,
  showLoanRepaymentModal: false,
  showLoanDetails: false,
  showLoanRepayments: false,
  showRepaymentSchedule: false,
  showLoanOptions: false,
  isSavingProvider: false,
  showLoanHistory: false,
  // showRepayments: false,
  showTransactions: false,
  showTopUp: false,
  showSupport: false,
  showWithdraw: false,
  showQuickRepayment: false,
  showVirtualAcctCompletionModal: false,
  walletConsentIsLoading: false,
  showTransactionDetail: false,
  showNewLoanOffer: false,
  IsDirectDebit: false,
  errorMsg: "",
  successMsg: {
    headerText: "",
    bodyText: ""
  }
};

export const updateWebSettingsReducer = (
  state = initialState,
  action: ReduxActionModel
) => {
  const { type, payload } = action;
  switch (type) {
    case WEB_SETTINGS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
