import React from 'react'
import styles from "./SignUpLayout.module.scss"
import embedLogo from "../../../../assets/web/Embed.svg"
import scene1v4 from "../../../../assets/web/SCENE1v4.svg"
import { Link } from 'react-router-dom'

interface SignUpProp {
    children: React.ReactNode
}

function SignUpLayout({children}: SignUpProp) {
  return (
    <div className={styles.layout_container}>
        <div>
            <header className={styles.logo__header}>
                <div>
                    <img src={embedLogo} alt='' />
                    <Link to="/" className={styles.logo}>embed</Link>
                </div>
            </header>
            <section>
                {children}
            </section>
        </div>
        <figure className={styles.svg__section}>
            <img src={scene1v4} alt='' />
            <h2>Unlock Credit that Advances your life</h2>
            <p>Seize the opportunity to unlock your future earnings now and fuel your exponential growth</p>
        </figure>
    </div>
  )
}

export default SignUpLayout