// src/custom-hooks/useBankStatementProvider.ts
import { useState, useRef } from "react";
import { message } from "antd";
import { useDispatch } from "react-redux";
import Okra from "okra-js";
import MonoConnect from "@mono.co/connect.js";
import { appInsights } from "../components/AppInsight/AppInsight";
import {
  updateBorrowerDetailsAction,
  updateWidgetSettingsAction,
} from "../redux/actions";
import { apiEndpoints } from "../apis/apiEndpoints";
import { putDotNetDataWithKey } from "../apis/apiMethods";
import CONFIG from "../helpers/config";
import { IBorrowerData } from "../interfaces/borrower";

interface UseBankStatementProviderProps {
  borrowerDetails: IBorrowerData;
  bankStatementLinkedStatus: any;
}

const useBankStatementProvider = ({
  borrowerDetails,
  bankStatementLinkedStatus,
}: UseBankStatementProviderProps) => {
  const dispatch = useDispatch();
  const accountLinkSuccessRef = useRef<boolean | null>(null);
  const monoWidgetData = useRef({
    opened: false,
    opened_dateTimeStamp: null as number | null,
    customer_email: "",
    is_institution_selected: false,
    selected_institution_name: null as string | null,
    institution_selected_dateTimeStamp: null as number | null,
  });
  const [isSavingProvider, setIsSavingProvider] = useState(false);

  const handleSaveCustomerCode = async (
    code: string,
    provider: "okra" | "mono"
  ) => {
    const reqBody = {
      bank_statement_provider_id: provider === "okra" ? 1 : 2,
      bank_statement_provider_customer_code: provider === "okra" ? code : "",
      authentication_code: provider === "mono" ? code : "",
      customer_id: borrowerDetails?.customer_id,
      aggregator_id: borrowerDetails?.aggregator_id,
    };

    try {
      setIsSavingProvider(true);
      await putDotNetDataWithKey(
        apiEndpoints.saveBankStatementProvider,
        reqBody
      );
      message.success("Bank provider details saved successfully");
      dispatch(
        updateBorrowerDetailsAction({
          bank_statement_connected: bankStatementLinkedStatus?.status,
        })
      );
    } catch (error) {
      console.log(error);
      appInsights.trackException({
        exception: new Error(JSON.stringify(error)),
        properties: { fileName: "LoanRequestStepFour.tsx" },
      });
      message.error("Something went wrong please try again");
    } finally {
      setIsSavingProvider(false);
    }
  };

  const handleLaunchOkraWidget = () => {
    if (!CONFIG.OKRA_PUBLIC_KEY || !CONFIG.OKRA_CLIENT_TOKEN) {
      return message.error("Update Config for Okra");
    }
    dispatch(
      updateWidgetSettingsAction({
        bankStatementWidgetOpened: true,
      })
    );
    Okra.buildWithOptions({
      name: "ADVANCLY",
      env: CONFIG.OKRA_ENV,
      app_id: "", // app_id from your app builder
      key: CONFIG.OKRA_PUBLIC_KEY,
      token: CONFIG.OKRA_CLIENT_TOKEN,
      products: ["auth", "transactions"], //others are ['auth','identity','balance','transactions', 'income'](in lowercase)
      onSuccess: (data: any) => {
        console.log("options success", data);
        handleSaveCustomerCode(data?.customer_id, "okra");
      },
      onClose: () => {
        console.log("options close");
      },
    });
  };

  const monoConnect = () => {
    const customer = {
      name: `${borrowerDetails?.first_name} ${borrowerDetails?.last_name}`,
      email: borrowerDetails?.email,
    };
    if (!CONFIG.MONO_PUBLIC_KEY) {
      return message.error("Update Config for Mono");
    }
    dispatch(
      updateWidgetSettingsAction({
        bankStatementWidgetOpened: true,
      })
    );
    const monoInstance = new MonoConnect({
      onClose: () => {
        console.log("Widget closed");
        appInsights.trackEvent({
          name: "MonoWidgetData",
          properties: {
            ...monoWidgetData.current,
            AccountLinkStatus: accountLinkSuccessRef.current,
            OutsideLoanProcess: true,
          },
        });
      },
      onLoad: () => console.log("Widget loaded successfully"),
      key: CONFIG.MONO_PUBLIC_KEY,
      data: { customer },
      onSuccess: async (data: { code: string }) => {
        accountLinkSuccessRef.current = true;
        handleSaveCustomerCode(data?.code, "mono");
      },
      onEvent: (eventName: string, data: any) => {
        if (eventName === "OPENED") {
          monoWidgetData.current = {
            ...monoWidgetData.current,
            opened: true,
            opened_dateTimeStamp: data?.timestamp,
            customer_email: borrowerDetails?.email,
          };
        } else if (eventName === "INSTITUTION_SELECTED") {
          monoWidgetData.current = {
            ...monoWidgetData.current,
            is_institution_selected: true,
            selected_institution_name: data.institution.name,
            institution_selected_dateTimeStamp: data?.timestamp,
          };
        }
      },
    });

    monoInstance.setup();

    return monoInstance.open();
  };

  return {
    handleLaunchOkraWidget,
    monoConnect,
    accountLinkSuccessRef,
    monoWidgetData,
    isSavingProvider,
  };
};

export default useBankStatementProvider;
