import React, { useEffect, useRef, useState } from "react";
import { postData } from "../../../apis/apiMethods";
import { errorHandler } from "../../../helpers/errorHandler";
import WidgetCustomTitle from "../WidgetCustomTitle/WidgetCustomTitle.component";
import WidgetNavCard from "../WidgetNavCard/WidgetNavCard.component";
import CorporateSignUp from "./CorporateSignUp.component";
import IndividualSignUp from "./IndividualSignUp.component";
import success from "../../../assets/success.svg";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { message } from "antd";
import { IndividualBvnValidationParams } from "./types";
import { sendResponseToClient } from "../../../helpers";
import { WIDGET_RESPONSE_STATUSES } from "../utils";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux-interface";
import { scrollToTop } from "../../../helpers/appHelpers";
import mixpanel from "mixpanel-browser";
import { MixPanel } from "../../../helpers/mixpanel";

const StepOneComponent = () => {
  const [otpError, setOtpError] = useState("");
  const widgetState = useSelector(
    (store: ReduxStoreModel) => store.widgetSettingsReducer,
  );
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer,
  );
  const { worldCountries, bankList, edit_widget, bank_details_input_enabled } =
    widgetState;
  const { customer_type } = borrowerDetails;
  const [loaders, setLoaders] = useState({
    loading: false,
    bvnLoading: false,
    otpLoading: false,
  });

  const ipRef = useRef(null);

  const [error, setError] = useState("");
  const [data, setData] = useState<any>();
  const [borrowerType, setBorrowerType] = useState<string | undefined>(
    borrowerDetails?.customer_type,
  );
  const { allow_sms_notification, allow_bank_details } = widgetState;
  const [state, setState] = useState({
    bvnValid: false,
    bvnIconClass: "fas fa-plus",
    otpIconClass: "fas fa-plus",
    bvn_phone: "",
    otp: "",
    timerExpired: false,
    businessRegNumberValidated: false,
    bvnValidated: false,
    emailValidated: false,
    bvnIconStatus: "editing",
    otpIconStatus: "editing",
    loan_application_referenceno: "",
    state_token: "",
    showRegistrationSuccess: false,
    bank_account_name: "",
    notify: "",
  });

  useEffect(() => {
    console.log({
      borrowerType,
    });
  }, [borrowerType]);

  useEffect(() => {
    setData(borrowerDetails);
  }, [borrowerDetails]);

  // const [countryCodeIsEditTrue, setCountryCodeIsEditTrue] = useState<
  //   string | null
  // >(null);

  // Validate and generate account name base on bank code and account number
  const validateAccount = async () => {
    if (data) {
      setLoaders({ ...loaders, loading: true });
      setState({ ...state, bank_account_name: "" });

      try {
        const { bank_account_number, bank_code, country_code } = data;
        if (bank_account_number.length >= 10) {
          const response = await postData(apiEndpoints.validateBankAccount, {
            bank_code: bank_code,
            bank_account_num: bank_account_number,
            country_code: country_code,
          });
          setState({ ...state, bank_account_name: response.bank_account_name });
        }
      } catch (error) {
        message.error(errorHandler(error));
        setError(errorHandler(error));
      } finally {
        setLoaders({ ...loaders, loading: false });
      }
    }
  };

  useEffect(() => {
    if (data) {
      const { bank_account_number, bank_code, country_code } = data;
      if (bank_account_number.length >= 10 && bank_code && country_code) {
        validateAccount();
      }
    }
    //eslint-disable-next-line
  }, [data?.bank_account_number, data?.bank_code]);

  const onClickNavCard = (borrowerType: "1" | "2" | "") => {
    setState({
      ...state,
      bvnValidated: false,
      businessRegNumberValidated: false,
      emailValidated: false,
    });
    setBorrowerType(borrowerType);
  };

  const handleChange = (name: string, value: string) => {
    if (data) {
      setData({ ...data, [name]: value });
      switch (name) {
        case "company_name":
        case "cac_number":
          setState({ ...state, businessRegNumberValidated: false });
          break;
        case "email":
          setState({ ...state, emailValidated: false });
          break;
        case "bvn":
          setState({ ...state, bvnValidated: false });
          break;
      }
    }
  };
  // const handleCountryCodeIsEditTrue = (countryCode: string) => {
  //   if (countryCode) {
  //     setCountryCodeIsEditTrue(countryCode);
  //   }
  // };

  const handleCacValidation = async (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.persist();
    handleBvnValidation();
  };

  const validateFields = (
    email: string,
    identity_number: string,
    country_code: string,
  ) => {
    let isValid = false;
    // validate fields
    if (borrowerType === "" || borrowerType === null) {
      setLoaders({ ...loaders, bvnLoading: true });
      setError(`Please choose Borrower Type`);
      return;
    }
    if (email === "") {
      setLoaders({ ...loaders, bvnLoading: true });
      setError(`Please enter your email address`);
      return;
    }

    if (identity_number === "") {
      setLoaders({ ...loaders, bvnLoading: true });
      setError(`Please enter Identity number`);
      return;
    }

    if (country_code === "") {
      setLoaders({ ...loaders, bvnLoading: true });
      setError(`"Please enter a Country Code"`);
      return;
    }
    isValid = true;
    return isValid;
  };

  const handleIndividualBvnValidation = async (
    data: IndividualBvnValidationParams,
  ) => {
    try {
      setError("");
      setLoaders({ ...loaders, bvnLoading: true, otpLoading: true });
      const res = await postData("/account/direct_borrower_signup/", data);
      setState({
        ...state,
        bvnValidated: true,
        state_token: res?.state_token,
      });
      // setBorrower({
      //   ...res,
      //   bvn_number: data?.bvn_number,
      //   borrower_type: borrowerType,
      //   email: data?.email,
      //   borrower_phone: data?.borrower_phone,
      //   country_code: data?.country_code,
      // });
    } catch (error) {
      scrollToTop();
      setError(errorHandler(error));
    } finally {
      setLoaders({ ...loaders, bvnLoading: false });
    }
  };

  const handleCooperateBvnValidation = async (dataParams: {
    aggregator_id: number;
    bank_code: string;
    gender: string;
    create_wallet: boolean;
    city: string;
    customer_phone: string;
    last_name: string;
    state_token: string;
    is_widget: boolean;
    residence_address: string;
    customer_category: string;
    bank_account_num: string;
    business_registration_number: string;
    bank_account_name: string;
    country_code: string;
    board_step: string;
    borrowerType: "2";
    company_name: string;
    state: string;
    photo_url: string;
    first_name: string;
    email: string;
  }) => {
    if (data) {
      try {
        setError("");
        setLoaders({ ...loaders, bvnLoading: true, otpLoading: true });
        const res = await postData(
          "/account/direct_onboard_corporate_borrower/",
          dataParams,
        );

        const reqBody = {
          borrower_type: borrowerType,
          bvn_number: data?.identity_number,
          aggregator_id: dataParams.aggregator_id,
          email: dataParams.email,
          board_step: "second_step",
          state_token: res.state_token,
          business_registration_number: dataParams.business_registration_number,
          company_name: dataParams.company_name,
          bank_account_name: state.bank_account_name,
          bank_account_num: data?.bank_account_number,
          bank_code: dataParams.bank_code,
          borrower_phone: dataParams.customer_phone,
          city: dataParams.city,
          first_name: dataParams.first_name,
          gender: dataParams.gender,
          last_name: dataParams.last_name,
          residence_address: dataParams.residence_address,
          state: dataParams.state,
          photo_url: "",
          is_widget: dataParams.is_widget,
          customer_category: dataParams.customer_category,
          create_wallet: data?.country_code?.toLowerCase() === "ng",
          // country_code
        };

        const response = await postData(
          "/account/direct_onboard_corporate_borrower/",
          reqBody,
        );

        setState({
          ...state,
          state_token: `${response.state_token}`,
          bvnValidated: true,
          bvnValid: true,
          businessRegNumberValidated: true,
          notify: `${res.message}`,
          bvn_phone: `${res.phone}`,
        });
        // setBorrower({
        //   ...response,
        //   bvn_number: data?.identity_number,
        //   email: dataParams.email,
        //   borrower_type: borrowerType,
        // });
      } catch (error) {
        scrollToTop();
        setError(errorHandler(error));
      } finally {
        setLoaders({ ...loaders, bvnLoading: false });
      }
    }
  };

  const handleBvnValidation = async () => {
    if (data) {
      const {
        email,
        company_name,
        business_registration_number,
        identity_number,
        borrower_phone,
        city,
        bank_code,
        aggregator_id,
        last_name,
        bank_account_number,
        first_name,
        gender,
        residence_address,
        customer_category,
        country_code,
      } = data;
      // validate fields
      if (company_name === "") {
        setLoaders({ ...loaders, loading: false });
        setError("Please enter your Business Name");
        return;
      }
      if (business_registration_number === "") {
        setLoaders({ ...loaders, loading: false });
        setError("Please enter your Business Registration number");
        return;
      }
      setState({
        ...state,
        otp: "",
      });
      if (
        !validateFields(
          email,
          identity_number ? identity_number : "",
          data?.country_code,
        )
      )
        return;
      // Individual borrowers who can't edit
      if (borrowerType === "1") {
        let data = {
          borrower_type: borrowerType,
          bvn_number: identity_number,
          aggregator_id,
          email,
          borrower_phone,
          board_step: "first_step",
          country_code,
        };
        await handleIndividualBvnValidation(data);
      } // Step 1 Corporate borrowers who can't edit
      else if (borrowerType === "2") {
        const requestBody = {
          company_name,
          business_registration_number,
          board_step: "first_step",
          aggregator_id: aggregator_id,
          bank_account_name: state.bank_account_name,
          bank_account_num: bank_account_number,
          bank_code,
          borrowerType,
          email,
          customer_phone: borrower_phone,
          city,
          first_name,
          gender,
          last_name,
          residence_address,
          state: data?.state,
          photo_url: "",
          state_token: state.state_token,
          is_widget: true,
          customer_category,
          create_wallet: country_code?.toLowerCase() === "ng",
          country_code,
        };
        //@ts-ignore
        await handleCooperateBvnValidation(requestBody);
      }
    }
  };

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => (ipRef.current = data.ip));

    MixPanel.track(`Page View: SignUp.tsx`, {
      page: "SignUp.tsx - (Widget Onboarding)",
    });
  }, []);

  const handleRegistration = async () => {
    if (data) {
      const {
        borrower_phone,
        city,
        bank_code,
        aggregator_id,
        last_name,
        bank_account_number,
        first_name,
        gender,
        residence_address,
        customer_type,
        country_code,
        email,
      } = data;

      const nextStepData = {
        aggregator_id,
        bank_account_name: state.bank_account_name,
        bank_account_num: bank_account_number,
        bank_code,
        board_step: borrowerType === "1" ? "second_step" : "third_step",
        borrower_type: borrowerType,
        email,
        borrower_phone: borrower_phone,
        city,
        first_name,
        gender,
        last_name,
        residence_address,
        state: data?.state,
        photo_url: "",
        state_token: state.state_token,
        is_widget: true,
        customer_category: customer_type,
        create_wallet: country_code?.toLowerCase() === "ng",
        country_code,
        // :
        //   edit_widget && countryCodeIsEditTrue
        //     ? countryCodeIsEditTrue
        //     : country_code,
        company_name: data?.company_name,
      };

      try {
        const urlToCall =
          borrowerType === "1"
            ? "/account/direct_borrower_signup/"
            : borrowerType === "2"
              ? "/account/direct_onboard_corporate_borrower/"
              : "";

        const response = await postData(urlToCall, nextStepData);
        setState({ ...state, showRegistrationSuccess: true });
        sendResponseToClient({
          status: WIDGET_RESPONSE_STATUSES.success,
          data: { message: response },
        });

        MixPanel.track("Sign Up", {
          "date created": new Date().toLocaleString(),
          ip: `${ipRef?.current}`,
          distinct_id: `${email}`,
          aggregator_id: `${aggregator_id}`,
          product: "embed widget",
        });

        MixPanel.identify(`${email}`);

        MixPanel.people.set({
          $name: `${first_name} ${last_name}`,
          $email: `${email}`,
          phone: `${borrower_phone}`,
          "aggregator id": `${aggregator_id}`,
          product: "embed widget",
          "customer type": `${borrowerType}`,
          "date created": new Date().toLocaleString(),
        });
        // setCountryCodeIsEditTrue(null);
      } catch (error) {
        setError(errorHandler(error));
        // setCountryCodeIsEditTrue(null);

        message.error(errorHandler(error));
      } finally {
        setLoaders({ ...loaders, bvnLoading: false, otpLoading: false });
      }
    }
  };
  const RegistrationCompleted = () => {
    window.location.reload();
  };

  const onOtpInput = (value: any) => {
    setError("");
    setState({ ...state, otp: value?.toString() });
  };

  const handleOtpValidation = async () => {
    const { otp, state_token } = state;
    setError("");
    setLoaders({ ...loaders, otpLoading: true });
    setState({ ...state, timerExpired: true });

    // data to be sent to endpoint
    const data = {
      otp: otp,
      state_token: state_token,
    };
    try {
      await postData("/account/validate_otp/", data);
      await handleRegistration();
    } catch (error) {
      setOtpError(errorHandler(error));
    } finally {
      setLoaders({ ...loaders, bvnLoading: false, otpLoading: false });
    }
  };

  const { bvnValidated, otp, showRegistrationSuccess } = state;

  const { bvnLoading, otpLoading, loading } = loaders;

  if (showRegistrationSuccess) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <img
            src={success}
            alt=""
            className="img-fluid"
            style={{
              height: "138px",
              width: "137px",
              objectFit: "contain",
            }}
          />
        </div>

        <p className="title text-center">Verification Successful!</p>
        <p className="text-center">
          Your verification was successful. Your registration is complete.
        </p>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <button
            onClick={RegistrationCompleted}
            className="btn advancly-btn btn-radius"
          >
            Done
          </button>
        </div>
      </>
    );
  }

  return (
    <div>
      {error ? <p className="alert alert-warning small">{error}</p> : null}

      {!borrowerType ? (
        <>
          <WidgetCustomTitle
            title="Choose a category"
            step={1}
            totalSteps={4}
          />
          <WidgetNavCard
            icon={<i className="ri-user-3-fill"></i>}
            title="Individual"
            subtitle="Get a loan offer as an individual"
            onClick={() => onClickNavCard("1")}
            // disabled={!edit_widget}
          />
          <WidgetNavCard
            icon={<i className="ri-briefcase-4-line"></i>}
            title="Corporate"
            subtitle="Get a loan offer as a company"
            onClick={() => onClickNavCard("2")}
            // disabled={!edit_widget}
          />
        </>
      ) : (
        <>
          <form>
            {/* 2 is corporate, 1 is  for individual  */}
            {borrowerType === "2" ? (
              <CorporateSignUp
                handleCacValidation={handleCacValidation}
                loading={loading}
                handleChange={handleChange}
                company_name={borrowerDetails?.company_name}
                edit_widget={edit_widget}
                business_registration_number={
                  borrowerDetails?.business_registration_number
                }
                businessRegNumberValidated={state.businessRegNumberValidated}
                customStyles={borrowerDetails?.customStyles}
                otp={otp}
                email={borrowerDetails?.email}
                customerPhoneNumber={borrowerDetails?.customer_phone}
                identity_number={borrowerDetails?.identity_number}
                bankList={bankList}
                bank_account_number={borrowerDetails?.bank_account_number}
                bank_name={borrowerDetails?.bank_name}
                bank_account_name={state?.bank_account_name}
                bvnLoading={bvnLoading}
                handleBvnValidation={handleBvnValidation}
                otpLoading={otpLoading}
                onOtpInput={onOtpInput}
                handleOtpValidation={handleOtpValidation}
                borrower_phone={borrowerDetails?.borrower_phone}
                country_code={borrowerDetails?.country_code}
                worldCountries={worldCountries}
                allowSmsNotification={allow_sms_notification}
                allowBankDetails={allow_bank_details}
                bankDetailsInputEnabledBoolean={bank_details_input_enabled}
                goBack={() => onClickNavCard("")}
                showBackBtn={!customer_type}
                otpError={otpError}
                setOtpError={setOtpError}

                // handleCountryCodeIsEditTrue={handleCountryCodeIsEditTrue}
              />
            ) : borrowerType === "1" ? (
              <IndividualSignUp
                email={borrowerDetails?.email}
                edit_widget={edit_widget}
                customStyles={borrowerDetails?.customStyles}
                identity_number={borrowerDetails?.identity_number}
                bankList={bankList}
                bank_account_number={borrowerDetails?.bank_account_number}
                bank_name={borrowerDetails?.bank_name}
                bank_account_name={state?.bank_account_name}
                borrower_phone={borrowerDetails?.borrower_phone}
                customerPhoneNumber={borrowerDetails?.customer_phone}
                bvnValidated={bvnValidated}
                otp={otp}
                bvnLoading={bvnLoading}
                otpLoading={otpLoading}
                allowSmsNotification={allow_sms_notification}
                allowBankDetails={allow_bank_details}
                bankDetailsInputEnabledBoolean={bank_details_input_enabled}
                handleBvnValidation={handleBvnValidation}
                onOtpInput={onOtpInput}
                handleOtpValidation={handleOtpValidation}
                country_code={borrowerDetails?.country_code}
                worldCountries={worldCountries}
                goBack={() => onClickNavCard("")}
                handleChange={handleChange}
                showBackBtn={!customer_type}
                otpError={otpError}
                setOtpError={setOtpError}

                // handleCountryCodeIsEditTrue={handleCountryCodeIsEditTrue}
              />
            ) : null}
          </form>
        </>
      )}
    </div>
  );
};

export default StepOneComponent;
