import React, { useState } from "react";
import SignUpLayout from "./signUpLayout/SignUpLayout";
import GettingStarted from "./gettingStarted/GettingStarted";
import OtpVerification from "./otpVerification/OtpVerification";
import BorrowerType from "./borrowerType/BorrowerType";
import Business from "../../components/business/Business";
import Individual from "../../components/individual/Individual";
import RegistrationSuccess from "./registrationSuccess/RegistrationSuccess";
import WelcomeScreen from "./welcomeScreen/WelcomeScreen";
import ResidentialInformation from "./residentialInformation/ResidentialInformation";

function SignUp() {
  const [currentStep, setCurrentStep] = useState(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <SignUpLayout>
      {currentStep === 0 && (
        <GettingStarted
          onNextStep={handleNextStep}
          error={error}
          setError={setError}
        />
      )}

      {currentStep === 1 && (
        <OtpVerification
          onNextStep={handleNextStep}
          setCurrentStep={setCurrentStep}
          setError={setError}
          error={error}
          setSuccess={setSuccess}
          success={success}
        />
      )}

      {currentStep === 2 && <BorrowerType setCurrentStep={setCurrentStep} />}

      {currentStep === 3 && (
        <Business
          error={error}
          setError={setError}
          setSuccess={setSuccess}
          success={success}
          setCurrentStep={setCurrentStep}
        />
      )}

      {currentStep === 4 && (
        <Individual
          error={error}
          setError={setError}
          setSuccess={setSuccess}
          success={success}
          setCurrentStep={setCurrentStep}
        />
      )}

      {currentStep === 5 && <WelcomeScreen setCurrentStep={setCurrentStep} />}

      {currentStep === 6 && (
        <ResidentialInformation
          error={error}
          setError={setError}
          setCurrentStep={setCurrentStep}
          isOnboarding={true}
        />
      )}

      {currentStep === 7 && (
        <RegistrationSuccess
          error={error}
          setError={setError}
          setSuccess={setSuccess}
          success={success}
        />
      )}
    </SignUpLayout>
  );
}

export default SignUp;
