import React, { useEffect, useState } from "react";
import { useQueryCache } from "react-query";
import styles from "./CompleteVirtualAcctModal.module.scss";
import closeIcon from "../../../../assets/web/close-square.svg";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

interface IcompleteVirtualAccountModal {
  bvn?: string;
  walletConsentDetails?: {
    status: boolean;
    url: string;
  };
  borrowerId?: number;
  aggregatorId?: number;
}

const CompleteVirtualAccountModal = ({
  bvn,
  walletConsentDetails,
}: IcompleteVirtualAccountModal) => {
  const [isCopied, setIsCopied] = useState(false);
  const queryCache = useQueryCache();
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (CompleteVirtualAccountModal.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleCopyClick = () => {
    if (bvn) {
      navigator.clipboard.writeText(bvn);
      setIsCopied(true);
    }

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleClose = () => {
    queryCache.invalidateQueries(["fetchWallet"]);
    document.body.style.overflow = "auto";

    dispatch(
      updateWebSettingsAction({ showVirtualAcctCompletionModal: false })
    );
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div
        className={styles.modal_container}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="completeVirtualAccountLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{
            height: "100%",
            maxWidth: "50%",
          }}
        >
          <div
            className={`modal-content ${styles.modal_sub_container}`}
            style={{ height: "98%" }}
          >
            <div className=" d-flex justify-content-between ">
              <h5
                style={{ textAlign: "center", width: "100%" }}
                className="modal-title"
                id="completeVirtualAccountLabel"
              >
                Customer Verification
              </h5>
            </div>
            <div
              className="modal-body"
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className={styles.card}>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  {" "}
                  <p>BVN {""}:</p>
                  <p>{bvn}</p>
                </div>

                <button onClick={handleCopyClick} disabled={isCopied}>
                  {isCopied ? "Copied!" : "Copy"}
                </button>
              </div>
              <iframe
                src={walletConsentDetails?.url}
                title="IGREE consent"
                width="100%"
                height="100%"
                style={{ border: "0" }}
              ></iframe>
              <button
                className={`pt-3 btn ${styles.closeIgreeConsent}`}
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <img
          src={closeIcon}
          alt=""
          className={styles.cancel_button}
          onClick={handleClose}
        />
      </div>
    </>
  );
};

export default CompleteVirtualAccountModal;
