import { useQuery } from "react-query";
import { getDotNetDataWithKey } from "../apis/apiMethods";

const getDirectDebitExistingAccounts = async ({
  customerId,
}: {
  customerId: number;
}) => {
  const directDebitBanksUrl = `/customer/${customerId}/direct_debit_accounts`;

  const data = await getDotNetDataWithKey(`${directDebitBanksUrl}`);
  return data.data;
};

export default function useDirectDebitExistingAccounts({
  customerId,
}: {
  customerId: number;
}) {
  return useQuery(
    [{ customerId }, "getDirectDebitExistingAccounts"],
    getDirectDebitExistingAccounts,
    {
      refetchOnWindowFocus: true,
    },
  );
}
