import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithKey } from "../apis/apiMethods";
type IfetchWalletDetails = {
  borrowerId: any;
  aggregatorId: any;
};
const getFetchWalletDetails = async ({
  borrowerId,
  aggregatorId,
}: IfetchWalletDetails) => {
  const data = await getDataWithKey(
    `${apiEndpoints.getWallet}?borrower_id=${borrowerId}&aggregator_id=${aggregatorId}`,
  );
  return data.data;
};
export default function useFetchWalletDetails({
  borrowerId,
  aggregatorId,
}: IfetchWalletDetails) {
  return useQuery(
    [{ borrowerId, aggregatorId }, "getFetchWalletDetails"],
    getFetchWalletDetails,
    {
      enabled: borrowerId && aggregatorId ? true : false,
    },
  );
}
