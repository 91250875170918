import React, { useEffect, useRef, useState } from "react";
import styles from "./SecurityQuestion.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../../helpers/yupValidators";
import CustomInputField from "../../../../../components/CustomHTMLElements/EmbedCustomInputField";
import useFetchSecurityQuestions from "../../../../../custom-hooks/useFetchSecurityQuestion";
import { Question } from "../../../../../interfaces/question";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { postData } from "../../../../../apis/apiMethods";
import { getToken } from "../../../../../helpers/appHelpers";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../interfaces/redux-interface";
import { updateWebSettingsAction } from "../../../../../redux/actions";
import { Alert } from "antd";

const schema = yup.object().shape({
  securityQuestion: yupValidators.securityQuestion,
  securityAnswer: yupValidators.securityAnswer,
});

interface FormData {
  securityQuestion: string;
  securityAnswer: string;
}

interface SecurityQuestionProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

function SecurityQuestion({ setCurrentStep }: SecurityQuestionProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredQuestionsList, setFilteredQuestionsList] = useState<
    Question[]
  >([]);
  const [questionLists, setQuestionsList] = useState<Question[]>([]);
  const [selectedQuestionDetails, setselectedQuestionDetails] =
    useState<Question>();
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const divRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer
  );
  const location = useLocation();

  const { data: questions, isLoading: isLoadingQuestions } =
    useFetchSecurityQuestions({
      token: getToken(),
    });

  const getQuestionId = (question: string) => {
    const result = questionLists.filter((questions) => {
      const foundQuestion = questions.question === question;
      return foundQuestion
        ? setselectedQuestionDetails({
            ...selectedQuestionDetails,
            id: questions.id,
          })
        : null;
    });

    return result;
  };

  useEffect(() => {
    if (selectedQuestionDetails?.question) {
      getQuestionId(selectedQuestionDetails?.question);
    }
  }, [selectedQuestionDetails?.question]);

  useEffect(() => {
    setQuestionsList(questions);
  }, [isLoadingQuestions]);

  const onSubmit = async ({ securityAnswer }: FormData) => {
    try {
      setCurrentStep(2);
      const reqBody = {
        customer_id: parseInt(userInfo?.customerId),
        question_id: Number(selectedQuestionDetails?.id),
        answer: securityAnswer,
      };
      await postData(apiEndpoints.customerSecurityQuestionAnswer, reqBody);
      setCurrentStep(1);
    } catch (error) {
      setCurrentStep(0);

      setTimeout(() => {
        dispatch(
          updateWebSettingsAction({ errorMsg: error.response?.data?.message })
        );
      }, 0);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setselectedQuestionDetails({
      ...selectedQuestionDetails,
      question: value,
    });

    setFilteredQuestionsList(
      questionLists.filter(
        (questions) => questions.question?.toLowerCase() === value.toLowerCase()
      )
    );

    setShowDropdown(true);
  };

  const handleSelectQuestion = (question: Question) => {
    setselectedQuestionDetails({
      ...selectedQuestionDetails,
      question: question.question,
    });

    setShowDropdown(false);
  };

  const handleClose = (e: any) => {
    if (divRef?.current && !divRef?.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, []);

  return (
    <div className={styles.stepOne_container}>
      {webStateData.errorMsg && (
        <div
          style={{ width: "100%", paddingTop: "50px", paddingBottom: "20px" }}
        >
          <Alert
            message={webStateData.errorMsg}
            type="error"
            showIcon
            closable
            onClose={() => dispatch(updateWebSettingsAction({ errorMsg: "" }))}
          />
        </div>
      )}
      <header className={styles.stepOne_header}>
        <div className={styles.title}>
          <h3>Security Question</h3>
        </div>
        <p>
          Kindly set a security question before creating your transaction pin.
        </p>
      </header>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`${styles.stepOne_form} ${
          location.pathname === `/${userInfo?.aggregator_name}` && styles.left
        }`}
      >
        <div ref={divRef} className={styles.selectQuestionContainer}>
          <label className="label-txt">Question</label>
          <input
            type="text"
            name="securityQuestion"
            ref={register}
            placeholder={
              isLoadingQuestions ? "loading, please wait" : "Select question"
            }
            value={selectedQuestionDetails?.question}
            onChange={handleInputChange}
            onFocus={() => setShowDropdown(true)}
            className={styles.selectedQuestionField}
            disabled={isLoadingQuestions}
          />
          {showDropdown && (
            <ul className={styles.questionDropdown}>
              {filteredQuestionsList?.length > 0
                ? filteredQuestionsList?.map((question) => (
                    <li
                      key={question.id}
                      onClick={() => handleSelectQuestion(question)}
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      {question.question}
                    </li>
                  ))
                : questionLists?.map((question) => (
                    <li
                      key={question.id}
                      onClick={() => handleSelectQuestion(question)}
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      {question.question}
                    </li>
                  ))}
            </ul>
          )}
          <span className={styles.question_error}>
            {errors.bankCode?.message}
          </span>
        </div>

        <CustomInputField
          label="Answer"
          name="securityAnswer"
          type="text"
          placeholder="Enter answer"
          reference={register}
          errors={errors.securityAnswer}
        />

        <div className={styles.submit__section}>
          <button type="submit" className={styles.submit_button}>
            Proceed
          </button>
        </div>
      </form>
    </div>
  );
}

export default SecurityQuestion;
