import CONFIG from "./config";

export const okraConfig = {
  short_url: CONFIG.OKRA_SHORT_URL,
};

export const paystackConfig = ({ email, amount }) => {
  return {
    publicKey: CONFIG.PAYSTACK_PUBLIC_KEY,
    email,
    amount,
    currency: "NGN",
    channels: ["card"],
    reference: new Date().getTime(),
  };
};
