import React, { ChangeEventHandler } from "react";
import { enforceMaxLength } from "../../helpers/enforceMaxLength";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";

interface ICustomTextAreaProps {
  name?: string;
  placeholder?: string;
  label?: string;
  errors?: any;
  max?: number | undefined;
  maxLength?: undefined | number;
  defaultValue?: string | number;
  value?: string | number;
  minLength?: undefined | number;
  readOnly?: boolean;
  onChange?: ChangeEventHandler;
  min?: number;
  showRequiredIcon?: boolean;
  hasActionButton?: boolean;
  actionButtonText?: string;
  onClickActionButton?: () => void;
  extraLabel?: string;
  bottomLabel?: string;
  rows?: number;
  disabled?: boolean | undefined;
  otherLabel?: string | boolean;
  otherLabelClassName?: string;
  customAreaStyle?: any;
  reference?: any
}
const CustomTextArea = React.forwardRef(
  (
    {
      name,
      placeholder,
      label,
      errors,
      maxLength,
      defaultValue,
      minLength,
      onChange,
      showRequiredIcon,
      extraLabel,
      rows,
      bottomLabel,
      disabled,
      otherLabel,
      otherLabelClassName,
      readOnly,
      reference,
      customAreaStyle = {},
    }: ICustomTextAreaProps,
  ) => {
    return (
      <div className="mb-2">
        <div className="d-flex align-items-center">
          <label className="form_label">{label}</label>
          {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
          {extraLabel && extraLabel.length && (
            <label className="form-label ml-2">
              <small className="crimson">{extraLabel}</small>
            </label>
          )}
          {otherLabel && (
            <label className="form-label ml-2">
              <small className={otherLabelClassName ? otherLabelClassName : ""}>
                {extraLabel}
              </small>
            </label>
          )}
        </div>
        <div className="form-group mb-0">
          <textarea
            style={{ ...customAreaStyle }}
            className="form-control"
            name={name}
            placeholder={placeholder}
            ref={reference}
            onKeyPress={enforceMaxLength}
            maxLength={maxLength}
            minLength={minLength}
            defaultValue={defaultValue}
            onChange={onChange}
            rows={rows ? rows : 3}
            disabled={disabled}
            readOnly={readOnly}
          />
          {bottomLabel && bottomLabel.length && !errors && (
            <small>{bottomLabel}</small>
          )}
          <ErrorHandler errors={errors} />
        </div>
      </div>
    );
  }
);

export default CustomTextArea;
