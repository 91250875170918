import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "../Withdraw.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../../helpers/yupValidators";
import CustomInputField from "../../../../../components/CustomHTMLElements/EmbedCustomInputField";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { postData } from "../../../../../apis/apiMethods";
import { Alert, Button } from "antd";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  pin: yupValidators.pin,
  confirmPin: yupValidators.confirmPin,
});

interface PinProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  success: string;
  setSuccess: Dispatch<SetStateAction<string>>;
  currentStep: number;
  residentialInfoStatus: any;
}

function Pin({
  setCurrentStep,
  error,
  setError,
  success,
  setSuccess,
  currentStep,
  residentialInfoStatus,
}: PinProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Withdraw  - (Pin.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const onSubmit = async ({ pin }: { pin: string }) => {
    setIsLoading(true);
    setError("");
    setSuccess("");
    let successTimeout: NodeJS.Timeout;
    let errorTimeout: NodeJS.Timeout;
    try {
      setCurrentStep(2);
      const reqBody = {
        customer_id: parseInt(userInfo?.customerId),
        pin,
      };

      await postData(apiEndpoints.customerSetPin, reqBody);
      setSuccess("Your pin was set successfully");

      successTimeout = setTimeout(() => {
        if (!residentialInfoStatus) {
          setCurrentStep(8);
        } else {
          setCurrentStep(1);
        }
      }, 1500);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Withdraw  - (Pin.tsx)",
        },
      });
      setCurrentStep(7);

      errorTimeout = setTimeout(() => {
        setError(error.response?.data?.message ?? error.message);
      }, 0);
    } finally {
      setIsLoading(false);
    }

    return () => {
      clearTimeout(successTimeout);
      clearTimeout(errorTimeout);
    };
  };

  useEffect(() => {
    if (currentStep === 7) {
      const timeoutId = setTimeout(() => {
        setSuccess("");
      }, 1500);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [currentStep]);

  return (
    <div className={styles.stepSeven_container}>
      {error && (
        <div
          style={{ width: "100%", paddingTop: "50px", paddingBottom: "20px" }}
        >
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}
      {success && (
        <div
          style={{ width: "100%", paddingTop: "50px", paddingBottom: "10px" }}
        >
          <Alert
            message={success}
            type="success"
            showIcon
            closable
            onClose={() => setSuccess("")}
          />
        </div>
      )}
      <header className={styles.stepSeven_header}>
        <h3>Set PIN</h3>
        <p>Create your four digit transaction pin</p>
      </header>

      <form className={styles.stepSeven_form} onSubmit={handleSubmit(onSubmit)}>
        <CustomInputField
          label="New PIN"
          type="password"
          reference={register}
          placeholder="****"
          hasActionButton={true}
          name="pin"
          errors={errors.pin}
          maxLength={4}
        />

        <CustomInputField
          reference={register}
          type="password"
          placeholder="****"
          label="Confirm PIN"
          hasActionButton={true}
          name="confirmPin"
          errors={errors.confirmPin}
          maxLength={4}
        />

        <div className={styles.submit_button}>
          <Button type="primary" htmlType="submit" disabled={isLoading}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}

export default Pin;
