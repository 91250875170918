import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import CustomInputField from "../../../../components/CustomHTMLElements/EmbedCustomInputField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import useFetchBanks from "../../../../custom-hooks/useFetchBanks";
import { Bank } from "../../../../interfaces/banks";
import user from "../../../../assets/profile/user.svg";
import { postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import styles from "./LoanRequest.module.scss";
import { Button } from "antd";
import { useQueryCache } from "react-query";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import { Alert } from "antd";
import { ISuccess } from "../../../../interfaces/success";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { MixPanel } from "../../../../helpers/mixpanel";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";

type NewDirectDebit = {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setSuccess: Dispatch<SetStateAction<ISuccess>>;
  existingDirectDebitAccountData: any;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
};

const schema = yup.object().shape({
  bankCode: yupValidators.bankName,
  accountNumber: yupValidators.accountNumber,
});

interface IBank {
  name?: string;
  code?: number | string;
}
const LoanRequestAddNewDirectDebit = ({
  setCurrentStep,
  setSuccess,
  existingDirectDebitAccountData,
  error,
  setError,
}: NewDirectDebit) => {
  const [isLoadingCustomerName, setIsLoadingCustomerName] = useState(false);
  const [customerName, setCustomerName] = useState<any>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredBankList, setFilteredBankList] = useState<Bank[]>([]);
  const [bankList, setBankList] = useState<Bank[]>([]);
  const [bankDetails, setBankDetails] = useState<IBank>();
  const [recipientAccountNumber, setRecipientAccountNumber] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { data: BankData, isLoading: isLoadingBanks } = useFetchBanks({
    country_code: "NG",
  });
  const divRef = useRef<HTMLDivElement>(null);
  const loanData = useSelector(
    (state: ReduxStoreModel) => state.loanDetailsReducer,
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (LoanRequestAddNewDirectDebit.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: LoanRequestAddNewDirectDebit.tsx`, {
      page: "LoanRequestAddNewDirectDebit.tsx - (Web)",
    });
  }, []);

  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const queryCache = useQueryCache();
  const customerId = userInfo.customerId;
  const directDebitUrl = `/customer/${userInfo.customerId}/direct_debit_accounts`;

  useEffect(() => {
    setBankList(BankData);
  }, [isLoadingBanks]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setBankDetails({
      ...bankDetails,
      name: value,
    });
    setFilteredBankList(
      bankList.filter((bank) =>
        bank.name.toLowerCase().includes(value.toLowerCase()),
      ),
    );

    setShowDropdown(true);
  };

  const handleClose = (e: any) => {
    if (divRef?.current && !divRef?.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, []);

  const getBankCode = (name: string) => {
    const result = bankList.filter((bank) => {
      const foundBank = bank.name.toLowerCase() === name.toLowerCase();
      return foundBank
        ? setBankDetails({ ...bankDetails, code: bank.code })
        : null;
    });

    return result;
  };

  useEffect(() => {
    if (bankDetails?.name) {
      getBankCode(bankDetails?.name);
    }
  }, [bankDetails?.name]);

  const handleSelectBank = (bank: Bank) => {
    setBankDetails({
      ...bankDetails,
      name: bank.name,
    });

    setShowDropdown(false);
  };

  const fetchCustomerName = async () => {
    setError("");
    setIsLoadingCustomerName(true);
    try {
      const reqBody = {
        bank_code: bankDetails?.code,
        bank_account_num: recipientAccountNumber,
      };

      const userDataResponse = await postData(
        apiEndpoints.customerValidateBankAccount,
        reqBody,
      );

      if (userDataResponse) {
        setError("");
      }

      setCustomerName(userDataResponse);
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (LoanRequestAddNewDirectDebit.tsx)",
        },
      });
    } finally {
      setIsLoadingCustomerName(false);
    }
  };

  useEffect(() => {
    if (bankDetails?.code && recipientAccountNumber?.length === 10) {
      fetchCustomerName();
    }
  }, [bankDetails?.code, recipientAccountNumber]);

  const handleSubmitDirectAccountDetails = async ({
    accountNumber,
    bankCode,
  }: {
    accountNumber: string;
    bankCode: string;
  }) => {
    setError("");
    setCurrentStep(4);
    const reqBody = {
      account_name: customerName?.bank_account_name,
      account_number: accountNumber,
      bank_code: bankDetails?.code,
      bank_name: bankDetails?.name,
      currency: "NGN",
    };

    try {
      await postData(directDebitUrl, reqBody);
      setSuccess({
        headerText: "Direct Debit Account Added Successfully",
        bodyText: `Proceed to select your direct debit account from the dropdown`,
      });
      queryCache.invalidateQueries([
        { customerId },
        "getDirectDebitExistingAccountsWeb",
      ]);

      MixPanel.track("Get Loan", {
        distinct_id: `${userInfo?.email}`,
        "loan amount": `${loanData.loan_amount}`,
        "loan tenor": `${loanData.loan_tenure}`,
        "product name": `${loanData.product_name}`,
        "loan status": `Added new Direct Debit account`,
        step: "LoanRequestAddNewDirectDebit",
      });

      setCurrentStep(5);
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (LoanRequestAddNewDirectDebit.tsx)",
        },
      });

      MixPanel.track("Get Loan", {
        distinct_id: `${userInfo?.email}`,
        "loan amount": `${loanData.loan_amount}`,
        "loan tenor": `${loanData.loan_tenure}`,
        "product name": `${loanData.product_name}`,
        "loan status": `Failed: Error adding new direct debit account`,
        step: "LoanRequestAddNewDirectDebit",
      });
      setCurrentStep(11);
    }
  };

  const handlePreviousStep = () => {
    if (existingDirectDebitAccountData?.bank_accounts.length >= 1) {
      setCurrentStep(8);
    } else {
      setCurrentStep(2);
    }

    setError("");
  };

  return (
    <div className={styles.stepEleven_container}>
      {error && (
        <div style={{ width: "100%", paddingTop: "50px" }}>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}
      <img
        src={arrowLeft}
        alt=""
        className={styles.arrow}
        onClick={handlePreviousStep}
      />
      <form
        onSubmit={handleSubmit(handleSubmitDirectAccountDetails)}
        className={styles.stepEleven_form}
      >
        <header className={styles.stepEleven_header}>
          <h3>Direct Debit Account</h3>
          <p>Please add a Direct debit account</p>
        </header>

        <div ref={divRef} className={styles.selectBankContainer}>
          <label className="label-txt">Bank Name</label>
          <input
            type="text"
            name="bankCode"
            ref={register}
            placeholder={
              isLoadingBanks
                ? "loading, please wait"
                : "E.g. Guaranty Trust Bank"
            }
            value={bankDetails?.name}
            onChange={handleInputChange}
            onFocus={() => setShowDropdown(true)}
            className={styles.selectedBankField}
            disabled={isLoadingBanks}
          />
          {showDropdown && (
            <ul className={styles.bankNameDropdown}>
              {filteredBankList?.length > 0
                ? filteredBankList?.map((bank) => (
                    <li
                      key={bank.id}
                      onClick={() => handleSelectBank(bank)}
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      {bank.name}
                    </li>
                  ))
                : bankList?.map((bank) => (
                    <li
                      key={bank.id}
                      onClick={() => handleSelectBank(bank)}
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      {bank.name}
                    </li>
                  ))}
            </ul>
          )}
          <span className={styles.bank_error}>{errors.bankCode?.message}</span>
        </div>

        <CustomInputField
          type="number"
          label="Account Number"
          placeholder="0000000000"
          name="accountNumber"
          errors={errors.accountNumber}
          reference={register}
          maxLength={10}
          onChange={(e: any) => setRecipientAccountNumber(e.target.value)}
        />

        <div className="d-flex align-items-center">
          <img
            src={user}
            className="img-fluid mr-2"
            alt=""
            style={{
              width: "11.48px",
              height: "13.2px",
              objectFit: "contain",
            }}
          />
          <small className="color-mid-purple account__name">
            {isLoadingCustomerName
              ? "Loading...."
              : customerName?.bank_account_name}
          </small>
        </div>
        <div className={styles.submit__btn}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!!error || isLoadingCustomerName || !customerName}
          >
            Submit New Account
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoanRequestAddNewDirectDebit;
