import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const fetchRepaymentSchedule = async ({ loan_ref }: { loan_ref: string }) => {
  const res = await getData(
    `${apiEndpoints.getWebLoanRepaymentSchedule}?loan_ref=${loan_ref}`,
  );

  return res?.data?.periods;
};

export default function useFetchRepaymentSchedule({
  loan_ref,
}: {
  loan_ref: string;
}) {
  return useQuery(
    [{ loan_ref }, "fetchRepaymentSchedule"],
    fetchRepaymentSchedule,
    {
      enabled: false,
    },
  );
}
