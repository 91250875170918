import React, { useEffect } from "react";
import styles from "./BorrowerType.module.scss";
import user from "../../../../assets/web/user.svg";
import briefcase from "../../../../assets/web/briefcase.svg";
import arrowRight from "../../../../assets/web/arrow-right.svg";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

interface BorrowerTypeProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

function BorrowerType({ setCurrentStep }: BorrowerTypeProps) {
  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Onboarding  - (BorrowerType.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleBusinessStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleIndividualStep = () => {
    setCurrentStep((prev) => prev + 2);
  };
  return (
    <div className={styles.userType_container}>
      <header className={styles.userType_header}>
        <h1>Choose a category</h1>
        <p>Select a category to continue</p>
      </header>

      <div className={styles.role}>
        <div className={styles.individual} onClick={handleIndividualStep}>
          <div className={styles.group1}>
            <div className={styles.userContainer}>
              <img src={user} alt="" />
            </div>
            <div className={styles.text}>
              <p>Individual</p>
              <p>Sign up as an individual</p>
            </div>
          </div>
          <img src={arrowRight} alt="" />
        </div>

        <div className={styles.business} onClick={handleBusinessStep}>
          <div className={styles.group2}>
            <div className={styles.businessContainer}>
              <img src={briefcase} alt="" />
            </div>
            <div className={styles.text}>
              <p>Business</p>
              <p>Sign up as an business</p>
            </div>
          </div>
          <img src={arrowRight} alt="" />
        </div>
      </div>
    </div>
  );
}

export default BorrowerType;
