import * as yup from "yup";

// Regular expression for phone number
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const numberRegExp = /[0-9]/;
export const yupValidators = {
  firstName: yup.string().required("Please enter your first name"),
  fullName: yup.string().required("Please enter your full name"),
  country: yup.string().required("Please select your country name"),
  partner: yup.string().required("Please select your partner name"),
  loanProduct: yup.string().required("Please select a product"),
  tenor: yup.string().required("Please select a tenor"),
  firstRepaymentDate: yup.string().required("Please select a first repayment date"),
  numberOfRepayments: yup.string().required("Please enter number of repayments"),
  repaymentDate: yup.string().required("Add a repayment date"),
  lastName: yup.string().required("Please enter your last name"),
  businessName: yup.string().required("Please enter your business name"),
  state: yup.string().required("Please select your state"),
  lga: yup.string().required("Please select your lga"),
  address: yup.string().required("Please enter your address"),
  businessRcNumber: yup
    .string()
    .required("Please enter your business RC number")
    .matches(/^[0-9]+$/, "Must contain only numbers"),
  age: yup
    .number()
    .required("Please enter your age")
    .positive()
    .integer()
    .min(18),
  bvn: yup
    .string()
    .required("Please enter your bvn")
    .matches(numberRegExp, "Invalid bvn"),
  dob: yup.string().required("Please enter your date of birth"),
  gender: yup.string().required("Please enter your gender"),

  directDebitAccount: yup.string().required("Please select direct debit"),
  nationalId: yup
    .string()
    .required("Please enter your national ID")
    .matches(numberRegExp, "Invalid national ID"),
  bankName: yup.string().required("Please select your bank name"),
  accountNumber: yup
    .string()
    .required("Please enter your account number")
    .matches(numberRegExp, "Invalid account number")
    .max(10, "Account number cannot have more than 10 digits"),
  email: yup
    .string()
    .trim()
    .email("This is an invalid email")
    .required("Please enter your email")
    .min(5, "Email must have at least 5 characters")
    .max(256, "Email cannot be longer than 256 characters"),
  password: yup
    .string()
    .required("Please enter your password")
    .min(8, "Password must have at least 8 characters")
    .max(50, "Password cannot be longer than 50 characters")
    .matches(/[^A-Za-z0-9]/, "Password must contain a special character")
    .matches(/[A-Z]/, "Password must contain an uppercase alphabet")
    .matches(/[a-z]/, "Password must contain a lowercase alphabet")
    .matches(/[0-9]/, "Password must contain a number"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords do not match")
    .required("Please confirm your password"),
  phone_number: yup
    .string()
    .required("Please enter your phone number")
    .matches(phoneRegExp, "Invalid phone number")
    .min(7, "Phone number must have at least 7 digits")
    .max(20, "Phone number cannot have more than 20 digits"),
  amount: yup
    .string()
    .required("Please enter an amount")
    .matches(numberRegExp, "Invalid amount")
    .min(1, "Amount must have at least 1 digit")
    .max(10, "Amount cannot have more than 10 digits"),
  otp: yup
    .string()
    .required("Please enter OTP")
    .matches(numberRegExp, "Invalid OTP"),
  pin: yup
    .string()
    .required("Please enter your pin")
    .max(4, "Pin must be four digits")
    .matches(/[0-9]/, "Pin must contain only numbers"),
  confirmPin: yup
    .string()
    .oneOf([yup.ref("pin"), null], "Pins do not match")
    .required("Please confirm your pin"),
  oldPassword: yup
    .string()
    .required("Please enter your old password")
    .min(8, "Password must have at least 8 characters")
    .max(128, "Password cannot be longer than 128 characters")
    .matches(/[^A-Za-z0-9]/, "Password must contain a special character")
    .matches(/[A-Z]/, "Password must contain an uppercase alphabet")
    .matches(/[a-z]/, "Password must contain a lowercase alphabet")
    .matches(/[0-9]/, "Password must contain a number"),
  newPassword: yup
    .string()
    .required("Please enter your new password")
    .min(8, "Password must have at least 8 characters")
    .max(128, "Password cannot be longer than 128 characters")
    .matches(/[^A-Za-z0-9]/, "Password must contain a special character")
    .matches(/[A-Z]/, "Password must contain an uppercase alphabet")
    .matches(/[a-z]/, "Password must contain a lowercase alphabet")
    .matches(/[0-9]/, "Password must contain a number"),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords do not match")
    .required("Please confirm your password"),
  note: yup.string().required("Please enter your message"),
  description: yup.string().required("Please enter your message"),
  cardNumber: yup
    .string()
    .required("Please enter your card number")
    .matches(numberRegExp, "Card number must contain only numbers")
    .min(16, "Invalid card number")
    .max(16, "Card number cannot be more than 16 digits"),
  expiryDate: yup.string().required("Please enter card expiry date"),
  cvv: yup
    .string()
    .required("Please enter your CVV number")
    .matches(numberRegExp, "CVV must contain only numbers")
    .max(3, "CVV number cannot have more than 3 digits"),
  securityQuestion: yup.string().required("Please select a security question"),
  securityAnswer: yup
    .string()
    .required("Please provide an answer to the security question above"),
};
