import React, { useEffect, useState } from "react";
import styles from "./PersonalInformation.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import CustomInputField from "../../../../components/CustomHTMLElements/EmbedCustomInputField";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { Icon } from "@iconify/react";
import useFetchCountries from "../../../../custom-hooks/useFetchCountries";
import { useLocation } from "react-router-dom";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import { Button } from "antd";
import { postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { decrypt } from "../../../../helpers/encryptor";
import cleanPhoneSavedPhoneNo from "../../../../helpers/cleanSavedPhoneNumber";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

enum ModalProfile {
  ModalProfileNavigator = "ModalProfileNavigator",
  ProfileInformation = "ProfileInformation",
  Card = "Card",
  DirectDebitAccountWeb = "DirectDebitAccountWeb",
  BankStatement = "BankStatement",
  Transaction = "Transaction",
  Password = "Password",
}

interface PersonalInformationProps {
  setCurrentSection?: React.Dispatch<React.SetStateAction<ModalProfile>>;
  walletDetails: any;
}

interface CountryCode {
  country_code?: string;
  currency?: string;
  dial_code?: string;
  id?: number;
  is_active?: number;
  name?: string;
}

const personalSchema = yup.object().shape({
  firstName: yupValidators.firstName,
  lastName: yupValidators.lastName,
  email: yupValidators.email,
  bvn: yupValidators.bvn,
  phoneNumber: yupValidators.phone_number,
});

const BusinessSchema = yup.object().shape({
  email: yupValidators.email,
  bvn: yupValidators.bvn,
  phoneNumber: yupValidators.phone_number,
  businessRcNumber: yupValidators.businessRcNumber,
  businessName: yupValidators.businessName,
});

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  bvn: string;
  phoneNumber: string;
  businessRcNumber: string;
  businessName: string;
}

function PersonalInformation({
  setCurrentSection,
  walletDetails,
}: PersonalInformationProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [searchedResults, setSearchedResults] = useState<CountryCode[]>([]);
  const [displayCountry, setDisplayCountry] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>({
    country_code: "ng",
    dial_code: "234",
  });
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      userInfo?.business_registration_number ? BusinessSchema : personalSchema
    ),
  });
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Profile  - (PersonalInformation.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const { data: countryLists } = useFetchCountries();

  useEffect(() => {
    setSelectedCountry({
      country_code: userInfo?.country?.toLowerCase(),
      dial_code: userInfo?.phoneNumberCountryDialCode,
    });
  }, [userInfo?.phoneNumberCountryDialCode, userInfo?.country]);

  const filterPrompts = (searchText: string) => {
    const regex = new RegExp(searchText, "i");

    return countryLists.filter((country: CountryCode) => {
      const name = country.name || "";
      const countryCode = country.country_code || "";

      return regex.test(name) || regex.test(countryCode);
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchTimeout as NodeJS.Timeout);
    setSearchText(e.target.value);

    //debounce method
    setSearchTimeout(
      setTimeout(() => {
        const searchResult = filterPrompts(e.target.value);
        setSearchedResults(searchResult);
      }, 500)
    );
  };

  const handleCountryDisplay = () => {
    setDisplayCountry((prev) => !prev);
  };

  const handleCountrySelection = (country: CountryCode) => {
    setSelectedCountry({
      country_code: country.country_code?.toLowerCase(),
      dial_code: country.dial_code,
    });
    setDisplayCountry(false);
  };

  const handleButtonClick = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleSelection = () => {
    if (setCurrentSection) {
      setCurrentSection(ModalProfile.ModalProfileNavigator);
    }
  };

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    bvn,
    phoneNumber,
  }: FormData) => {
    const reqBody = {
      first_name: firstName,
      phone_number: phoneNumber,
      email: email,
      last_name: lastName,
      country: userInfo?.country,
      phone_no_country_dial_code: userInfo?.phoneNumberCountryDialCode,
      businessRcNumber: userInfo?.business_registration_number,
      businessName: userInfo?.company_name,
    };
    setIsLoading(true);

    try {
      if (
        window.confirm("Are you sure you want to update your phone number?")
      ) {
        await postData(apiEndpoints.updateUser, reqBody);
        handleButtonClick();
      }
    } catch (error) {
      dispatch(
        updateWebSettingsAction({
          errorMsg: error.response?.data?.message ?? error.message,
        })
      );
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Profile  - (PersonalInformation.tsx)",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentStep === 1) {
      dispatch(
        updateWebSettingsAction({
          successMsg: { bodyText: "Update successful" },
        })
      );

      setTimeout(() => {
        window.location.reload();
        dispatch(
          updateWebSettingsAction({
            successMsg: { bodyText: "" },
            errorMsg: "",
          })
        );
      }, 3000);
    }
  }, [currentStep]);

  const renderContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <form
            className={`${styles.form} ${
              location.pathname === `/${userInfo?.aggregator_name}` &&
              styles.alignLeft
            }`}
            onSubmit={handleSubmit(onSubmit)}
          >
            {userInfo?.business_registration_number ? (
              <div className={styles.fullName}>
                <CustomInputField
                  label="Business Name"
                  type="text"
                  placeholder="Enter your business name"
                  reference={register}
                  name="businessName"
                  defaultValue={userInfo?.company_name}
                  readOnly
                  errors={errors.businessName}
                />

                <CustomInputField
                  label="Business Reg Number"
                  type="text"
                  placeholder="Enter your business reg no"
                  name="businessRcNumber"
                  defaultValue={userInfo?.business_registration_number}
                  readOnly
                  reference={register}
                  errors={errors.businessRcNumber}
                />
              </div>
            ) : (
              <div className={styles.fullName}>
                <CustomInputField
                  label="First Name"
                  type="text"
                  placeholder="Enter your first name"
                  reference={register}
                  name="firstName"
                  defaultValue={userInfo?.first_name}
                  readOnly
                  errors={errors.firstName}
                />

                <CustomInputField
                  label="Last Name"
                  type="text"
                  placeholder="Enter your last name"
                  name="lastName"
                  defaultValue={userInfo?.last_name}
                  readOnly
                  reference={register}
                  errors={errors.lastName}
                />
              </div>
            )}
            <CustomInputField
              type="email"
              placeholder="example@mail.com"
              label="Email address"
              name="email"
              defaultValue={userInfo?.email}
              readOnly
              errors={errors.email}
              reference={register}
            />
            <div className={styles.verify_phone}>
              <label htmlFor="phone_number" className={styles.phone}>
                Phone Number
              </label>
              <div className={styles.selected_option}>
                <div className={styles.flag} onClick={handleCountryDisplay}>
                  <Icon
                    icon={`flag:${selectedCountry.country_code}-4x3`}
                    className={styles.iconify}
                  />
                  <span>{selectedCountry.dial_code}</span>
                  {displayCountry ? (
                    <IoIosArrowUp className={styles.arrow} />
                  ) : (
                    <IoIosArrowDown className={styles.arrow} />
                  )}
                </div>
                <input
                  type="tel"
                  name="phoneNumber"
                  defaultValue={cleanPhoneSavedPhoneNo(walletDetails)}
                  ref={register}
                />
              </div>
              {displayCountry && (
                <div className={styles.options}>
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleSearchChange}
                    className={styles.search_box}
                    placeholder="Search Country Name"
                  />
                  <ol>
                    {searchText
                      ? searchedResults?.map((country) => (
                          <li
                            key={country.name}
                            onClick={() => handleCountrySelection(country)}
                          >
                            <div>
                              <Icon
                                icon={`flag:${country.country_code?.toLowerCase()}-4x3`}
                                className={styles.iconify}
                              />
                              <span>{country.name}</span>
                            </div>
                            <span>+{country.dial_code}</span>
                          </li>
                        ))
                      : countryLists?.map((country: CountryCode) => (
                          <li
                            key={country.name}
                            onClick={() => handleCountrySelection(country)}
                          >
                            <div>
                              <Icon
                                icon={`flag:${country.country_code?.toLowerCase()}-4x3`}
                                className={styles.iconify}
                              />
                              <span>{country.name}</span>
                            </div>
                            <span>{country.dial_code}</span>
                          </li>
                        ))}
                  </ol>
                </div>
              )}
              <span className={styles.phoneNumberValidation_error}>
                {errors.phoneNumber?.message}
              </span>
            </div>

            <CustomInputField
              label="BVN"
              type="number"
              placeholder="Enter BVN"
              name="bvn"
              defaultValue={decrypt(userInfo?.bvn)}
              readOnly
              reference={register}
              errors={errors.bvn}
            />

            <div className={styles.submit__btn}>
              <Button type="primary" htmlType="submit" disabled={isLoading}>
                {isLoading ? "Updating profile..." : "Update"}
              </Button>
            </div>
          </form>
        );
      case 1:
        return (
          <div className={styles.stepTwo_container}>
            <div className={styles.verification_success}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="75"
                height="75"
                viewBox="0 0 75 75"
                fill="none"
              >
                <path
                  d="M37.4856 0C58.1739 0 74.9712 16.7973 74.9712 37.4856C74.9712 58.1739 58.1739 74.9712 37.4856 74.9712C16.7973 74.9712 0 58.1739 0 37.4856C0 16.7973 16.7973 0 37.4856 0Z"
                  fill="#404040"
                />
                <path
                  d="M37.4637 3.54529C56.1953 3.54529 71.404 18.7539 71.404 37.4856C71.404 56.2172 56.1953 71.4258 37.4637 71.4258C18.7321 71.4258 3.52344 56.2172 3.52344 37.4856C3.52344 18.7539 18.7321 3.54529 37.4637 3.54529Z"
                  fill="white"
                />
                <path
                  d="M22.1348 38.7302L31.3106 47.6975L52.7905 26.2177"
                  stroke="#00C83C"
                  strokeWidth="3.75377"
                />
              </svg>
              <p>Profile updated successfully</p>
            </div>
          </div>
        );

      default:
        return null;
    }
  };
  return (
    <div
      className={`${styles.personalInfo_container} ${
        location.pathname === `/${userInfo?.aggregator_name}` &&
        styles.alignment
      }`}
    >
      {location.pathname === `/${userInfo?.aggregator_name}` && (
        <img
          src={arrowLeft}
          alt=""
          className={styles.left}
          onClick={handleSelection}
        />
      )}
      <header className={styles.header}>
        <h3>
          {userInfo?.business_registration_number
            ? "Business Information"
            : "Personal Information"}
        </h3>
      </header>

      {renderContent()}
    </div>
  );
}

export default PersonalInformation;
