import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import { IoMdArrowRoundBack } from "react-icons/io";
import { postDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../../../helpers/errorHandler";
import WidgetAddNewCard from "./WidgetAddNewCard";
import NewLoader from "../../../components/NewLoader";
import { SuccessComponent } from "../../../SuccessComponent";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { Alert } from "antd";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";
import { useSelector } from "react-redux";

const LoanRepaymentViaNewCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  let location = useLocation<any>();
  const history = useHistory();
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer
  );
  const { customer_id, aggregator_id, returningUserDetails } = borrowerDetails;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRepaymentViaNewCard.tsx",
      isLoggedIn: true,
    });
  }, []);

  async function handleRepaymentWithCard() {
    setIsLoading(true);
    try {
      const response = await postDataWithKey(
        apiEndpoints.makeRepayment,
        {
          aggregator_id: aggregator_id,
          borrower_id: customer_id,
          borrower_repayment_mode: "Card",
          loan_id: location?.state?.loan_id,
          amount: Number(location?.state?.amount),
        },
        // @ts-ignore
        returningUserDetails?.encryptedKey
      );

      if (response.status) {
        setSuccess(response.message);
        setDone(true);
        setError("");
      }
    } catch (error: any) {
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRepaymentViaNewCard },
      });
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <NewLoader />;
  }
  if (done) {
    return (
      <SuccessComponent
        btnText="Done"
        body={success}
        title="Repayment Successful!"
        handler={() => history.push(ROUTES.widgetLoan)}
        type="success"
      />
    );
  }

  return (
    <div>
      {error && (
        <div style={{ paddingBottom: "10px" }}>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => {
              if (setError) {
                setError("");
              }
            }}
          />
        </div>
      )}
      <div onClick={() => history.goBack()} className="cursor">
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <WidgetAddNewCard
        updatePayStackStatus={() => null}
        handleRepaymentWithCard={handleRepaymentWithCard}
      />
    </div>
  );
};

export default LoanRepaymentViaNewCard;
