import React, { Fragment, useEffect, useState } from "react";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import { useLocation, useHistory } from "react-router-dom";
import styles from "./ResetPassword.module.scss";
import { getData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import mixpanel from "mixpanel-browser";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../redux/actions";
import { MixPanel } from "../../../helpers/mixpanel";

export interface ResetPasswordData {
  email: string;
  stateToken: string;
}

function ResetPassword() {
  const [currentStep, setCurrentStep] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [isValidatingAggregator, setIsValidatingAggregator] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [resetPasswordData, setResetPasswordData] = useState<ResetPasswordData>(
    {
      email: "",
      stateToken: "",
    },
  );

  useEffect(() => {
    MixPanel.track(`Page View: ResetPassword.tsx`, {
      page: "ResetPassword.tsx - (Web)",
    });
  }, []);

  const urlExtension = location.pathname.split("/").filter(Boolean);

  async function validateAggregator(shortName: string) {
    setIsValidatingAggregator(true);

    try {
      const response = await getData(
        `${apiEndpoints.validateAggregator}?shortName=${shortName}`,
      );
      if (!response.status) {
        setIsValidatingAggregator(false);
        history.push(`/${urlExtension[0]}/page-not-found`);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (ResetPassword.tsx)",
        },
      });
      history.push(`/${urlExtension[0]}/page-not-found`);
    } finally {
      setIsValidatingAggregator(false);
    }
  }

  const partner = urlExtension[0];

  useEffect(() => {
    validateAggregator(urlExtension[0]);
  }, [partner]);

  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prev) => prev - 1);
    dispatch(
      updateWebSettingsAction({ errorMsg: "", successMsg: { bodyText: "" } }),
    );
  };

  return (
    <>
      {isValidatingAggregator ? (
        <div className={styles.validate_aggregator}>
          <span className={`spinner-border spinner-border-lg mr-2 `}></span>
          <span className={styles.validating_text}>
            Please wait, we are validating this aggregator...
          </span>
        </div>
      ) : (
        <Fragment>
          {currentStep === 0 && (
            <StepOne
              onNextStep={handleNextStep}
              setResetPasswordData={setResetPasswordData}
              setUserEmail={setUserEmail}
            />
          )}
          {currentStep === 1 && (
            <StepTwo
              onNextStep={handleNextStep}
              onPrevStep={handlePreviousStep}
              setResetPasswordData={setResetPasswordData}
              userEmail={userEmail}
              resetPasswordData={resetPasswordData}
            />
          )}
          {currentStep === 2 && (
            <StepThree
              onPrevStep={handlePreviousStep}
              resetPasswordData={resetPasswordData}
              aggregatorShortName={urlExtension[0]}
            />
          )}
        </Fragment>
      )}
    </>
  );
}

export default ResetPassword;
