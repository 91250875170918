import React from "react";
import styles from "./ActionButtons.module.scss";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import redDot from "../../../../assets/red-dot.svg"

interface ActionButtonProps {
  icon?: string;
  title?: string;
  buttonBg?: string;
  setShowTopUp?: boolean;
  setShowSupport?: boolean;
  setShowWithdraw?: boolean;
  showLoanOptionsFlag?: boolean;
  showQuickRepayment?: boolean;
  pendingLoanOffer?: any;
}

function ActionButton({
  icon,
  title,
  buttonBg,
  setShowTopUp,
  setShowSupport,
  setShowWithdraw,
  showQuickRepayment,
  showLoanOptionsFlag,
  pendingLoanOffer
}: ActionButtonProps) {
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    if (setShowTopUp) {
      dispatch(updateWebSettingsAction({ showTopUp: true }));
    }

    if (setShowSupport) {
      dispatch(updateWebSettingsAction({ showSupport: true }));
    }

    if (setShowWithdraw) {
      dispatch(updateWebSettingsAction({ showWithdraw: true }));
    }

    if (showLoanOptionsFlag) {
      dispatch(updateWebSettingsAction({ showLoanOptions: true }));
    }

    if(showQuickRepayment) {
        dispatch(updateWebSettingsAction({ showQuickRepayment: true }));
    }

    document.body.style.overflow = "hidden";
  };

  return (
    <button className={styles.button} onClick={handleButtonClick}>
      <span
        style={{ backgroundColor: buttonBg }}
        className={styles.icon_container}
      >
        <img src={icon} alt="" />
      </span>
      <span>{title}</span>
      {pendingLoanOffer?.length > 0 && <img src={redDot} alt="" className={styles.red_dot} />}
    </button>
  );
}

export default ActionButton;
