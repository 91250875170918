import React, {
  useEffect,
  Dispatch,
  SetStateAction,
  useState,
  useMemo,
} from "react";
import styles from "./LoanOptions.module.scss";
import arrowRight from "../../../../assets/web/arrow-right.svg";
import history from "../../../../assets/web/money-recieved.svg";
import wallet from "../../../../assets/web/money-add.svg";
import closeIcon from "../../../../assets/web/close-square.svg";
import infoCircle from "../../../../assets/web/info-circle-loan.svg";
import adv from "../../../../assets/web/a.svg";
import { IoIosArrowForward } from "react-icons/io";
import moment from "moment";
import Loader from "../../../../components/Loader/Loader";
import { Alert, Button, message } from "antd";
import { fetchWebLoanTransactions } from "../../../../custom-hooks/useFetchLoanHistory";
import { useQuery, useQueryCache } from "react-query";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  updateLoanDetailsAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import redDot from "../../../../assets/red-dot.svg";
import { useFetchPendingLoanOffer } from "../../../../custom-hooks/useFetchPendingLoanOffer";
import { LoanOfferProps } from "../newLoanOffer/NewLoanOffer";
import { Setting } from "../../../../interfaces/borrower";

export interface Loan {
  id: number;
  amount: string;
  requestDate: string;
  approvalDate: string;
  dueDate: string;
  tenor: string;
  interest: string;
  amountToRepay: string;
  outstanding: string;
  amountRepaid: string;
  referenceNumber: string;
  disbursementStatus: string;
  loanStatus: string;
}

interface LoanOptionsProps {
  userExistsData: any;
  isRefetchingLoanDetails: () => void;
  setPageNumber: Dispatch<SetStateAction<number>>;
  pageNumber: number;
  handleBankStatement: () => any;
  isLoadingBankStatementLinkedStatus: boolean;
  bankStatementLinkedStatus: any;
  setCurrentLoanOptionStep: Dispatch<SetStateAction<number>>;
  currentLoanOptionStep: number;
}

enum Status {
  Active = "active",
  Repaid = "repaid",
  Approved = "approved",
  Rejected = "rejected",
  Overdue = "overdue",
  AwaitingApproval = "awaiting approval",
  SubmittedAndAwaitingApproval = "submitted_and_awaiting_approval",
  WithdrawnByClient = "withdrawnByClient",
}

function LoanOptions({
  isRefetchingLoanDetails,
  setPageNumber,
  pageNumber,
  handleBankStatement,
  isLoadingBankStatementLinkedStatus,
  bankStatementLinkedStatus,
  setCurrentLoanOptionStep,
  currentLoanOptionStep,
}: LoanOptionsProps) {
  const [mostRecentLoanOffer, setMostRecentLoanOffer] =
    useState<LoanOfferProps>();
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer,
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (LoanOptions.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const { data: loanHistory, isLoading: isLoanHistoryLoading } = useQuery(
    [
      { customerId: userInfo?.customerId, page: pageNumber },
      "fetchWebLoanTransactions",
    ],
    fetchWebLoanTransactions,
    {
      refetchOnWindowFocus: true,
    },
  );

  const { data: pendingLoanOffer, isLoading: isLoadingPendingLoanOffer } =
    useFetchPendingLoanOffer({
      customer_id: userInfo?.customerId,
    });

  const webAggregatorState = useSelector(
    (state: ReduxStoreModel) => state.webAggregatorSettingsReducer,
  );

  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );

  const { courseDescription, courseAmount } = borrowerDetails;

  const requireEquityContribution = useMemo(() => {
    const setting = webAggregatorState?.data?.find(
      (settings: Setting) =>
        settings.setting_name === "require_equity_contribution",
    );
    return setting?.setting_value === "1";
  }, [webAggregatorState]);

  const getStatusStyle = (status: string | undefined) => {
    switch (status?.toLowerCase()) {
      case Status.Active:
      case Status.Approved:
      case Status.Repaid:
        return styles.flagActive;
      case Status.AwaitingApproval:
      case Status.SubmittedAndAwaitingApproval:
        return styles.flagPending;
      case Status.Overdue:
      case Status.Rejected:
        return styles.flagRejected;
      default:
        return styles.flagStale;
    }
  };

  useEffect(() => {
    if (pageNumber < loanHistory?.totalPages) {
      const nextPage = pageNumber + 1;
      queryCache.prefetchQuery(
        [
          { customerId: userInfo?.customerId, page: nextPage },
          "fetchWebLoanTransactions",
        ],
        fetchWebLoanTransactions,
      );
    }
  }, [pageNumber, queryCache, userInfo?.customerId, loanHistory?.totalPages]);

  const selectLoan = (id: number) => {
    loanHistory.data?.find((loan: any) =>
      loan?.id === id
        ? dispatch(updateLoanDetailsAction({ loan_ref: loan.loanRef }))
        : "",
    );

    setTimeout(() => {
      isRefetchingLoanDetails();
      dispatch(
        updateWebSettingsAction({
          showLoanDetails: true,
          showLoanOptions: false,
          errorMsg: "",
          successMsg: { bodyText: "" },
        }),
      );
    }, 0);
  };

  const handleLoanRequest = () => {
    if (requireEquityContribution && !courseDescription && !courseAmount) {
      message.error("No course information selected");
    } else if (requireEquityContribution && courseDescription && courseAmount) {
      dispatch(
        updateWebSettingsAction({
          showLoanOptions: false,
        }),
      );
      dispatch(
        updateWebSettingsAction({
          showLoanRequestModal: true,
          errorMsg: "",
          successMsg: { bodyText: "" },
        }),
      );
    } else {
      dispatch(
        updateWebSettingsAction({
          showLoanOptions: false,
        }),
      );
      dispatch(
        updateWebSettingsAction({
          showLoanRequestModal: true,
          errorMsg: "",
          successMsg: { bodyText: "" },
        }),
      );
    }
  };

  const handleViewLoanHistory = () => {
    setCurrentLoanOptionStep(1);

    setPageNumber(1);

    dispatch(
      updateWebSettingsAction({ errorMsg: "", successMsg: { bodyText: "" } }),
    );
  };

  const handleClose = () => {
    document.body.style.overflow = "auto";
    dispatch(
      updateWebSettingsAction({
        showLoanOptions: false,
        errorMsg: "",
        successMsg: { bodyText: "" },
      }),
    );

    setCurrentLoanOptionStep(0);
  };

  const handlePreviousPage = () => {
    setCurrentLoanOptionStep(0);
  };

  useEffect(() => {
    if (!isLoadingPendingLoanOffer && pendingLoanOffer) {
      const recentLoanOffer = pendingLoanOffer[pendingLoanOffer?.length - 1];
      setMostRecentLoanOffer(recentLoanOffer);
    }
  }, [pendingLoanOffer, isLoadingPendingLoanOffer]);

  const renderContent = () => {
    switch (currentLoanOptionStep) {
      case 0:
        return (
          <div className={styles.stepOne_container}>
            <header className={styles.stepOne_header}>
              <h3>Get Loan</h3>
            </header>
            <div className={styles.stepOne_options}>
              {webStateData?.isSavingProvider ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Loader />
                </div>
              ) : !isLoadingBankStatementLinkedStatus &&
                !bankStatementLinkedStatus.status ? (
                <div className={styles.linkBankstatement}>
                  <img src={infoCircle} alt="" />
                  <span>
                    Link your bank statement to improve your credit eligibility
                  </span>
                  <button onClick={handleBankStatement}>Proceed</button>
                </div>
              ) : null}

              <button
                className={styles.new_request}
                onClick={handleLoanRequest}
              >
                <span className={styles.newLoan_container}>
                  <span className={styles.icon_container}>
                    <img src={wallet} alt="" />
                  </span>
                  <span className={styles.text}>
                    <span>Request for a new loan</span>
                    <span>Get a new loan offer</span>
                  </span>
                </span>
                <img src={arrowRight} alt="" />
              </button>

              <button className={styles.offers} onClick={handleViewLoanHistory}>
                <span className={styles.newLoan_container}>
                  <span className={styles.icon_container}>
                    <img src={history} alt="" />
                  </span>
                  <span className={styles.text}>
                    {pendingLoanOffer?.length > 0 ? (
                      <img src={redDot} alt="" className={styles.red_dot} />
                    ) : null}
                    <span className={styles.view_history_text}>
                      View your loan history
                    </span>
                    <span>See your loan offers</span>
                  </span>
                </span>
                <img src={arrowRight} alt="" />
              </button>
            </div>
          </div>
        );
      case 1:
        return (
          <div className={styles.stepTwo_container}>
            <img
              src={arrowLeft}
              alt=""
              className={styles.arrow_left}
              onClick={handlePreviousPage}
            />
            <header className={styles.stepTwo_header}>
              <h3>Loan History</h3>
            </header>

            {isLoanHistoryLoading ? (
              <div className={styles.isLoading_container}>
                <Loader message="Loading loan data..." />
              </div>
            ) : (
              <div className={styles.history_container}>
                {loanHistory?.data?.length === 0 ? (
                  <h4 className="text-center text-muted">
                    You currently have no loan records
                  </h4>
                ) : (
                  <>
                    {loanHistory?.data?.map((loan: any) => (
                      <div
                        key={loan?.id}
                        className={styles.loan_card}
                        onClick={() => selectLoan(loan.id)}
                      >
                        <div className={styles.logoAndAmount}>
                          <span className={styles.icon_container}>
                            <img src={adv} alt="" />
                          </span>
                          <div className={styles.amountAndDate}>
                            <span className={styles.amount}>
                              ₦{loan?.loanAmount.toLocaleString()}
                            </span>
                            {pendingLoanOffer?.length > 0 &&
                            mostRecentLoanOffer?.requested_loan_id ===
                              loan?.id ? (
                              <img
                                src={redDot}
                                alt=""
                                className={styles.red_dot}
                              />
                            ) : null}
                            {loan?.status.toLowerCase() ===
                              Status.SubmittedAndAwaitingApproval ||
                            loan?.status.toLowerCase() === Status.Rejected ||
                            loan?.status.toLowerCase() ===
                              Status.WithdrawnByClient ? (
                              ""
                            ) : (
                              <span className={styles.date}>
                                {moment(loan?.loanMaturityDate).format(
                                  "D/MM/YYYY",
                                )}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className={styles.flagNrefNo}>
                          <span className={getStatusStyle(loan?.status)}>
                            {loan?.status.toLowerCase() ===
                            Status.SubmittedAndAwaitingApproval
                              ? "Awaiting approval"
                              : loan.status}
                          </span>
                          <span className={styles.loanRef}>{loan.loanRef}</span>
                        </div>
                        <IoIosArrowForward className={styles.arrow} />
                      </div>
                    ))}

                    <div className={styles.pagination_controls}>
                      <Button
                        onClick={() => setPageNumber((prev) => prev - 1)}
                        disabled={pageNumber === 1}
                      >
                        Prev
                      </Button>
                      <button>{pageNumber}</button>
                      <Button
                        disabled={pageNumber >= loanHistory?.totalPages}
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_container}>
        {webStateData.errorMsg && (
          <div className={styles.alert}>
            <Alert
              message={webStateData.errorMsg}
              type="error"
              showIcon
              closable
              onClose={() =>
                dispatch(updateWebSettingsAction({ errorMsg: "" }))
              }
            />
          </div>
        )}
        {webStateData.successMsg.bodyText && (
          <div className={styles.alert}>
            <Alert
              message={webStateData.successMsg.bodyText}
              type="success"
              showIcon
              closable
              onClose={() =>
                dispatch(
                  updateWebSettingsAction({ successMsg: { bodyText: "" } }),
                )
              }
            />
          </div>
        )}
        <div className={styles.modal_steps_wrapper}>
          {renderContent()}

          <img
            src={closeIcon}
            alt=""
            className={styles.cancel_button}
            onClick={handleClose}
          />
        </div>
      </div>
    </>
  );
}

export default LoanOptions;
