import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import React, { useEffect } from 'react'
import styles from "./TransactionDetail.module.scss"
import closeIcon from "../../../../assets/web/close-square.svg"
import { WalletTransactions } from '../../../../interfaces/transactions'
import { appInsights } from '../../../../components/AppInsight/AppInsight'

interface TransactionDetailsProps {
  setTransactionDetail: React.Dispatch<
    React.SetStateAction<WalletTransactions[] | null>
  >;
  transactionDetail: WalletTransactions[] | null;
}

function TransactionDetails({
  setTransactionDetail,
  transactionDetail,
}: TransactionDetailsProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (TransactionDetails.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleClose = () => {
    setTransactionDetail(null);
    document.body.style.overflow = "auto";

    dispatch(updateWebSettingsAction({ showTransactionDetail: false }));
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_container}>
        <div className={styles.modal_wrapper}>
          <header className={styles.header}>
            <h4>Transaction details</h4>
            <img
              src={closeIcon}
              alt=""
              className={styles.cancel_button}
              onClick={handleClose}
            />
          </header>

          {transactionDetail?.map((details) => (
            <div className={styles.form} key={details.transactionId}>
              <p>
                <strong>Amount:</strong> ₦{details?.amount?.toLocaleString()}
              </p>
              <p>
                <strong>Recipient account number:</strong>{" "}
                {details.recipientAccountNumber}
              </p>
              <p>
                <strong>Remark:</strong> {details.transactionRemarks}
              </p>
              <p>
                <strong>Transaction status:</strong> {details.transactionStatus}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default TransactionDetails;
