import React, { useEffect } from "react";
import styles from "./Pin.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../../helpers/yupValidators";
import CustomInputField from "../../../../../components/CustomHTMLElements/EmbedCustomInputField";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { postData } from "../../../../../apis/apiMethods";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../interfaces/redux-interface";
import { updateWebSettingsAction } from "../../../../../redux/actions";
import { Alert } from "antd";
import { useLocation } from "react-router-dom";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  pin: yupValidators.pin,
  confirmPin: yupValidators.confirmPin,
});

interface PinProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

function Pin({ setCurrentStep }: PinProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const location = useLocation()
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer
  );

  const urlExtension = location.pathname.split("/").filter(Boolean);


  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (TransactionPin.tsx)",
      isLoggedIn: true,
    });
  }, []);
  
  const onSubmit = async ({ pin }: { pin: string }) => {
    try {
      setCurrentStep(2);
      const reqBody = {
        customer_id: parseInt(userInfo?.customerId),
        pin,
      };

      await postData(apiEndpoints.customerSetPin, reqBody);
      setCurrentStep(3);
      dispatch(
        updateWebSettingsAction({
          errorMsg: "",
        })
      );
    } catch (error) {
      setCurrentStep(1);

      setTimeout(() => {
        dispatch(
          updateWebSettingsAction({
            errorMsg: error.response?.data?.message ?? error.message,
          })
        );
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Web - (TransactionPin.tsx)",
          },
        });
      }, 0);
    }
  };

  return (
    <div className={styles.stepTwo_container}>
      {webStateData.errorMsg && urlExtension[1] !== "profile" && (
        <div
          style={{ width: "100%", paddingTop: "50px", paddingBottom: "20px" }}
        >
          <Alert
            message={webStateData.errorMsg}
            type="error"
            showIcon
            closable
            onClose={() => dispatch(updateWebSettingsAction({ errorMsg: "" }))}
          />
        </div>
      )}
      <header className={styles.stepTwo_header}>
        <h3>Set PIN</h3>
        <p>Create your four digit transaction pin</p>
      </header>

      <form className={styles.stepTwo_form} onSubmit={handleSubmit(onSubmit)}>
        <CustomInputField
          label="New PIN"
          type="password"
          reference={register}
          placeholder="****"
          hasActionButton={true}
          name="pin"
          errors={errors.pin}
          maxLength={4}
        />

        <CustomInputField
          reference={register}
          type="password"
          placeholder="****"
          label="Confirm PIN"
          hasActionButton={true}
          name="confirmPin"
          errors={errors.confirmPin}
          maxLength={4}
        />

        <button type="submit" className={styles.submit_button}>
          Save
        </button>
      </form>
    </div>
  );
}

export default Pin;
