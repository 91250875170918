import { apiEndpoints } from "../apis/apiEndpoints";
import { getData, getDataWithKey } from "../apis/apiMethods";
import { useQuery } from "react-query";

const fetchLoanDetails = async ({ loanRef }: { loanRef: string }) => {
  const res = await getData(`${apiEndpoints.loanDetails}?loanRef=${loanRef}`);

  return res.data.loan_details;
};

export function useFetchLoanTransactionDetails({
  loanRef,
}: {
  loanRef: string;
}) {
  return useQuery([{ loanRef }, "fetchLoanDetails"], fetchLoanDetails, {
    enabled: false,
  });
}

const fetchWidgetLoanDetails = async ({
  loanRef,
  aggregator_id,
}: {
  loanRef: string;
  aggregator_id: number;
}) => {
  const res = await getDataWithKey(
    `${apiEndpoints.getWidgetLoanDetails}?loanRef=${loanRef}&aggregator_id=${aggregator_id}`
  );

  return res.data?.loan_details;
};

export function useFetchWidgetLoanTransactionDetails({
  loanRef,
  aggregator_id,
}: {
  loanRef: string;
  aggregator_id: number;
}) {
  return useQuery(
    [{ loanRef, aggregator_id }, "fetchWidgetLoanDetails"],
    fetchWidgetLoanDetails,
    {
      enabled: true,
    }
  );
}
