import React, { useRef, useState } from "react";
import { PaystackConsumer } from "react-paystack";
import { postDotNetDataWithKey } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { paystackConfig } from "../../../../helpers/thirdPartyConfig";
import { errorHandler, errorToast } from "../../../../helpers/errorHandler";
import { appInsights } from "../../../AppInsight/AppInsight";
import { useSelector } from "react-redux";

const AddCard = ({
  email,
  updatePayStackStatus,
  fetchWallet,
  fetchAddedCards,
}) => {
  const borrowerDetails = useSelector((store) => store.borrowerDetailsReducer);
  const { customer_id, aggregator_id, returningUserDetails } = borrowerDetails;
  const [isLoading, setIsLoading] = useState(false);
  const paystackRef = useRef();
  const openPaystackWidget = () => {
    paystackRef?.current?.click();
  };

  const onPaystackSuccess = async (data) => {
    setIsLoading(true);
    try {
      await postDotNetDataWithKey(
        apiEndpoints.addCard,
        {
          aggregator_id: aggregator_id,
          borrower_id: customer_id,
          paystack_reference: data?.reference,
          paystack_transaction: data?.trans,
        },
        returningUserDetails?.encryptedKey
      );
      fetchWallet();
      fetchAddedCards();
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: AddCard },
      });
    }
    setIsLoading(false);
  };

  const onPaystackClose = () => {
    setIsLoading(false);
    // window.location.reload();
  };

  const paystackComponentProps = {
    ...paystackConfig({
      email: email,
      amount: 5000, // 50 naira is 5000 kobo
    }),
    onSuccess: (reference) => {
      onPaystackSuccess(reference);
    },
    onClose: onPaystackClose,
  };
  return (
    <div>
      <button
        disabled={isLoading}
        className="btn btn-primary"
        onClick={() => {
          updatePayStackStatus();
          openPaystackWidget();
        }}
      >
        {isLoading ? (
          <span className="spinner-border text-white" />
        ) : (
          "Add Card"
        )}
      </button>

      <PaystackConsumer {...paystackComponentProps}>
        {({ initializePayment }) => (
          <button
            ref={paystackRef}
            onClick={() => {
              setIsLoading(true);
              initializePayment(onPaystackSuccess, onPaystackClose);
            }}
            className="d-none"
          />
        )}
      </PaystackConsumer>
    </div>
  );
};

export default AddCard;
