import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./ResidentialInformation.module.scss";
import { Alert, Button } from "antd";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import arrowDown from "../../../../assets/web/arrow-down.svg";
import arrowUp from "../../../../assets/web/arrow-up-1.svg";
import { nigerianStateData } from "../../../../helpers/nigerianStatesAndLga";
import { getData, postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import { useLocation } from "react-router-dom";
import { encrypt } from "../../../../helpers/encryptor";
import { MixPanel } from "../../../../helpers/mixpanel";

const schema = yup.object().shape({
  state: yupValidators["state"],
  lga: yupValidators["lga"],
  address: yupValidators["address"],
});

interface FormData {
  state: string;
  lga: string;
  address: string;
}

interface ResidentialInformationProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  isOnboarding?: boolean;
  isLoanRequest?: boolean;
  isWithdrawal?: boolean;
}

function ResidentialInformation({
  setCurrentStep,
  error,
  setError,
  isOnboarding,
  isLoanRequest,
  isWithdrawal,
}: ResidentialInformationProps) {
  const [showStateDropdown, setShowStateDropdown] = useState<boolean>(false);
  const [showLgaDropdown, setShowLgaDropdown] = useState<boolean>(false);
  const [selectedStateValue, setSelectedStateValue] = useState("");
  const [filteredStateValues, setFilteredStateValues] = useState<any>([]);
  const [localGovtList, setLocalGovtList] = useState<any>([]);
  const [selectedLocalGovtValue, setSelectedLgaValue] = useState("");
  const [filteredLgaValues, setFilteredLgaValues] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);

  const token = localStorage.getItem("token");
  const location = useLocation();

  const borrowerData = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );

  const loanData = useSelector(
    (state: ReduxStoreModel) => state.loanDetailsReducer,
  );

  const stateDivRef = useRef<HTMLDivElement>(null);

  const lgaDivRef = useRef<HTMLDivElement>(null);

  const urlExtension = location.pathname.split("/").filter(Boolean);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  function prevStepNavigationHandler() {
    setCurrentStep(8);
  }

  function selectStateHandler(name: string) {
    setSelectedStateValue(name);

    setShowStateDropdown(false);
  }

  function handleCloseStateList(e: any) {
    if (stateDivRef.current && !stateDivRef.current.contains(e.target)) {
      setShowStateDropdown(false);
    }
  }

  function handleCloseLgaList(e: any) {
    if (lgaDivRef.current && !lgaDivRef.current.contains(e.target)) {
      setShowLgaDropdown(false);
    }
  }

  function handleStateValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setSelectedStateValue(value);
    if (value) {
      setError("");
    } else {
      setError("Please select a state");
    }
    const filterValue = nigerianStateData.filter((state) =>
      state.name.toLowerCase().includes(value.toLowerCase()),
    );

    setFilteredStateValues(filterValue);

    setShowStateDropdown(true);
  }

  useEffect(() => {
    const result = nigerianStateData.find(
      (state) => state.name === selectedStateValue,
    );

    setLocalGovtList(result);
  }, [selectedStateValue]);

  function handleLgaValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setSelectedLgaValue(value);

    const filterLgaValue = localGovtList?.localGovernments.filter(
      (lga: string) => lga.toLowerCase().includes(value.toLowerCase()),
    );

    setFilteredLgaValues(filterLgaValue);

    setShowLgaDropdown(true);
  }

  function selectLgaHandler(lga: string) {
    setSelectedLgaValue(lga);

    setShowLgaDropdown(false);
  }

  useEffect(() => {
    if (isOnboarding) {
      MixPanel.track(`Page View: OnboardingResidentialInformation.tsx`, {
        page: "OnboardingResidentialInformation.tsx - (Web Onboarding)",
      });
    } else if (isLoanRequest) {
      MixPanel.track(`Page View: LoanRequestResidentialInformation.tsx`, {
        page: "LoanRequestResidentialInformation.tsx - (Web)",
      });
    } else {
      MixPanel.track(`Page View: ResidentialInformation.tsx`, {
        page: "ResidentialInformation.tsx - (Web)",
      });
    }

    window.addEventListener("click", handleCloseStateList);
    window.addEventListener("click", handleCloseLgaList);

    return () => {
      window.removeEventListener("click", handleCloseStateList);
      window.removeEventListener("click", handleCloseLgaList);
    };
  }, []);

  async function onSubmit({ state, lga, address }: FormData) {
    let successTimeout: NodeJS.Timeout;
    setError("");

    setIsLoading(true);

    try {
      if (userInfo?.customerId && token) {
        const response = await postData(
          apiEndpoints.createResidentialInformation,
          {
            state,
            lga,
            street_address: address,
            customer_id: userInfo?.customerId,
          },
        );

        if (response.status && isLoanRequest) {
          setCurrentStep(3);
        } else if (response.status && isWithdrawal) {
          setCurrentStep(1);
        }
      } else {
        const userExistResponse = await getData(
          `${apiEndpoints.checkCustomerExistsWeb}?identity_number=${borrowerData?.bvn_number}&phone_number=${borrowerData?.borrower_phone}&aggregator_id=${borrowerData?.aggregator_id}&email=${borrowerData?.email}&business_registration_number=${borrowerData?.business_registration_number}`,
        );

        const loginResponse = await postData(apiEndpoints.login, {
          username: borrowerData?.email,
          password: borrowerData?.password,
        });

        if (
          userExistResponse?.message?.toLowerCase() === "match found" &&
          loginResponse.status
        ) {
          const ssoAuthToken = loginResponse?.sso_auth_token;
          const encryptedToken = encrypt(ssoAuthToken);
          localStorage.setItem("token", encryptedToken);

          const response = await postData(
            apiEndpoints.createResidentialInformation,
            {
              state,
              lga,
              street_address: address,
              customer_id: userExistResponse.data?.customer_id,
            },
          );

          if (response.status && isOnboarding) {
            setCurrentStep(7);
          }
        }
      }
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);

      if (isOnboarding) {
        MixPanel.track("Sign Up", {
          "date created": new Date().toLocaleString(),
          distinct_id: `${borrowerData.email}`,
          URL: window.location.href,
          product: "Embed Web",
          status: `Failed: ${error.response?.data?.message ?? error.message}`,
          step: "ResidentialInformation.tsx",
        });
      }

      if (isLoanRequest) {
        MixPanel.track("Get Loan", {
          distinct_id: `${userInfo?.email}`,
          "loan amount": `${loanData.loan_amount}`,
          "loan tenor": `${loanData.loan_tenure}`,
          "product name": `${loanData.product_name}`,
          "loan status": `Failed: Error adding residential information`,
          step: "LoanRequestResidentialInformation",
        });
      }
    } finally {
      setIsLoading(false);
    }

    return () => {
      clearTimeout(successTimeout);
    };
  }

  return (
    <>
      {error && (
        <div
          style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}
        >
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}

      <div className={styles.residential_information_container}>
        {urlExtension[1] !== "sign-up" && (
          <img
            src={arrowLeft}
            alt=""
            className={styles.arrow}
            onClick={prevStepNavigationHandler}
          />
        )}
        <header className={styles.residential_information_header}>
          <h3>Residential Information</h3>
          <p>Kindly provide us with your residential information</p>
        </header>

        <form
          className={styles.residential_information_form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div ref={stateDivRef} className={styles.state_input_outer_container}>
            <div className={styles.state_input_label_container}>
              <label className="">State</label>
              <input
                type="text"
                name="state"
                ref={register}
                placeholder="Select state"
                value={selectedStateValue}
                onChange={handleStateValueChange}
                onFocus={() => setShowStateDropdown(true)}
                className={styles.input_field}
              />
              {!showStateDropdown ? (
                <img
                  src={arrowDown}
                  alt=""
                  className={styles.input_pointer}
                  onClick={() => setShowStateDropdown(true)}
                />
              ) : (
                <img
                  src={arrowUp}
                  alt=""
                  className={styles.input_pointer}
                  onClick={() => setShowStateDropdown(false)}
                />
              )}
              <span className={styles.alert}>{errors.state?.message}</span>
            </div>

            {showStateDropdown && (
              <ul className={styles.stateNameDropdown}>
                {filteredStateValues.length > 0
                  ? filteredStateValues?.map((state: any) => (
                      <li
                        key={state.name}
                        onClick={() => selectStateHandler(state.name)}
                        style={{ padding: "8px", cursor: "pointer" }}
                      >
                        {state.name}
                      </li>
                    ))
                  : nigerianStateData?.map((state) => (
                      <li
                        key={state.name}
                        onClick={() => selectStateHandler(state.name)}
                        style={{ padding: "8px", cursor: "pointer" }}
                      >
                        {state.name}
                      </li>
                    ))}
              </ul>
            )}
          </div>

          <div
            ref={lgaDivRef}
            className={styles.local_govt_input_outer_container}
          >
            <div className={styles.local_govt_input_label_container}>
              <label className="">Local Government Area</label>
              <input
                type="text"
                name="lga"
                ref={register}
                placeholder="Select local government area"
                value={selectedLocalGovtValue}
                onChange={handleLgaValueChange}
                onFocus={() => setShowLgaDropdown(true)}
                className={styles.input_field}
              />
              {!showLgaDropdown ? (
                <img
                  src={arrowDown}
                  alt=""
                  className={styles.input_pointer}
                  onClick={() => setShowLgaDropdown(true)}
                />
              ) : (
                <img
                  src={arrowUp}
                  alt=""
                  className={styles.input_pointer}
                  onClick={() => setShowLgaDropdown(false)}
                />
              )}
              <span className={styles.alert}>{errors.lga?.message}</span>
            </div>

            {showLgaDropdown && (
              <ul className={styles.localGovtNameDropdown}>
                {filteredLgaValues?.length > 0
                  ? filteredLgaValues?.map((lga: string) => (
                      <li
                        key={lga}
                        onClick={() => selectLgaHandler(lga)}
                        style={{ padding: "8px", cursor: "pointer" }}
                      >
                        {lga}
                      </li>
                    ))
                  : localGovtList?.localGovernments?.map((lga: string) => (
                      <li
                        key={lga}
                        onClick={() => selectLgaHandler(lga)}
                        style={{ padding: "8px", cursor: "pointer" }}
                      >
                        {lga}
                      </li>
                    ))}
              </ul>
            )}
          </div>

          <div className={styles.input_container}>
            <div className={styles.input_label_container}>
              <label>Street Address</label>
              <input
                type="text"
                name="address"
                ref={register}
                placeholder="Enter your street address"
                className={`${styles.input_field}`}
                // value={loanInputData.amount}
                // onChange={(e) => handleChange(e, "amount")}
              />
            </div>

            <span className={styles.alert}>{errors.address?.message}</span>
          </div>

          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            Submit
          </Button>
        </form>
      </div>
    </>
  );
}

export default ResidentialInformation;
